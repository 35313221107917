import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { getAllEmailSetup } from "../../store/actions/emailSetups";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";

function DynamicEmailForm({
  id,
  pageKey,
  page,
  submit,
  forAdmin,
  disabled,
  handleClose,
  actionStatus,
  handlePreviewEmail,
}) {
  const dispatch = useDispatch();
  const [emailSetUps, setEmailSetUps] = useState([]);
  const [email, setEmail] = useState("");
  const [passengerEmail, setPassengerEmail] = useState("");
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    setError,
    formState: { errors, isSubmitted },
    clearErrors,
  } = useForm();

  useEffect(() => {
    if (setValue && forAdmin) {
      setValue("bcc", "allcretetours@gmail.com");
    }
  }, [setValue]);

  useEffect(() => {
    if (forAdmin) {
      dispatch(
        getAllEmailSetup(page, pageKey, id, (err, data) => {
          if (data?.status == 200) {
            setEmailSetUps(data?.result);
            if (data?.email) {
              setEmail(data?.email);
            }
            if (data?.passengerEmail) {
              setPassengerEmail(data?.passengerEmail);
              setValue("email", data?.passengerEmail);
            }
            fillEmailSetupForm(
              data?.result.find(
                (i) => i?.email_setup_name == "Voucher Supplier"
              )
            );
          }
          if (err) {
            console.log(err);
          }
        })
      );
    }
  }, []);

  const fillEmailSetupForm = (ev) => {
    if (ev) {
      setValue("id", ev.id);
      setValue("email_setup_id", ev.id);
      setValue("email_body", ev.email_body);
      setValue("email_setup_name", ev.email_setup_name);
      setValue("subject", ev.email_subject);
      setValue("email_setup_preview_link", ev.email_setup_preview_link || "");
    } else {
      setValue("id", "");
      setValue("email_setup_id", "");
      setValue("email_body", "");
      setValue("bcc", "allcretetours@gmail.com");
      setValue("cc", "");
      setValue("email_setup_name", "");
      setValue("subject", "");
      setValue("email_setup_preview_link", "");
    }
  };

  const onSubmit = (data) => {
    submit(data);
  };

  const getCustomNotRequiredStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;
    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#198754" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];

    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };
  return (
    <>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header
          className="modal_header"
          closeButton
          style={
            forAdmin
              ? { padding: "15px" }
              : { background: "#f88f35", padding: "15px" }
          }
        >
          <Modal.Title className="modal_title">
            {" "}
            {forAdmin ? "Send Email" : "Send Via Email"}
          </Modal.Title>
        </Modal.Header>
        <div className="form-block">
          <Modal.Body>
            <div className="modal_content">
              <div className="email-modal-form">
                {forAdmin ? (
                  <Row className="mb-2">
                    <Form.Group>
                      <Form.Label>Email Template</Form.Label>
                      <Select
                        id={"email_template"}
                        value={
                          Array.isArray(emailSetUps)
                            ? emailSetUps.find((i) => i?.id === watch("id"))
                            : {}
                        }
                        isClearable={true}
                        styles={getCustomNotRequiredStyles("email_setup_name")}
                        name="email_setup_name"
                        options={emailSetUps}
                        getOptionValue={(i) => i.email_body}
                        getOptionLabel={(i) => i.email_setup_name}
                        onChange={(ev) => fillEmailSetupForm(ev)}
                      />
                    </Form.Group>
                  </Row>
                ) : null}
                {forAdmin ? (
                  <Row>
                    {page === "Tour" ? (
                      <Col sm={6}>
                        <Form.Check
                          className="send_email_radio_button"
                          type="radio"
                          id="emailTypeSupplier"
                          name="emailType"
                          label="Supplier Email"
                          value="supplier"
                          {...register("emailType", {
                            required: true,
                          })}
                          onChange={() => {
                            setValue("email", email);
                          }}
                        />
                      </Col>
                    ) : (
                      page === "Transfer" && (
                        <Col sm={6}>
                          <Form.Check
                            className="send_email_radio_button"
                            type="radio"
                            id="emailTypeDriver"
                            name="emailType"
                            label="Driver Email"
                            value="driver"
                            {...register("emailType", {
                              required: true,
                            })}
                            onChange={() => {
                              setValue("email", email);
                            }}
                          />
                        </Col>
                      )
                    )}
                    <Col sm={6}>
                      <Form.Check
                        className="send_email_radio_button"
                        type="radio"
                        id="emailTypePassenger"
                        name="emailType"
                        label="Passenger Email"
                        value="passenger"
                        defaultChecked={true}
                        {...register("emailType", {
                          required: true,
                        })}
                        onChange={() => {
                          setValue("email", passengerEmail);
                        }}
                      />
                    </Col>
                  </Row>
                ) : null}

                <Row className="mb-2">
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <span style={{ color: "red" }}> *</span>
                    <Form.Control
                      id="email"
                      type="email"
                      name="email"
                      value={watch("email")}
                      {...register("email", {
                        required: "Required Field",
                        pattern: {
                          value:
                            /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}(,\s*[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7})*$/,
                          message: "Invalid Format",
                        },
                      })}
                      isInvalid={isSubmitted && errors.email}
                      isValid={isSubmitted && !errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      name="subject"
                      type="text"
                      {...register("subject")}
                      style={getCustomStylesForInput("subject")}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group>
                    <Form.Label>CC</Form.Label>
                    <Form.Control
                      id="cc"
                      name="cc"
                      type="email"
                      style={getCustomStylesForInput("cc")}
                      {...register("cc", {
                        pattern: {
                          value:
                            /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}(,\s*[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7})*$/,
                          message: "Invalid Format",
                        },
                      })}
                    />
                    {watch("cc") && errors?.cc ? (
                      <span className="invalid_message">
                        {errors?.cc?.message}
                      </span>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group>
                    <Form.Label>BCC</Form.Label>
                    <Form.Control
                      id="bcc"
                      name="bcc"
                      type="email"
                      style={getCustomStylesForInput("bcc")}
                      {...register("bcc", {
                        pattern: {
                          value:
                            /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}(,\s*[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7})*$/,
                          message: "Invalid Format",
                        },
                      })}
                    />
                    {watch("bcc") && errors?.bcc ? (
                      <span className="invalid_message">
                        {errors?.bcc?.message}
                      </span>
                    ) : null}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group>
                    <Form.Label>
                      Body <span style={{ color: "red" }}> *</span>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      rows={5}
                      as="textarea"
                      name="email_body"
                      isInvalid={
                        (!isSubmitted &&
                          errors.email_body?.message &&
                          !watch("email_body")) ||
                        (isSubmitted && !watch("email_body"))
                      }
                      isValid={isSubmitted && watch("email_body")}
                      {...register("email_body", { required: true })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email_body?.message || "Required Field"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <div className={forAdmin ? "previewButtons" : "modalButtons"}>
            {forAdmin && (
              <LoadingButton
                size="small"
                className="login_submit_btn btn btn-primary "
                loadingPosition="center"
                variant="contained"
                loading={actionStatus === "pending"}
                onClick={(ev) => {
                  if (!watch("email_body")) {
                    setError("email_body", {
                      type: "custom",
                      message: "Required Field",
                    });
                  } else {
                    clearErrors("email_body");
                    handlePreviewEmail(watch());
                  }
                }}
              >
                <span>Preview</span>
              </LoadingButton>
            )}
            <LoadingButton
              size="small"
              className="login_submit_btn btn btn-primary "
              loadingPosition="center"
              variant="contained"
              loading={disabled}
              type={"submit"}
              disabled={disabled}
            >
              <span>
                {actionStatus === "pending"
                  ? ""
                  : forAdmin
                  ? "Confirm"
                  : "Submit"}
              </span>
            </LoadingButton>
            <Button
              variant="secondary"
              style={{ marginLeft: "15px" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Form>
    </>
  );
}

export default DynamicEmailForm;
