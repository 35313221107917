import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { userValidation } from "./validationSchema";
import {
  blockPageAction,
  handleUpdateMyProfile,
} from "../../../store/actions/users";
import { LoadingButton } from "@mui/lab";
import { FiEye, FiEyeOff } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import { Riple } from "react-loading-indicators";

function UsersForm({ user, actionStatus, getMyAccountData }) {
  const dispatch = useDispatch();
  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(userValidation),
    defaultValues: { ...user, user_name: user.email } || {},
  });

  const [pass, setPass] = React.useState({
    old: true,
    new: true,
    new_confirm: true,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
    reset,
    watch,
    setError,
    setValue,
  } = methods;

  const handlePassVis = (key) => {
    setPass((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user, reset]);

  // useEffect(() => {
  //   if (isDirty) {
  //     dispatch(blockPageAction(`/my-profile`));
  //   } else {
  //     dispatch(blockPageAction(""));
  //   }
  // }, [isDirty, dispatch]);

  const onEdit = (data) => {
    if (data.password && data.confirm_password && !data.old_password) {
      setError("old_password", { type: "custom", message: "Required Field" });
      return;
    }
    if (
      data.user_name === null ||
      data.user_name === undefined ||
      (!data.user_name && data.email)
    ) {
      data.user_name = data.email;
    }
    dispatch(
      handleUpdateMyProfile(data, (err, response) => {
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (response?.status === 200) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            window.location.reload();
            setValue("old_password", "");
            setValue("password", "");
            setValue("confirm_password", "");
          }
        }
      })
    );
  };

  const handleReset = () => {
    reset(user);
    getMyAccountData();
  };

  return (
    <>
      {actionStatus === "pending" ? (
        <div className="dashboard-empty-div">
          <Riple color="#134074" size="large" text="" textColor="" />
        </div>
      ) : (
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onEdit)} noValidate>
            <Modal.Body>
              <div>
                <h5 style={{ fontSize: 17 }}>Personal Information</h5>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Full Name <span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        minLength={2}
                        maxLength={254}
                        name="full_name"
                        {...register("full_name")}
                        isInvalid={isSubmitted && !!errors.full_name}
                        isValid={isSubmitted && !errors.full_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.full_name?.message || "Required Field"}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Email<span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...register("email")}
                        isInvalid={isSubmitted && !!errors.email}
                        isValid={isSubmitted && !errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>User Name</Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={user?.user_name}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <PhoneInput
                        inputProps={{
                          name: "phone_number",
                        }}
                        buttonStyle={
                          isSubmitted ? { borderColor: "#198754" } : null
                        }
                        inputStyle={
                          isSubmitted ? { borderColor: "#198754" } : null
                        }
                        value={watch("phone_number") || ""}
                        onChange={(value) => {
                          setValue("phone_number", value, {
                            shouldDirty: true,
                          });
                        }}
                      />
                      {errors.phone_number?.message && (
                        <span className="invalid_message">
                          {errors.phone_number?.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div>
                <h5 style={{ fontSize: 17 }}>Change Password</h5>
                <Row>
                  <Col sm={4}>
                    <Form.Group className="mb-3 pass_input">
                      <Form.Label>Current Password</Form.Label>
                      <InputGroup className="input_block">
                        <Form.Control
                          type={pass.old ? "password" : "text"}
                          autoComplete="off"
                          name="old_password"
                          value={watch("old_password") || ""}
                          {...register("old_password")}
                          className={errors?.old_password && "is-invalid"}
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          onClick={() => handlePassVis("old")}
                        >
                          {pass.old ? (
                            <FiEyeOff cursor="pointer" />
                          ) : (
                            <FiEye cursor="pointer" />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <span className="custom-invalid-feedback">
                        {errors.old_password?.message}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3 pass_input">
                      <Form.Label>New Password</Form.Label>
                      <InputGroup className="input_block">
                        <Form.Control
                          type={pass.new ? "password" : "text"}
                          name="password"
                          className={errors?.password && "is-invalid"}
                          autoComplete="off"
                          value={watch("password") || ""}
                          {...register("password")}
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          onClick={() => handlePassVis("new")}
                        >
                          {pass.new ? (
                            <FiEyeOff cursor="pointer" />
                          ) : (
                            <FiEye cursor="pointer" />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <span className="custom-invalid-feedback">
                        {errors.password?.message}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3 pass_input">
                      <Form.Label>Confirm Password</Form.Label>
                      <InputGroup className="input_block">
                        <Form.Control
                          type={pass.new_confirm ? "password" : "text"}
                          name="confirm_password"
                          className={errors?.confirm_password && "is-invalid"}
                          autoComplete="off"
                          value={watch("confirm_password") || ""}
                          {...register("confirm_password")}
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          onClick={() => handlePassVis("new_confirm")}
                        >
                          {pass.new_confirm ? (
                            <FiEyeOff cursor="pointer" />
                          ) : (
                            <FiEye cursor="pointer" />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <span className="custom-invalid-feedback">
                        {errors.confirm_password?.message}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="previewButtonsID">
                <Button
                  variant={!isDirty ? "secondary" : "primary"}
                  onClick={handleReset}
                  disabled={!isDirty}
                >
                  Reset
                </Button>
                <input
                  value="Save"
                  type="submit"
                  disabled={!isDirty}
                  className={`submit_button btn ${
                    !isDirty ? "btn-secondary" : "btn-primary"
                  }`}
                />
                {/*<LoadingButton*/}
                {/*    size="small"*/}
                {/*    className="login_submit_btn btn btn-primary"*/}
                {/*    loadingPosition="center"*/}
                {/*    variant="contained"*/}

                {/*    type="submit"*/}
                {/*   */}
                {/*>*/}
                {/*  <span>{actionStatus === "pending" ? "" : "Save"}</span>*/}
                {/*</LoadingButton>*/}
              </div>
            </Modal.Footer>
          </Form>
        </FormProvider>
      )}
    </>
  );
}

export default UsersForm;
