export const GET_RESERVATIONS_REQUEST = 'GET_RESERVATIONS_REQUEST';
export const GET_RESERVATIONS_SUCCESS = 'GET_RESERVATIONS_SUCCESS';
export const GET_RESERVATIONS_FAIL = 'GET_RESERVATIONS_FAIL';

export function getReservationsAction(searchConfig) {
  return {
    type: GET_RESERVATIONS_REQUEST,
    payload: {
      searchConfig,
    },
  };
}

export const GET_ALL_RESERVATIONS_REQUEST = 'GET_ALL_RESERVATIONS_REQUEST';
export const GET_ALL_RESERVATIONS_SUCCESS = 'GET_ALL_RESERVATIONS_SUCCESS';
export const GET_ALL_RESERVATIONS_FAIL = 'GET_ALL_RESERVATIONS_FAIL';

export function getAllReservationsAction(cb) {
  return {
    type: GET_ALL_RESERVATIONS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const ADD_RESERVATIONS_REQUEST = 'ADD_RESERVATIONS_REQUEST';
export const ADD_RESERVATIONS_SUCCESS = 'ADD_RESERVATIONS_SUCCESS';
export const ADD_RESERVATIONS_FAIL = 'ADD_RESERVATIONS_FAIL';

export function addReservationsAction(formData, cb) {
  return {
    type: ADD_RESERVATIONS_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_RESERVATION_REQUEST = 'DELETE_RESERVATION_REQUEST';
export const DELETE_RESERVATION_SUCCESS = 'DELETE_RESERVATION_SUCCESS';
export const DELETE_RESERVATION_FAIL = 'DELETE_RESERVATION_FAIL';

export function deleteReservationAction(id, cb) {
  return {
    type: DELETE_RESERVATION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const UPDATE_RESERVATION_REQUEST = 'UPDATE_RESERVATION_REQUEST';
export const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
export const UPDATE_RESERVATION_FAIL = 'UPDATE_RESERVATION_FAIL';

export function updateReservationAction(formData, cb) {
  return {
    type: UPDATE_RESERVATION_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_RESERVATION_REQUEST = 'GET_SINGLE_RESERVATION_REQUEST';
export const GET_SINGLE_RESERVATION_SUCCESS = 'GET_SINGLE_RESERVATION_SUCCESS';
export const GET_SINGLE_RESERVATION_FAIL = 'GET_SINGLE_RESERVATION_FAIL';

export function getSingleReservationAction(id, cb) {
  return {
    type: GET_SINGLE_RESERVATION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_RESERVATIONS_REQUEST = 'EXPORT_RESERVATIONS_REQUEST';
export const EXPORT_RESERVATIONS_SUCCESS = 'EXPORT_RESERVATIONS_SUCCESS';
export const EXPORT_RESERVATIONS_FAIL = 'EXPORT_RESERVATIONS_FAIL';

export function exportExcelReservationsAction(searchConfig, cb) {
  return {
    type: EXPORT_RESERVATIONS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const UPDATE_STATUS_RESERVATIONS_REQUEST = 'UPDATE_STATUS_RESERVATIONS_REQUEST';
export const UPDATE_STATUS_RESERVATIONS_SUCCESS = 'UPDATE_STATUS_RESERVATIONS_SUCCESS';
export const UPDATE_STATUS_RESERVATIONS_FAIL = 'UPDATE_STATUS_RESERVATIONS_FAIL';

export function updateStatusReservationsAction(id, cb) {
  return {
    type: UPDATE_STATUS_RESERVATIONS_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const HANDLE_GET_ACCOMMODATIONS_REQUEST = 'HANDLE_GET_ACCOMMODATIONS_REQUEST';
export const HANDLE_GET_ACCOMMODATIONS_SUCCESS = 'HANDLE_GET_ACCOMMODATIONS_SUCCESS';
export const HANDLE_GET_ACCOMMODATIONS_FAIL = 'HANDLE_GET_ACCOMMODATIONS_FAIL';

export function handleGetAccommodations(key, cb) {
  return {
    type: HANDLE_GET_ACCOMMODATIONS_REQUEST,
    payload: {
      key,
      cb,
    },
  };
}


export const HANDLE_GET_ACCOMMODATION_ROOMS_REQUEST = 'HANDLE_GET_ACCOMMODATION_ROOMS_REQUEST';
export const HANDLE_GET_ACCOMMODATION_ROOMS_SUCCESS = 'HANDLE_GET_ACCOMMODATION_ROOMS_SUCCESS';
export const HANDLE_GET_ACCOMMODATION_ROOMS_FAIL = 'HANDLE_GET_ACCOMMODATION_ROOMS_FAIL';

export function handleGetAccommodationRooms(key, id, cb) {
  return {
    type: HANDLE_GET_ACCOMMODATION_ROOMS_REQUEST,
    payload: {
      key,
      id,
      cb,
    },
  };
}

export const HANDLE_GET_ROOM_RESERVATIONS_REQUEST = 'HANDLE_GET_ROOM_RESERVATIONS_REQUEST';
export const HANDLE_GET_ROOM_RESERVATIONS_SUCCESS = 'HANDLE_GET_ROOM_RESERVATIONS_SUCCESS';
export const HANDLE_GET_ROOM_RESERVATIONS_FAIL = 'HANDLE_GET_ROOM_RESERVATIONS_FAIL';

export function handleGetRoomReservations(key, id, cb) {
  return {
    type: HANDLE_GET_ROOM_RESERVATIONS_REQUEST,
    payload: {
      key,
      id,
      cb,
    },
  };
}