import * as Yup from "yup";

export const supplierValidation = Yup.object().shape({
  full_name: Yup.string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)*$/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .required("Required Field"),
  email: Yup.string()
    .required("Required Field")
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .email("Invalid Format"),
  phone_number: Yup.string()
    .required("Required Field")
    .matches(/^\+?[()\d -]{6,25}/, {
      message: "Invalid format",
      excludeEmptyString: true,
    }),
  user_name: Yup.string()
    .matches(/^[\S\s]*$/, "Invalid Format")
    .when("add_user", {
      is: true,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),
  add_user: Yup.boolean(),
});

export const driverValidation = Yup.object().shape({
  add_user: Yup.boolean(),
  full_name: Yup.string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)*$/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .required("Required Field"),
  passport_details: Yup.string()
    .matches(/^(?!\s*$).+/, {
      message: "Only spaces are not allowed",
      excludeEmptyString: true,
    })
    .required("Required Field"),
  driving_license_number: Yup.string()
    .matches(/^(?!\s*$).+/, {
      message: "Only spaces are not allowed",
      excludeEmptyString: true,
    })
    .required("Required Field"),
  vehicle_type: Yup.string()
    .matches(/^(?!\s*$).+/, {
      message: "Only spaces are not allowed",
      excludeEmptyString: true,
    })
    .required("Required Field"),
  registration_number: Yup.string()
    .matches(/^(?!\s*$).+/, {
      message: "Only spaces are not allowed",
      excludeEmptyString: true,
    })
    .required("Required Field"),
  user_name: Yup.string()
    .matches(/^[\S\s]*$/, "Invalid Format")
    .when("add_user", {
      is: true,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),
  email: Yup.string()
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .email("Invalid Format")
    .nullable()
    .when("add_user", {
      is: true,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export const sales_agent_validation = Yup.object().shape({
  full_name: Yup.string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)*$/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .required("Required Field"),
  email: Yup.string()
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .email("Invalid Format")
    .nullable()
    .when("add_user", {
      is: true,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),
  phone_number: Yup.string()
    .matches(/^\+?[()\d -]{6,25}/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .nullable(),
  user_name: Yup.string()
    .matches(/^[\S\s]*$/, "Invalid Format")
    .when("add_user", {
      is: true,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),
  add_user: Yup.boolean(), // user_full_name: Yup.string()
  //   .matches(/^\S*$/, 'Invalid Format')
  //   .when('add_user', {
  //     is: true,
  //     then: schema => schema.required('Required Field'),
  //     otherwise: schema => schema.notRequired(),
  //   }),
});

export const tour_manager_validation = Yup.object().shape({
  full_name: Yup.string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)*$/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .required("Required Field"),
  email: Yup.string()
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .email("Invalid Format")
    .nullable()
    .when("add_user", {
      is: true,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),

  user_name: Yup.string()
    .matches(/^[\S\s]*$/, "Invalid Format")
    .when("add_user", {
      is: true,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),
  phone_number: Yup.string()
    .matches(/^\+?[()\d -]{6,25}/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    })
    .nullable(),
  add_user: Yup.boolean(),
});
