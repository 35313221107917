import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./generatePdf.scss";
import Grid from "@mui/material/Unstable_Grid2";
import AdCard from "../../components/adCard/AdCard";
import { useParams } from "react-router-dom";
import {
  checkVoucherAction,
  downloadVoucherAction,
  sendEmailTourVoucherAction,
  shareMyLocation,
} from "../../store/actions/tourVouchers";
import Stack from "@mui/material/Stack";
import VoucherTemplate from "../../components/pdfTemplates/VoucherTemplate";
import TransferTemplate from "../../components/pdfTemplates/TransferTemplate";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { Modal } from "react-bootstrap";
import DynamicEmailForm from "./DynamicEmailForm";
import { emailValidateForSend } from "../../services/emailValidate";
import SeeLocationModal from "../../components/modals/SeeLocationModal";
import {
  checkTransferVoucherAction,
  downloadTransferVoucherAction,
  sendEmailTransferVoucherAction,
  shareMyLocationForTransfer,
} from "../../store/actions/transferVouchers";
import NotFoundPdf from "./NotFoundPdf";
import FingerLoading from "../../components/loading/FingerLoading";
import { getWebAdvertisementsAction } from "../../store/actions/advertisements";

export default function GeneratePdf() {
  const dispatch = useDispatch();
  const params = useParams();
  const nodeRef = useRef(null);
  const getTransferVoucherStatus = useSelector(
    (store) => store.transferVouchers.status
  );
  const getTourVoucherStatus = useSelector(
    (store) => store.tourVouchers.status
  );
  const transferDownloadStatus = useSelector(
    (store) => store.transferVouchers.downloadStatus
  );
  const voucherDownloadStatus = useSelector(
    (store) => store.tourVouchers.downloadStatus
  );
  const shareLocationStatus = useSelector(
    (store) => store.tourVouchers.shareLocationStatus
  );
  const shareLocationStatusTransfer = useSelector(
    (store) => store.transferVouchers.shareLocationStatus
  );
  const [ads, setAds] = useState([]);
  const [notFound, setNotFound] = useState({ found: true, errorMessage: "" });
  const [pdfData, setPdfData] = useState({});
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [submit, setSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidCC, setIsValidCC] = useState(false);
  const [isValidBCC, setIsValidBCC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDriversLocation, setShowDriversLocation] = useState(false);
  useEffect(() => {
    getAdvertisementList();
    if (params?.page === "transfer_vouchers") {
      checkVoucherTransfer();
    }
    if (params?.page === "tour_vouchers") {
      checkVoucher();
    }
  }, []);

  const handleClose = () => {
    setShowEmailModal(false);
    setFormData({});
    setIsValidEmail(false);
    setIsValidCC(false);
    setIsValidBCC(false);
    setValidated(false);
    setSubmit(false);
    setShowDriversLocation(false);
    setTimeout(() => {
      setLoading(false)
    }, 400);
  };
  const handleChange = (key, value) => {
    if (["email", "cc", "bcc"].includes(key)) {
      emailValidateForSend(
        key,
        value,
        setIsValidEmail,
        setIsValidBCC,
        setIsValidCC
      );
      if (isValidEmail) {
        setFormData({ ...formData, email: value });
      }
      if (isValidBCC) {
        setFormData({ ...formData, bcc: value });
      }
      if (isValidCC) {
        setFormData({ ...formData, cc: value });
      }
    }
    setFormData({ ...formData, [key]: value });
  };
  const getAdvertisementList = () => {
    dispatch(
      getWebAdvertisementsAction(params?.reference_number, (err, data) => {
        if (data) {
          setAds(data?.allAdvertisements);
        }
      })
    );
  };
  const checkVoucherTransfer = () => {
    dispatch(
      checkTransferVoucherAction(params, (err, data) => {
        if (data?.status === 200) {
          setPdfData(data?.result);
        }
        if (err) {
          setNotFound({ found: false, errorMessage: err });
        }
      })
    );
  };
  const checkVoucher = () => {
    dispatch(
      checkVoucherAction(params, (err, data) => {
        if (data?.status === 200) {
          setPdfData(data?.result);
        }
        if (err) {
          setNotFound({ found: false, errorMessage: err });
        }
      })
    );
  };

  const sendVoucherEmail = (data) => {
    if(loading === true) {
      return
    }
    setLoading(true)
    dispatch(
      sendEmailTourVoucherAction(
        data,
        pdfData?.id,
        params?.reference_number,
        (err, data) => {
          if (data?.status === 200) {
            toast.success("Successfully Sent", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setFormData({});
            handleClose();
          }
        }
      )
    );
  };

  const sendTransferEmail = (data) => {
    if(loading === true) {
      return
    }
    setLoading(true)
    dispatch(
      sendEmailTransferVoucherAction(
        data,
        pdfData?.id,
        params?.reference_number,
        (err, data) => {
          if (data?.status === 200) {
            toast.success("Successfully Sent", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setFormData({});
            handleClose();
          }
        }
      )
    );
  };

  const downloadVoucherPdf = () => {
    const role = "";
    dispatch(
      downloadVoucherAction(
        pdfData?.id,
        pdfData?.ag_ref_number,
        params?.page,
        role,
        params?.reference_number,
        (err, data) => {
          if (data?.status === 200) {
            toast.success("Successfully Downloaded", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }
      )
    );
  };
  const downloadTransferPdf = () => {
    dispatch(
      downloadTransferVoucherAction(
        pdfData?.id,
        pdfData?.reference_number,
        params?.page,
        "",
        params?.reference_number,
        (err, data) => {
          if (data?.status === 200) {
            toast.success("Successfully Downloaded", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }
      )
    );
  };

  const downloadPdf = () => {
    if (params?.page === "transfer_vouchers") {
      downloadTransferPdf();
    }
    if (params?.page === "tour_vouchers") {
      downloadVoucherPdf();
    }
  };

  const handleSendEmail = () => setShowEmailModal(true);
  const confirmSendEmail = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSubmit(true);
    const form = event.currentTarget;
    // if (form.checkValidity() === false || !isValidEmail) {
    if (!isFormValid()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      if (params?.page === "transfer_vouchers") {
        sendTransferEmail();
      }
      if (params?.page === "tour_vouchers") {
        sendVoucherEmail();
      }
    }
  };
  const isFormValid = () => {
    return !(
      !formData.email ||
      (formData.email && !isValidEmail) ||
      !formData.email_body ||
      (formData.cc && !isValidCC) ||
      (formData.bcc && !isValidBCC)
    );
  };

  const shareLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          if (
            latitude !== undefined &&
            longitude !== undefined &&
            pdfData?.id
          ) {
            let voucher_id = pdfData?.id;
            if (params?.page === "tour_vouchers") {
              dispatch(
                shareMyLocation(
                  latitude,
                  longitude,
                  voucher_id,
                  (err, data) => {
                    if (!err && data) {
                      toast.success("Location successfully shared");
                    } else {
                      toast.error("Something went wrong!");
                    }
                  }
                )
              );
            }
            if (params?.page === "transfer_vouchers") {
              dispatch(
                shareMyLocationForTransfer(
                  latitude,
                  longitude,
                  voucher_id,
                  (err, data) => {
                    if (!err && data) {
                      toast.success("Location successfully shared");
                    } else {
                      toast.error("Something went wrong!");
                    }
                  }
                )
              );
            }
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported in this browser.");
    }
  };
  const getDriverLocation = () => {
    const driverUrl =
      pdfData?.transfer?.driver_location_url ||
      pdfData?.tour?.driver_location_url;
    if (pdfData && driverUrl) {
      setShowDriversLocation(true);
    } else {
      toast.error("Driver has not shared the location yet.");
    }
  };
  return (
    <div className="web_content">
      {getTransferVoucherStatus === "pending" ||
      getTourVoucherStatus === "pending" ||
      (!getTourVoucherStatus && !getTransferVoucherStatus) ? (
        <FingerLoading />
      ) : notFound.found === false ? (
        <div className="web_container">
          <Grid container spacing={3} className="generate_grid_block">
            <Grid lg={2.5} md={12} sm={12} xs={12} className="left_ads">
              <Grid container spacing={1} className="ad_block">
                {ads?.map(
                  (ad) =>
                    ad?.column === "left" && (
                      <Grid xs={12} key={ad?.id} style={{ width: "100%" }}>
                        <div className="animate__animated animate__fadeInLeft">
                          <AdCard data={ad} />
                        </div>
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
            <Grid lg={7} md={12} sm={12} xs={12} className="pdf_grid_item">
              {" "}
              <NotFoundPdf message={notFound.errorMessage} />
            </Grid>
            <Grid lg={2.5} md={12} sm={12} xs={12} className="right_ads">
              <Grid container spacing={1} className="ad_block">
                {ads?.map(
                  (ad) =>
                    ad?.column === "right" && (
                      <Grid xs={12} key={ad?.id} style={{ width: "100%" }}>
                        <div className="animate__animated animate__fadeInRight">
                          <AdCard data={ad} />
                        </div>
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          <div className="web_container">
            <Grid container spacing={2} className="generate_grid_block">
              <Grid sm={2.9}  xs={12} className="left_ads">
                <Grid container spacing={1} className="ad_block">
                  {ads?.map(
                    (ad) =>
                      ad?.column === "left" && (
                        <Grid xs={12} key={ad?.id} style={{ width: "100%" }}>
                          <div className="animate__animated animate__fadeInLeft">
                            <AdCard data={ad} />
                          </div>
                        </Grid>
                      )
                  )}
                </Grid>
              </Grid>
              <Grid sm={6}  xs={12} className="pdf_grid_item">
                <div ref={nodeRef}>
                  <div className="pdf_block">
                    <Stack
                      spacing={1}
                      direction="row"
                      className="template_button_block"
                    >
                      <LoadingButton
                        color="primary"
                        onClick={handleSendEmail}
                        variant="contained"
                        loading={loading}
                        disabled={loading}
                        loadingPosition="center"
                      >
                        <span>Send Via Email</span>
                      </LoadingButton>
                      <LoadingButton
                        onClick={downloadPdf}
                        color="primary"
                        variant="contained"
                        loading={
                          transferDownloadStatus === "pending" ||
                          voucherDownloadStatus === "pending"
                        }
                        loadingPosition="center"
                      >
                        <span
                          style={
                            transferDownloadStatus === "pending" ||
                            voucherDownloadStatus === "pending"
                              ? { opacity: 0 }
                              : { opacity: 1 }
                          }
                        >
                          Download Pdf
                        </span>
                      </LoadingButton>
                      <LoadingButton
                        onClick={getDriverLocation}
                        color="primary"
                        variant="contained"
                        loadingPosition="center"
                      >
                        See Driver's Location
                      </LoadingButton>
                      <LoadingButton
                        onClick={shareLocation}
                        color="primary"
                        variant="contained"
                        loading={
                          params?.page === "tour_vouchers"
                            ? shareLocationStatus === "pending"
                            : shareLocationStatusTransfer === "pending"
                        }
                        loadingPosition="center"
                      >
                        <span
                          style={
                            shareLocationStatus === "pending" ||
                            shareLocationStatusTransfer === "pending"
                              ? { opacity: 0 }
                              : { opacity: 1 }
                          }
                        >
                          Share Location
                        </span>
                      </LoadingButton>
                    </Stack>
                    {params?.page === "tour_vouchers" ? (
                      <div
                        style={{ position: "relative" }}
                        className="animate__animated animate__fadeIn"
                      >
                        <VoucherTemplate data={pdfData} />
                      </div>
                    ) : params?.page === "transfer_vouchers" ? (
                      <div
                        style={{ position: "relative" }}
                        className="animate__animated animate__fadeIn"
                      >
                        <TransferTemplate data={pdfData} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </Grid>
              <Grid sm={2.9}  xs={12} className="right_ads">
                <Grid container spacing={1} className="ad_block">
                  {ads?.map(
                    (ad) =>
                      ad?.column === "right" && (
                        <Grid xs={12} key={ad?.id} style={{ width: "100%" }}>
                          <div className="animate__animated animate__fadeInRight">
                            <AdCard data={ad} />
                          </div>
                        </Grid>
                      )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            show={showEmailModal}
            onHide={handleClose}
            size="lg"
            centered
          >
            <DynamicEmailForm
              handleSubmit={confirmSendEmail}
              handleChange={handleChange}
              handleClose={handleClose}
              validated={validated}
              formData={formData}
              isValidEmail={isValidEmail}
              isValidCC={isValidCC}
              isValidBCC={isValidBCC}
              submit={
                params.page === "tour_vouchers" ? sendVoucherEmail : sendTransferEmail
              }
              page={params?.page}
              forAdmin={false}
            />
          </Modal>
          <SeeLocationModal
            data={pdfData}
            type="generated_pdf"
            handleClose={handleClose}
            show={showDriversLocation}
            title={`Driver's Location`}
            url={
              pdfData &&
              (pdfData?.transfer?.driver_location_url ||
                pdfData?.tour?.driver_location_url)
            }
          />
        </>
      )}
    </div>
  );
}
