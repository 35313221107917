import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import UsersForm from "./UsersForm";
import { sales_agent_validation } from "./validationSchema";
import {
  blockPageAction,
  registerRequest,
  updateUser,
} from "../../../store/actions/users";
import { LoadingButton } from "@mui/lab";
import PhoneInput from "react-phone-input-2";

function SalesAgentForm({
  handleClose,
  singleSalesAgent,
  getUsersList,
  add_new_modal,
  actionStatus,
}) {
  const { id } = useParams();
  const searchConfig = useSelector((store) => store.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(sales_agent_validation),
    defaultValues: id
      ? { ...singleSalesAgent, add_user: !!singleSalesAgent?.user_name }
      : { role: "Sales Agent" },
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid, isDirty, isSubmitted },
    reset,
    clearErrors,
  } = methods;

  useEffect(() => {
    if (id && singleSalesAgent) {
      reset({
        ...singleSalesAgent,
        add_user: !!singleSalesAgent.user_name,
        new_password: !!singleSalesAgent.user_name,
      });
    }
  }, [id, singleSalesAgent, reset]);

  useEffect(() => {
    if (id && singleSalesAgent) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/users/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const onSubmit = (data) => {
    setDisabled(true);
    if (!data.email || data.email === "") {
      data.email = null;
    }
    dispatch(
      registerRequest(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        } else {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          getUsersList(searchConfig);
          handleClose();
          setTimeout(() => {
            setDisabled(false);
          }, 400);
        }
      })
    );
  };

  const onEdit = (data) => {
    dispatch(
      updateUser(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            navigate("/users");
          }
        }
      })
    );
  };

  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    const hasValue = watch(field) && watch(field).length > 0 && !id;
    const isValid = isSubmitted && !isInvalid;

    return {
      borderColor: isInvalid
        ? "#dc3545"
        : isValid || hasValue
        ? "#198754"
        : "#ced4da",
    };
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(id ? onEdit : onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Full Name<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="full_name"
                    name="full_name"
                    type="text"
                    {...register("full_name")}
                    isInvalid={isSubmitted && !!errors.full_name}
                    isValid={isSubmitted && !errors.full_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.full_name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Label>
                  Email
                  {watch("add_user") ? (
                    <span style={{ color: "red" }}> *</span>
                  ) : null}
                </Form.Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      id="supplier_email"
                      {...field}
                      style={getCustomStylesForInput("email")}
                      {...register("email", {
                        required: !!watch("add_user") && "Required Field",
                        pattern: {
                          value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                          message: "Invalid format",
                        },
                      })}
                    />
                  )}
                />
                {errors.email && (
                  <span className="invalid_message">
                    {errors.email?.message}
                  </span>
                )}
              </Col>
              <Col>
                <Form.Label>Phone Number</Form.Label>
                <PhoneInput
                  inputProps={{
                    id: "supplier_phone_number",
                    name: "phone_number",
                  }}
                  buttonStyle={
                    isSubmitted && !watch("phone_number")
                      ? { borderColor: "#198754" }
                      : isSubmitted && watch("phone_number")
                      ? { borderColor: "#198754" }
                      : { borderColor: "#ced4da" }
                  }
                  inputStyle={
                    isSubmitted && !watch("phone_number")
                      ? { borderColor: "#198754" }
                      : isSubmitted && watch("phone_number")
                      ? { borderColor: "#198754" }
                      : { borderColor: "#ced4da" }
                  }
                  value={watch("phone_number") || ""}
                  onChange={(value) => {
                    setValue("phone_number", value ? value : null, {
                      shouldDirty: true,
                    });
                    clearErrors("phone_number");
                  }}
                />
                {errors.phone_number?.message && (
                  <span className="invalid_message">
                    {errors.phone_number?.message}
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Controller
                  name="add_user"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="driver_create_account"
                          checked={field.value}
                          disabled={id && singleSalesAgent?.user_name}
                          onChange={(ev) => {
                            field.onChange(ev.target.checked);
                            if (!ev.target.checked) {
                              setValue("user_name", null);
                            }
                          }}
                        />
                      }
                      label="Create Account?"
                    />
                  )}
                />
              </Col>
            </Row>
            {watch("add_user") ||
            (!add_new_modal && singleSalesAgent?.user_name) ? (
              <UsersForm
                setValue={setValue}
                control={control}
                singleUser={singleSalesAgent}
                isSubmitted={isSubmitted}
              />
            ) : null}
          </Modal.Body>
        </div>
        <Modal.Footer>
          {id ? (
            <div className="previewButtonsID">
              <Button
                variant={isFormDisabled ? "secondary" : "primary"}
                disabled={isFormDisabled}
                onClick={() => reset({ ...singleSalesAgent })}
              >
                Reset
              </Button>
              <LoadingButton
                  size="big"
                  className={`preview_submit_btn btn ${
                      isFormDisabled ? "btn-secondary" : "btn-primary"
                  }`}
                  style={{ height: "100%", fontSize: "1rem", lineHeight: "1.55" }}
                  loading={actionStatus === "pending"}
                  disabled={isFormDisabled}
                  variant="contained"
                  type="submit"
              >
                Save
              </LoadingButton>
            </div>
          ) : (
            <>
              <div className="previewButtons">
                <LoadingButton
                  size="small"
                  className="login_submit_btn btn btn-primary "
                  loadingPosition="center"
                  variant="contained"
                  loading={actionStatus === "pending"}
                  type={"submit"}
                  disabled={disabled}
                >
                  <span>Save</span>
                </LoadingButton>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Modal.Footer>
      </Form>
    </FormProvider>
  );
}

export default SalesAgentForm;
