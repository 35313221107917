export const ADD_WEB_HOTEL_REQUEST = "ADD_WEB_HOTEL_REQUEST";
export const ADD_WEB_HOTEL_SUCCESS = "ADD_WEB_HOTEL_SUCCESS";
export const ADD_WEB_HOTEL_FAIL = "ADD_WEB_HOTEL_FAIL";

export function addHotelAction(formData, cb) {
  return {
    type: ADD_WEB_HOTEL_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_WEB_HOTEL_REQUEST = "UPDATE_WEB_HOTEL_REQUEST";
export const UPDATE_WEB_HOTEL_SUCCESS = "UPDATE_WEB_HOTEL_SUCCESS";
export const UPDATE_WEB_HOTEL_FAIL = "UPDATE_WEB_HOTEL_FAIL";

export function updateHotel(formData, cb) {
  return {
    type: UPDATE_WEB_HOTEL_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_WEB_HOTEL_REQUEST = "DELETE_WEB_HOTEL_REQUEST";
export const DELETE_WEB_HOTEL_SUCCESS = "DELETE_WEB_HOTEL_SUCCESS";
export const DELETE_WEB_HOTEL_FAIL = "DELETE_WEB_HOTEL_FAIL";

export function deleteHotel(id, cb) {
  return {
    type: DELETE_WEB_HOTEL_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_WEB_HOTELS_REQUEST = "GET_WEB_HOTELS_REQUEST";
export const GET_WEB_HOTELS_SUCCESS = "GET_WEB_HOTELS_SUCCESS";
export const GET_WEB_HOTELS_FAIL = "GET_WEB_HOTELS_FAIL";

export function getHotelsAction(searchConfig, cb) {
  return {
    type: GET_WEB_HOTELS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const STATUS_WEB_HOTEL_REQUEST = "STATUS_WEB_HOTEL_REQUEST";
export const STATUS_WEB_HOTEL_SUCCESS = "STATUS_WEB_HOTEL_SUCCESS";
export const STATUS_WEB_HOTEL_FAIL = "STATUS_WEB_HOTEL_FAIL";

export function changeHotelStatusAction(id, cb) {
  return {
    type: STATUS_WEB_HOTEL_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_SINGLE_WEB_HOTEL_REQUEST = "GET_SINGLE_WEB_HOTEL_REQUEST";
export const GET_SINGLE_WEB_HOTEL_SUCCESS = "GET_SINGLE_WEB_HOTEL_SUCCESS";
export const GET_SINGLE_WEB_HOTEL_FAIL = "GET_SINGLE_WEB_HOTEL_FAIL";

export function getSingleHotel(id, cb) {
  return {
    type: GET_SINGLE_WEB_HOTEL_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_WEB_HOTELS_REQUEST = "EXPORT_WEB_HOTELS_REQUEST";
export const EXPORT_WEB_HOTELS_SUCCESS = "EXPORT_WEB_HOTELS_SUCCESS";
export const EXPORT_WEB_HOTELS_FAIL = "EXPORT_WEB_HOTELS_FAIL";

export function exportExcelHotelsAction(searchConfig, cb) {
  return {
    type: EXPORT_WEB_HOTELS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const GET_WEB_HOTELS_GALLERY_REQUEST = "GET_WEB_HOTELS_GALLERY_REQUEST";
export const GET_WEB_HOTELS_GALLERY_SUCCESS = "GET_WEB_HOTELS_GALLERY_SUCCESS";
export const GET_WEB_HOTELS_GALLERY_FAIL = "GET_WEB_HOTELS_GALLERY_FAIL";

export function getWebHotelGallery(id, cb) {
  return {
    type: GET_WEB_HOTELS_GALLERY_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_WEB_HOTELS_GALLERY_REQUEST =
  "UPDATE_WEB_HOTELS_GALLERY_REQUEST";
export const UPDATE_WEB_HOTELS_GALLERY_SUCCESS =
  "UPDATE_WEB_HOTELS_GALLERY_SUCCESS";
export const UPDATE_WEB_HOTELS_GALLERY_FAIL = "UPDATE_WEB_HOTELS_GALLERY_FAIL";

export function updateWebHotelGallery(id, formData, cb) {
  return {
    type: UPDATE_WEB_HOTELS_GALLERY_REQUEST,
    payload: { id, formData, cb },
  };
}

export const UPDATE_WEB_HOTEL_TYPE_REQUEST = "UPDATE_WEB_HOTEL_TYPE_REQUEST";
export const UPDATE_WEB_HOTEL_TYPE_SUCCESS = "UPDATE_WEB_HOTEL_TYPE_SUCCESS";
export const UPDATE_WEB_HOTEL_TYPE_FAIL = "UPDATE_WEB_HOTEL_TYPE_FAIL";

export function updateSingleHotelType(id, type, cb) {
  return {
    type: UPDATE_WEB_HOTEL_TYPE_REQUEST,
    payload: { id, type, cb },
  };
}

//Hotel Rooms
export const GET_WEB_HOTEL_ROOM_REQUEST = "GET_WEB_HOTEL_ROOM_REQUEST";
export const GET_WEB_HOTEL_ROOM_SUCCESS = "GET_WEB_HOTEL_ROOM_SUCCESS";
export const GET_WEB_HOTEL_ROOM_FAIL = "GET_WEB_HOTEL_ROOM_FAIL";

export function getHotelRoomAction(id, searchConfig, cb) {
  return {
    type: GET_WEB_HOTEL_ROOM_REQUEST,
    payload: { id, searchConfig, cb },
  };
}

export const ADD_WEB_HOTEL_ROOM_REQUEST = "ADD_WEB_HOTEL_ROOM_REQUEST";
export const ADD_WEB_HOTEL_ROOM_SUCCESS = "ADD_WEB_HOTEL_ROOM_SUCCESS";
export const ADD_WEB_HOTEL_ROOM_FAIL = "ADD_WEB_HOTEL_ROOM_FAIL";

export function addRoomAction(id, formData, cb) {
  return {
    type: ADD_WEB_HOTEL_ROOM_REQUEST,
    payload: {
      id,
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_WEB_HOTEL_ROOM_REQUEST =
  "GET_SINGLE_WEB_HOTEL_ROOM_REQUEST";
export const GET_SINGLE_WEB_HOTEL_ROOM_SUCCESS =
  "GET_SINGLE_WEB_HOTEL_ROOM_SUCCESS";
export const GET_SINGLE_WEB_HOTEL_ROOM_FAIL = "GET_SINGLE_WEB_HOTEL_ROOM_FAIL";

export function getSingleHotelRoom(id, cb) {
  return {
    type: GET_SINGLE_WEB_HOTEL_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const STATUS_WEB_HOTEL_ROOM_REQUEST = "STATUS_WEB_HOTEL_ROOM_REQUEST";
export const STATUS_WEB_HOTEL_ROOM_SUCCESS = "STATUS_WEB_HOTEL_ROOM_SUCCESS";
export const STATUS_WEB_HOTEL_ROOM_FAIL = "STATUS_WEB_HOTEL_ROOM_FAIL";

export function changeHotelRoomStatusAction(id, cb) {
  return {
    type: STATUS_WEB_HOTEL_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const UPDATE_WEB_HOTEL_ROOM_REQUEST = "UPDATE_WEB_HOTEL_ROOM_REQUEST";
export const UPDATE_WEB_HOTEL_ROOM_SUCCESS = "UPDATE_WEB_HOTEL_ROOM_SUCCESS";
export const UPDATE_WEB_HOTEL_ROOM_FAIL = "UPDATE_WEB_HOTEL_ROOM_FAIL";

export function updateHotelRoom(formData, cb) {
  return {
    type: UPDATE_WEB_HOTEL_ROOM_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_WEB_HOTEL_ROOM_REQUEST = "DELETE_WEB_HOTEL_ROOM_REQUEST";
export const DELETE_WEB_HOTEL_ROOM_SUCCESS = "DELETE_WEB_HOTEL_ROOM_SUCCESS";
export const DELETE_WEB_HOTEL_ROOM_FAIL = "DELETE_WEB_HOTEL_ROOM_FAIL";

export function deleteHotelRoom(id, cb) {
  return {
    type: DELETE_WEB_HOTEL_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_WEB_HOTEL_ROOM_GALLERY_REQUEST =
  "GET_WEB_HOTEL_ROOM_GALLERY_REQUEST";
export const GET_WEB_HOTEL_ROOM_GALLERY_SUCCESS =
  "GET_WEB_HOTEL_ROOM_GALLERY_SUCCESS";
export const GET_WEB_HOTEL_ROOM_GALLERY_FAIL =
  "GET_WEB_HOTEL_ROOM_GALLERY_FAIL";

export function getWebHotelRoomGallery(id, cb) {
  return {
    type: GET_WEB_HOTEL_ROOM_GALLERY_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_WEB_HOTEL_ROOM_GALLERY_REQUEST =
  "UPDATE_WEB_HOTEL_ROOM_GALLERY_REQUEST";
export const UPDATE_WEB_HOTEL_ROOM_GALLERY_SUCCESS =
  "UPDATE_WEB_HOTEL_ROOM_GALLERY_SUCCESS";
export const UPDATE_WEB_HOTEL_ROOM_GALLERY_FAIL =
  "UPDATE_WEB_HOTEL_ROOM_GALLERY_FAIL";

export function updateWebHotelRoomGallery(id, formData, cb) {
  return {
    type: UPDATE_WEB_HOTEL_ROOM_GALLERY_REQUEST,
    payload: { id, formData, cb },
  };
}
