import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Country } from "country-state-city";
import InputErrorMessages from "../inputErrorMessages/InputErrorMessages";
import { isEmpty } from "lodash";

function CompanyDetails({ onSubmit, goBack, loadingStatus }) {
  const {
    reset,
    watch,
    control,
    trigger,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    const countriesWithStates = allCountries.filter((country) => {
      const excludedCountries = ["AZ", "TR"];
      return !excludedCountries.includes(country.isoCode);
    });
    setCountries(countriesWithStates);
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="sign_up">
        <Row>
          <Col sm={12}>
            <Form.Control
              type="text"
              id="company_name"
              className="company_name"
              name="company_name"
              placeholder="Company Name *"
              style={errors.company_name && { borderColor: "#EE204D" }}
              {...register("company_name", {
                required: "Required Field",
              })}
            />
            <InputErrorMessages message={errors?.company_name?.message} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-2">
              <Controller
                name="country_code"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder={"Country of Operation *"}
                    classNamePrefix="react-select"
                    options={countries}
                    className={`form-control chosen-select  ${
                      errors?.country_code?.message ? "border-red" : ""
                    }`}
                    getOptionLabel={(countries) => countries.name}
                    getOptionValue={(countries) => countries.isoCode}
                    {...register("country_code", {
                      required: "Required Field",
                    })}
                    value={
                      !isEmpty(countries) &&
                      countries?.find(
                        (country) => country.isoCode === watch("country_code")
                      )
                    }
                    onChange={(ev) => {
                      setValue("country_name", ev?.name);
                      setValue("country_code", ev?.isoCode);
                      trigger("country_code");
                    }}
                  />
                )}
              />
              <InputErrorMessages message={errors?.country_code?.message} />
            </Form.Group>
          </Col>
        </Row>
        <div className="login-button-div">
          <LoadingButton
            size="big"
            className="login_submit_btn btn btn-primary login_button company_details_btn"
            variant="contained"
            onClick={goBack}
          >
            <span>Back</span>
          </LoadingButton>
          <LoadingButton
            size="big"
            className="login_submit_btn btn btn-primary login_button company_details_btn"
            loading={loadingStatus === "pending"}
            loadingPosition="start"
            variant="contained"
            type="submit"
          >
            <span>Sign Up</span>
          </LoadingButton>
          <small className="login_text_p">
            Have an Account?
            <strong>
              <small className="login_btn_small">
                <Link to="/login"> Login Here</Link>
              </small>
            </strong>
          </small>
        </div>
      </Form>
    </>
  );
}

export default CompanyDetails;
