import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {getHashedIdAction, sortAction} from "../../store/actions/search";
import { getColumns } from "./column";
import TableTitle from "../../components/table_title/TableTitle";
import SearchFilterBar from "../../components/search_filter_bar/SearchFilterBar";
import Table from "../../components/table";
import { Modal } from "react-bootstrap";
import EmailSetupsForm from "./EmailSetupsForm";
import UpdateStatusModal from "../../components/modals/UpdateStatusModal";
import DeleteModal2 from "../../components/modals/DeleteModal2";
import Pagination from "../../components/pagination/Pagination";
import {
  addEmailSetupAction,
  deleteEmailSetupAction,
  getEmailSetupsListAction,
} from "../../store/actions/emailSetups";
import MobileLayout from "../../components/mobileLayout/MobileLayout";
import { email_setup_mobile_column } from "../../staticVariables/mobileColumns";
import DynamicModal from "../../components/modals/DynamicModal";
import TermsConditionsForm from "../termsAndConditions/TermsConditionsForm";
import ModalButtons from "../../components/modals/modalButtons/ModalButtons";
import { toast } from "react-toastify";

export const emailSetupStatus = [
  {
    id: "Active",
    color: "green",
  },
  {
    id: "Inactive",
    color: "#dc3545",
  },
];

function EmailSetups(props) {
  let rows = useSelector((store) => store.emailSetups.emailSetups);
  let actionStatus = useSelector((store) => store.emailSetups.actionStatus);
  let rowStatus = useSelector((store) => store.emailSetups.status);
  let searchConfig = useSelector((store) => store.search);
  const count = 0;
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [rowId, setRowId] = useState("");
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getEmailSetupsList(searchConfig);
  }, []);

  const getEmailSetupsList = (searchConfig) => {
    dispatch(getEmailSetupsListAction(searchConfig));
  };

  // Redirect to preview page on click the data of the table
  const redirectToPages = (event) => {
    if (event.field == "recovery_email" && event.row.recovery_email != null) {
      for (var value of rows) {
        if (value.email == event.formattedValue) {
          navigate(`/users/${value.id}`);
        }
      }
    }
  };

  const redirectToPreview = useCallback((id) => {
    dispatch(
        getHashedIdAction(id, (err, data) => {
          if (data?.hash) {
            navigate(`/setup/email_setup/${data?.hash}`);
          }
        })
    );
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setShow(false);
    setPopupTitle("");
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
    setTimeout(() => {
      setDisabled(false);
    }, 400);
  };

  const handleAddConfirm = async (data) => {
    setDisabled(true);
    dispatch(
      addEmailSetupAction(data, (err, data) => {
        if (data) {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        }
        dispatch(getEmailSetupsListAction(searchConfig));
        handleClose();
      })
    );
  };

  const handleDelete = (params) => {
    setShowDelete(true);
    setRowId(params);
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deleteEmailSetupAction(rowId.row.id, (err, data) => {
        if (data) {
          dispatch(getEmailSetupsListAction(searchConfig));
          handleClose();
        }
      })
    );
  };

  const handleShow = (title) => {
    setShow(true);
    setPopupTitle(title);
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getEmailSetupsList(searchConfig);
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = (reason) => {};

  const handleUpdateStatus = (params) => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {};

  const columns = getColumns({
    handleUpdateStatus,
    rows,
    redirectToPreview,
    handleDelete,
    navigate,
  });

  return (
    <div className="admin_tables_block">
      <TableTitle
        handleShow={handleShow}
        title={"Email Setup"}
        addNew={true}
        // exportExcel={exportExcel}
        selectedRows={selectedRows}
        deleteSelectedRows={deleteSelectedRows}
        // exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getEmailSetupsList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        parentTitle={"EmailSetups"}
        link="/users"
        fetch={true}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPreview}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={"Email Setup"}
        link="/setup/email_setup"
        mobileCol={email_setup_mobile_column}
        status={emailSetupStatus}
        search={getEmailSetupsList}
      />
      <DynamicModal
        show={show}
        hide={() => setShow(false)}
        title={"Add New Email Setup"}
        body={
          <EmailSetupsForm
            submit={handleAddConfirm}
            footer={
              <ModalButtons
                submit={handleAddConfirm}
                cancel={() => setShow(false)}
                isLoading={actionStatus === "pending"}
                saveDisabled={disabled}
              />
            }
          />
        }
      />
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === "Active" ? "de-activate" : "activate"
        }  the ${rowId?.full_name} User. Are you sure?`}
        title={"Update EmailSetups"}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={"You are about to delete selected items. Are you sure?"}
        title={"Delete"}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={handleClose}
        confirm={(e) => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId?.id} Email setup. Are you sure?`}
        title={"Delete"}
        actionStatus={actionStatus}
      />

      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getEmailSetupsList}
      />
    </div>
  );
}

export default EmailSetups;
