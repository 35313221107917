import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "./GalleryForm.scss";
import { MdEdit } from "react-icons/md";
import ModalImage from "react-modal-image";

const { REACT_APP_API_URL } = process.env;

function WebsiteGalleryForm({ submit, footer, gallery, main_image }) {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [addNewImage, setAddNewImage] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [editMainImage, setEditMainImage] = useState(false);
  const [deletedImagesIds, setDeletedImagesIds] = useState([]);

  const {
    watch,
    register,
    setValue,
    setError,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm();

  useEffect(() => {
    if (id && gallery && images.length === 0) {
      setImages([...gallery]);
    }
    if (id && main_image && !mainImage) {
      setMainImage(main_image);
    }
  }, [id, gallery, main_image]);

  const handleMainImageChange = (file) => {
    if (
      file &&
      file.type.startsWith("image/") &&
      file.type !== "image/svg+xml"
    ) {
      const blobUrl = URL.createObjectURL(file);
      setMainImage({ file, url: blobUrl });
      setValue(`main_image`, file.name, { shouldDirty: true });
      setValue(`main_image_file`, file, { shouldDirty: true });
      if (editMainImage) setEditMainImage(false);
    } else if (file && file.type === "image/svg+xml") {
      setError("main_image", {
        type: "svg_support",
        message: "SVG files are not supported.",
      });
    }
  };

  const handleRemoveMainImage = () => {
    setEditMainImage(true);
    setMainImage(null);
  };

  const handleAddImage = () => {
    setImages([...images, { file: null, url: null }]);
    setAddNewImage(true);
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (
      file &&
      file.type.startsWith("image/") &&
      file.type !== "image/svg+xml"
    ) {
      const blobUrl = URL.createObjectURL(file);
      const updatedImages = [...images];
      const selectedAllImages = [...selectedImages];
      updatedImages[index] = { file, url: blobUrl, isNew: true };
      selectedAllImages[index] = { file };
      setImages(updatedImages);
      setSelectedImages(selectedAllImages);
      if (addNewImage) setAddNewImage(false);
    } else if (file && file.type === "image/svg+xml") {
      setError(`images[${index}]`, {
        type: "svg_support",
        message: "SVG files are not supported.",
      });
    }
  };

  const handleRemoveImage = (index, id) => {
    const updatedImages = images.filter((_, imgIndex) => imgIndex !== index);
    setImages(updatedImages);
    setDeletedImagesIds((prevIds) => [...prevIds, id]);
    clearErrors(`images[${index}]`);
  };

  const onSubmit = (data) => {
    const allFields = {
      ...data,
      images: selectedImages,
      deletedImages: deletedImagesIds,
    };
    submit(allFields);
  };

  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="form-block">
        <Modal.Body>
          <Row>
            <Col lg={4} className="mb-3">
              <Form.Group>
                <Form.Label htmlFor="formMainImage">
                  Main Image
                  <span style={{ color: "red" }}> *</span>
                </Form.Label>
                {(!main_image && !mainImage) || editMainImage ? (
                  <>
                    <Form.Control
                      type="file"
                      accept=".jpg, .png, image/jpeg, image/png"
                      {...register("main_image", { required: true })}
                      style={getCustomStylesForInput("main_image")}
                      onChange={(ev) =>
                        handleMainImageChange(ev.target.files[0])
                      }
                    />
                    {errors?.main_image &&
                      errors?.main_image.type !== "svg_support" && (
                        <span
                          style={{
                            color: "#dc3545",
                            fontSize: "0.875em",
                            marginTop: "0.25rem",
                          }}
                        >
                          Required Field
                        </span>
                      )}
                    {errors?.main_image &&
                    errors?.main_image.type === "svg_support" ? (
                      <p className="custom-invalid-feedback">
                        {errors?.main_image?.message}
                      </p>
                    ) : null}
                  </>
                ) : null}
                {mainImage && !editMainImage ? (
                  <div
                    className="mt-2 position-relative main-image-dropzone"
                    style={{ background: "whitesmoke" }}
                  >
                    <ModalImage
                      hideZoom={true}
                      className="main-modal-image"
                      small={
                        mainImage.url ||
                        `${REACT_APP_API_URL}/web_tours_images/${mainImage}`
                      }
                      large={
                        mainImage.url ||
                        `${REACT_APP_API_URL}/web_tours_images/${mainImage}`
                      }
                      alt=""
                    />
                    <Button
                      variant="danger"
                      size="sm"
                      className="position-absolute top-0 right-5 start-100 translate-middle"
                      onClick={handleRemoveMainImage}
                      style={{ transform: "translate(-50%, -50%)" }}
                    >
                      <MdEdit />
                    </Button>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Label>Additional Images</Form.Label>
            {images.map((image, index) => (
              <Col key={index} xs={12} md={4} className="mb-3">
                {addNewImage && !image.url ? (
                  <Form.Group controlId={`formFile${index}`}>
                    <Form.Control
                      type="file"
                      accept=".jpg, .png, image/jpeg, image/png"
                      id={`formFile${index}`}
                      {...register("images")}
                      onChange={(e) => handleImageChange(e, index)}
                    />
                    {errors?.images?.[index]?.type === "svg_support" ? (
                      <p className="custom-invalid-feedback">
                        {errors.images[index]?.message}
                      </p>
                    ) : null}
                  </Form.Group>
                ) : null}
                {image.url && (
                  <div
                    className="mt-3 position-relative images-dropzone"
                    style={{ background: "whitesmoke" }}
                  >
                    <ModalImage
                      hideZoom={true}
                      className="modal-image"
                      small={
                        image.isNew
                          ? image.url
                          : `${REACT_APP_API_URL}${image.url}`
                      }
                      large={
                        image.isNew
                          ? image.url
                          : `${REACT_APP_API_URL}${image.url}`
                      }
                      alt=""
                    />
                    <Button
                      variant="danger"
                      size="sm"
                      className="position-absolute top-0 right-5 start-100 translate-middle"
                      onClick={() => handleRemoveImage(index, image.id)}
                      style={{ transform: "translate(-50%, -50%)" }}
                    >
                      X
                    </Button>
                  </div>
                )}
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="mt-3">
              <Button variant="primary" onClick={handleAddImage}>
                + Add Image
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </div>
      <Modal.Footer>{footer && footer}</Modal.Footer>
    </Form>
  );
}

export default WebsiteGalleryForm;
