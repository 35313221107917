import React, { useState } from "react";
import "./webWrapper.scss";
import WebHeader from "../webHeader/WebHeader";
import WebFooter from "../webFooter/WebFooter";
import { Outlet, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { titleFromEnv } from "../../services/utils";

const { REACT_APP_TYPE, REACT_APP_WEB_SINGLE_NAME } = process.env;

export default function WebWrapper() {
  const [history, setHistory] = useState([]);
  const location = useLocation();
  const isGeneratePdf = location.pathname.includes("/generate_pdf");
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {REACT_APP_TYPE === "single" && REACT_APP_WEB_SINGLE_NAME
              ? titleFromEnv(REACT_APP_WEB_SINGLE_NAME)
              : "InTravelSync"}
          </title>
          {/* <link rel="icon" href="%PUBLIC_URL%/images/favicon_web.ico" /> */}
          <link
            rel="icon"
            href={
              REACT_APP_TYPE === "single"
                ? "/single_app_favicon_web.ico"
                : "/favicon.ico"
            }
          />
        </Helmet>
      </HelmetProvider>
      <div className="web_page">
        <WebHeader />
        <Outlet history={history} />
        {!isGeneratePdf && REACT_APP_TYPE === "single" ? <WebFooter /> : null}
      </div>
    </>
  );
}
