import React, { useEffect, useState, useRef } from "react";
import "./pickUpPoints.scss";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { MdOutlineClear } from "react-icons/md";

import { blockPageAction } from "../../store/actions/users";
import { useForm } from "react-hook-form";
import {TrimmedData} from "../../services/TrimObjectFields";

const { REACT_APP_API_URL } = process.env;

function PickUpPointsForm({ submit, footer, formData }) {
  const [selectedPhotos, setSelectedPhotos] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const {
    reset,
    watch,
    register,
    setValue,
    setError,
    trigger,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm();

  useEffect(() => {
    if (id && formData) {
      reset(formData);
      setSelectedPhotos({
        pick_up_photo: formData.pick_up_photo
          ? `${REACT_APP_API_URL}/pick_up_points_images/${formData.pick_up_photo}`
          : null,
        map_photo: formData.map_photo
          ? `${REACT_APP_API_URL}/pick_up_points_images/${formData.map_photo}`
          : null,
      });
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/pick_up_points/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImg = (key) => {
    setValue(`${key}`, null, { shouldDirty: true });
    setValue(`${key}_file`, null, { shouldDirty: true });
    setSelectedPhotos((prev) => ({
      ...prev,
      [key]: null,
    }));
    if (id && formData) {
      formData[key] = null;
      reset(formData);
      setIsFormDisabled(false);
    }
  };

  const handlePhotoChange = (key, val) => {
    if (val && val.type.startsWith("image/") && val.type !== "image/svg+xml") {
      setValue(`${key}`, val.name, { shouldDirty: true });
      setValue(`${key}_file`, val, { shouldDirty: true });
      setSelectedPhotos({ ...selectedPhotos, [key]: URL.createObjectURL(val) });
      clearErrors(key);
      trigger(key);
    } else if (val && val.type === "image/svg+xml") {
      setError(key, {
        type: "svg_support",
        message: "SVG files are not supported.",
      });
    }
  };

  const onSubmit = (data) => {
    const trimmedData = TrimmedData(data);
    submit(trimmedData);
  };

  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Pick-Up Name
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="pick_up_point_name"
                    name="pick_up_name"
                    isInvalid={isSubmitted && !!errors.pick_up_name}
                    isValid={isSubmitted && !errors.pick_up_name}
                    maxLength={254}
                    {...register("pick_up_name", {
                      required: "Required Field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pick_up_name
                        ? errors.pick_up_name.message
                        : "Required Field"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Label>
                  Address <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  id="pick_up_point_address"
                  name="pick_up_address"
                  type="text"
                  isInvalid={isSubmitted && !!errors.pick_up_address}
                  isValid={isSubmitted && !errors.pick_up_address}
                  {...register("pick_up_address", {
                    required: "Required Field",
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "Only spaces are not allowed",
                    },
                  })}
                  maxLength={254}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.pick_up_address
                      ? errors.pick_up_address.message
                      : "Required Field"}
                </Form.Control.Feedback>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Embed a Map</Form.Label>
                  <Form.Control
                    id="pick_up_point_map_embed"
                    name="map_embed"
                    type="text"
                    isInvalid={isSubmitted && !!errors.map_embed}
                    isValid={isSubmitted && !errors.map_embed}
                    {...register("map_embed", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.map_embed?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ marginBottom: 0 }}>
                    Pick-Up Photo
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <div className="file-input file-upload">
                    <label htmlFor="pick_up_photo">
                      <input
                        className="choose-btn"
                        type="button"
                        value="Choose"
                        onClick={handleClick}
                      />
                    </label>
                    <input
                      className="border-none"
                      type="text"
                      value={watch("pick_up_photo") || ""}
                      readOnly
                      style={getCustomStylesForInput("pick_up_photo")}
                    />
                    <input
                      type="file"
                      id="pick_up_photo"
                      name="logo"
                      accept=".jpg, .png, image/jpeg, image/png"
                      {...register("pick_up_photo", {
                        required: !watch("pick_up_photo"),
                      })}
                      onChange={(ev) => {
                        handlePhotoChange("pick_up_photo", ev.target.files[0]);
                      }}
                      ref={fileInputRef}
                    />
                    {watch("pick_up_photo") ? (
                      <MdOutlineClear
                        id="remove_pick_up_photo"
                        className="remove_img"
                        onClick={() => handleRemoveImg("pick_up_photo")}
                      />
                    ) : null}
                  </div>
                  {errors?.pick_up_photo &&
                    !watch("pick_up_photo") &&
                    errors?.pick_up_photo.type !== "svg_support" && (
                      <span
                        style={{
                          color: "#dc3545",
                          fontSize: "0.875em",
                          marginTop: "0.25rem",
                        }}
                      >
                        Required Field
                      </span>
                    )}
                  {errors?.pick_up_photo &&
                  !selectedPhotos?.pick_up_photo &&
                  errors?.pick_up_photo.type === "svg_support" ? (
                    <p className="custom-invalid-feedback">
                      {errors?.pick_up_photo?.message}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ marginBottom: 0 }}>Map Photo</Form.Label>
                  <div className="file-input file-upload">
                    <label htmlFor="map_photo">
                      <input
                        className="choose-btn"
                        type="button"
                        value="Choose"
                        onClick={handleClick}
                      />
                    </label>
                    <input
                      className="border-none"
                      type="text"
                      value={watch("map_photo") || ""}
                      readOnly
                      style={getCustomStylesForInput("map_photo")}
                    />
                    <input
                      type="file"
                      id="map_photo"
                      name="logo"
                      accept=".jpg, .png, image/jpeg, image/png"
                      {...register("map_photo", {
                        onChange: (e) =>
                          handlePhotoChange("map_photo", e.target.files[0]),
                      })}
                      ref={fileInputRef}
                    />
                    {watch("map_photo") ? (
                      <MdOutlineClear
                        id="remove_map_photo"
                        className="remove_img"
                        onClick={() => handleRemoveImg("map_photo")}
                      />
                    ) : null}
                  </div>
                  {errors?.map_photo &&
                  !selectedPhotos?.map_photo &&
                  errors?.map_photo.type === "svg_support" ? (
                    <p className="custom-invalid-feedback">
                      {errors?.map_photo?.message}
                    </p>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                {selectedPhotos?.pick_up_photo && (
                  <div className="pick-up-img-container">
                    <img src={selectedPhotos?.pick_up_photo} alt="" />
                  </div>
                )}
                {id &&
                  formData?.pick_up_photo &&
                  !selectedPhotos.pick_up_photo && (
                    <div className="pick-up-img-container">
                      <img
                        src={`${REACT_APP_API_URL}/pick_up_points_images/${formData?.pick_up_photo}`}
                        alt="pick_up_photo"
                      />
                    </div>
                  )}
              </Col>
              <Col sm={6}>
                {selectedPhotos?.map_photo && (
                  <div className="pick-up-img-container">
                    <img src={selectedPhotos?.map_photo} alt="map_photo" />
                  </div>
                )}
                {id && formData?.map_photo && !selectedPhotos.map_photo && (
                  <div className="pick-up-img-container">
                    <img
                      src={`${REACT_APP_API_URL}/pick_up_points_images/${formData?.map_photo}`}
                      alt="map_photo"
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
        </div>
        {/*<Modal.Footer>{footer && footer}</Modal.Footer>*/}
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default PickUpPointsForm;
