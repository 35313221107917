// import React, { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import "./web_search.scss";
// import { useDispatch } from "react-redux";
// import {
//   checkPreviewVoucherAction,
//   checkVoucherAction,
//   goToPreviewAction,
// } from "../../store/actions/tourVouchers";
// import { useNavigate } from "react-router-dom";
// import { LoadingButton } from "@mui/lab";
// import {
//   checkPreviewTransferVoucherAction,
//   checkTransferVoucherAction,
//   goToTransferVoucherPreviewAction,
// } from "../../store/actions/transferVouchers";
// import { Form } from "react-bootstrap";
// import { useForm } from "react-hook-form";
//
// export default function WebSearch({ searchTitle, title }) {
//   const [formData, setFormData] = useState({});
//   const [submit, setSubmit] = useState(false);
//   const [isLoading, setLoading] = useState(false);
//   const {
//     watch,
//     register,
//     setValue,
//     handleSubmit,
//     setError,
//     formState: { errors, isSubmitted },
//     clearErrors,
//   } = useForm();
//   const dispatch = useDispatch();
//
//   useEffect(() => {
//     setFormData({});
//     setSubmit(false);
//   }, [title]);
//
//   const handleChange = (key, val) => {
//     setFormData({ ...formData, [key]: val });
//   };
//   const checkTransfer = () => {
//     dispatch(
//       checkPreviewTransferVoucherAction(formData, (err, data) => {
//         if (data?.status == 200 && data?.result) {
//           const id = data?.result?.id;
//           dispatch(
//             goToTransferVoucherPreviewAction(id, (err, data) => {
//               if (data && data.status === 200) {
//                 if (data.webLink) {
//                   window.open(data.webLink);
//                 }
//               }
//             })
//           );
//         }
//         if (err) {
//           toast.error(err);
//         }
//         setLoading(false);
//       })
//     );
//   };
//   const checkVoucher = () => {
//     dispatch(
//       checkPreviewVoucherAction(formData, (err, data) => {
//         if (data?.status == 200 && data?.result) {
//           const id = data?.result?.id;
//           dispatch(
//             goToPreviewAction(id, (err, data) => {
//               if (data && data.status === 200) {
//                 if (data.webLink) {
//                   window.open(data.webLink);
//                 }
//               }
//             })
//           );
//         }
//         if (err) {
//           toast.error(err);
//         }
//         setLoading(false);
//       })
//     );
//   };
//
//   const handleSearch = async (ev) => {
//     ev.preventDefault();
//     setSubmit(true);
//     if (formData.reference_number) {
//       setLoading(true);
//       if (searchTitle === "Transfer") {
//         checkTransfer();
//       }
//       if (searchTitle === "Voucher") {
//         checkVoucher();
//       }
//     }
//   };
//   return (
//     <>
//       <div className="banner">
//         <div className="web_container web_content">
//           <h1>{title}</h1>
//         </div>
//       </div>
//       <div className="main">
//         <div className="web_container web_content">
//           <div className="search_div">
//             <h3 className="search_p">
//               Check & Download Your {searchTitle} Here!
//             </h3>
//             <div className="input_div">
//               <form onSubmit={handleSearch} className="check_form">
//                 {/*<Form.Group className="mb-2">*/}
//                 {/*  <Form.Control*/}
//                 {/*    id={"reference_number"}*/}
//                 {/*    name="reference_number"*/}
//                 {/*    type="text"*/}
//                 {/*    maxLength={254}*/}
//                 {/*    isInvalid={isSubmitted && !!errors.reference_number}*/}
//                 {/*    isValid={isSubmitted && !errors.reference_number}*/}
//                 {/*    {...register("reference_number", {*/}
//                 {/*      pattern: {*/}
//                 {/*        required: "Required",*/}
//                 {/*        value: /^(?!\s*$).+/,*/}
//                 {/*        message: "Only spaces are not allowed",*/}
//                 {/*      },*/}
//                 {/*    })}*/}
//                 {/*    onChange={(event) =>*/}
//                 {/*      handleChange("reference_number", event.target.value)*/}
//                 {/*    }*/}
//                 {/*  />*/}
//                 {/*  <Form.Control.Feedback type="invalid">*/}
//                 {/*    {errors.reference_number?.message}*/}
//                 {/*  </Form.Control.Feedback>*/}
//                 {/*</Form.Group>*/}
//
//                 <input
//                     required
//                     pattern={'/^(?!\s*$).+/'}
//                     type="text"
//                     name="reference_number"
//                     value={formData.reference_number || ""}
//                     placeholder="Reference Number *"
//                     style={
//                       !formData.reference_number && submit
//                           ? { borderColor: "#e50404" }
//                           : null
//                     }
//                     onChange={(event) =>
//                         handleChange("reference_number", event.target.value)
//                     }
//                 />
//                 <small className="input_small">
//                   {!formData.reference_number && submit && "Required Field"}
//                 </small>
//                 {/*<input type="button" value="CHECK" onClick={handleSearch} />*/}
//                 <LoadingButton
//                   onClick={handleSearch}
//                   type="submit"
//                   color="primary"
//                   variant="contained"
//                   loading={isLoading}
//                   loadingPosition="left"
//                   disabled={isLoading}
//                 >
//                   <span style={isLoading ? { opacity: 0 } : { opacity: 1 }}>
//                     CHECK
//                   </span>
//                 </LoadingButton>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }



import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./web_search.scss";
import { useDispatch } from "react-redux";
import {
  checkPreviewVoucherAction,
  checkVoucherAction,
  goToPreviewAction,
} from "../../store/actions/tourVouchers";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  checkPreviewTransferVoucherAction,
  checkTransferVoucherAction,
  goToTransferVoucherPreviewAction,
} from "../../store/actions/transferVouchers";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";

export default function WebSearch({ searchTitle, title }) {
  const [isLoading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isSubmitted },
    clearErrors,
    watch,
  } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    // This clears errors or form data if the title changes
    clearErrors();
  }, [title, clearErrors]);

  const checkTransfer = () => {
    dispatch(
        checkPreviewTransferVoucherAction(watch("reference_number"), (err, data) => {
          if (data?.status === 200 && data?.result) {
            const id = data?.result?.id;
            dispatch(
                goToTransferVoucherPreviewAction(id, (err, data) => {
                  if (data && data.status === 200 && data.webLink) {
                    window.open(data.webLink);
                  }
                })
            );
          }
          if (err) {
            toast.error(err);
          }
          setLoading(false);
        })
    );
  };

  const checkVoucher = () => {
    dispatch(
        checkPreviewVoucherAction(watch("reference_number"), (err, data) => {
          if (data?.status === 200 && data?.result) {
            const id = data?.result?.id;
            dispatch(
                goToPreviewAction(id, (err, data) => {
                  if (data && data.status === 200 && data.webLink) {
                    window.open(data.webLink);
                  }
                })
            );
          }
          if (err) {
            toast.error(err);
          }
          setLoading(false);
        })
    );
  };

  const onSubmit = (data) => {
    setLoading(true);
    if (searchTitle === "Transfer") {
      checkTransfer();
    }
    if (searchTitle === "Voucher") {
      checkVoucher();
    }
  };

  return (
      <>
        <div className="banner">
          <div className="web_container web_content">
            <h1>{title}</h1>
          </div>
        </div>
        <div className="main">
          <div className="web_container web_content">
            <div className="search_div">
              <h3 className="search_p">
                Check & Download Your {searchTitle} Here!
              </h3>
              <div className="input_div">
                <form onSubmit={handleSubmit(onSubmit)} className="check_form">
                  <input
                      type="text"
                      name="reference_number"
                      placeholder="Reference Number *"
                      {...register("reference_number", {
                        required: "Required Field",
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only spaces are not allowed",
                        },
                      })}
                      style={
                        errors.reference_number && isSubmitted
                            ? { borderColor: "#e50404" }
                            : null
                      }
                  />
                  {errors.reference_number && isSubmitted && (
                      <small className="input_small">{errors.reference_number.message}</small>
                  )}

                  <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={isLoading}
                      loadingPosition="left"
                      disabled={isLoading}
                  >
                  <span style={isLoading ? { opacity: 0 } : { opacity: 1 }}>
                    CHECK
                  </span>
                  </LoadingButton>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
