import React, { useState } from 'react';
import './tab_menu.scss';
import Box from '@mui/material/Box';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import { pages } from '../../pagesData/pages';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function TabMenu2() {
  const accessList = useSelector(store => store?.users?.accessList);
  const [index, setIndex] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = (e, v) => {
    document.querySelectorAll('.tab_menu_item_2').forEach(item => {
      if (location?.pathname !== item?.name) {
        item.classList.remove('Joy-selected');
      }
    });
    navigate(e.target?.name);
    setIndex(v);
  };

  return (
    <Box
      className="tab_menu_block_2"
      sx={{
        flexGrow: 1,
        borderRadius: 'sm',
      }}
    >
      <Tabs
        size="sm"
        value={index}
        aria-label="Bottom Navigation"
        // onChange={(event, value) => handleChange(event, value)}
        sx={theme => ({
          borderRadius: 'xl',
          maxWidth: 425,
          width: '100%',
          mx: 'auto',
          boxShadow: theme.shadow.sm,
          '--Tabs-gap': '8px',
          [`& .${tabClasses.root}`]: {
            boxShadow: 'none',
            borderRadius: 'lg',
            whiteSpace: 'nowrap',
            transition: '0.3s',
            fontWeight: 'lg',
            flex: 1,
          },
        })}
      >
        <TabList
          variant="plain"
          sx={{ '--ListItemDecorator-size': '28px' }}
          className="tab_menu_list"
        >
          {pages.map(
            ({ children }) =>
              children &&
              children.map(
                ({ id, path, title, element, icon, bottom_nav, accessId }) =>
                  bottom_nav &&
                  accessList?.includes(accessId) && (
                    <div
                      onClick={event => handleChange(event, id)}
                      className="tab_menu_item_block_2"
                    >
                      <Tab
                        // onClick={event => handleChange(event, id)}
                        className={
                          location.pathname.includes(path)
                            ? 'tab_menu_item_2 Joy-selected'
                            : 'tab_menu_item_2'
                        }
                        orientation="vertical"
                        name={path}
                      >
                        <ListItemDecorator>{icon}</ListItemDecorator>
                        {title}
                      </Tab>
                    </div>
                  ),
              ),
          )}
        </TabList>
      </Tabs>
    </Box>
  );
}
