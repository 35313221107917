export const GET_ALL_ROLES_REQUEST = 'GET_ALL_ROLES_REQUEST';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_ROLES_FAIL = 'GET_ALL_ROLES_FAIL';

export function getAllRolesAction(cb) {
  return {
    type: GET_ALL_ROLES_REQUEST,
    payload: {
      cb,
    },
  };
}
