import { api } from "../Api";
import axios from "axios";
import Account from "../../services/Account";

const { REACT_APP_API_URL } = process.env;

export default class Hotels {
  static addHotel(formData) {
    return api.post(`/admin/web/hotels`, formData);
  }

  static updateHotel(formData) {
    return api.put(`/admin/web/hotels/${formData.id}`, formData);
  }

  static deleteHotel(id) {
    return api.delete(`/admin/web/hotels/${id}`);
  }

  static getSingleHotel(id) {
    return api.get(`/admin/web/hotels/${id}`);
  }

  static getHotels(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/admin/web/hotels/`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static changeHotelStatus(id) {
    return api.put(`/admin/web/hotels/change/status/${id}`);
  }

  static handleExportHotelsExcel(page, search, filter, [{ sort, field }]) {
    let service_date = {};
    if (filter.service_date) {
      if (filter.service_date["$gte"]) {
        service_date.startDate = filter.service_date["$gte"];
      }
      if (filter.service_date["$lte"]) {
        service_date.endDate = filter.service_date["$lte"];
      }
    }
    return api({
      url: `/admin/web/hotels/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        search,
        service_date,
        page,
        sort,
        field,
      },
    });
  }

  static getGallery(id) {
    return api.get(`/admin/web/hotels/gallery/images/${id}`);
  }

  static updateGallery(id, formData) {
    const fd = new FormData();

    fd.append("main_image", formData?.main_image_file);
    if (formData.images && formData.images.length > 0) {
      formData.images.forEach((image) => {
        fd.append("images", image.file);
      });
    }
    fd.append("formData", JSON.stringify({ ...formData }));

    const res = axios.put(
      `${REACT_APP_API_URL}/admin/web/hotels/gallery/images/${id}`,
      fd,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
          Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
        },
      }
    );

    return res;
  }
  static updateType(id) {
    return api.put(`/admin/web/featured/hotels/${id}`);
  }

  //Hotel Rooms

  static getHotelRooms(id, page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/admin/web/hotel_rooms/${id}`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static addHotelRoom(id, formData) {
    return api.post(`/admin/web/hotel_rooms/${id}`, formData);
  }

  static getSingleHotelRoom(id) {
    return api.get(`/admin/web/hotel_rooms/single/${id}`);
  }

  static changeHotelRoomStatus(id) {
    return api.put(`/admin/web/hotel_rooms/change/status/${id}`);
  }

  static updateHotelRoom(formData) {
    return api.put(`/admin/web/hotel_rooms/${formData.id}`, formData);
  }

  static deleteHotelRoom(id) {
    return api.delete(`/admin/web/hotel_rooms/${id}`);
  }

  static getRoomGallery(id) {
    return api.get(`/admin/web/hotel_rooms/gallery/images/${id}`);
  }

  static updateRoomGallery(id, formData) {
    const fd = new FormData();

    fd.append("main_image", formData?.main_image_file);
    if (formData.images && formData.images.length > 0) {
      formData.images.forEach((image) => {
        fd.append("images", image.file);
      });
    }
    fd.append("formData", JSON.stringify({ ...formData }));

    const res = axios.put(
      `${REACT_APP_API_URL}/admin/web/hotel_rooms/gallery/images/${id}`,
      fd,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
          Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
        },
      }
    );

    return res;
  }
}
