import * as Yup from "yup";

export const userValidation = Yup.object().shape({
  full_name: Yup.string()
      .required("Required Field")
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)*$/, {
          message: "Invalid Format",
          excludeEmptyString: true,
      }),
  email: Yup.string()
      .required("Required Field")
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: "Invalid Email Address",
      excludeEmptyString: true,
    })
    .email("Invalid Email Address")
    .nullable(),

  phone_number: Yup.string()
    .nullable()
    .notRequired()
    .matches(/^\+?[()\d -]{6,25}/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    }),
  // user_name: Yup.string(),
  // .required("Required Field"),
  old_password: Yup.string(),
  password: Yup.string().when(["old_password", "confirm_password"], {
    is: (old_password, confirm_password) =>
      !!old_password || !!confirm_password,
    then: (schema) => schema.required("Required Field"),
    otherwise: (schema) => schema.notRequired(),
  }),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .when("old_password", {
      is: (old_password) => !!old_password,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export const supplierValidation = Yup.object().shape({
  full_name: Yup.string()
      .required("Required Field")
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)*$/, {
          message: "Invalid Format",
          excludeEmptyString: true,
      }),
  email: Yup.string()
    .required("Required Field")
    .matches(/[a-zA-Z0-9\._-]+@[a-zA-Z0-9.-_]+\.[a-z]{2,6}$/, {
      message: "Invalid Email Address",
      excludeEmptyString: true,
    })
    .email("Invalid Email Address"),
  phone_number: Yup.string()
    .required("Required Field")
    .matches(/^\+?[()\d -]{6,25}/, {
      message: "Invalid Format",
      excludeEmptyString: true,
    }),
  description: Yup.string().matches(/^(?!\s*$).+/, {
    message: "Only spaces are not allowed",
    excludeEmptyString: true,
  }),
  // user_name: Yup.string().required("Required Field"),
  old_password: Yup.string(),
  password: Yup.string().when(["old_password", "confirm_password"], {
    is: (old_password, confirm_password) =>
      !!old_password || !!confirm_password,
    then: (schema) => schema.required("Required Field"),
    otherwise: (schema) => schema.notRequired(),
  }),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .when("old_password", {
      is: (old_password) => !!old_password,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export const driverValidation = Yup.object().shape({
  full_name: Yup.string()
      .required("Required Field")
      .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ]+(?: [A-Za-zÀ-ÖØ-öø-ÿ]+)*$/, {
          message: "Invalid Format",
          excludeEmptyString: true,
      }),
  passport_details: Yup.string()
      .required("Required Field")
      .matches(/^(?!\s*$).+/, {
          message: "Only spaces are not allowed",
          excludeEmptyString: true,
      }),
  driving_license_number: Yup.string()
      .required("Required Field")
      .matches(/^(?!\s*$).+/, {
          message: "Only spaces are not allowed",
          excludeEmptyString: true,
      }),
  vehicle_type: Yup.string()
      .required("Required Field")
      .matches(/^(?!\s*$).+/, {
          message: "Only spaces are not allowed",
          excludeEmptyString: true,
      }),
  registration_number: Yup.string()
      .required("Required Field")
      .matches(/^(?!\s*$).+/, {
          message: "Only spaces are not allowed",
          excludeEmptyString: true,
      }),
  // user_name: Yup.string().required("Required Field"),
  old_password: Yup.string(),
  password: Yup.string().when(["old_password", "confirm_password"], {
    is: (old_password, confirm_password) =>
      !!old_password || !!confirm_password,
    then: (schema) => schema.required("Required Field"),
    otherwise: (schema) => schema.notRequired(),
  }),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .when("old_password", {
      is: (old_password) => !!old_password,
      then: (schema) => schema.required("Required Field"),
      otherwise: (schema) => schema.notRequired(),
    }),
});
