import moment from 'moment';

export const messagesUpdateForm = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  country: 'United States',
  stateId: '',
  city: '',
  domain: '',
  address: '',
  zip_code: '',
  service_date: '',
  service_time_range: '',
  call_time: '',
  appliance: '',
  brand: '',
  description: '',
};
export const messagesForm = {
  country: 'United States',
};
export const callsForm = {
  from_phoneNumber: '',
  country: 'United States',
};

export const userForm = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  phone_number: '',
  rolesId: '',
};
export const settingsForm = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  phone_number: '',
  rolesId: '',
};
export const payment = {
  payment_method: 'BA',
  account_holder: '',
  account_type: '',
  payment_number: '',
};

export const orderForm = {
  clientId: '',
  stateId: '',
  city: '',
  address: '',
  zip_code: '',
  service_date: '',
  service_time_range: '',
  appliance: 'Refrigerator',
  brand: '',
  description: '',
  'invoice_id.payment_type': 'CASH',
  // 'invoice_id.prepay': 0,
  'invoice_id.materials': [
    {
      part_name: '',
      quantity: '',
      price: '',
    },
  ],
  'invoice_id.days_on_labor': '30 days',
  'invoice_id.preview_date': moment(new Date()).format('L'),
  'invoice_id.recommendations': [],
};

export const priceFormData = [
  {
    part_name: '',
    quantity: '',
    price: '',
  },
];

export const checkOrdersGeneral = formData => {
  const keys = Object.keys(orderForm);
  for (let i of keys) {
    if (
      (formData && (formData[i] === undefined || formData[i] === '')) ||
      !/^[0-9\-]{1,13}$/.test(formData['zip_code'])
    ) {
      return true;
    }
    // if (i === 'zip_code' && formData[i]) {
    //   if (!/^\d{3,10}$/.test(formData[i])) {
    //     return true;
    //   }
    // }
  }
  return false;
};
