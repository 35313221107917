import React from "react";
import "./fingerLoadign.scss";

function FingerLoading(props) {
  return (
    <div className="d-flex justify-content-center align-items-center finger-loading-container animate__fadeIn animate__fadeOut">
      <div className="🤚">
        <div className="👉"></div>
        <div className="👉"></div>
        <div className="👉"></div>
        <div className="👉"></div>
        <div className="🌴"></div>
        <div className="👍"></div>
      </div>
    </div>
  );
}

export default FingerLoading;
