import { call, put, takeLatest } from "redux-saga/effects";
import {
  DELETE_SELECTED_TRANSFER_TYPES_FAIL,
  DELETE_SELECTED_TRANSFER_TYPES_SUCCESS,
  EXPORT_TRANSFER_TYPES_FAIL,
  EXPORT_TRANSFER_TYPES_SUCCESS,
  GET_ALL_TRANSFER_TYPES_FAIL,
  GET_ALL_TRANSFER_TYPES_REQUEST,
  GET_ALL_TRANSFER_TYPES_SUCCESS,
  // UPDATE_STATUS_TRANSFER_TYPE_FAIL,
  // UPDATE_STATUS_TRANSFER_TYPE_REQUEST,
  // UPDATE_STATUS_TRANSFER_TYPE_SUCCESS,
} from "../actions/transferTypes";
import TransferTypes from "../../api/transferTypes";
import {
  ADD_CUSTOM_SETTING_FAIL,
  ADD_CUSTOM_SETTING_REQUEST,
  ADD_CUSTOM_SETTING_SUCCESS,
  DELETE_CUSTOM_SETTING_FAIL,
  DELETE_CUSTOM_SETTING_REQUEST,
  DELETE_CUSTOM_SETTING_SUCCESS,
  EDIT_CUSTOM_SETTING_FAIL,
  EDIT_CUSTOM_SETTING_REQUEST,
  EDIT_CUSTOM_SETTING_SUCCESS,
  GET_CUSTOM_SETTINGS_LIST_FAIL,
  GET_CUSTOM_SETTINGS_LIST_REQUEST,
  GET_CUSTOM_SETTINGS_LIST_SUCCESS,
  GET_DASHBOARD_INFO_FAIL,
  GET_DASHBOARD_INFO_REQUEST,
  GET_DASHBOARD_INFO_SUCCESS,
  GET_DASHBOARD_SELECTED_YEAR,
  GET_DASHBOARD_SELECTED_YEAR_FAIL,
  GET_DASHBOARD_SELECTED_YEAR_SUCCESS,
  GET_SINGLE_CUSTOM_SETTING_FAIL,
  GET_SINGLE_CUSTOM_SETTING_REQUEST,
  GET_SINGLE_CUSTOM_SETTING_SUCCESS,
} from "../actions/customSettings";
import CustomSettings from "../../api/customSettings";

export default function* watcher() {
  yield takeLatest(GET_CUSTOM_SETTINGS_LIST_REQUEST, getSettings);
  yield takeLatest(DELETE_CUSTOM_SETTING_REQUEST, deleteSettings);
  yield takeLatest(ADD_CUSTOM_SETTING_REQUEST, addSetting);
  yield takeLatest(GET_SINGLE_CUSTOM_SETTING_REQUEST, getSingleSetting);
  yield takeLatest(EDIT_CUSTOM_SETTING_REQUEST, updateSetting);
  // yield takeLatest(UPDATE_STATUS_TRANSFER_TYPE_REQUEST, handleStatusUpdate);
  yield takeLatest(GET_ALL_TRANSFER_TYPES_REQUEST, getAllSettings);
  yield takeLatest(GET_DASHBOARD_INFO_REQUEST, getDashboardInfo);
  yield takeLatest(GET_DASHBOARD_SELECTED_YEAR, getDashboardInfoByYear);
}

function* addSetting(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(CustomSettings.addCustomSetting, formData);
    yield put({
      type: ADD_CUSTOM_SETTING_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_CUSTOM_SETTING_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* getSettings(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.formData;

    const { data } = yield call(
      CustomSettings.getCustomSettings,
      page,
      limit,
      search,
      filter,
      sort
    );
    yield put({
      type: GET_CUSTOM_SETTINGS_LIST_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_CUSTOM_SETTINGS_LIST_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* deleteSettings(action) {
  try {
    const id = action.payload.formData;
    const { data } = yield call(CustomSettings.deleteSetting, id);
    yield put({
      type: DELETE_CUSTOM_SETTING_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_CUSTOM_SETTING_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* deleteSelectedTransferTypes(action) {
  try {
    const { selectedRows } = action.payload;
    const { data } = yield call(
      TransferTypes.deleteSelectedTransferTypes,
      selectedRows
    );
    yield put({
      type: DELETE_SELECTED_TRANSFER_TYPES_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: DELETE_SELECTED_TRANSFER_TYPES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* getSingleSetting(action) {
  try {
    const id = action.payload.formData;
    const { data } = yield call(CustomSettings.getSingleSetting, id);
    yield put({
      type: GET_SINGLE_CUSTOM_SETTING_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_CUSTOM_SETTING_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* updateSetting(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(CustomSettings.updateTransferType, formData);
    yield put({
      type: EDIT_CUSTOM_SETTING_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: EDIT_CUSTOM_SETTING_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* getAllSettings(action) {
  try {
    const { data } = yield call(TransferTypes.getAllTransferTypes);
    yield put({
      type: GET_ALL_TRANSFER_TYPES_SUCCESS,
      payload: {
        data: data,
      },
    });
  } catch (e) {
    yield put({
      type: GET_ALL_TRANSFER_TYPES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}

function* getDashboardInfo(action) {
  try {
    const {
      years = {
        tours: 2024,
        tour_vouchers: 2024,
        transfers: 2024,
        transfer_vouchers: 2024,
      },
    } = action.payload;

    const { data } = yield call(CustomSettings.getDashboardInfo, years);
    yield put({
      type: GET_DASHBOARD_INFO_SUCCESS,
      payload: {
        data: data,
        years,
      },
    });
  } catch (e) {
    yield put({
      type: GET_DASHBOARD_INFO_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}

function* getDashboardInfoByYear(action) {
  try {
    const { year, model } = action.payload;

    const { data } = yield call(
      CustomSettings.getDashboardInfoYear,
      year,
      model
    );

    yield put({
      type: GET_DASHBOARD_SELECTED_YEAR_SUCCESS,
      payload: {
        data: data,
        year,
        model,
      },
    });
  } catch (e) {
    yield put({
      type: GET_DASHBOARD_SELECTED_YEAR_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}

function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      TransferTypes.handleExportTransferTypesExcel,
      page,
      search,
      filter,
      sort
    );
    yield put({
      type: EXPORT_TRANSFER_TYPES_SUCCESS,
    });
    const blob = new Blob([data], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, `TransferTypes.xlsx`);
  } catch (e) {
    console.log(e);
    yield put({
      type: EXPORT_TRANSFER_TYPES_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

// function* handleStatusUpdate(action) {
//   try {
//     const { id } = action.payload;
//     const { data } = yield call(TransferTypes.updateStatus, id);
//     yield put({
//       type: UPDATE_STATUS_TRANSFER_TYPE_SUCCESS,
//       payload: data,
//     });

//     if (action.payload.cb) {
//       action.payload.cb(null, data);
//     }
//   } catch (e) {
//     yield put({
//       type: UPDATE_STATUS_TRANSFER_TYPE_FAIL,
//       message: e.message,
//       payload: e.response.data,
//     });
//     if (action.payload.cb) {
//       action.payload.cb(e.response.data, null);
//     }
//   }
// }
