import { takeLatest, call, put } from "redux-saga/effects";
import {
  ADD_WEB_HOTEL_REQUEST,
  ADD_WEB_HOTEL_SUCCESS,
  ADD_WEB_HOTEL_FAIL,
  UPDATE_WEB_HOTEL_REQUEST,
  UPDATE_WEB_HOTEL_SUCCESS,
  UPDATE_WEB_HOTEL_FAIL,
  DELETE_WEB_HOTEL_REQUEST,
  DELETE_WEB_HOTEL_SUCCESS,
  DELETE_WEB_HOTEL_FAIL,
  GET_SINGLE_WEB_HOTEL_REQUEST,
  GET_SINGLE_WEB_HOTEL_SUCCESS,
  GET_SINGLE_WEB_HOTEL_FAIL,
  GET_WEB_HOTELS_REQUEST,
  GET_WEB_HOTELS_SUCCESS,
  GET_WEB_HOTELS_FAIL,
  EXPORT_WEB_HOTELS_REQUEST,
  EXPORT_WEB_HOTELS_SUCCESS,
  EXPORT_WEB_HOTELS_FAIL,
  STATUS_WEB_HOTEL_REQUEST,
  STATUS_WEB_HOTEL_SUCCESS,
  STATUS_WEB_HOTEL_FAIL,
  GET_WEB_HOTELS_GALLERY_REQUEST,
  GET_WEB_HOTELS_GALLERY_SUCCESS,
  GET_WEB_HOTELS_GALLERY_FAIL,
  UPDATE_WEB_HOTELS_GALLERY_REQUEST,
  UPDATE_WEB_HOTELS_GALLERY_SUCCESS,
  UPDATE_WEB_HOTELS_GALLERY_FAIL,
  GET_WEB_HOTEL_ROOM_REQUEST,
  GET_WEB_HOTEL_ROOM_SUCCESS,
  GET_WEB_HOTEL_ROOM_FAIL,
  ADD_WEB_HOTEL_ROOM_REQUEST,
  ADD_WEB_HOTEL_ROOM_SUCCESS,
  ADD_WEB_HOTEL_ROOM_FAIL,
  GET_SINGLE_WEB_HOTEL_ROOM_REQUEST,
  GET_SINGLE_WEB_HOTEL_ROOM_SUCCESS,
  GET_SINGLE_WEB_HOTEL_ROOM_FAIL,
  STATUS_WEB_HOTEL_ROOM_REQUEST,
  STATUS_WEB_HOTEL_ROOM_SUCCESS,
  STATUS_WEB_HOTEL_ROOM_FAIL,
  UPDATE_WEB_HOTEL_ROOM_REQUEST,
  UPDATE_WEB_HOTEL_ROOM_SUCCESS,
  UPDATE_WEB_HOTEL_ROOM_FAIL,
  DELETE_WEB_HOTEL_ROOM_REQUEST,
  DELETE_WEB_HOTEL_ROOM_SUCCESS,
  DELETE_WEB_HOTEL_ROOM_FAIL,
  GET_WEB_HOTEL_ROOM_GALLERY_REQUEST,
  GET_WEB_HOTEL_ROOM_GALLERY_SUCCESS,
  GET_WEB_HOTEL_ROOM_GALLERY_FAIL,
  UPDATE_WEB_HOTEL_ROOM_GALLERY_REQUEST,
  UPDATE_WEB_HOTEL_ROOM_GALLERY_SUCCESS,
  UPDATE_WEB_HOTEL_ROOM_GALLERY_FAIL,
  UPDATE_WEB_HOTEL_TYPE_REQUEST,
  UPDATE_WEB_HOTEL_TYPE_SUCCESS,
  UPDATE_WEB_HOTEL_TYPE_FAIL,
} from "../actions/websiteHotels";
import WebHotels from "../../api/website_management/websiteHotels";

export default function* watcher() {
  yield takeLatest(ADD_WEB_HOTEL_REQUEST, addHotelAction);
  yield takeLatest(UPDATE_WEB_HOTEL_REQUEST, updateHotelAction);
  yield takeLatest(DELETE_WEB_HOTEL_REQUEST, deleteHotelAction);
  yield takeLatest(GET_SINGLE_WEB_HOTEL_REQUEST, getSingleHotelAction);
  yield takeLatest(GET_WEB_HOTELS_REQUEST, handleGetHotelsAction);
  yield takeLatest(STATUS_WEB_HOTEL_REQUEST, handleChangeStatusAction);
  yield takeLatest(EXPORT_WEB_HOTELS_REQUEST, handleExportExcelAction);
  yield takeLatest(GET_WEB_HOTELS_GALLERY_REQUEST, handleGetHotelGalleryAction);
  yield takeLatest(
    UPDATE_WEB_HOTELS_GALLERY_REQUEST,
    handleUpdateHotelGalleryAction
  );
  yield takeLatest(UPDATE_WEB_HOTEL_TYPE_REQUEST, handleUpdateHotelTypeAction);
  //Hotel Rooms
  yield takeLatest(GET_WEB_HOTEL_ROOM_REQUEST, handleGetHotelRoomAction);
  yield takeLatest(ADD_WEB_HOTEL_ROOM_REQUEST, addHotelRoomAction);
  yield takeLatest(GET_SINGLE_WEB_HOTEL_ROOM_REQUEST, getSingleHotelRoomAction);
  yield takeLatest(STATUS_WEB_HOTEL_ROOM_REQUEST, handleChangeRoomStatusAction);
  yield takeLatest(UPDATE_WEB_HOTEL_ROOM_REQUEST, updateHotelRoomAction);
  yield takeLatest(DELETE_WEB_HOTEL_ROOM_REQUEST, deleteHotelRoomAction);
  yield takeLatest(
    GET_WEB_HOTEL_ROOM_GALLERY_REQUEST,
    handleGetHotelRoomGalleryAction
  );
  yield takeLatest(
    UPDATE_WEB_HOTEL_ROOM_GALLERY_REQUEST,
    handleUpdateHotelRoomGalleryAction
  );
}

//Hotels

function* addHotelAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebHotels.addHotel, formData);
    yield put({
      type: ADD_WEB_HOTEL_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_WEB_HOTEL_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* updateHotelAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebHotels.updateHotel, formData);
    yield put({
      type: UPDATE_WEB_HOTEL_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_HOTEL_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* deleteHotelAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebHotels.deleteHotel, id);
    yield put({
      type: DELETE_WEB_HOTEL_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_WEB_HOTEL_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* getSingleHotelAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebHotels.getSingleHotel, id);
    yield put({
      type: GET_SINGLE_WEB_HOTEL_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_WEB_HOTEL_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetHotelsAction(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;

    const { data } = yield call(
      WebHotels.getHotels,
      page,
      limit,
      search,
      filter,
      sort
    );

    yield put({
      type: GET_WEB_HOTELS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_HOTELS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleChangeStatusAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebHotels.changeHotelStatus, id);
    yield put({
      type: STATUS_WEB_HOTEL_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_WEB_HOTEL_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleExportExcelAction(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      WebHotels.handleExportHotelsExcel,
      page,
      search,
      filter,
      sort
    );
    yield put({
      type: EXPORT_WEB_HOTELS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, `Website_Hotels.xlsx`);
  } catch (e) {
    yield put({
      type: EXPORT_WEB_HOTELS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleGetHotelGalleryAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebHotels.getGallery, id);
    yield put({
      type: GET_WEB_HOTELS_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_HOTELS_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateHotelGalleryAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebHotels.updateGallery, id, formData);
    yield put({
      type: UPDATE_WEB_HOTELS_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_HOTELS_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

//Hotel Rooms

function* handleGetHotelRoomAction(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { id } = action.payload;

    const { data } = yield call(
      WebHotels.getHotelRooms,
      id,
      page,
      limit,
      search,
      filter,
      sort
    );
    yield put({
      type: GET_WEB_HOTEL_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_HOTEL_ROOM_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* addHotelRoomAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebHotels.addHotelRoom, id, formData);
    yield put({
      type: ADD_WEB_HOTEL_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_WEB_HOTEL_ROOM_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* getSingleHotelRoomAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebHotels.getSingleHotelRoom, id);
    yield put({
      type: GET_SINGLE_WEB_HOTEL_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SINGLE_WEB_HOTEL_ROOM_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleChangeRoomStatusAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebHotels.changeHotelRoomStatus, id);
    yield put({
      type: STATUS_WEB_HOTEL_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_WEB_HOTEL_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* updateHotelRoomAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebHotels.updateHotelRoom, formData);
    yield put({
      type: UPDATE_WEB_HOTEL_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_HOTEL_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* deleteHotelRoomAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebHotels.deleteHotelRoom, id);
    yield put({
      type: DELETE_WEB_HOTEL_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_WEB_HOTEL_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* handleGetHotelRoomGalleryAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebHotels.getRoomGallery, id);
    yield put({
      type: GET_WEB_HOTEL_ROOM_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_HOTEL_ROOM_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateHotelRoomGalleryAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebHotels.updateRoomGallery, id, formData);
    yield put({
      type: UPDATE_WEB_HOTEL_ROOM_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_HOTEL_ROOM_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateHotelTypeAction(action) {
  try {
    const { id, type } = action.payload;
    const { data } = yield call(WebHotels.updateType, id, type);
    yield put({
      type: UPDATE_WEB_HOTEL_TYPE_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_HOTEL_TYPE_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
