import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PageTitle from "../../components/PageTitle.js";
import {useNavigate, useParams} from "react-router-dom";
import {FadeLoader} from "react-spinners";
import {getSingleTour, updateTour} from "../../store/actions/tours";
import {blockPageAction} from "../../store/actions/users";
import ModalButtonsReset from "../../components/modals/modalButtons/ModalButtonsReset";
import {toast} from "react-toastify";
import ToursForm from "./ToursForm";

function PreviewTours() {
    const rowStatus = useSelector((store) => store?.tours?.status);
    const actionStatus = useSelector((store) => store?.tours?.actionStatus);
    const [disabled, setDisabled] = useState(false);
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();

    useEffect(() => {
        handleGetSingle(id);
        return () => {
            dispatch(blockPageAction(""));
        };
    }, []);

    const handleGetSingle = (id) => {
        dispatch(
            getSingleTour(id, (err, data) => {
                if (data && data.tour) {
                    setFormData(data?.tour);
                } else if (err) {
                    navigate("/dashboard");
                }
            })
        );
        return () => {
        };
    };

    const handleEditConfirm = (data) => {
        setDisabled(true);
        dispatch(
            updateTour(data, (err, data) => {
                if (err) {
                    toast.error(err, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 5000,
                    });
                    setDisabled(false);
                } else {
                    if (data) {
                        toast.success("Successfully updated.", {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 5000,
                        });
                        setTimeout(() => {
                            setDisabled(false);
                        }, 400);
                        navigate("/tours");
                    }
                }
            })
        );
    };

    return (
        <>
            <PageTitle
                parentTitle={"Tours"}
                childTitle={formData?.tour_name || ""}
                parentLink={"/tours"}
            />
            <div className="form-block">
                {rowStatus === "success" ? (
                    <ToursForm
                        submit={handleEditConfirm}
                        formData={formData}
                        footer={
                            <ModalButtonsReset
                                submit={handleEditConfirm}
                                reset={() => {
                                    handleGetSingle(id);
                                }}
                                disabled={disabled}
                                isLoading={actionStatus === "pending"}
                            />
                        }
                    />
                ) : (
                    <div className="form-loading">
                        <FadeLoader
                            color={`var(--primary_main)`}
                            loading={rowStatus === "pending"}
                            size={10}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default PreviewTours;
