import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_SUPPLIERS_REQUEST,
  GET_SUPPLIERS_FAIL,
  GET_SUPPLIERS_SUCCESS,
  CREATE_SUPPLIER_REQUEST,
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_FAIL,
  EDIT_SUPPLIER_REQUEST,
  EDIT_SUPPLIER_SUCCESS,
  EDIT_SUPPLIER_FAIL,
  GET_SINGLE_SUPPLIER_FAIL,
  GET_SINGLE_SUPPLIER_REQUEST,
  GET_SINGLE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_REQUEST,
  DELETE_SUPPLIER_SUCCESS,
  DELETE_SUPPLIER_FAIL,
  EXPORT_SUPPLIERS_REQUEST,
  EXPORT_SUPPLIERS_SUCCESS,
  EXPORT_SUPPLIERS_FAIL,
  DELETE_SELECTED_SUPPLIERS_REQUEST,
  DELETE_SELECTED_SUPPLIERS_SUCCESS,
  DELETE_SELECTED_SUPPLIERS_FAIL,
  UPDATE_STATUS_SUPPLIER_REQUEST,
  UPDATE_STATUS_SUPPLIER_SUCCESS,
  UPDATE_STATUS_SUPPLIER_FAIL,
} from '../actions/suppliers';
import Suppliers from '../../api/suppliers';

export default function* watcher() {
  yield takeLatest(GET_SUPPLIERS_REQUEST, getSuppliers);
  yield takeLatest(CREATE_SUPPLIER_REQUEST, createSupplier);
  yield takeLatest(EDIT_SUPPLIER_REQUEST, editSupplier);
  yield takeLatest(GET_SINGLE_SUPPLIER_REQUEST, getSingleSupplier);
  yield takeLatest(DELETE_SUPPLIER_REQUEST, handleDeleteSupplier);
  yield takeLatest(EXPORT_SUPPLIERS_REQUEST, handleExportExcel);
  yield takeLatest(DELETE_SELECTED_SUPPLIERS_REQUEST, deleteSelectedSuppliers);
  yield takeLatest(UPDATE_STATUS_SUPPLIER_REQUEST, handleStatusUpdate);
}

function* getSuppliers(action) {
  try {
    const { page, limit, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      Suppliers.getSuppliersList,
      page,
      limit,
      search,
      filter,
      sort,
    );
    yield put({
      type: GET_SUPPLIERS_SUCCESS,
      payload: {
        data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SUPPLIERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}

function* createSupplier(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Suppliers.createSupplier, formData);

    yield put({
      type: CREATE_SUPPLIER_SUCCESS,
      payload: {
        data: data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: CREATE_SUPPLIER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* editSupplier(action) {
  try {
    const { formData, id } = action.payload;
    const { data } = yield call(Suppliers.editSupplier, id, formData);

    yield put({
      type: EDIT_SUPPLIER_SUCCESS,
      payload: {
        data: data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: EDIT_SUPPLIER_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* getSingleSupplier(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Suppliers.singleSupplier, id);
    yield put({
      type: GET_SINGLE_SUPPLIER_SUCCESS,
      payload: {
        data: data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SINGLE_SUPPLIER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}

function* handleDeleteSupplier(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Suppliers.deleteSupplier, id);
    yield put({
      type: DELETE_SUPPLIER_SUCCESS,
      payload: {
        data: data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_SUPPLIER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}
function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      Suppliers.handleExportSupplierExcel,
      page,
      search,
      filter,
      sort,
    );
    yield put({
      type: EXPORT_SUPPLIERS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: 'application/vnd.ms-excel',
    });
    saveAs(blob, `Suppliers.xlsx`);
  } catch (e) {
    yield put({
      type: EXPORT_SUPPLIERS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* deleteSelectedSuppliers(action) {
  try {
    const { selectedRows } = action.payload;
    const { data } = yield call(
      Suppliers.deleteSelectedSuppliers,
      selectedRows,
    );
    yield put({
      type: DELETE_SELECTED_SUPPLIERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_SELECTED_SUPPLIERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}

function* handleStatusUpdate(action) {
  try {
    const { id } = action.payload;

    const { data } = yield call(Suppliers.updateStatus, id);
    yield put({
      type: UPDATE_STATUS_SUPPLIER_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_STATUS_SUPPLIER_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}
