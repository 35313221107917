import React from "react";
import logoSVG from "../../assets/images/white-logo.svg";
import { Form, InputGroup } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { setPassword } from "../../store/actions/users";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { titleFromEnv } from "../../services/utils";
const { REACT_APP_TYPE, REACT_APP_SINGLE_NAME } = process.env;

function SetPassword() {
  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const loadingStatus = useSelector((store) => store.users.userSetPassStatus);
  const [pass, setPass] = React.useState({
    new: true,
    new_confirm: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const watchPassword = watch("password", "");

  const confirmPasswordValidation = (value) => {
    if (value !== watchPassword) {
      return "Passwords do not match";
    }
    return true;
  };
  const handlePassVis = (key) => {
    if (pass[key] === true) {
      setPass({ ...pass, [key]: false });
    } else {
      setPass({ ...pass, [key]: true });
    }
  };
  const onSubmit = (data) => {
    const formData = { ...data, hash: params?.hash };
    dispatch(
      setPassword(formData, (err, data) => {
        if (err) {
          toast.error(err, {
            autoClose: 5000,
          });
        } else if (data?.status === 200) {
          reset();
          toast.success("Password successfully set.", {
            autoClose: 5000,
          });
          navigate(`/login`);
        }
      })
    );
  };

  return (
    <div className="login-page parent clearfix">
      <div className="login_page_overlay">
        <div className="login">
          <div className="container">
            <h3 className="login-page_logo">
              {REACT_APP_TYPE === "single" && REACT_APP_SINGLE_NAME ? (
                <h3 className="login-page_title" style={{ fontSize: "1.4em" }}>
                  {titleFromEnv(REACT_APP_SINGLE_NAME)}
                </h3>
              ) : (
                <h3 className="login-page_logo">
                  <img src={logoSVG} width={"290px"} />
                </h3>
              )}
            </h3>
            <h5 className="login-page_title">Set Password</h5>
            <div className="login-form">
              <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <InputGroup className="mb-3">
                  <div className="password-input-icon-group">
                    <div className="mb-3 input-group">
                      <InputGroup className="input_block">
                        <Form.Control
                          className="input"
                          id="password"
                          type={pass.new ? "password" : "text"}
                          placeholder="Set Password *"
                          autoComplete="new_password"
                          style={errors.password && { borderColor: "#EE204D" }}
                          {...register("password", {
                            required: "Required Field",
                            // minLength: {
                            //   value: 6,
                            //   message:
                            //     "Password must be at least 6 characters long",
                            // },
                          })}
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          onClick={() => handlePassVis("new")}
                        >
                          {pass.new ? (
                            <FiEyeOff cursor="pointer" />
                          ) : (
                            <FiEye cursor="pointer" />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.password && (
                        <span className="required-message">
                          {errors?.password?.message}
                        </span>
                      )}
                    </div>
                    <div className="input-group">
                      <InputGroup className="input_block">
                        <Form.Control
                          className="input"
                          type={pass.new_confirm ? "password" : "text"}
                          id="confirm_password"
                          placeholder="Confirm Password *"
                          autoComplete="confirm_password"
                          style={
                            errors.confirmPassword && { borderColor: "#EE204D" }
                          }
                          {...register("confirmPassword", {
                            required: "Required Field",
                            validate: confirmPasswordValidation,
                          })}
                        />
                        <InputGroup.Text
                          id="basic-addon1"
                          onClick={() => handlePassVis("new_confirm")}
                        >
                          {pass.new_confirm ? (
                            <FiEyeOff cursor="pointer" />
                          ) : (
                            <FiEye cursor="pointer" />
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      {errors.confirmPassword && (
                        <span className="required-message">
                          {errors?.confirmPassword?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </InputGroup>
                <div className="mb-3 login-button-div">
                  <LoadingButton
                    size="big"
                    className="login_submit_btn btn btn-primary login_button"
                    loading={loadingStatus === "pending"}
                    loadingPosition="start"
                    variant="contained"
                    type="submit"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
