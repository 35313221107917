import React from "react";
import { Button, Modal } from "react-bootstrap";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const apiKey = "AIzaSyDHNrL2CCxm7OTa7l29QpC2x5k6KPtDveU";

function SeeLocationModal({ show, type, title, url, data, handleClose }) {
  const mapContainerStyle = {
    width: "100%",
    height: "50vh",
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className="modal_header"
        closeButton
        style={
          type === "generated_pdf"
            ? { background: "#f88f35", padding: "15px" }
            : null
        }
      >
        <Modal.Title className="modal_title">{title}</Modal.Title>
      </Modal.Header>
      <div className="form-block">
        <Modal.Body>
          {type === "admin_table" && (
            <>
              {data.longitude && data.longitude && (
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={{
                    lat: +data.latitude,
                    lng: +data.longitude,
                  }}
                  zoom={18}
                >
                  <Marker
                    position={{ lat: +data.latitude, lng: +data.longitude }}
                    label={data?.passenger_name[0] || "P"}
                  />
                </GoogleMap>
              )}
            </>
          )}
          {url && type === "generated_pdf" && (
            <iframe width="100%" height="280" src={url}></iframe>
          )}
        </Modal.Body>
      </div>
      <Modal.Footer>
        <div className="previewButtons">
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default SeeLocationModal;
