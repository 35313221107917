import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle.js";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import AdvertisementsForm from "./AdvertisementsForm.js";
import {
  getAllAdvertisementsAction,
  getSingleAdvertisement,
  updateAdvertisement,
} from "../../store/actions/advertisements.js";
import { blockPageAction } from "../../store/actions/users";
import ModalButtonsReset from "../../components/modals/modalButtons/ModalButtonsReset";

function PreviewAdvertisement() {
  let allAds = useSelector((store) => store?.advertisements?.allAds);
  const rowStatus = useSelector((store) => store?.advertisements?.status);
  const actionStatus = useSelector(
    (store) => store?.advertisements?.actionStatus
  );
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({});
  const [siteName, setSiteName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAllAdvertisementsAction());
    handleEdit(id);
    return () => {
      dispatch(blockPageAction(""));
    };
  }, []);

  const handleEdit = (id) => {
    dispatch(
      getSingleAdvertisement(id, (err, data) => {
        if (data) {
          setFormData(data?.advertisement);
          setSiteName(
            data.advertisement?.ad_title_1
              ? "ID: " +
                  data?.advertisement?.id +
                  " - " +
                  data?.advertisement?.ad_title_1
              : "ID: " + data?.advertisement?.id
          );
        } else if (err) {
          navigate(`/dashboard`);
        }
      })
    );
  };

  const handleEditConfirm = async (data) => {
    setDisabled(true);
    dispatch(
      updateAdvertisement(data, (err, data) => {
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        } else {
          if (data) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            navigate("/setup/advertisements");
          }
          setTimeout(() => {
            setDisabled(false);
          }, 400);
        }
      })
    );
  };

  return (
    <>
      <PageTitle
        parentTitle={"Advertisements"}
        childTitle={siteName}
        parentLink={"/setup/advertisements"}
      />
      <div className="form-block">
        {rowStatus === "success" ? (
          <AdvertisementsForm
            submit={handleEditConfirm}
            formData={formData}
            footer={
              <ModalButtonsReset
                submit={handleEditConfirm}
                disabled={disabled}
                isLoading={actionStatus === "pending"}
                reset={() => {
                  handleEdit(id);
                }}
              />
            }
            rows={allAds}
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === "pending"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewAdvertisement;
