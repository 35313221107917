export const GET_ROOM_RESERVATIONS_REQUEST = "GET_ROOM_RESERVATIONS_REQUEST";
export const GET_ROOM_RESERVATIONS_SUCCESS = "GET_ROOM_RESERVATIONS_SUCCESS";
export const GET_ROOM_RESERVATIONS_FAIL = "GET_ROOM_RESERVATIONS_FAIL";

export function getRoomReservationAction(searchConfig, params, cb) {
  return {
    type: GET_ROOM_RESERVATIONS_REQUEST,
    payload: {
      searchConfig,
      params,
      cb,
    },
  };
}

export const GET_ALL_ROOM_RESERVATIONS_REQUEST =
  "GET_ALL_ROOM_RESERVATIONS_REQUEST";
export const GET_ALL_ROOM_RESERVATIONS_SUCCESS =
  "GET_ALL_ROOM_RESERVATIONS_SUCCESS";
export const GET_ALL_ROOM_RESERVATIONS_FAIL = "GET_ALL_ROOM_RESERVATIONS_FAIL";

export function getAllRoomReservationAction(hash, cb) {
  return {
    type: GET_ALL_ROOM_RESERVATIONS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_WEB_ROOM_RESERVATIONS_REQUEST =
  "GET_WEB_ROOM_RESERVATIONS_REQUEST";
export const GET_WEB_ROOM_RESERVATIONS_SUCCESS =
  "GET_WEB_ROOM_RESERVATIONS_SUCCESS";
export const GET_WEB_ROOM_RESERVATIONS_FAIL = "GET_WEB_ROOM_RESERVATIONS_FAIL";

export function getWebRoomReservationAction(hash, cb) {
  return {
    type: GET_WEB_ROOM_RESERVATIONS_REQUEST,
    payload: {
      hash,
      cb,
    },
  };
}

export const ADD_ROOM_RESERVATION_REQUEST = "ADD_ROOM_RESERVATION_REQUEST";
export const ADD_ROOM_RESERVATION_SUCCESS = "ADD_ROOM_RESERVATION_SUCCESS";
export const ADD_ROOM_RESERVATION_FAIL = "ADD_ROOM_RESERVATION_FAIL";

export function addRoomReservationAction(formData, cb) {
  return {
    type: ADD_ROOM_RESERVATION_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_ROOM_RESERVATION_REQUEST =
  "DELETE_ROOM_RESERVATION_REQUEST";
export const DELETE_ROOM_RESERVATION_SUCCESS =
  "DELETE_ROOM_RESERVATION_SUCCESS";
export const DELETE_ROOM_RESERVATION_FAIL = "DELETE_ROOM_RESERVATION_FAIL";

export function deleteRoomReservation(id, cb) {
  return {
    type: DELETE_ROOM_RESERVATION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const UPDATE_ROOM_RESERVATION_REQUEST =
  "UPDATE_ROOM_RESERVATION_REQUEST";
export const UPDATE_ROOM_RESERVATION_SUCCESS =
  "UPDATE_ROOM_RESERVATION_SUCCESS";
export const UPDATE_ROOM_RESERVATION_FAIL = "UPDATE_ROOM_RESERVATION_FAIL";

export function updateRoomReservation(formData, cb) {
  return {
    type: UPDATE_ROOM_RESERVATION_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_ROOM_RESERVATION_REQUEST =
  "GET_SINGLE_ROOM_RESERVATION_REQUEST";
export const GET_SINGLE_ROOM_RESERVATION_SUCCESS =
  "GET_SINGLE_ROOM_RESERVATION_SUCCESS";
export const GET_SINGLE_ROOM_RESERVATION_FAIL =
  "GET_SINGLE_ROOM_RESERVATION_FAIL";

export function getSingleRoomReservation(id, cb) {
  return {
    type: GET_SINGLE_ROOM_RESERVATION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_ROOM_RESERVATIONS_REQUEST =
  "EXPORT_ROOM_RESERVATIONS_REQUEST";
export const EXPORT_ROOM_RESERVATIONS_SUCCESS =
  "EXPORT_ROOM_RESERVATIONS_SUCCESS";
export const EXPORT_ROOM_RESERVATIONS_FAIL = "EXPORT_ROOM_RESERVATIONS_FAIL";

export function exportExcelRoomReservationAction(searchConfig, cb) {
  return {
    type: EXPORT_ROOM_RESERVATIONS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const UPDATE_STATUS_ROOM_RESERVATION_REQUEST =
  "UPDATE_STATUS_ROOM_RESERVATION_REQUEST";
export const UPDATE_STATUS_ROOM_RESERVATION_SUCCESS =
  "UPDATE_STATUS_ROOM_RESERVATION_SUCCESS";
export const UPDATE_STATUS_ROOM_RESERVATION_FAIL =
  "UPDATE_STATUS_ROOM_RESERVATION_FAIL";

export function updateStatusRoomReservationAction(id, cb) {
  return {
    type: UPDATE_STATUS_ROOM_RESERVATION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}
