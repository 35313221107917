export const page_limit = 10;
export const order = [{ field: "updatedAt", sort: "desc" }];
export const defaultSearchConfigs = {
  search: "",
  filter: {},
  sort: [
    {
      field: "updatedAt",
      sort: "desc",
    },
  ],
  page: 1,
  limit: 10,
  defaultFilter: false,
};

export const excludeFilterKeys = ["facilities"];
