import { api } from './Api';

export default class TransferTypes {
  static addTransferType(formData) {
    return api.post(`/transfer_types`, { ...formData });
  }
  static getTransferTypes(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/transfer_types`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static deleteTransferType(id) {
    return api.delete(`/transfer_types/${id}`);
  }
  static updateTransferType(formData) {
    return api.put(`/transfer_types/${formData?.id}`, { ...formData });
  }
  static getSingleTransferType(id) {
    return api.get(`/transfer_types/single/${id}`);
  }
  static deleteSelectedTransferTypes(selectedRows) {
    return api.post(`/transfer_types/delete_selected_transfer_types`, {
      selectedRows,
    });
  }
  static getAllTransferTypes() {
    return api.get(`/transfer_types/all`);
  }
  static updateStatus(id) {
    return api.put(`/transfer_types/status/${id}`);
  }
  static handleExportTransferTypesExcel(
    page,
    search,
    filter,
    [{ sort, field }],
  ) {
    return api({
      url: `/transfer_types/excel`,
      method: 'GET',
      responseType: 'blob',
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }
}
