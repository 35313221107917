import { takeLatest, call, put } from "redux-saga/effects";
import {
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAIL,
  UPDATE_COMPANY_SETTINGS_REQUEST,
  UPDATE_COMPANY_SETTINGS_SUCCESS,
  UPDATE_COMPANY_SETTINGS_FAIL,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  SET_THEME_REQUEST,
  SET_THEME_SUCCESS,
  SET_THEME_FAIL,
  GET_THEME_REQUEST,
  GET_THEME_SUCCESS,
  GET_THEME_FAIL,
  SET_THEME_MODE_REQUEST,
  SET_THEME_MODE_SUCCESS,
  SET_THEME_MODE_FAIL,
  TO_DEFAULT_THEME_REQUEST,
  TO_DEFAULT_THEME_SUCCESS,
  TO_DEFAULT_THEME_FAIL,
  GET_THEME_LIST_REQUEST,
  GET_THEME_LIST_SUCCESS,
  GET_THEME_LIST_FAIL,
  GET_COMPANY_SETTINGS_REQUEST,
  GET_COMPANY_SETTINGS_SUCCESS,
  GET_COMPANY_SETTINGS_FAIL,
} from "../actions/settings";
import Settings from "../../api/settings";
import {
  GET_HASHED_ID,
  GET_HASHED_ID_FAIL,
  GET_HASHED_ID_SUCCESS,
} from "../actions/search";

export default function* watcher() {
  yield takeLatest(GET_COMPANY_SETTINGS_REQUEST, handleGetCompanySettings);
  yield takeLatest(
    UPDATE_COMPANY_SETTINGS_REQUEST,
    handleUpdateCompanySettings
  );
  yield takeLatest(UPDATE_SETTINGS_REQUEST, handleUpdateSettings);
  yield takeLatest(GET_SETTINGS_REQUEST, handleGetSettings);
  yield takeLatest(SET_THEME_REQUEST, handleSetTheme);
  yield takeLatest(GET_THEME_REQUEST, handleGetTheme);
  yield takeLatest(GET_THEME_LIST_REQUEST, handleGetThemeList);
  yield takeLatest(SET_THEME_MODE_REQUEST, changeThemeMode);
  yield takeLatest(TO_DEFAULT_THEME_REQUEST, toDefaultTheme);
  yield takeLatest(GET_HASHED_ID, handleGetHashedId);
}
function* handleGetCompanySettings(action) {
  try {
    const { data } = yield call(Settings.getCompanySettings);
    yield put({
      type: GET_COMPANY_SETTINGS_SUCCESS,
      payload: {
        data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_COMPANY_SETTINGS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* handleUpdateCompanySettings(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Settings.updateCompanySettings, formData);
    yield put({
      type: UPDATE_COMPANY_SETTINGS_SUCCESS,
      payload: {
        data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_COMPANY_SETTINGS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetSettings(action) {
  try {
    const { data } = yield call(Settings.getSettings);
    yield put({
      type: GET_SETTINGS_SUCCESS,
      payload: {
        data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SETTINGS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* handleGetTheme(action) {
  try {
    const { mode } = action.payload;
    const { data } = yield call(Settings.getTheme, mode);
    yield put({
      type: GET_THEME_SUCCESS,
      payload: {
        data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_THEME_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* handleGetThemeList(action) {
  try {
    const { data } = yield call(Settings.getThemeList);
    yield put({
      type: GET_THEME_LIST_SUCCESS,
      payload: {
        data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_THEME_LIST_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleUpdateSettings(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Settings.updateSettings, formData);
    yield put({
      type: UPDATE_SETTINGS_SUCCESS,
      payload: {
        data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_SETTINGS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleSetTheme(action) {
  try {
    const { palette_id, mode } = action.payload;
    const { data } = yield call(Settings.setTheme, palette_id, mode);
    yield put({
      type: SET_THEME_SUCCESS,
      payload: {
        data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: SET_THEME_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* changeThemeMode(action) {
  try {
    const { palette_id, mode } = action.payload;
    const { data } = yield call(Settings.changeThemeMode, palette_id, mode);
    yield put({
      type: SET_THEME_MODE_SUCCESS,
      payload: {
        data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: SET_THEME_MODE_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* toDefaultTheme(action) {
  try {
    const { data } = yield call(Settings.toDefaultTheme);
    yield put({
      type: TO_DEFAULT_THEME_SUCCESS,
      payload: {
        data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: TO_DEFAULT_THEME_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetHashedId(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Settings.getHashedId, id);
    yield put({
      type: GET_HASHED_ID_SUCCESS,
      payload: {
        data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_HASHED_ID_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
