import { api } from "./Api";
import Account from "../services/Account";
import { isEmpty } from "lodash";

export default class TourVouchers {
  static addTourVoucher(formData) {
    return api.post(`/tour_vouchers`, { ...formData });
  }

  static getTourVouchers(page, limit, search, filter, [{ sort, field }]) {
    if (!isEmpty(filter && filter.status)) {
      filter.state = filter.status;
      delete filter.status;
    }
    return api.get(`/tour_vouchers`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static getPreviewLink(id) {
    return api.get(`/tour_vouchers/generate/weblink/${id}`, {});
  }

  static getPreviewVoucher(id, page, limit, search, filter, [{ sort, field }]) {
    if (!isEmpty(filter && filter.status)) {
      filter.state = filter.status;
      delete filter.status;
    }
    return api.get(`/tour_vouchers/tour/${id}`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static deleteTourVoucher(id) {
    return api.delete(`/tour_vouchers/${id}`);
  }

  static updateTourVoucher(formData) {
    return api.put(`/tour_vouchers/${formData?.id}`, { ...formData });
  }

  static updateTourVoucherState(formData) {
    return api.put(`/tour_vouchers/state/${formData?.id}`, { ...formData });
  }

  static getSingleTourVoucher(id) {
    return api.get(`/tour_vouchers/single/${id}`);
  }

  static getTourVoucherLoc(id) {
    return api.get(`/locations/last/${id}`);
  }

  static deleteSelectedTourVouchers(selectedRows) {
    return api.post(`/tour_vouchers/delete_selected_appliances`, {
      selectedRows,
    });
  }

  static getAllTourVouchers() {
    return api.get(`/tour_vouchers/all`);
  }

  static changeTourVoucherStatus(id) {
    return api.put(`/tour_vouchers/status/${id}`);
  }

  static addComment(id, comment) {
    return api.put(`/tour_vouchers/comment/${id}`, { comment });
  }

  static copy(id) {
    return api.post(`/tour_vouchers/copy/${id}`);
  }

  static sendTourVoucherEmail(id, formData) {
    return api.post(`/tour_vouchers/send_email/${id}`, formData);
  }

  static sendTourVoucherEmailFromWeb(id, hash, formData) {
    return api.post(`/tour_vouchers/web_send_email/${id}`, formData, {
      params: {
        hash,
      },
    });
  }

  static handleExportExcel(page, search, filter, [{ sort, field }]) {
    let service_date = {};
    if (filter.service_date) {
      if (filter.service_date["$gte"]) {
        service_date.startDate = filter.service_date["$gte"];
      }
      if (filter.service_date["$lte"]) {
        service_date.endDate = filter.service_date["$lte"];
      }
    }
    return api({
      url: `/tour_vouchers/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        search,
        service_date,
        page,
        sort,
        field,
      },
    });
  }

  static checkTourVoucher({ reference_number }) {
    return api.get(`/tour_vouchers/check`, {
      params: {
        hash: reference_number,
      },
    });
  }

  static checkPreviewTourVoucher(ref_num) {
    return api.get(`/tour_vouchers/check/preview_tour_voucher/`, {
      params: {
        reference_number: ref_num,
      },
    });
  }


  static downloadVoucher(data) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "arraybuffer",
    };

    if (data.hash) {
      config.params = {
        hash: data.hash,
      };
    }

    return api.get(
      `/tour_vouchers/download_pdf/${data.id}?role=${data.role}`,
      config
    );
  }

  static fetchTourVouchers() {
    return api.get(`/tour_vouchers/bokun`);
  }

  static shareMyLocation(location) {
    return api.put(`/tour_vouchers/location/update`, location);
  }

  static handleDownloadReport(
    group_field,
    user_id,
    service_date_from,
    service_date_to
  ) {
    let service_date;
    if (service_date_from && service_date_to) {
      service_date = { $gte: service_date_from, $lte: service_date_to };
    } else if (service_date_from && !service_date_to) {
      service_date = { $gte: service_date_from };
    } else if (!service_date_from && service_date_to) {
      service_date = { $lte: service_date_to };
    }

    return api({
      url: `/tour_vouchers/users/report`,
      method: "GET",
      responseType: "blob",
      params: {
        group_field: group_field && group_field?.id,
        service_date,
        user_id:
          user_id === "all" || user_id === "none" ? user_id : user_id?.id,
      },
    });
  }

  static handleGetVouchersForKPIReport() {
    return api.get("/tour_vouchers/kpi/report/years");
  }

  static handleDownloadKPIReport(group_field, user_id, year) {
    return api({
      url: `/tour_vouchers/kpi/report`,
      method: "GET",
      responseType: "blob",
      params: {
        year: year?.value,
        criteria: group_field && group_field?.id,
        userId: user_id === "all" || user_id === "none" ? user_id : user_id?.id,
      },
    });
  }
}
