import React from "react";
import logoSVG from "../../assets/images/white-logo.svg";
import { Form, InputGroup } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { findAccount } from "../../store/actions/users";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { titleFromEnv } from "../../services/utils";
const { REACT_APP_SINGLE_NAME, REACT_APP_TYPE } = process.env;

function ForgotPassword() {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const loadingStatus = useSelector(
    (store) => store.users.findUserAccountStatus
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    dispatch(
      findAccount(data, (err, data) => {
        if (err) {
          toast.error(err, {
            autoClose: 5000,
          });
        } else if (data?.status === 200) {
          reset();
          toast.success(
            "Password Reset link successfully sent to your email.",
            {
              autoClose: 5000,
            }
          );
          navigate(`/login`);
        }
      })
    );
  };

  return (
    <div className="login-page parent clearfix">
      <div className="login_page_overlay">
        <div className="login">
          <div className="container">
            <h3 className="login-page_logo">
              {REACT_APP_TYPE === "single" ? (
                <h3 className="login-page_title" style={{ fontSize: "1.4em" }}>
                  {titleFromEnv(REACT_APP_SINGLE_NAME)}
                </h3>
              ) : (
                <img src={logoSVG} width={"290px"} alt={"logo"} />
              )}
            </h3>
            <h5 className="login-page_title">Find your account</h5>
            <div className="login-form">
              <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email *"
                    style={errors.email && { borderColor: "#EE204D" }}
                    {...register("email", {
                      required: "Required Field",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email format",
                      },
                    })}
                  />
                  {errors?.email?.message && (
                    <span className="required-message">
                      {errors?.email?.message}
                    </span>
                  )}
                </InputGroup>
                <div className="mb-3 login-button-div">
                  <LoadingButton
                    size="big"
                    className="login_submit_btn btn btn-primary login_button"
                    loading={loadingStatus === "pending"}
                    loadingPosition="start"
                    variant="contained"
                    type="submit"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    <span>Submit</span>
                  </LoadingButton>
                </div>
              </Form>
            </div>
          </div>
          {REACT_APP_TYPE !== "single" && (
              <div className="footer_container">
                <div className="container">
                  <p className="link_styles">
                    <a
                        className="website_links"
                        target="_blank"
                        href="https://intravelsync.com/privacy-policy/"
                    >
                      Privacy Policy
                    </a>
                    <a
                        className="website_links"
                        target="_blank"
                        href="https://intravelsync.com/refund-policy/"
                    >
                      Refund Policy
                    </a>
                  </p>
                </div>
              </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
