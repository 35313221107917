import axios from "axios";
import { api } from "./Api";
import Account from "../services/Account";
const { REACT_APP_API_URL } = process.env;

export default class PickUpPoints {
  static addPickUpPoint(formData) {
    const fd = new FormData();
    fd.append("pick_up_photo_file", formData?.pick_up_photo_file);
    fd.append("map_photo_file", formData?.map_photo_file);
    fd.append("formData", JSON.stringify({ ...formData }));

    const res = axios.post(`${REACT_APP_API_URL}/pick_up_point`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
        Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
      },
    });
    return res;
  }
  static getPickUpPoints(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/pick_up_point`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static deletePickUpPoint(id) {
    return api.delete(`/pick_up_point/${id}`);
  }
  static updatePickUpPoint(formData) {
    const fd = new FormData();
    fd.append("pick_up_photo_file", formData?.pick_up_photo_file);
    fd.append("map_photo_file", formData?.map_photo_file);
    fd.append("formData", JSON.stringify({ ...formData }));

    const res = axios.put(
      `${REACT_APP_API_URL}/pick_up_point/${formData?.id}`,
      fd,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
          Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
        },
      }
    );
    return res;
  }
  static getSinglePickUpPoint(id) {
    return api.get(`/pick_up_point/single/${id}`);
  }
  static deleteSelectedCPickUpPoints(selectedRows) {
    return api.post(`/pick_up_point/delete_selected_cities`, {
      selectedRows,
    });
  }
  static getAllPickUpPoints() {
    return api.get(`/pick_up_point/get/all`);
  }
  static changePickUpPointsStatus(id, status) {
    return api.put(`/pick_up_point/status/${id}`, { status: status });
  }
  static handleExportExcel(page, search, filter, [{ sort, field }]) {
    return api({
      url: `/pick_up_point/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }
}
