import {
  ADD_WEB_APARTMENT_REQUEST,
  ADD_WEB_APARTMENT_SUCCESS,
  ADD_WEB_APARTMENT_FAIL,
  UPDATE_WEB_APARTMENT_REQUEST,
  UPDATE_WEB_APARTMENT_SUCCESS,
  UPDATE_WEB_APARTMENT_FAIL,
  DELETE_WEB_APARTMENT_REQUEST,
  DELETE_WEB_APARTMENT_SUCCESS,
  DELETE_WEB_APARTMENT_FAIL,
  GET_SINGLE_WEB_APARTMENT_REQUEST,
  GET_SINGLE_WEB_APARTMENT_SUCCESS,
  GET_SINGLE_WEB_APARTMENT_FAIL,
  GET_WEB_APARTMENTS_REQUEST,
  GET_WEB_APARTMENTS_SUCCESS,
  GET_WEB_APARTMENTS_FAIL,
  STATUS_WEB_APARTMENT_REQUEST,
  STATUS_WEB_APARTMENT_SUCCESS,
  STATUS_WEB_APARTMENT_FAIL,
  EXPORT_WEB_APARTMENTS_REQUEST,
  EXPORT_WEB_APARTMENTS_SUCCESS,
  EXPORT_WEB_APARTMENTS_FAIL,
  GET_WEB_APARTMENTS_GALLERY_REQUEST,
  GET_WEB_APARTMENTS_GALLERY_SUCCESS,
  GET_WEB_APARTMENTS_GALLERY_FAIL,
  UPDATE_WEB_APARTMENTS_GALLERY_REQUEST,
  UPDATE_WEB_APARTMENTS_GALLERY_SUCCESS,
  UPDATE_WEB_APARTMENTS_GALLERY_FAIL,
  GET_WEB_APARTMENT_ROOM_REQUEST,
  GET_WEB_APARTMENT_ROOM_SUCCESS,
  GET_WEB_APARTMENT_ROOM_FAIL,
  ADD_WEB_APARTMENT_ROOM_REQUEST,
  ADD_WEB_APARTMENT_ROOM_SUCCESS,
  ADD_WEB_APARTMENT_ROOM_FAIL,
  GET_SINGLE_WEB_APARTMENT_ROOM_REQUEST,
  GET_SINGLE_WEB_APARTMENT_ROOM_SUCCESS,
  GET_SINGLE_WEB_APARTMENT_ROOM_FAIL,
  STATUS_WEB_APARTMENT_ROOM_REQUEST,
  STATUS_WEB_APARTMENT_ROOM_SUCCESS,
  STATUS_WEB_APARTMENT_ROOM_FAIL,
  UPDATE_WEB_APARTMENT_ROOM_REQUEST,
  UPDATE_WEB_APARTMENT_ROOM_SUCCESS,
  UPDATE_WEB_APARTMENT_ROOM_FAIL,
  DELETE_WEB_APARTMENT_ROOM_REQUEST,
  DELETE_WEB_APARTMENT_ROOM_SUCCESS,
  DELETE_WEB_APARTMENT_ROOM_FAIL,
  GET_WEB_APARTMENT_ROOM_GALLERY_REQUEST,
  GET_WEB_APARTMENT_ROOM_GALLERY_SUCCESS,
  GET_WEB_APARTMENT_ROOM_GALLERY_FAIL,
  UPDATE_WEB_APARTMENT_ROOM_GALLERY_REQUEST,
  UPDATE_WEB_APARTMENT_ROOM_GALLERY_SUCCESS,
  UPDATE_WEB_APARTMENT_ROOM_GALLERY_FAIL,
} from "../actions/websiteApartments";

const initialState = {
  status: "",
  apartments: [],
  actionStatus: "",
  singleApartment: {},
  apartmentsGallery: [],
  exportExcelStatus: "",
  getSelectionApartmentsStatus: "",
  //Apartment Rooms
  rooms: [],
  roomName: "",
  singleApartmentRoom: {},
  apartmentRoomGallery: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    //Apartments
    case ADD_WEB_APARTMENT_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_WEB_APARTMENT_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_WEB_APARTMENT_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_WEB_APARTMENT_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_APARTMENT_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_APARTMENT_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_WEB_APARTMENT_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_WEB_APARTMENT_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_WEB_APARTMENT_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_SINGLE_WEB_APARTMENT_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_WEB_APARTMENT_SUCCESS: {
      return {
        ...state,
        status: "success",
        singleApartment: action.payload.apartment,
      };
    }
    case GET_SINGLE_WEB_APARTMENT_FAIL: {
      return {
        ...state,
        status: "pending",
      };
    }

    case GET_WEB_APARTMENTS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_APARTMENTS_SUCCESS: {
      return {
        ...state,
        status: "success",
        apartments: action.payload.data.apartments,
        count: action.payload.data.count,
      };
    }
    case GET_WEB_APARTMENTS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case STATUS_WEB_APARTMENT_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case STATUS_WEB_APARTMENT_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case STATUS_WEB_APARTMENT_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case EXPORT_WEB_APARTMENTS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: "pending",
      };
    }
    case EXPORT_WEB_APARTMENTS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: "success",
      };
    }
    case EXPORT_WEB_APARTMENTS_FAIL: {
      return {
        ...state,
        exportExcelStatus: "fail",
      };
    }

    case GET_WEB_APARTMENTS_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_APARTMENTS_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
        apartmentsGallery: action.payload.data?.gallery,
      };
    }
    case GET_WEB_APARTMENTS_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_APARTMENTS_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_APARTMENTS_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_APARTMENTS_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    //Apartment Rooms
    case GET_WEB_APARTMENT_ROOM_REQUEST: {
      return {
        ...state,
        hotelName: "",
        status: "pending",
      };
    }
    case GET_WEB_APARTMENT_ROOM_SUCCESS: {
      return {
        ...state,
        status: "success",
        count: action.payload.data.count,
        rooms: action.payload.data?.apartmentRooms,
        roomName: action.payload.data?.apartment?.apartment_name,
      };
    }
    case GET_WEB_APARTMENT_ROOM_FAIL: {
      return {
        ...state,
        hotelName: "",
        status: "fail",
      };
    }

    case ADD_WEB_APARTMENT_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_WEB_APARTMENT_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_WEB_APARTMENT_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_SINGLE_WEB_APARTMENT_ROOM_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_WEB_APARTMENT_ROOM_SUCCESS: {
      return {
        ...state,
        status: "success",
        singleApartmentRoom: action.payload.data.room,
      };
    }
    case GET_SINGLE_WEB_APARTMENT_ROOM_FAIL: {
      return {
        ...state,
        status: "pending",
      };
    }

    case STATUS_WEB_APARTMENT_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case STATUS_WEB_APARTMENT_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case STATUS_WEB_APARTMENT_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_WEB_APARTMENT_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_APARTMENT_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_APARTMENT_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_WEB_APARTMENT_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_WEB_APARTMENT_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_WEB_APARTMENT_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_WEB_APARTMENT_ROOM_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_APARTMENT_ROOM_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
        apartmentRoomGallery: action.payload.data?.apartmentRooms,
      };
    }
    case GET_WEB_APARTMENT_ROOM_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_APARTMENT_ROOM_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_APARTMENT_ROOM_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_APARTMENT_ROOM_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    default: {
      return state;
    }
  }
}
