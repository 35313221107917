import {
  GET_ALL_LOGS_REQUEST,
  GET_ALL_LOGS_SUCCESS,
  GET_ALL_LOGS_FAIL,
} from "../actions/logs";

const initialState = {
  status: "",
  allLogs: [],
  count: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LOGS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_ALL_LOGS_SUCCESS: {
      return {
        ...state,
        status: "success",
        allLogs: action.payload?.data?.logs,
        count: action.payload?.data?.count,
      };
    }
    case GET_ALL_LOGS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }
    default: {
      return state;
    }
  }
}
