import {
  ADD_WEB_SUITES_REQUEST,
  ADD_WEB_SUITES_SUCCESS,
  ADD_WEB_SUITES_FAIL,
  UPDATE_WEB_SUITES_REQUEST,
  UPDATE_WEB_SUITES_SUCCESS,
  UPDATE_WEB_SUITES_FAIL,
  DELETE_WEB_SUITES_REQUEST,
  DELETE_WEB_SUITES_SUCCESS,
  DELETE_WEB_SUITES_FAIL,
  GET_SINGLE_WEB_SUITES_REQUEST,
  GET_SINGLE_WEB_SUITES_SUCCESS,
  GET_SINGLE_WEB_SUITES_FAIL,
  GET_WEB_SUITES_REQUEST,
  GET_WEB_SUITES_SUCCESS,
  GET_WEB_SUITES_FAIL,
  STATUS_WEB_SUITES_REQUEST,
  STATUS_WEB_SUITES_SUCCESS,
  STATUS_WEB_SUITES_FAIL,
  EXPORT_WEB_SUITES_REQUEST,
  EXPORT_WEB_SUITES_SUCCESS,
  EXPORT_WEB_SUITES_FAIL,
  GET_WEB_SUITES_GALLERY_REQUEST,
  GET_WEB_SUITES_GALLERY_SUCCESS,
  GET_WEB_SUITES_GALLERY_FAIL,
  UPDATE_WEB_SUITES_GALLERY_REQUEST,
  UPDATE_WEB_SUITES_GALLERY_SUCCESS,
  UPDATE_WEB_SUITES_GALLERY_FAIL,
  GET_WEB_SUITE_ROOM_REQUEST,
  GET_WEB_SUITE_ROOM_SUCCESS,
  GET_WEB_SUITE_ROOM_FAIL,
  ADD_WEB_SUITE_ROOM_REQUEST,
  ADD_WEB_SUITE_ROOM_SUCCESS,
  ADD_WEB_SUITE_ROOM_FAIL,
  GET_SINGLE_WEB_SUITE_ROOM_REQUEST,
  GET_SINGLE_WEB_SUITE_ROOM_SUCCESS,
  GET_SINGLE_WEB_SUITE_ROOM_FAIL,
  STATUS_WEB_SUITE_ROOM_REQUEST,
  STATUS_WEB_SUITE_ROOM_SUCCESS,
  STATUS_WEB_SUITE_ROOM_FAIL,
  UPDATE_WEB_SUITE_ROOM_REQUEST,
  UPDATE_WEB_SUITE_ROOM_SUCCESS,
  UPDATE_WEB_SUITE_ROOM_FAIL,
  DELETE_WEB_SUITE_ROOM_REQUEST,
  DELETE_WEB_SUITE_ROOM_SUCCESS,
  DELETE_WEB_SUITE_ROOM_FAIL,
  GET_WEB_SUITE_ROOM_GALLERY_REQUEST,
  GET_WEB_SUITE_ROOM_GALLERY_SUCCESS,
  GET_WEB_SUITE_ROOM_GALLERY_FAIL,
  UPDATE_WEB_SUITE_ROOM_GALLERY_REQUEST,
  UPDATE_WEB_SUITE_ROOM_GALLERY_SUCCESS,
  UPDATE_WEB_SUITE_ROOM_GALLERY_FAIL,
} from "../actions/websiteSuites";

const initialState = {
  rooms: [],
  suites: [],
  status: "",
  suiteName: "",
  singleSuite: {},
  actionStatus: "",
  suiteGallery: [],
  singleSuiteRoom: {},
  suiteRoomGallery: [],
  exportExcelStatus: "",
  getSelectionSuitesStatus: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_WEB_SUITES_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_WEB_SUITES_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_WEB_SUITES_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_WEB_SUITES_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_SUITES_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_SUITES_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_WEB_SUITES_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_WEB_SUITES_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_WEB_SUITES_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_SINGLE_WEB_SUITES_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_WEB_SUITES_SUCCESS: {
      return {
        ...state,
        status: "success",
        singleSuite: action.payload.data.result,
      };
    }
    case GET_SINGLE_WEB_SUITES_FAIL: {
      return {
        ...state,
        status: "pending",
      };
    }

    case GET_WEB_SUITES_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_SUITES_SUCCESS: {
      return {
        ...state,
        status: "success",
        suites: action.payload.data.suites,
        count: action.payload.data.count,
      };
    }
    case GET_WEB_SUITES_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case STATUS_WEB_SUITES_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case STATUS_WEB_SUITES_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case STATUS_WEB_SUITES_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case EXPORT_WEB_SUITES_REQUEST: {
      return {
        ...state,
        exportExcelStatus: "pending",
      };
    }
    case EXPORT_WEB_SUITES_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: "success",
      };
    }
    case EXPORT_WEB_SUITES_FAIL: {
      return {
        ...state,
        exportExcelStatus: "fail",
      };
    }

    case GET_WEB_SUITES_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_SUITES_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
        suiteGallery: action.payload.data?.gallery,
      };
    }
    case GET_WEB_SUITES_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_SUITES_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_SUITES_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_SUITES_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    //Suite Rooms
    case GET_WEB_SUITE_ROOM_REQUEST: {
      return {
        ...state,
        hotelName: "",
        status: "pending",
      };
    }
    case GET_WEB_SUITE_ROOM_SUCCESS: {
      return {
        ...state,
        status: "success",
        count: action.payload.data.count,
        rooms: action.payload.data?.suiteRooms,
        suiteName: action.payload.data?.suite?.suite_name,
      };
    }
    case GET_WEB_SUITE_ROOM_FAIL: {
      return {
        ...state,
        hotelName: "",
        status: "fail",
      };
    }

    case ADD_WEB_SUITE_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_WEB_SUITE_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_WEB_SUITE_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_SINGLE_WEB_SUITE_ROOM_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_WEB_SUITE_ROOM_SUCCESS: {
      return {
        ...state,
        status: "success",
        singleSuiteRoom: action.payload.data.room,
      };
    }
    case GET_SINGLE_WEB_SUITE_ROOM_FAIL: {
      return {
        ...state,
        status: "pending",
      };
    }

    case STATUS_WEB_SUITE_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case STATUS_WEB_SUITE_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case STATUS_WEB_SUITE_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_WEB_SUITE_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_SUITE_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_SUITE_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_WEB_SUITE_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_WEB_SUITE_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_WEB_SUITE_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_WEB_SUITE_ROOM_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_SUITE_ROOM_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
        suiteRoomGallery: action.payload.data?.gallery,
      };
    }
    case GET_WEB_SUITE_ROOM_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_SUITE_ROOM_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_SUITE_ROOM_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_SUITE_ROOM_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    default: {
      return state;
    }
  }
}
