import {
  GET_SUPPLIERS_REQUEST,
  GET_SUPPLIERS_SUCCESS,
  GET_SUPPLIERS_FAIL,
  GET_SINGLE_SUPPLIER_FAIL,
  GET_SINGLE_SUPPLIER_SUCCESS,
  GET_SINGLE_SUPPLIER_REQUEST,
  CREATE_SUPPLIER_SUCCESS,
  CREATE_SUPPLIER_REQUEST,
  CREATE_SUPPLIER_FAIL,
  EXPORT_SUPPLIERS_REQUEST,
  EXPORT_SUPPLIERS_SUCCESS,
  EXPORT_SUPPLIERS_FAIL,
} from '../actions/suppliers';

const initialState = {
  suppliers: [],
  allSuppliers: [],
  singleSupplier: {},
  status: '',
  pageCount: 0,
  exportExcelStatus: '',
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUPPLIERS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }

    case GET_SUPPLIERS_SUCCESS: {
      return {
        ...state,
        status: 'success',
        suppliers: action.payload.data?.result,
        pageCount: action.payload.data?.count,
      };
    }
    case GET_SUPPLIERS_FAIL: {
      return {
        ...state,
        status: 'error',
      };
    }

    // case LOG_OUT: {
    //   return {
    //     suppliers: [],
    //     allSuppliers: [],
    //     singleSupplier: {},
    //     status: '',
    //     pageCount: 0,
    //   };
    // }

    case GET_SINGLE_SUPPLIER_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_SUPPLIER_SUCCESS: {
      return {
        ...state,
        singleSupplier: action.payload.data,
        status: 'success',
      };
    }
    case GET_SINGLE_SUPPLIER_FAIL: {
      return {
        ...state,
        status: 'error',
      };
    }
    case CREATE_SUPPLIER_REQUEST: {
      return {
        ...state,
      };
    }

    case CREATE_SUPPLIER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }

    case CREATE_SUPPLIER_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    case EXPORT_SUPPLIERS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: 'pending',
      };
    }

    case EXPORT_SUPPLIERS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: 'success',
      };
    }

    case EXPORT_SUPPLIERS_FAIL: {
      return {
        ...state,
        exportExcelStatus: 'fail',
      };
    }

    default: {
      return state;
    }
  }
}
