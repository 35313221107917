import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

function LocationMapTransfer({ coordinates }) {
  const mapContainerStyle = {
    width: "100%",
    height: "500px",
  };
  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={{
        lat: +coordinates[0].lat,
        lng: +coordinates[0].lng,
      }}
      zoom={10}
    >
      {coordinates?.map((location, index) => (
        <Marker
          key={index}
          position={{ lat: +location?.lat, lng: +location?.lng }}
          label={{
            text:
              location.type === "pick_up_address"
                ? `P-${location.id.toString()}`
                : location.type === "location"
                ? `C-${location.id.toString()}`
                : location.isPickUpPoint === false
                ? `P-${location.id.toString()}`
                : "P",
            color: "#041232",
            fontSize: "1.5em",
          }}
        />
      ))}
    </GoogleMap>
  );
}

export default LocationMapTransfer;
