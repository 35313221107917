import { takeLatest, call, put } from "redux-saga/effects";
import {
  ADD_WEB_SUITES_REQUEST,
  ADD_WEB_SUITES_SUCCESS,
  ADD_WEB_SUITES_FAIL,
  UPDATE_WEB_SUITES_REQUEST,
  UPDATE_WEB_SUITES_SUCCESS,
  UPDATE_WEB_SUITES_FAIL,
  DELETE_WEB_SUITES_REQUEST,
  DELETE_WEB_SUITES_SUCCESS,
  DELETE_WEB_SUITES_FAIL,
  GET_SINGLE_WEB_SUITES_REQUEST,
  GET_SINGLE_WEB_SUITES_SUCCESS,
  GET_SINGLE_WEB_SUITES_FAIL,
  GET_WEB_SUITES_REQUEST,
  GET_WEB_SUITES_SUCCESS,
  GET_WEB_SUITES_FAIL,
  STATUS_WEB_SUITES_REQUEST,
  STATUS_WEB_SUITES_SUCCESS,
  STATUS_WEB_SUITES_FAIL,
  EXPORT_WEB_SUITES_REQUEST,
  EXPORT_WEB_SUITES_SUCCESS,
  EXPORT_WEB_SUITES_FAIL,
  GET_WEB_SUITES_GALLERY_REQUEST,
  GET_WEB_SUITES_GALLERY_SUCCESS,
  GET_WEB_SUITES_GALLERY_FAIL,
  UPDATE_WEB_SUITES_GALLERY_REQUEST,
  UPDATE_WEB_SUITES_GALLERY_SUCCESS,
  UPDATE_WEB_SUITES_GALLERY_FAIL,
  GET_WEB_SUITE_ROOM_REQUEST,
  GET_WEB_SUITE_ROOM_SUCCESS,
  GET_WEB_SUITE_ROOM_FAIL,
  ADD_WEB_SUITE_ROOM_REQUEST,
  ADD_WEB_SUITE_ROOM_SUCCESS,
  ADD_WEB_SUITE_ROOM_FAIL,
  GET_SINGLE_WEB_SUITE_ROOM_REQUEST,
  GET_SINGLE_WEB_SUITE_ROOM_SUCCESS,
  GET_SINGLE_WEB_SUITE_ROOM_FAIL,
  STATUS_WEB_SUITE_ROOM_REQUEST,
  STATUS_WEB_SUITE_ROOM_SUCCESS,
  STATUS_WEB_SUITE_ROOM_FAIL,
  UPDATE_WEB_SUITE_ROOM_REQUEST,
  UPDATE_WEB_SUITE_ROOM_SUCCESS,
  UPDATE_WEB_SUITE_ROOM_FAIL,
  DELETE_WEB_SUITE_ROOM_REQUEST,
  DELETE_WEB_SUITE_ROOM_SUCCESS,
  DELETE_WEB_SUITE_ROOM_FAIL,
  GET_WEB_SUITE_ROOM_GALLERY_REQUEST,
  GET_WEB_SUITE_ROOM_GALLERY_SUCCESS,
  GET_WEB_SUITE_ROOM_GALLERY_FAIL,
  UPDATE_WEB_SUITE_ROOM_GALLERY_REQUEST,
  UPDATE_WEB_SUITE_ROOM_GALLERY_SUCCESS,
  UPDATE_WEB_SUITE_ROOM_GALLERY_FAIL,
} from "../actions/websiteSuites";
import WebSuites from "../../api/website_management/websiteSuites";

export default function* watcher() {
  yield takeLatest(ADD_WEB_SUITES_REQUEST, addSuitesAction);
  yield takeLatest(UPDATE_WEB_SUITES_REQUEST, updateSuitesAction);
  yield takeLatest(DELETE_WEB_SUITES_REQUEST, deleteSuitesAction);
  yield takeLatest(GET_SINGLE_WEB_SUITES_REQUEST, getSingleSuitesAction);
  yield takeLatest(GET_WEB_SUITES_REQUEST, handleGetSuitesAction);
  yield takeLatest(STATUS_WEB_SUITES_REQUEST, handleChangeStatusAction);
  yield takeLatest(EXPORT_WEB_SUITES_REQUEST, handleExportExcelAction);
  yield takeLatest(GET_WEB_SUITES_GALLERY_REQUEST, handleGetSuiteGalleryAction);
  yield takeLatest(
    UPDATE_WEB_SUITES_GALLERY_REQUEST,
    handleUpdateSuiteGalleryAction
  );
  //Suite Rooms
  yield takeLatest(GET_WEB_SUITE_ROOM_REQUEST, handleGetSuiteRoomAction);
  yield takeLatest(ADD_WEB_SUITE_ROOM_REQUEST, addSuiteRoomAction);
  yield takeLatest(GET_SINGLE_WEB_SUITE_ROOM_REQUEST, getSingleSuiteRoomAction);
  yield takeLatest(STATUS_WEB_SUITE_ROOM_REQUEST, handleChangeRoomStatusAction);
  yield takeLatest(UPDATE_WEB_SUITE_ROOM_REQUEST, updateSuiteRoomAction);
  yield takeLatest(DELETE_WEB_SUITE_ROOM_REQUEST, deleteSuiteRoomAction);
  yield takeLatest(
    GET_WEB_SUITE_ROOM_GALLERY_REQUEST,
    handleGetSuiteRoomGalleryAction
  );
  yield takeLatest(
    UPDATE_WEB_SUITE_ROOM_GALLERY_REQUEST,
    handleUpdateSuiteRoomGalleryAction
  );
}

function* addSuitesAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebSuites.addSuites, formData);
    yield put({
      type: ADD_WEB_SUITES_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_WEB_SUITES_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* updateSuitesAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebSuites.updateSuites, formData);
    yield put({
      type: UPDATE_WEB_SUITES_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_SUITES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* deleteSuitesAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebSuites.deleteSuites, id);
    yield put({
      type: DELETE_WEB_SUITES_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_WEB_SUITES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* getSingleSuitesAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebSuites.getSingleSuites, id);
    yield put({
      type: GET_SINGLE_WEB_SUITES_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_WEB_SUITES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetSuitesAction(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;

    const { data } = yield call(
      WebSuites.getSuites,
      page,
      limit,
      search,
      filter,
      sort
    );

    yield put({
      type: GET_WEB_SUITES_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_SUITES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleChangeStatusAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebSuites.changeSuitesStatus, id);
    yield put({
      type: STATUS_WEB_SUITES_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_WEB_SUITES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleExportExcelAction(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      WebSuites.handleExportSuitesExcel,
      page,
      search,
      filter,
      sort
    );
    yield put({
      type: EXPORT_WEB_SUITES_SUCCESS,
    });
    const blob = new Blob([data], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, `Website_Suites.xlsx`);
  } catch (e) {
    yield put({
      type: EXPORT_WEB_SUITES_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleGetSuiteGalleryAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebSuites.getGallery, id);
    yield put({
      type: GET_WEB_SUITES_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_SUITES_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateSuiteGalleryAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebSuites.updateGallery, id, formData);
    yield put({
      type: UPDATE_WEB_SUITES_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_SUITES_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

//Suite Rooms
function* handleGetSuiteRoomAction(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { id } = action.payload;

    const { data } = yield call(
      WebSuites.getSuiteRooms,
      id,
      page,
      limit,
      search,
      filter,
      sort
    );
    yield put({
      type: GET_WEB_SUITE_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_SUITE_ROOM_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* addSuiteRoomAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebSuites.addSuiteRoom, id, formData);
    yield put({
      type: ADD_WEB_SUITE_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_WEB_SUITE_ROOM_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* getSingleSuiteRoomAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebSuites.getSingleVillaRoom, id);
    yield put({
      type: GET_SINGLE_WEB_SUITE_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SINGLE_WEB_SUITE_ROOM_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleChangeRoomStatusAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebSuites.changeSuiteRoomStatus, id);
    yield put({
      type: STATUS_WEB_SUITE_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_WEB_SUITE_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* updateSuiteRoomAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebSuites.updateSuiteRoom, formData);
    yield put({
      type: UPDATE_WEB_SUITE_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_SUITE_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* deleteSuiteRoomAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebSuites.deleteSuiteRoom, id);
    yield put({
      type: DELETE_WEB_SUITE_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_WEB_SUITE_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* handleGetSuiteRoomGalleryAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebSuites.getRoomGallery, id);
    yield put({
      type: GET_WEB_SUITE_ROOM_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_SUITE_ROOM_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateSuiteRoomGalleryAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebSuites.updateRoomGallery, id, formData);
    yield put({
      type: UPDATE_WEB_SUITE_ROOM_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_SUITE_ROOM_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
