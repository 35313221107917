import React from 'react';
import '../../pages/tourVouchers/tourVoucher.scss';

function NoLocationWindow() {

    return (
        <div className="map_container_style">
            <p className="no_location_text_style">No location is shared yet</p>
        </div>

    );
}

export default NoLocationWindow;
