import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle.js";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import {
  getSingleTransferTypeAction,
  updateTransferTypeAction,
} from "../../store/actions/transferTypes";
import TransferTypesForm from "./TransferTypesForm.js";
import { blockPageAction } from "../../store/actions/users";
import { editEmailSetupAction } from "../../store/actions/emailSetups";
import { toast } from "react-toastify";
import EmailSetupsForm from "../emailSetups/EmailSetupsForm";
import ModalButtonsReset from "../../components/modals/modalButtons/ModalButtonsReset";
import { TrimmedData } from "../../services/TrimObjectFields";

function PreviewTransferTypes() {
  const rowStatus = useSelector((store) => store?.transferTypes?.status);
  let actionStatus = useSelector((store) => store?.transferTypes?.actionStatus);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({});
  const [siteName, setSiteName] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    handleEdit(id);
    return () => {
      dispatch(blockPageAction(""));
    };
  }, []);

  // Get the row data to edit
  const handleEdit = (id) => {
    dispatch(
      getSingleTransferTypeAction(id, (err, data) => {
        if (data) {
          setFormData(data?.transferType);
          setSiteName(
            data?.transferType?.type
              ? `ID: ${data?.transferType?.id} ${data?.transferType?.type}`
              : `ID: ${data?.transferType?.id}`
          );
        } else if (err) {
          navigate(`/dashboard`);
        }
      })
    );
  };

  const handleEditConfirm = async (data) => {
    const trimmedData = TrimmedData(data);
    setDisabled(true);
    dispatch(
      updateTransferTypeAction(trimmedData, (err, data) => {
        if (err) {
          toast.error(err.errors.error ? err.errors.error : err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        } else {
          if (data) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            navigate("/setup/transfer_types");
          }
          setTimeout(() => {
            setDisabled(false);
          }, 400);
        }
      })
    );
  };

  return (
    <>
      <PageTitle
        parentTitle={"Transfer Types"}
        childTitle={siteName}
        parentLink={"/setup/transfer_types"}
      />
      <div className="form-block">
        {rowStatus === "success" ? (
          <TransferTypesForm
            submit={handleEditConfirm}
            formData={formData}
            footer={
              <ModalButtonsReset
                disabled={disabled}
                isLoading={actionStatus === "pending"}
                submit={handleEditConfirm}
                reset={() => {
                  handleEdit(id);
                }}
              />
            }
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === "pending"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewTransferTypes;
