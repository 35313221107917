import { LOG_OUT } from "./users";
import { toast } from "react-toastify";

export const GET_TOUR_VOUCHERS_REQUEST = "GET_TOUR_VOUCHERS_REQUEST";
export const GET_TOUR_VOUCHERS_SUCCESS = "GET_TOUR_VOUCHERS_SUCCESS";
export const GET_TOUR_VOUCHERS_FAIL = "GET_TOUR_VOUCHERS_FAIL";

export function getTourVouchersAction(searchConfig, cb) {
  return {
    type: GET_TOUR_VOUCHERS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const GET_TOUR_PREVIEW_VOUCHERS_REQUEST =
  "GET_TOUR_PREVIEW_VOUCHERS_REQUEST";
export const GET_TOUR_PREVIEW_VOUCHERS_SUCCESS =
  "GET_TOUR_PREVIEW_VOUCHERS_SUCCESS";
export const GET_TOUR_PREVIEW_VOUCHERS_FAIL = "GET_TOUR_PREVIEW_VOUCHERS_FAIL";

export function getTourPreviewVouchersAction(id, searchConfig, cb) {
  return {
    type: GET_TOUR_PREVIEW_VOUCHERS_REQUEST,
    payload: {
      id,
      searchConfig,
      cb,
    },
  };
}

export const GET_ALL_TOUR_VOUCHERS_REQUEST = "GET_ALL_TOUR_VOUCHERS_REQUEST";
export const GET_ALL_TOUR_VOUCHERS_SUCCESS = "GET_ALL_TOUR_VOUCHERS_SUCCESS";
export const GET_ALL_TOUR_VOUCHERS_FAIL = "GET_ALL_TOUR_VOUCHERS_FAIL";

export function getAllTourVouchers(cb) {
  return {
    type: GET_ALL_TOUR_VOUCHERS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const ADD_TOUR_VOUCHER_REQUEST = "ADD_TOUR_VOUCHER_REQUEST";
export const ADD_TOUR_VOUCHER_SUCCESS = "ADD_TOUR_VOUCHER_SUCCESS";
export const ADD_TOUR_VOUCHER_FAIL = "ADD_TOUR_VOUCHER_FAIL";

export function addTourVoucherAction(formData, cb) {
  return {
    type: ADD_TOUR_VOUCHER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GO_TO_TOUR_VOUCHER_PREVIEW_REQUEST =
  "GO_TO_TOUR_VOUCHER_PREVIEW_REQUEST";
export const GO_TO_TOUR_VOUCHER_PREVIEW_SUCCESS =
  "GO_TO_TOUR_VOUCHER_PREVIEW_SUCCESS";
export const GO_TO_TOUR_VOUCHER_PREVIEW_FAIL =
  "GO_TO_TOUR_VOUCHER_PREVIEW_FAIL";

export function goToPreviewAction(id, cb) {
  return {
    type: GO_TO_TOUR_VOUCHER_PREVIEW_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_TOUR_VOUCHER_REQUEST = "DELETE_TOUR_VOUCHER_REQUEST";
export const DELETE_TOUR_VOUCHER_SUCCESS = "DELETE_TOUR_VOUCHER_SUCCESS";
export const DELETE_TOUR_VOUCHER_FAIL = "DELETE_TOUR_VOUCHER_FAIL";

export function deleteTourVoucher(id, cb) {
  return {
    type: DELETE_TOUR_VOUCHER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_SELECTED_TOUR_VOUCHERS_REQUEST =
  "DELETE_SELECTED_TOUR_VOUCHERS_REQUEST";
export const DELETE_SELECTED_TOUR_VOUCHERS_SUCCESS =
  "DELETE_SELECTED_TOUR_VOUCHERS_SUCCESS";
export const DELETE_SELECTED_TOUR_VOUCHERS_FAIL =
  "DELETE_SELECTED_TOUR_VOUCHERS_FAIL";

export function deleteSelectedTourVouchers(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_TOUR_VOUCHERS_REQUEST,
    payload: {
      selectedRows,
      cb,
    },
  };
}

export const UPDATE_TOUR_VOUCHER_REQUEST = "UPDATE_TOUR_VOUCHER_REQUEST";
export const UPDATE_TOUR_VOUCHER_SUCCESS = "UPDATE_TOUR_VOUCHER_SUCCESS";
export const UPDATE_TOUR_VOUCHER_FAIL = "UPDATE_TOUR_VOUCHER_FAIL";

export function updateTourVoucher(formData, cb) {
  return {
    type: UPDATE_TOUR_VOUCHER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_TOUR_VOUCHER_STATE_REQUEST =
  "UPDATE_TOUR_VOUCHER_STATE_REQUEST";
export const UPDATE_TOUR_VOUCHER_STATE_SUCCESS =
  "UPDATE_TOUR_VOUCHER_STATE_SUCCESS";
export const UPDATE_TOUR_VOUCHER_STATE_FAIL = "UPDATE_TOUR_VOUCHER_STATE_FAIL";

export function updateTourVoucherState(formData, cb) {
  return {
    type: UPDATE_TOUR_VOUCHER_STATE_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_VOUCHER_LOCATION_REQUEST = "GET_VOUCHER_LOCATION_REQUEST";
export const GET_VOUCHER_LOCATION_SUCCESS = "GET_VOUCHER_LOCATION_SUCCESS";
export const GET_VOUCHER_LOCATION_FAIL = "GET_VOUCHER_LOCATION_FAIL";

export function getVoucherLocation(id, cb) {
  return {
    type: GET_VOUCHER_LOCATION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const ADD_COMMENT_TOUR_VOUCHER_REQUEST =
  "ADD_COMMENT_TOUR_VOUCHER_REQUEST";
export const ADD_COMMENT_TOUR_VOUCHER_SUCCESS =
  "ADD_COMMENT_TOUR_VOUCHER_SUCCESS";
export const ADD_COMMENT_TOUR_VOUCHER_FAIL = "ADD_COMMENT_TOUR_VOUCHER_FAIL";

export function addCommentTourVoucherAction(id, comment, cb) {
  return {
    type: ADD_COMMENT_TOUR_VOUCHER_REQUEST,
    payload: {
      comment,
      id,
      cb,
    },
  };
}

export const CHANGE_FETCH_PERCENT = "CHANGE_FETCH_PERCENT";

export function changeFetchPercent(dispatch, percent) {
  if (dispatch) {
    dispatch({
      type: CHANGE_FETCH_PERCENT,
      payload: {
        percent,
      },
    });
  }
}

export function finalizeFetching(dispatch, percent) {
  if (dispatch) {
    dispatch({
      type: GET_TOUR_VOUCHERS_REQUEST,
      payload: {
        searchConfig: {
          defaultFilter: false,
          filter: {},
          limit: 10,
          page: 1,
          search: "",
          sort: [{ field: "updatedAt", sort: "desc" }],
        },
      },
    });
  }
}

export const GET_SINGLE_TOUR_VOUCHER_REQUEST =
  "GET_SINGLE_TOUR_VOUCHER_REQUEST";
export const GET_SINGLE_TOUR_VOUCHER_SUCCESS =
  "GET_SINGLE_TOUR_VOUCHER_SUCCESS";
export const GET_SINGLE_TOUR_VOUCHER_FAIL = "GET_SINGLE_TOUR_VOUCHER_FAIL";

export function getSingleTourVoucher(id, cb) {
  return {
    type: GET_SINGLE_TOUR_VOUCHER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const CHANGE_TOUR_VOUCHER_STATUS_REQUEST =
  "CHANGE_TOUR_VOUCHER_STATUS_REQUEST";
export const CHANGE_TOUR_VOUCHER_STATUS_SUCCESS =
  "CHANGE_TOUR_VOUCHER_STATUS_SUCCESS";
export const CHANGE_TOUR_VOUCHER_STATUS_FAIL =
  "CHANGE_TOUR_VOUCHER_STATUS_FAIL";

export function changeTourVoucherStatus(id, cb) {
  return {
    type: CHANGE_TOUR_VOUCHER_STATUS_REQUEST,
    payload: { id, cb },
  };
}

export const EXPORT_TOUR_VOUCHERS_REQUEST = "EXPORT_TOUR_VOUCHERS_REQUEST";
export const EXPORT_TOUR_VOUCHERS_SUCCESS = "EXPORT_TOUR_VOUCHERS_SUCCESS";
export const EXPORT_TOUR_VOUCHERS_FAIL = "EXPORT_TOUR_VOUCHERS_FAIL";

export function exportExcelVoucherAction(searchConfig, cb) {
  return {
    type: EXPORT_TOUR_VOUCHERS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const COPY_TOUR_VOUCHER_REQUEST = "COPY_TOUR_VOUCHER_REQUEST";
export const COPY_TOUR_VOUCHER_SUCCESS = "COPY_TOUR_VOUCHER_SUCCESS";
export const COPY_TOUR_VOUCHER_FAIL = "COPY_TOUR_VOUCHER_FAIL";

export function copyTourVoucher(id, cb) {
  return {
    type: COPY_TOUR_VOUCHER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const SEND_EMAIL_TOUR_VOUCHER_REQUEST =
  "SEND_EMAIL_TOUR_VOUCHER_REQUEST";
export const SEND_EMAIL_TOUR_VOUCHER_SUCCESS =
  "SEND_EMAIL_TOUR_VOUCHER_SUCCESS";
export const SEND_EMAIL_TOUR_VOUCHER_FAIL = "SEND_EMAIL_TOUR_VOUCHER_FAIL";

export function sendEmailTourVoucherAction(formData, id, hash, cb) {
  return {
    type: SEND_EMAIL_TOUR_VOUCHER_REQUEST,
    payload: {
      formData,
      id,
      hash,
      cb,
    },
  };
}

export const DOWNLOAD_VOUCHER_REQUEST = "DOWNLOAD_VOUCHER_REQUEST";
export const DOWNLOAD_VOUCHER_SUCCESS = "DOWNLOAD_VOUCHER_SUCCESS";
export const DOWNLOAD_VOUCHER_FAIL = "DOWNLOAD_VOUCHER_FAIL";

export function downloadVoucherAction(id, ag_ref_number, page, role, hash, cb) {
  return {
    type: DOWNLOAD_VOUCHER_REQUEST,
    payload: {
      id,
      ag_ref_number,
      page,
      hash,
      role,
      cb,
    },
  };
}

export const CHECK_VOUCHER_REQUEST = "CHECK_VOUCHER_REQUEST";
export const CHECK_VOUCHER_SUCCESS = "CHECK_VOUCHER_SUCCESS";
export const CHECK_VOUCHER_FAIL = "CHECK_VOUCHER_FAIL";

export function checkVoucherAction(formData, cb) {
  return {
    type: CHECK_VOUCHER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}
export const CHECK_PREVIEW_VOUCHER_REQUEST = "CHECK_PREVIEW_VOUCHER_REQUEST";
export const CHECK_PREVIEW_VOUCHER_SUCCESS = "CHECK_PREVIEW_VOUCHER_SUCCESS";
export const CHECK_PREVIEW_VOUCHER_FAIL = "CHECK_PREVIEW_VOUCHER_FAIL";

export function checkPreviewVoucherAction(ref_num, cb) {
  return {
    type: CHECK_PREVIEW_VOUCHER_REQUEST,
    payload: {
      ref_num,
      cb,
    },
  };
}

export const FETCH_TOUR_VOUCHER_REQUEST = "FETCH_TOUR_VOUCHER_REQUEST";
export const FETCH_TOUR_VOUCHER_SUCCESS = "FETCH_TOUR_VOUCHER_SUCCESS";
export const FETCH_TOUR_VOUCHER_FAIL = "FETCH_TOUR_VOUCHER_FAIL";

export function fetchTourVoucher(cb) {
  return {
    type: FETCH_TOUR_VOUCHER_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_DRIVERS_LOCATION_REQUEST = "GET_DRIVERS_LOCATION_REQUEST";
export const GET_DRIVERS_LOCATION_SUCCESS = "GET_DRIVERS_LOCATION_SUCCESS";
export const GET_DRIVERS_LOCATION_FAIL = "GET_DRIVERS_LOCATION_FAIL";

export function getDriversLocation(location, cb) {
  return {
    type: GET_DRIVERS_LOCATION_REQUEST,
    payload: {
      location,
      cb,
    },
  };
}

export const SHARE_MY_LOCATION_REQUEST = "SHARE_MY_LOCATION_REQUEST";
export const SHARE_MY_LOCATION_SUCCESS = "SHARE_MY_LOCATION_SUCCESS";
export const SHARE_MY_LOCATION_FAIL = "SHARE_MY_LOCATION_FAIL";

export function shareMyLocation(latitude, longitude, voucher_id, cb) {
  return {
    type: SHARE_MY_LOCATION_REQUEST,
    payload: {
      latitude,
      longitude,
      voucher_id,
      cb,
    },
  };
}

export const DOWNLOAD_TOUR_VOUCHERS_REPORT_REQUEST =
  "DOWNLOAD_TOUR_VOUCHERS_REPORT_REQUEST";
export const DOWNLOAD_TOUR_VOUCHERS_REPORT_SUCCESS =
  "DOWNLOAD_TOUR_VOUCHERS_REPORT_SUCCESS";
export const DOWNLOAD_TOUR_VOUCHERS_REPORT_FAIL =
  "DOWNLOAD_TOUR_VOUCHERS_REPORT_FAIL";

export function downloadReportVoucherAction(data, cb) {
  return {
    type: DOWNLOAD_TOUR_VOUCHERS_REPORT_REQUEST,
    payload: { data, cb },
  };
}

export const GET_TOUR_VOUCHERS_FOR_KPI_REPORT_REQUEST =
  "GET_TOUR_VOUCHERS_FOR_KPI_REPORT_REQUEST";
export const GET_TOUR_VOUCHERS_FOR_KPI_REPORT_SUCCESS =
  "GET_TOUR_VOUCHERS_FOR_KPI_REPORT_SUCCESS";
export const GET_TOUR_VOUCHERS_FOR_KPI_REPORT_FAIL =
  "GET_TOUR_VOUCHERS_FOR_KPI_REPORT_FAIL";

export function getTourVouchersForKPIReport(cb) {
  return {
    type: GET_TOUR_VOUCHERS_FOR_KPI_REPORT_REQUEST,
    payload: { cb },
  };
}

export const DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_REQUEST =
  "DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_REQUEST";
export const DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_SUCCESS =
  "DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_SUCCESS";
export const DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_FAIL =
  "DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_FAIL";

export function downloadKPIReportVoucherAction(data, cb) {
  return {
    type: DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_REQUEST,
    payload: { data, cb },
  };
}
