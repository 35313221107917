import {
  GET_DIRECTIONS_REQUEST,
  GET_DIRECTIONS_SUCCESS,
  GET_SINGLE_DIRECTION_REQUEST,
  GET_SINGLE_DIRECTION_SUCCESS,
  GET_DIRECTIONS_FAIL,
  GET_ALL_DIRECTIONS_REQUEST,
  GET_ALL_DIRECTIONS_SUCCESS,
  EXPORT_DIRECTIONS_REQUEST,
  EXPORT_DIRECTIONS_SUCCESS,
  EXPORT_DIRECTIONS_FAIL,
  ADD_DIRECTION_REQUEST,
  ADD_DIRECTION_SUCCESS,
  ADD_DIRECTION_FAIL,
  DELETE_DIRECTION_REQUEST,
  DELETE_DIRECTION_SUCCESS,
  DELETE_DIRECTION_FAIL,
  DELETE_SELECTED_DIRECTIONS_SUCCESS,
  DELETE_SELECTED_DIRECTIONS_FAIL,
  DELETE_SELECTED_DIRECTIONS_REQUEST,
  UPDATE_DIRECTION_REQUEST,
  UPDATE_DIRECTION_SUCCESS,
  UPDATE_DIRECTION_FAIL,
  STATUS_DIRECTION_REQUEST,
  STATUS_DIRECTION_SUCCESS,
  STATUS_DIRECTION_FAIL,
} from '../actions/directions';

const initialState = {
  directions: [],
  singleDirection: {},
  allDirections: [],
  status: '',
  exportExcelStatus: '',
  actionStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DIRECTIONS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_DIRECTIONS_SUCCESS: {
      return {
        ...state,
        directions: action.payload.data.rows,
        status: 'success',
        count: action.payload.data.count,
      };
    }
    case GET_DIRECTIONS_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }

    case GET_ALL_DIRECTIONS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_ALL_DIRECTIONS_SUCCESS: {
      return {
        ...state,
        allDirections: action.payload.data.allDirections,
        status: 'success',
      };
    }

    case GET_SINGLE_DIRECTION_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_DIRECTION_SUCCESS: {
      return {
        ...state,
        singleDirection: action.payload.data.result,
        status: 'success',
      };
    }
    case ADD_DIRECTION_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case ADD_DIRECTION_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case ADD_DIRECTION_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case DELETE_DIRECTION_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_DIRECTION_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_DIRECTION_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case DELETE_SELECTED_DIRECTIONS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_SELECTED_DIRECTIONS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_SELECTED_DIRECTIONS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case UPDATE_DIRECTION_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case UPDATE_DIRECTION_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case UPDATE_DIRECTION_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case STATUS_DIRECTION_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case STATUS_DIRECTION_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case STATUS_DIRECTION_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case EXPORT_DIRECTIONS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: 'pending',
      };
    }
    case EXPORT_DIRECTIONS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: 'success',
      };
    }
    case EXPORT_DIRECTIONS_FAIL: {
      return {
        ...state,
        exportExcelStatus: 'fail',
      };
    }

    default: {
      return state;
    }
  }
}
