import { Grid, Container } from '@mui/material';
import DataCard from '../dataCard/DataCard';
import { BsViewList, BsCardChecklist } from 'react-icons/bs';
import DataList from '../dataList/DataList';
import { changeCardView, changeListView } from '../../store/actions/settings';
import { useDispatch, useSelector } from 'react-redux';
import LoadingCircle from '../loading/LoadingCircle';
import './mobileLayout.scss';

export default function MobileLayout({
  rows,
  columns,
  mobileCol,
  status,
  changeStatusHandler,
  rowStatus,
}) {
  const card = useSelector(store => store?.orders?.card);
  const dispatch = useDispatch();

  const changeToCard = () => {
    dispatch(changeCardView());
    localStorage.setItem('cardView', 'true');
  };
  const changeToList = () => {
    dispatch(changeListView());
    localStorage.setItem('cardView', 'false');
  };

  return (
    <Container maxWidth="lg" className="mobile_view">
      {/* <div className="icon_block_mobile_view">
        {mobileCol?.mobileCard.length === 0 ? (
          <div className="mode_block">
            <BsCardChecklist
              onClick={changeToCard}
              style={{ background: card ? 'rgb(81 116 124 / 17%)' : null }}
            />
          </div>
        ) : (
          <div className="mode_block">
            <BsViewList
              onClick={changeToList}
              style={{ background: !card ? 'rgb(81 116 124 / 17%)' : null }}
            />
            <BsCardChecklist
              onClick={changeToCard}
              style={{ background: card ? 'rgb(81 116 124 / 17%)' : null }}
            />
          </div>
        )}
      </div> */}
      <Grid
        container
        spacing={2}
        direction="column"
        marginTop="0"
        justifyContent="center"
      >
        {Array.isArray(rows) && rows?.length ? (
            rows?.map(row => (
              <Grid key={row.id} item style={{ width: '100%' }}>
                <DataCard
                  key={row.id}
                  row={row}
                  columns={columns}
                  mobileCol={mobileCol.mobileCard}
                  seeMore={mobileCol.mobileCard}
                  status={status}
                  changeStatusHandler={changeStatusHandler}
                />
              </Grid>
            ))
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10%',
              }}
            >
              <LoadingCircle rows={rows} status={rowStatus} />
            </div>
          )}
      </Grid>
    </Container>
  );
}
