import {GET_SELECT_TOURS_REQUEST} from "./tours";

export const GET_ALL_TOURS_TRANSFERS_REQUEST =
  'GET_ALL_TOURS_TRANSFERS_REQUEST';
export const GET_ALL_TOURS_TRANSFERS_SUCCESS =
  'GET_ALL_TOURS_TRANSFERS_SUCCESS';
export const GET_ALL_TOURS_TRANSFERS_FAIL = 'GET_ALL_TOURS_TRANSFERS_FAIL';

export function getAllToursAction(cb) {
  return {
    type: GET_ALL_TOURS_TRANSFERS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_TOURS_TRANSFERS_REQUEST = 'GET_TOURS_TRANSFERS_REQUEST';
export const GET_TOURS_TRANSFERS_SUCCESS = 'GET_TOURS_TRANSFERS_SUCCESS';
export const GET_TOURS_TRANSFERS_FAIL = 'GET_TOURS_TRANSFERS_FAIL';

export function getToursTransfersAction(searchConfig, cb) {
  return {
    type: GET_TOURS_TRANSFERS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const ADD_TOUR_TRANSFERS_REQUEST = 'ADD_TOUR_TRANSFERS_REQUEST';
export const ADD_TOUR_TRANSFERS_SUCCESS = 'ADD_TOUR_TRANSFERS_SUCCESS';
export const ADD_TOUR_TRANSFERS_FAIL = 'ADD_TOUR_TRANSFERS_FAIL';

export function addTourTransferAction(formData, cb) {
  return {
    type: ADD_TOUR_TRANSFERS_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_TOUR_TRANSFERS_REQUEST = 'DELETE_TOUR_TRANSFERS_REQUEST';
export const DELETE_TOUR_TRANSFERS_SUCCESS = 'DELETE_TOUR_TRANSFERS_SUCCESS';
export const DELETE_TOUR_TRANSFERS_FAIL = 'DELETE_TOUR_TRANSFERS_FAIL';

export function deleteTourTransfer(id, cb) {
  return {
    type: DELETE_TOUR_TRANSFERS_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_SELECTED_TOURS_TRANSFERS_REQUEST =
  'DELETE_SELECTED_TOURS_TRANSFERS_REQUEST';
export const DELETE_SELECTED_TOURS_TRANSFERS_SUCCESS =
  'DELETE_SELECTED_TOURS_TRANSFERS_SUCCESS';
export const DELETE_SELECTED_TOURS_TRANSFERS_FAIL =
  'DELETE_SELECTED_TOURS_TRANSFERS_FAIL';

export function deleteSelectedToursTransfers(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_TOURS_TRANSFERS_REQUEST,
    payload: {
      selectedRows,
      cb,
    },
  };
}

export const UPDATE_TOUR_TRANSFERS_REQUEST = 'UPDATE_TOUR_TRANSFERS_REQUEST';
export const UPDATE_TOUR_TRANSFERS_SUCCESS = 'UPDATE_TOUR_TRANSFERS_SUCCESS';
export const UPDATE_TOUR_TRANSFERS_FAIL = 'UPDATE_TOUR_TRANSFERS_FAIL';

export function updateTourTransfer(formData, cb) {
  return {
    type: UPDATE_TOUR_TRANSFERS_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const STATUS_TOUR_TRANSFERS_REQUEST = 'STATUS_TOUR_TRANSFERS_REQUEST';
export const STATUS_TOUR_TRANSFERS_SUCCESS = 'STATUS_TOUR_TRANSFERS_SUCCESS';
export const STATUS_TOUR_TRANSFERS_FAIL = 'STATUS_TOUR_TRANSFERS_FAIL';

export function changeTourStatusAction(id, cb) {
  return {
    type: STATUS_TOUR_TRANSFERS_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const ADD_COMMENT_TOUR_TRANSFERS_REQUEST =
  'ADD_COMMENT_TOUR_TRANSFERS_REQUEST';
export const ADD_COMMENT_TOUR_TRANSFERS_SUCCESS =
  'ADD_COMMENT_TOUR_TRANSFERS_SUCCESS';
export const ADD_COMMENT_TOUR_TRANSFERS_FAIL =
  'ADD_COMMENT_TOUR_TRANSFERS_FAIL';

export function addCommentTourTransfersAction(id, hidden_note, cb) {
  return {
    type: ADD_COMMENT_TOUR_TRANSFERS_REQUEST,
    payload: {
      hidden_note,
      id,
      cb,
    },
  };
}

export const GET_SINGLE_TOUR_TRANSFERS_REQUEST =
  'GET_SINGLE_TOUR_TRANSFERS_REQUEST';
export const GET_SINGLE_TOUR_TRANSFERS_SUCCESS =
  'GET_SINGLE_TOUR_TRANSFERS_SUCCESS';
export const GET_SINGLE_TOUR_TRANSFERS_FAIL = 'GET_SINGLE_TOUR_TRANSFERS_FAIL';

export function getSingleTourTransfer(id, cb) {
  return {
    type: GET_SINGLE_TOUR_TRANSFERS_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_TOUR_TRANSFERS_REQUEST = 'EXPORT_TOUR_TRANSFERS_REQUEST';
export const EXPORT_TOUR_TRANSFERS_SUCCESS = 'EXPORT_TOUR_TRANSFERS_SUCCESS';
export const EXPORT_TOUR_TRANSFERS_FAIL = 'EXPORT_TOUR_TRANSFERS_FAIL';

export function exportExcelTourTransfersAction(searchConfig, cb) {
  return {
    type: EXPORT_TOUR_TRANSFERS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const COPY_TOUR_TRANSFER_REQUEST = 'COPY_TOUR_TRANSFER_REQUEST';
export const COPY_TOUR_TRANSFER_SUCCESS = 'COPY_TOUR_TRANSFER_SUCCESS';
export const COPY_TOUR_TRANSFER_FAIL = 'COPY_TOUR_TRANSFER_FAIL';

export function copyTourTransfer(id, cb) {
  return {
    type: COPY_TOUR_TRANSFER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const SEND_EMAIL_TOUR_TRANSFER_REQUEST =
  'SEND_EMAIL_TOUR_TRANSFER_REQUEST';
export const SEND_EMAIL_TOUR_TRANSFER_SUCCESS =
  'SEND_EMAIL_TOUR_TRANSFER_SUCCESS';
export const SEND_EMAIL_TOUR_TRANSFER_FAIL = 'SEND_EMAIL_TOUR_TRANSFER_FAIL';

export function sendEmailTourTransferAction(formData, id, cb) {
  return {
    type: SEND_EMAIL_TOUR_TRANSFER_REQUEST,
    payload: {
      formData,
      id,
      cb,
    },
  };
}

export const DOWNLOAD_TRANSFER_REQUEST = 'DOWNLOAD_TRANSFER_REQUEST';
export const DOWNLOAD_TRANSFER_SUCCESS = 'DOWNLOAD_TRANSFER_SUCCESS';
export const DOWNLOAD_TRANSFER_FAIL = 'DOWNLOAD_TRANSFER_FAIL';

export function downloadTransferAction(id, ref_number, page, cb) {
  return {
    type: DOWNLOAD_TRANSFER_REQUEST,
    payload: {
      id,
      ref_number,
      page,
      cb,
    },
  };
}

export const CHECK_TRANSFER_REQUEST = 'CHECK_TRANSFER_REQUEST';
export const CHECK_TRANSFER_SUCCESS = 'CHECK_TRANSFER_SUCCESS';
export const CHECK_TRANSFER_FAIL = 'CHECK_TRANSFER_FAIL';

export function checkTransferAction(formData, cb) {
  return {
    type: CHECK_TRANSFER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const EXPORT_TOUR_TRANSFER_REQUEST = 'EXPORT_TOUR_TRANSFER_REQUEST';
export const EXPORT_TOUR_TRANSFER_SUCCESS = 'EXPORT_TOUR_TRANSFER_SUCCESS';
export const EXPORT_TOUR_TRANSFER_FAIL = 'EXPORT_TOUR_TRANSFER_FAIL';

export function exportExcelAction(searchConfig, cb) {
  return {
    type: EXPORT_TOUR_TRANSFER_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const GET_SELECT_TRANSFERS_REQUEST = 'GET_SELECT_TRANSFERS_REQUEST';
export const GET_SELECT_TRANSFERS_SUCCESS = 'GET_SELECT_TRANSFERS_SUCCESS';
export const GET_SELECT_TRANSFERS_FAIL = 'GET_SELECT_TRANSFERS_FAIL';

export function getSelectTransfers(service_date, cb) {
  return {
    type: GET_SELECT_TRANSFERS_REQUEST,
    payload: {
      service_date,
      cb,
    },
  };
}
