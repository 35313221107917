import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_TRANSFER_TYPES_REQUEST,
  GET_TRANSFER_TYPES_SUCCESS,
  GET_TRANSFER_TYPES_FAIL,
  ADD_TRANSFER_TYPE_REQUEST,
  ADD_TRANSFER_TYPE_SUCCESS,
  ADD_TRANSFER_TYPE_FAIL,
  DELETE_TRANSFER_TYPE_REQUEST,
  DELETE_TRANSFER_TYPE_SUCCESS,
  DELETE_TRANSFER_TYPE_FAIL,
  GET_SINGLE_TRANSFER_TYPE_REQUEST,
  GET_SINGLE_TRANSFER_TYPE_SUCCESS,
  GET_SINGLE_TRANSFER_TYPE_FAIL,
  UPDATE_TRANSFER_TYPE_REQUEST,
  UPDATE_TRANSFER_TYPE_SUCCESS,
  UPDATE_TRANSFER_TYPE_FAIL,
  DELETE_SELECTED_TRANSFER_TYPES_REQUEST,
  DELETE_SELECTED_TRANSFER_TYPES_SUCCESS,
  DELETE_SELECTED_TRANSFER_TYPES_FAIL,
  GET_ALL_TRANSFER_TYPES_REQUEST,
  GET_ALL_TRANSFER_TYPES_SUCCESS,
  GET_ALL_TRANSFER_TYPES_FAIL,
  EXPORT_TRANSFER_TYPES_REQUEST,
  EXPORT_TRANSFER_TYPES_SUCCESS,
  EXPORT_TRANSFER_TYPES_FAIL,
  UPDATE_STATUS_TRANSFER_TYPE_REQUEST,
  UPDATE_STATUS_TRANSFER_TYPE_SUCCESS,
  UPDATE_STATUS_TRANSFER_TYPE_FAIL,
} from '../actions/transferTypes';
import TransferTypes from '../../api/transferTypes';

export default function* watcher() {
  yield takeLatest(GET_TRANSFER_TYPES_REQUEST, getTransferTypes);
  yield takeLatest(DELETE_TRANSFER_TYPE_REQUEST, deleteTransferType);
  yield takeLatest(ADD_TRANSFER_TYPE_REQUEST, addTransferType);
  yield takeLatest(GET_SINGLE_TRANSFER_TYPE_REQUEST, getSingleTransferType);
  yield takeLatest(UPDATE_TRANSFER_TYPE_REQUEST, updateTransferType);
  yield takeLatest(
    DELETE_SELECTED_TRANSFER_TYPES_REQUEST,
    deleteSelectedTransferTypes,
  );
  yield takeLatest(GET_ALL_TRANSFER_TYPES_REQUEST, getAllTransferTypes);
  yield takeLatest(EXPORT_TRANSFER_TYPES_REQUEST, handleExportExcel);
  yield takeLatest(UPDATE_STATUS_TRANSFER_TYPE_REQUEST, handleStatusUpdate);
}
function* addTransferType(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(TransferTypes.addTransferType, formData);
    yield put({
      type: ADD_TRANSFER_TYPE_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_TRANSFER_TYPE_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* getTransferTypes(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      TransferTypes.getTransferTypes,
      page,
      limit,
      search,
      filter,
      sort,
    );
    yield put({
      type: GET_TRANSFER_TYPES_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_TRANSFER_TYPES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* deleteTransferType(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(TransferTypes.deleteTransferType, id);
    yield put({
      type: DELETE_TRANSFER_TYPE_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_TRANSFER_TYPE_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}
function* deleteSelectedTransferTypes(action) {
  try {
    const { selectedRows } = action.payload;
    const { data } = yield call(
      TransferTypes.deleteSelectedTransferTypes,
      selectedRows,
    );
    yield put({
      type: DELETE_SELECTED_TRANSFER_TYPES_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: DELETE_SELECTED_TRANSFER_TYPES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* getSingleTransferType(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(TransferTypes.getSingleTransferType, id);
    yield put({
      type: GET_SINGLE_TRANSFER_TYPE_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_TRANSFER_TYPE_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* updateTransferType(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(TransferTypes.updateTransferType, formData);
    yield put({
      type: UPDATE_TRANSFER_TYPE_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_TRANSFER_TYPE_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* getAllTransferTypes(action) {
  try {
    const { data } = yield call(TransferTypes.getAllTransferTypes);
    yield put({
      type: GET_ALL_TRANSFER_TYPES_SUCCESS,
      payload: {
        data: data,
      },
    });
  } catch (e) {
    yield put({
      type: GET_ALL_TRANSFER_TYPES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}
function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      TransferTypes.handleExportTransferTypesExcel,
      page,
      search,
      filter,
      sort,
    );
    yield put({
      type: EXPORT_TRANSFER_TYPES_SUCCESS,
    });
    const blob = new Blob([data], {
      type: 'application/vnd.ms-excel',
    });
    saveAs(blob, `TransferTypes.xlsx`);
  } catch (e) {
    console.log(e);
    yield put({
      type: EXPORT_TRANSFER_TYPES_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
function* handleStatusUpdate(action) {
  try {
    const { id } = action.payload;

    const { data } = yield call(TransferTypes.updateStatus, id);

    yield put({
      type: UPDATE_STATUS_TRANSFER_TYPE_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_STATUS_TRANSFER_TYPE_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}
