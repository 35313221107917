import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../components/table";
import Pagination from "../../components/pagination/Pagination";
import SearchFilterBar from "../../components/search_filter_bar/SearchFilterBar";
import TableTitle from "../../components/table_title/TableTitle";
import {
  clearSearchAction,
  getHashedIdAction,
  setPage,
  sortAction,
} from "../../store/actions/search";
import DeleteModal2 from "../../components/modals/DeleteModal2";
import { getColumns } from "./column";
import TransferVouchersForm from "./TransferVouchersForm";
import {
  addCommentTransferVoucherAction,
  addTransferVoucherAction,
  changeTransferVoucherStatus,
  copyTransferVoucher,
  deleteTransferVoucher,
  downloadKPIReportTransferVouchersAction,
  downloadReportTransferVouchersAction,
  downloadTransferVoucherAction,
  exportExcelTransferVoucherAction,
  fetchTourVoucher,
  getSingleTransferVoucher,
  getTransferPreviewVouchersAction,
  getTransferVouchersAction,
  goToTransferVoucherPreviewAction,
  sendEmailTransferVoucherAction,
  updateTransferVoucher,
  updateTransferVoucherState,
} from "../../store/actions/transferVouchers";
import CommentModal from "../../components/modals/CommentModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import EmailModal from "../../components/modals/EmailModal";
import UpdateStatusModal from "../../components/modals/UpdateStatusModal";
import SelectModal from "../../components/modals/SelectModal";
import PageTitle from "../../components/PageTitle";
import { isEmpty } from "lodash";
import SeeLocationModal from "../../components/modals/SeeLocationModal";
import "./transferVoucher.scss";
import { defaultSearchConfigs } from "../../services/globalVariables";
import NoLocationWindow from "../../components/location/NoLocationWindow";
import LocationMapTransfer from "../../components/location/LocationMapTransfer";
import {
  deleteSelectedTourVouchers,
  goToPreviewAction,
} from "../../store/actions/tourVouchers";
import MobileLayout from "../../components/mobileLayout/MobileLayout";
import { transfer_vouchers_mobile_column } from "../../staticVariables/mobileColumns";
import DynamicModal from "../../components/modals/DynamicModal";
import PickUpPointsForm from "../pickUpPoints/PickUpPointsForm";
import ModalButtons from "../../components/modals/modalButtons/ModalButtons";
import {
  addPickUpPointAction,
  getPickUpPointsAction,
} from "../../store/actions/pickUpPoints";
import GenerateTransfersReportModal from "../../components/modals/GenerateTransfersReportModal";

const state = [
  { id: 1, status: "New", value: "New" },
  { id: 2, status: "Confirmed", value: "Confirmed" },
  { id: 3, status: "Cancelled", value: "Cancelled" },
  { id: 4, status: "Reserved", value: "Reserved" },
  { id: 5, status: "Rejected", value: "Rejected" },
];

export const transferVoucherStatus = [
  {
    id: "Active",
    color: "green",
  },
  {
    id: "Inactive",
    color: "#dc3545",
  },
];

function TransferVouchers() {
  let rows = useSelector((store) => store?.transferVouchers?.transferVouchers);
  let coordinates = useSelector(
    (store) => store?.transferVouchers?.coordinates
  );
  let vouchersWithLocation = useSelector(
    (store) => store?.transferVouchers?.transfersWithLocation
  );
  let fetchStatus = useSelector(
    (store) => store?.transferVouchers?.fetchStatus
  );
  let actionStatus = useSelector(
    (store) => store?.transferVouchers?.actionStatus
  );
  let exportExcelStatus = useSelector(
    (store) => store?.transferVouchers?.exportExcelStatus
  );
  let childTitle = useSelector((store) => store?.transferVouchers?.tableTitle);
  let rowStatus = useSelector((store) => store?.transferVouchers?.status);
  let tableTitle = useSelector((store) => store?.transferVouchers?.tableTitle);
  const accessList = useSelector((store) => store.users.accessList);
  const count = useSelector((store) => store.transferVouchers.count);
  const account = useSelector((store) => store.users.account);
  let searchConfig = useSelector((store) => store.search);
  let downloadStatus = useSelector(
    (store) => store.transferVouchers.downloadStatus
  );
  let downloadStatusId = useSelector(
    (store) => store.transferVouchers.downloadStatusId
  );
  let downloadReportStatus = useSelector(
    (store) => store?.transferVouchers?.downloadReportStatus
  );

  const [showPassengersLocation, setShowPassengersLocation] = useState(false);
  const [passengerData, setPassengerData] = useState({});
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const [emailModal, setEmailModal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const [openEmailPreview, setOpenEmailPreview] = useState(false);
  const [email_body, setEmail_body] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({});
  const [comment, setComment] = useState("");
  const [show, setShow] = useState(false);
  const [row, setRow] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);

  useEffect(() => {
    searchConfig = defaultSearchConfigs;
    getTransferVoucherList(searchConfig);
    return () => {
      dispatch(clearSearchAction());
    };
  }, [id]);

  const getTransferVoucherList = (searchConfig) => {
    if (id) {
      dispatch(
        getTransferPreviewVouchersAction(id, searchConfig, (err, data) => {
          if (err) {
            navigate("/dashboard");
          }
        })
      );
    } else {
      dispatch(getTransferVouchersAction(searchConfig));
    }
  };

  const getPreviewTourVoucherList = (searchConfig, tourId) => {
    dispatch(getTransferPreviewVouchersAction(searchConfig, tourId || id));
  };
  // Redirect to preview page on click the data of the table
  const redirectToPages = (event) => {
    if (event.field == "recovery_email" && event.row.recovery_email != null) {
      for (var value of rows) {
        if (value.email == event.formattedValue) {
          navigate(`/admin-data/appliances/${value.id}`);
        }
      }
    }
  };

  const redirectToPreview = useCallback((id, page) => {
    dispatch(
      getHashedIdAction(id, (err, data) => {
        if (data?.hash) {
          navigate(`/${page}/${data?.hash}`);
        }
      })
    );
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setShow(false);
    setPopupTitle("");
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
    setShowConfirmModal(false);
    setCommentModal(false);
    setEmailModal(false);
    setComment("");
    setShowState(false);
    setShowPassengersLocation(false);
    setShowDateRangeModal(false);
    setTimeout(() => {
      setDisabled(false);
    }, 400);
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setShowDelete(true);
    setRow(params);
  };
  const handleDownloadPdf = (ev, params) => {
    ev.preventDefault();
    ev.stopPropagation();
    dispatch(
      downloadTransferVoucherAction(
        params.id,
        params.ag_ref_number,
        "voucher",
        account.role,
        null,
        (err, data) => {
          if (data?.status == 200) {
            toast.success("Successfully Downloaded", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
          }
        }
      )
    );
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deleteTransferVoucher(id, (err, data) => {
        if (data) {
          handleClose();
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
        getTransferVoucherList(searchConfig);
      })
    );
  };

  const downloadReport = () => {
    setShowDateRangeModal(true);
  };
  const downloadReportConfirm = (data) => {
    dispatch(
      downloadReportTransferVouchersAction(data, (err, blob) => {
        if (!err && blob) {
          handleClose();
        }
      })
    );
  };
  const downloadKPIReportConfirm = (data) => {
    dispatch(
      downloadKPIReportTransferVouchersAction(data, (err, blob) => {
        if (!err && blob) {
          handleClose();
        }
      })
    );
  };

  const handleShow = (title) => {
    setShow(true);
    setPopupTitle(title);
  };

  const handleAddConfirm = async (data) => {
    setDisabled(true);
    dispatch(
      addTransferVoucherAction(data, (err, data) => {
        if (data && data.status === 200) {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          dispatch(getTransferVouchersAction(searchConfig));
          handleClose();
        } else if (err) {
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        }
      })
    );
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getTransferVoucherList(searchConfig);
  };

  const exportExcel = (params) => {
    dispatch(exportExcelTransferVoucherAction(searchConfig));
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = (reason) => {
    dispatch(
      deleteSelectedTourVouchers(selectedRows, (err, data) => {
        if (data) {
          if (selectedRows.length === rows.length && searchConfig.page > 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          setSelectedRows([]);
          getTransferVoucherList(searchConfig);
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setShowSelectedDelete(false);
        }
      })
    );
  };

  const handleUpdateStatus = (params) => {
    setRow(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    dispatch(
      changeTransferVoucherStatus(row?.id, (err, data) => {
        if (data) {
          getTransferVoucherList(searchConfig);
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        setShowStatus(false);
      })
    );
  };

  const openCommentModal = (params) => {
    setRow(params?.id || params?.row?.id);
    const comment = params?.row
      ? params?.row?.hidden_note || params?.hidden_note
      : "";
    if (comment) {
      setComment(comment);
    } else {
      setComment("");
    }
    setCommentModal(true);
  };

  const handleAddComment = (comment) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    dispatch(
      addCommentTransferVoucherAction(row, comment, (err, data) => {
        if (data) {
          handleClose();
          dispatch(getTransferVouchersAction(searchConfig));
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setTimeout(() => {
            setIsSubmitting(false);
          }, 500);
        }
      })
    );
  };

  const handleCopy = (params) => {
    dispatch(
      copyTransferVoucher(row, (err, data) => {
        if (data) {
          handleClose();
          getTransferVoucherList(searchConfig);
          toast.success("Successfully copied.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
      })
    );
  };

  const openCopyModal = (params) => {
    setRow(params?.id || params?.row?.id);
    setShowConfirmModal(true);
  };

  const openEmailModal = (params) => {
    setRow(params?.row || params);
    setEmailModal(true);
  };

  const handlePreviewEmail = (emailFormData) => {
    if (emailFormData?.email_body) {
      const linkTag = `<span  style="color: #0656c8">${
        emailFormData?.email_setup_preview_link || "Voucher"
      }</span>`;
      const body = emailFormData.email_body
        .replace(/\n/g, "<br />")
        .replaceAll("{{voucherPreviewLink}}", linkTag);
      setEmail_body(body);
    }
    setOpenEmailPreview(!openEmailPreview);
  };

  const handleSendEmail = (emailForm) => {
    setDisabled(true);
    dispatch(
      sendEmailTransferVoucherAction(emailForm, row.id, null, (err, data) => {
        if (data) {
          toast.success("Successfully Sent", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
      })
    );
  };
  const handleFetch = () => {
    dispatch(
      fetchTourVoucher((err, data) => {
        if (data) {
          getTransferVoucherList(searchConfig);
          toast.success(`Successfully Fetched ${data?.result} records`, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        if (err) {
          toast.error(err || "error", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
      })
    );
  };
  const handleState = (ev, params) => {
    setFormData(params?.row);
    setShowState(true);
    setRow(row);
  };

  const handleConfirmState = (data) => {
    setDisabled(true);
    dispatch(
      updateTransferVoucherState(data, (err, data) => {
        if (data) {
          getTransferVoucherList(searchConfig);
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        setShowState(false);
      })
    );
  };

  const getPassengerLocation = (data) => {
    if (data && data.latitude && data.longitude) {
      setPassengerData({ ...data });
      setShowPassengersLocation(true);
    } else {
      toast.error("Url does not exist");
    }
  };

  const navigateToWebPreview = (params) => {
    dispatch(
      goToTransferVoucherPreviewAction(params.id, (err, data) => {
        if (data && data.webLink) {
          window.open(data.webLink);
        }
      })
    );
  };

  const columns = getColumns({
    handleUpdateStatus,
    downloadStatus,
    downloadStatusId,
    rows,
    redirectToPreview,
    handleDelete,
    handleDownloadPdf,
    navigate,
    openCommentModal,
    openCopyModal,
    openEmailModal,
    handleState,
    getPassengerLocation,
    navigateToWebPreview,
    role: !isEmpty(account) ? account.role : "",
  });

  return (
    <div className="admin_tables_block">
      {id ? (
        <PageTitle
          parentTitle={"Transfers"}
          childTitle={id && tableTitle ? tableTitle : childTitle || ""}
          parentLink={`/transfers/`}
        />
      ) : (
        <TableTitle
          handleShow={handleShow}
          title={"Transfer Vouchers"}
          addNew={
            !isEmpty(account) &&
            ["Admin", "Supplier", "Tour Manager"].includes(account.role)
          }
          exportExcel={exportExcel}
          selectedRows={selectedRows}
          deleteSelectedRows={deleteSelectedRows}
          fetchStatus={fetchStatus}
          exportExcelStatus={exportExcelStatus}
          downloadReport={
            !isEmpty(account) &&
            ["Admin", "Tour Manager"].includes(account.role)
              ? downloadReport
              : null
          }
          downloadReportStatus={downloadReportStatus}
        />
      )}

      <SearchFilterBar
        getList={getTransferVoucherList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkboxSelection={accessList && accessList.includes("176")}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={"Transfer Vouchers"}
        link="/transfer_vouchers"
        mobileCol={transfer_vouchers_mobile_column}
        status={transferVoucherStatus}
        search={getTransferVoucherList}
      />
      <DynamicModal
        show={show}
        hide={() => setShow(false)}
        title={"Add New Transfer Voucher"}
        body={
          <TransferVouchersForm
            submit={handleAddConfirm}
            footer={
              <ModalButtons
                submit={handleAddConfirm}
                cancel={() => setShow(false)}
                isLoading={actionStatus === "pending"}
                saveDisabled={disabled}
              />
            }
          />
        }
      />
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={"You are about to delete selected items. Are you sure?"}
        title={"Delete"}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={(e) => handleConfirmDelete(e, row)}
        content={`You are about to delete the #${row} transfer voucher. Are you sure?`}
        title={"Delete"}
        actionStatus={actionStatus}
      />
      <DynamicModal
        show={commentModal}
        hide={() => setCommentModal(false)}
        title={"Add Comment"}
        body={
          <CommentModal
            comment={comment}
            submit={handleAddComment}
            hide={() => setCommentModal(false)}
            footer={
              <ModalButtons
                submit={handleAddComment}
                cancel={() => setCommentModal(false)}
                // isLoading={actionStatus === "pending"}
                isLoading={isSubmitting}
              />
            }
          />
        }
      />
      <DynamicModal
        show={openEmailPreview}
        hide={() => setOpenEmailPreview(false)}
        title={"Email Preview"}
        body={
          <div className="p-3">
            <div
              dangerouslySetInnerHTML={{ __html: email_body }}
              style={{ wordBreak: "break-word" }}
            />
          </div>
        }
      />
      <EmailModal
        show={emailModal}
        handleClose={handleClose}
        title="Send Email"
        confirm={handleSendEmail}
        page="Transfer"
        actionStatus={actionStatus}
        handlePreviewEmail={handlePreviewEmail}
        disabled={disabled}
        id={row?.id ? row?.id : null}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={handleClose}
        title="Copy Transfer Voucher"
        handleConfirm={handleCopy}
        content={"Make sure to update the data after the copy."}
        actionStatus={actionStatus}
      />
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          row?.status === "Active" ? "de-activate" : "activate"
        }  the #${row?.id} Transfer Voucher. Are you sure?`}
        title={"Update Transfer Voucher"}
        actionStatus={actionStatus}
      />
      <SelectModal
        className="react-select"
        classNamePrefix="react-select"
        title={"Change Transfer Voucher Status"}
        select={state}
        confirm={handleConfirmState}
        show={showState}
        handleClose={handleClose}
        setValidate={setNoteValidate}
        validate={noteValidate}
        formData={formData}
        setFormData={setFormData}
        single={row}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getTransferVoucherList}
      />
      {showDateRangeModal && (
        <GenerateTransfersReportModal
          show={showDateRangeModal}
          handleClose={handleClose}
          downloadReport={downloadReportConfirm}
          downloadKPIReport={downloadKPIReportConfirm}
          actionStatus={downloadReportStatus}
        />
      )}
      {!isEmpty(passengerData) && (
        <SeeLocationModal
          type="admin_table"
          data={passengerData}
          handleClose={handleClose}
          show={showPassengersLocation}
          title={`Passenger's Location`}
          url={`https://www.google.com/maps/search/${passengerData?.latitude}+${passengerData?.longitude}/`}
        />
      )}
      {rowStatus === "success" && (
        <div>
          {id && (
            <h4 className="passenger_location_title">Passenger's Locations</h4>
          )}
          {id && coordinates?.length ? (
            <LocationMapTransfer coordinates={coordinates} />
          ) : id && !coordinates?.length ? (
            <NoLocationWindow />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default TransferVouchers;
