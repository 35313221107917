import React from "react";
import { Button } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";

export default function ModalButtonsReset({
  reset,
  preview,
  disabled,
  isLoading,
}) {
  return (
    <div className="previewButtons">
      <Button
        variant={disabled ? "secondary" : "primary"}
        onClick={reset}
        disabled={disabled}
      >
        Reset
      </Button>
      {preview && (
        <Button variant="primary" color="white" onClick={preview}>
          Preview
        </Button>
      )}
      <LoadingButton
        size="big"
        className={`preview_submit_btn btn ${
          disabled ? "btn-secondary" : "btn-primary"
        }`}
        style={{
          height: "100%",
          fontSize: "1rem",
          lineHeight: "1.55",
          fontWeight: 400,
        }}
        loading={isLoading}
        disabled={disabled}
        variant="contained"
        type="submit"
      >
        Save
      </LoadingButton>
    </div>
  );
}
