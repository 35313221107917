import React from "react";
import "./template.scss";
import Grid from "@mui/material/Unstable_Grid2";
import moment from "moment";

const { REACT_APP_API_URL } = process.env;

export default function VoucherTemplate({ data }) {
  const {
    adult_members,
    ag_ref_number,
    child_members,
    infant_members,
    total_passengers_members,
    passenger_name,
    passenger_phone,
    show_reference_number,
    original_reference_number,
  } = data;
  return (
    <div className="pdf_template ">
      <Grid container spacing={2}>
        <Grid item style={{ background: "#f88f35" }} xs={12}>
          Voucher-Excursion Tours
        </Grid>
        {show_reference_number ? (
          <>
            <Grid item style={{ background: "#f88f35" }} xs={4}>
              Reference Number
            </Grid>
            <Grid item style={{ background: "#f88f35" }} xs={4}>
              Agency Reference N
            </Grid>
            <Grid item style={{ background: "#f88f35" }} xs={4}>
              Passenger
            </Grid>
            <Grid item style={{ background: "#f9e1cc" }} xs={4}>
              {original_reference_number}
            </Grid>
            <Grid item style={{ background: "#f9e1cc" }} xs={4}>
              {ag_ref_number}
            </Grid>
            <Grid item style={{ background: "#f9e1cc" }} xs={4}>
              {passenger_name}
              <p style={{ marginBottom: "0" }}>
                {passenger_phone ? passenger_phone : ""}
              </p>
            </Grid>
          </>
        ) : (
          <>
            <Grid item style={{ background: "#f88f35" }} xs={6}>
              Agency Reference N
            </Grid>
            <Grid item style={{ background: "#f88f35" }} xs={6}>
              Passenger
            </Grid>
            <Grid item style={{ background: "#f9e1cc" }} xs={6}>
              {ag_ref_number}
            </Grid>
            <Grid item style={{ background: "#f9e1cc" }} xs={6}>
              {passenger_name}
              <p style={{ marginBottom: "0" }}>
                {passenger_phone ? passenger_phone : ""}
              </p>
            </Grid>
          </>
        )}
        <Grid item style={{ background: "#f88f35" }} xs={3}>
          Tour Name
        </Grid>
        <Grid item style={{ background: "#f9e1cc" }} xs={9}>
          {data?.tour?.direction && data?.tour?.direction?.direction_name}
        </Grid>

        <Grid item style={{ background: "#f88f35" }} xs={4}>
          Date
        </Grid>
        <Grid item style={{ background: "#f88f35" }} xs={4}>
          Start Time
        </Grid>
        <Grid item style={{ background: "#f88f35" }} xs={4}>
          Finish Time
        </Grid>

        <Grid item style={{ background: "#f9e1cc" }} xs={4}>
          {data?.tour?.service_date &&
            moment(data?.tour?.service_date).format("MM/DD/YYYY")}
        </Grid>
        <Grid item style={{ background: "#f9e1cc" }} xs={4}>
          {data?.tour?.start_time}
        </Grid>
        <Grid item style={{ background: "#f9e1cc" }} xs={4}>
          {data?.tour?.end_time}
        </Grid>


        <Grid item style={{ background: "#f88f35" }} xs={3}>
          Adults
        </Grid>
          <Grid item style={{ background: "#f88f35" }} xs={3}>
              Children
          </Grid>
        <Grid item style={{ background: "#f88f35" }} xs={3}>
          Infants
        </Grid>
        <Grid item style={{ background: "#f88f35" }} xs={3}>
          Total Passengers
        </Grid>


        <Grid item style={{ background: "#f9e1cc" }} xs={3}>
          {adult_members || 0}
        </Grid>
          <Grid item style={{ background: "#f9e1cc" }} xs={3}>
              {child_members || 0}
          </Grid>
        <Grid item style={{ background: "#f9e1cc" }} xs={3}>
          {infant_members || 0}
        </Grid>
        <Grid item style={{ background: "#f9e1cc" }} xs={3}>
          {total_passengers_members}
        </Grid>

        <Grid item style={{ background: "#f88f35" }} xs={3}>
          Accommodation
        </Grid>
        <Grid item style={{ background: "#f9e1cc" }} xs={9}>
          {data?.accommodation}
        </Grid>
        <Grid item style={{ background: "#f88f35" }} xs={6}>
          Pick-up Point
        </Grid>
        <Grid item style={{ background: "#f88f35" }} xs={6}>
          Pick-up Time
        </Grid>
        <Grid item style={{ background: "#f9e1cc" }} xs={6}>
          {data?.tour?.pickUpPoint && data?.tour?.pickUpPoint?.pick_up_name}
        </Grid>
        <Grid item style={{ background: "#f9e1cc" }} xs={6}>
          {data?.tour?.pick_up_time}
        </Grid>
        <Grid item style={{ background: "#f88f35" }} xs={6}>
          Pick-up Point Photo
        </Grid>
        <Grid item style={{ background: "#f88f35" }} xs={6}>
          Directions
        </Grid>
        <Grid
          style={{
            background: "#f9e1cc",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
          }}
          xs={6}
        >
          {data?.tour?.pickUpPoint && data?.tour?.pickUpPoint?.pick_up_photo ? (
            <img
              src={`${REACT_APP_API_URL}/pick_up_points_images/${data?.tour?.pickUpPoint?.pick_up_photo}`}
              alt="pick-up-photo"
            />
          ) : (
            "No Photo"
          )}
        </Grid>
        <Grid
          xs={6}
          style={{
            background: "#f9e1cc",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
          }}
        >
          {data?.tour?.pickUpPoint && data?.tour?.pickUpPoint?.map_embed ? (
            <div
              style={{ width: "100%", height: "200px" }}
              className="map_iframe"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.tour?.pickUpPoint?.map_embed,
                }}
              />
            </div>
          ) : data?.tour?.pickUpPoint?.map_photo &&
            !data?.tour?.pickUpPoint?.map_embed ? (
            <a
              href={`https://www.google.com/maps/search/${data?.tour?.pickUpPoint.pick_up_address}`}
              target="_blank"
              style={{ width: "100%", height: "100%" }}
            >
              <img
                src={`${REACT_APP_API_URL}/pick_up_points_images/${data?.tour?.pickUpPoint?.map_photo}`}
                alt="map_photo"
                style={{ objectFit: "contain" }}
              />
            </a>
          ) : (
            "No Map"
          )}
        </Grid>
        <Grid
          style={{
            background: "#f88f35",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={3}
        >
          Logo on bus
        </Grid>
        <Grid
          item
          style={{
            background: "#f9e1cc",
            fontWeight: "bold",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 0,
          }}
          xs={3}
        >
          {data?.tour?.supplierUser?.supplier_logo ? (
            <img
              src={`${REACT_APP_API_URL}/supplier_logos/${data?.tour?.supplierUser?.supplier_logo}`}
              alt="supplier_logo"
              style={{ objectFit: "scale-down", width: "55%" }}
            />
          ) : (
            "No Photo"
          )}
        </Grid>
        <Grid
          item
          style={
            !data?.tour?.pickUpPoint?.map_photo &&
            !data?.tour?.pickUpPoint?.map_embed
              ? {
                  background: "#f9e1cc",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }
              : {
                  background: "#f9e1cc",
                }
          }
          xs={6}
        >
          {data?.tour?.pickUpPoint && data?.tour?.pickUpPoint?.pick_up_address}
          {data?.tour?.pickUpPoint?.map_photo ||
          data?.tour?.pickUpPoint?.map_embed ? (
            <p
              style={{
                fontStyle: "italic",
                fontWeight: 300,
                fontSize: "13px",
                marginBottom: "0.3rem",
              }}
            >
              Please click on the map to direct you
            </p>
          ) : null}
        </Grid>
        <Grid
          item
          style={{
            background: "#f88f35",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={3}
        >
          Notes
        </Grid>
        <Grid item style={{ background: "#f9e1cc", textAlign: "left" }} xs={9}>
          {data?.tour?.direction && data?.tour?.direction?.direction_notes}
        </Grid>

        <Grid item style={{ background: "#f88f35" }} xs={3}>
          Contact info
        </Grid>
        <Grid item style={{ background: "#f9e1cc" }} xs={9}>
          {data?.tour?.contact_info}
        </Grid>
      </Grid>
    </div>
  );
}
