export const ADD_WEB_APARTMENT_REQUEST = "ADD_WEB_APARTMENT_REQUEST";
export const ADD_WEB_APARTMENT_SUCCESS = "ADD_WEB_APARTMENT_SUCCESS";
export const ADD_WEB_APARTMENT_FAIL = "ADD_WEB_APARTMENT_FAIL";

export function addApartment(formData, cb) {
  return {
    type: ADD_WEB_APARTMENT_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_WEB_APARTMENT_REQUEST = "UPDATE_WEB_APARTMENT_REQUEST";
export const UPDATE_WEB_APARTMENT_SUCCESS = "UPDATE_WEB_APARTMENT_SUCCESS";
export const UPDATE_WEB_APARTMENT_FAIL = "UPDATE_WEB_APARTMENT_FAIL";

export function updateApartment(formData, cb) {
  return {
    type: UPDATE_WEB_APARTMENT_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_WEB_APARTMENT_REQUEST = "DELETE_WEB_APARTMENT_REQUEST";
export const DELETE_WEB_APARTMENT_SUCCESS = "DELETE_WEB_APARTMENT_SUCCESS";
export const DELETE_WEB_APARTMENT_FAIL = "DELETE_WEB_APARTMENT_FAIL";

export function deleteApartment(id, cb) {
  return {
    type: DELETE_WEB_APARTMENT_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_SINGLE_WEB_APARTMENT_REQUEST =
  "GET_SINGLE_WEB_APARTMENT_REQUEST";
export const GET_SINGLE_WEB_APARTMENT_SUCCESS =
  "GET_SINGLE_WEB_APARTMENT_SUCCESS";
export const GET_SINGLE_WEB_APARTMENT_FAIL = "GET_SINGLE_WEB_APARTMENT_FAIL";

export function getSingleApartments(id, cb) {
  return {
    type: GET_SINGLE_WEB_APARTMENT_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_WEB_APARTMENTS_REQUEST = "GET_WEB_APARTMENTS_REQUEST";
export const GET_WEB_APARTMENTS_SUCCESS = "GET_WEB_APARTMENTS_SUCCESS";
export const GET_WEB_APARTMENTS_FAIL = "GET_WEB_APARTMENTS_FAIL";

export function getApartmentsAction(searchConfig, cb) {
  return {
    type: GET_WEB_APARTMENTS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const STATUS_WEB_APARTMENT_REQUEST = "STATUS_WEB_APARTMENT_REQUEST";
export const STATUS_WEB_APARTMENT_SUCCESS = "STATUS_WEB_APARTMENT_SUCCESS";
export const STATUS_WEB_APARTMENT_FAIL = "STATUS_WEB_APARTMENT_FAIL";

export function changeApartmentsStatusAction(id, cb) {
  return {
    type: STATUS_WEB_APARTMENT_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_WEB_APARTMENTS_REQUEST = "EXPORT_WEB_APARTMENTS_REQUEST";
export const EXPORT_WEB_APARTMENTS_SUCCESS = "EXPORT_WEB_APARTMENTS_SUCCESS";
export const EXPORT_WEB_APARTMENTS_FAIL = "EXPORT_WEB_APARTMENTS_FAIL";

export function exportExcelApartmentsAction(searchConfig, cb) {
  return {
    type: EXPORT_WEB_APARTMENTS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const GET_WEB_APARTMENTS_GALLERY_REQUEST =
  "GET_WEB_APARTMENTS_GALLERY_REQUEST";
export const GET_WEB_APARTMENTS_GALLERY_SUCCESS =
  "GET_WEB_APARTMENTS_GALLERY_SUCCESS";
export const GET_WEB_APARTMENTS_GALLERY_FAIL =
  "GET_WEB_APARTMENTS_GALLERY_FAIL";

export function getWebApartmentGallery(id, cb) {
  return {
    type: GET_WEB_APARTMENTS_GALLERY_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_WEB_APARTMENTS_GALLERY_REQUEST =
  "UPDATE_WEB_APARTMENTS_GALLERY_REQUEST";
export const UPDATE_WEB_APARTMENTS_GALLERY_SUCCESS =
  "UPDATE_WEB_APARTMENTS_GALLERY_SUCCESS";
export const UPDATE_WEB_APARTMENTS_GALLERY_FAIL =
  "UPDATE_WEB_APARTMENTS_GALLERY_FAIL";

export function updateWebApartmentGallery(id, formData, cb) {
  return {
    type: UPDATE_WEB_APARTMENTS_GALLERY_REQUEST,
    payload: { id, formData, cb },
  };
}

// Apartments Rooms

export const GET_WEB_APARTMENT_ROOM_REQUEST = "GET_WEB_APARTMENT_ROOM_REQUEST";
export const GET_WEB_APARTMENT_ROOM_SUCCESS = "GET_WEB_APARTMENT_ROOM_SUCCESS";
export const GET_WEB_APARTMENT_ROOM_FAIL = "GET_WEB_APARTMENT_ROOM_FAIL";

export function getApartmentRoomAction(id, searchConfig, cb) {
  return {
    type: GET_WEB_APARTMENT_ROOM_REQUEST,
    payload: { id, searchConfig, cb },
  };
}

export const ADD_WEB_APARTMENT_ROOM_REQUEST = "ADD_WEB_APARTMENT_ROOM_REQUEST";
export const ADD_WEB_APARTMENT_ROOM_SUCCESS = "ADD_WEB_APARTMENT_ROOM_SUCCESS";
export const ADD_WEB_APARTMENT_ROOM_FAIL = "ADD_WEB_APARTMENT_ROOM_FAIL";

export function addRoomAction(id, formData, cb) {
  return {
    type: ADD_WEB_APARTMENT_ROOM_REQUEST,
    payload: {
      id,
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_WEB_APARTMENT_ROOM_REQUEST =
  "GET_SINGLE_WEB_APARTMENT_ROOM_REQUEST";
export const GET_SINGLE_WEB_APARTMENT_ROOM_SUCCESS =
  "GET_SINGLE_WEB_APARTMENT_ROOM_SUCCESS";
export const GET_SINGLE_WEB_APARTMENT_ROOM_FAIL =
  "GET_SINGLE_WEB_APARTMENT_ROOM_FAIL";

export function getSingleApartmentRoom(id, cb) {
  return {
    type: GET_SINGLE_WEB_APARTMENT_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const STATUS_WEB_APARTMENT_ROOM_REQUEST =
  "STATUS_WEB_APARTMENT_ROOM_REQUEST";
export const STATUS_WEB_APARTMENT_ROOM_SUCCESS =
  "STATUS_WEB_APARTMENT_ROOM_SUCCESS";
export const STATUS_WEB_APARTMENT_ROOM_FAIL = "STATUS_WEB_APARTMENT_ROOM_FAIL";

export function changeApartmentRoomStatusAction(id, cb) {
  return {
    type: STATUS_WEB_APARTMENT_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const UPDATE_WEB_APARTMENT_ROOM_REQUEST =
  "UPDATE_WEB_APARTMENT_ROOM_REQUEST";
export const UPDATE_WEB_APARTMENT_ROOM_SUCCESS =
  "UPDATE_WEB_APARTMENT_ROOM_SUCCESS";
export const UPDATE_WEB_APARTMENT_ROOM_FAIL = "UPDATE_WEB_APARTMENT_ROOM_FAIL";

export function updateApartmentRoom(formData, cb) {
  return {
    type: UPDATE_WEB_APARTMENT_ROOM_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_WEB_APARTMENT_ROOM_REQUEST =
  "DELETE_WEB_APARTMENT_ROOM_REQUEST";
export const DELETE_WEB_APARTMENT_ROOM_SUCCESS =
  "DELETE_WEB_APARTMENT_ROOM_SUCCESS";
export const DELETE_WEB_APARTMENT_ROOM_FAIL = "DELETE_WEB_APARTMENT_ROOM_FAIL";

export function deleteApartmentRoom(id, cb) {
  return {
    type: DELETE_WEB_APARTMENT_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_WEB_APARTMENT_ROOM_GALLERY_REQUEST =
  "GET_WEB_APARTMENT_ROOM_GALLERY_REQUEST";
export const GET_WEB_APARTMENT_ROOM_GALLERY_SUCCESS =
  "GET_WEB_APARTMENT_ROOM_GALLERY_SUCCESS";
export const GET_WEB_APARTMENT_ROOM_GALLERY_FAIL =
  "GET_WEB_APARTMENT_ROOM_GALLERY_FAIL";

export function getWebApartmentRoomGallery(id, cb) {
  return {
    type: GET_WEB_APARTMENT_ROOM_GALLERY_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_WEB_APARTMENT_ROOM_GALLERY_REQUEST =
  "UPDATE_WEB_APARTMENT_ROOM_GALLERY_REQUEST";
export const UPDATE_WEB_APARTMENT_ROOM_GALLERY_SUCCESS =
  "UPDATE_WEB_APARTMENT_ROOM_GALLERY_SUCCESS";
export const UPDATE_WEB_APARTMENT_ROOM_GALLERY_FAIL =
  "UPDATE_WEB_APARTMENT_ROOM_GALLERY_FAIL";

export function updateWebApartmentRoomGallery(id, formData, cb) {
  return {
    type: UPDATE_WEB_APARTMENT_ROOM_GALLERY_REQUEST,
    payload: { id, formData, cb },
  };
}
