export const defaultTheme = {
  id: 17,
  palette_id: 17,
  mode: "light",
  primary_light: "#8DA9C4",
  primary_main: "#14213d",
  primary_dark: "#14213D",
  neutral_light: "#e3e3e3",
  neutral_main: "#D1D4D6",
  neutral_dark: "#5e5e5e",
  success_light: "#2f662e",
  success_main: "#2f662e",
  success_dark: "#2f662e",
  error_light: "#e57373",
  error_main: "#f44336",
  error_dark: "#d32f2f",
  text_main: "#303030",
  text_dark: "#000",
  background_main: "#fff",
  createdBy: 1,
  updated_BY: 1,
};
