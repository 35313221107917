import { pages } from "../pagesData/pages";

export const serviceTime = [
  { time: "9-12" },
  { time: "10-13" },
  { time: "11-14" },
  { time: "12-15" },
  { time: "13-16" },
  { time: "14-17" },
  { time: "15-18" },
  { time: "16-19" },
  { time: "17-20" },
];

export const serviceTimeRange = [
  { value: "9-12", label: "9-12" },
  { value: "10-13", label: "10-13" },
  { value: "11-14", label: "11-14" },
  { value: "12-15", label: "12-15" },
  { value: "13-16", label: "13-16" },
  { value: "14-17", label: "14-17" },
  { value: "15-18", label: "15-18" },
  { value: "16-19", label: "16-19" },
  { value: "17-20", label: "17-20" },
];
export const dateKeys = [
  "service_date",
  "reservation_date",
  "createdAt",
  "createdAt",
  "updatedAt",
  "updatedAt",
  "last_login",
  "leadDate",
  "end_date",
];

export const statusKeys = [
  "status",
  "page",
  "auto_renewal",
  // 'service_time_range',
];

export const filterSelects = {
  roles_status: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ],
  users_status: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ],
  tours_status: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ],
  vouchers_status: [
    { value: "New", label: "New" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Reserved", label: "Reserved" },
    { value: "Rejected", label: "Rejected" },
  ],
  transfers_status: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ],
  transfer_vouchers_status: [
    { value: "New", label: "New" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Reserved", label: "Reserved" },
    { value: "Rejected", label: "Rejected" },
  ],
  transfers_vouchers_status: [
    { value: "New", label: "New" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Reserved", label: "Reserved" },
    { value: "Rejected", label: "Rejected" },
  ],
  directions_status: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ],
  pick_up_points_status: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ],
  transfer_types_status: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ],
  advertisements_status: [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ],
  websites_status: [
    { value: "Completed", label: "Complete" },
    { value: "Incomplete", label: "Incomplete" },
  ],
  providers_status: [
    { value: "Completed", label: "Complete" },
    { value: "Incomplete", label: "Incomplete" },
  ],
  providers_auto_renewal: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ],
  "email-accounts_status": [
    { value: "Completed", label: "Complete" },
    { value: "Incomplete", label: "Incomplete" },
  ],
  messages_status: [
    { value: "Open", label: "Open" },
    { value: "Contacted", label: "Contacted" },
    { value: "Qualifieded", label: "Qualified" },
    { value: "Not Qualified", label: "Not Qualified" },
    { value: "Converted", label: "Converted" },
  ],
  tours_vouchers_status: [
    { value: "New", label: "New" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Reserved", label: "Reserved" },
    { value: "Rejected", label: "Rejected" },
  ],
  text_messages_status: [
    { value: "Open", label: "Open" },
    { value: "Contacted", label: "Contacted" },
    { value: "Qualifieded", label: "Qualified" },
    { value: "Not Qualified", label: "Not Qualified" },
    { value: "Converted", label: "Converted" },
  ],
  messages_service_time_range: serviceTimeRange,
  calls_service_time_range: serviceTimeRange,
  orders_service_time_range: serviceTimeRange,
  calls_status: [
    { value: "Open", label: "Open" },
    { value: "Contacted", label: "Contacted" },
    { value: "Qualifieded", label: "Qualified" },
    { value: "Not Qualified", label: "Not Qualified" },
    { value: "Converted", label: "Converted" },
  ],
  call_backs_status: [
    { value: "Open", label: "Open" },
    { value: "Contacted", label: "Contacted" },
    { value: "Qualifieded", label: "Qualified" },
    { value: "Not Qualified", label: "Not Qualified" },
    { value: "Converted", label: "Converted" },
  ],
  orders_status: [
    { value: "New", label: "New" },
    { value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Paid", label: "Paid" },
  ],
  clients_status: [
    { value: "Completed", label: "Complete" },
    { value: "Incomplete", label: "Incomplete" },
  ],
  "payment-methods_card_type": [
    { value: "VISA", label: "VISA" },
    { value: "MASTERCARD", label: "MASTERCARD" },
    { value: "MAESTRO", label: "MAESTRO" },
    { value: "VISA ELECTRON", label: "VISA ELECTRON" },
    { value: "DELTA", label: "DELTA" },
    { value: "DISCOVER", label: "DISCOVER" },
    { value: "AMERICAN EXPRESS", label: "AMERICAN EXPRESS" },
  ],
  logs_page: [
    { value: "Companies", label: "Companies" },
    { value: "States", label: "States" },
    { value: "Cities", label: "Cities" },
    { value: "Brands", label: "Brands" },
    { value: "Appliances", label: "Appliances" },
    { value: "Users", label: "Users" },
    { value: "Roles", label: "Roles" },
    { value: "Email Accounts", label: "Email Accounts" },
    { value: "Providers", label: "Providers" },
    { value: "Websites", label: "Websites" },
    { value: "Payment Methods", label: "Payment Methods" },
    { value: "Business Directories", label: "Business Directories" },
    { value: "Messages", label: "Messages" },
    { value: "Texts", label: "Texts" },
    { value: "Calls", label: "Calls" },
    { value: "Call Requests", label: "Call Requests" },
    { value: "Orders", label: "Orders" },
    { value: "Clients", label: "Clients" },
  ],
};

export const pagesFetch = [
  "/leads/messages",
  "/leads/calls",
  "/leads/calls",
  "/leads/call_backs",
  "/leads/text_messages",
];

export const defaultProperties = [
  {
    id: 1,
    label: "Hotels",
    value: "hotel",
    key: "hotel_room_id",
    room_parent_key: "parent_hotel_id",
  },
  {
    id: 2,
    label: "Villas",
    value: "villa",
    key: "villa_room_id",
    room_parent_key: "parent_villa_id",
  },
  {
    id: 3,
    label: "Suites",
    value: "suite",
    key: "suite_room_id",
    room_parent_key: "parent_suite_id",
  },
  {
    id: 4,
    label: "Apartments",
    value: "apartment",
    key: "apartment_room_id",
    room_parent_key: "parent_apartment_id",
  },
];
