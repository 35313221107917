import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_TERMS_AND_CONDITIONS_REQUEST,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_TERMS_AND_CONDITIONS_FAIL,
  ADD_TERMS_AND_CONDITION_REQUEST,
  ADD_TERMS_AND_CONDITION_SUCCESS,
  ADD_TERMS_AND_CONDITION_FAIL,
  DELETE_TERMS_AND_CONDITION_REQUEST,
  DELETE_TERMS_AND_CONDITION_SUCCESS,
  DELETE_TERMS_AND_CONDITION_FAIL,
  UPDATE_TERMS_AND_CONDITION_REQUEST,
  UPDATE_TERMS_AND_CONDITION_SUCCESS,
  UPDATE_TERMS_AND_CONDITION_FAIL,
  GET_SINGLE_TERMS_AND_CONDITION_REQUEST,
  GET_SINGLE_TERMS_AND_CONDITION_SUCCESS,
  GET_SINGLE_TERMS_AND_CONDITION_FAIL,
  GET_ALL_TERMS_AND_CONDITIONS_REQUEST,
  GET_ALL_TERMS_AND_CONDITIONS_SUCCESS,
  GET_ALL_TERMS_AND_CONDITIONS_FAIL,
} from '../actions/termsConditions';
import TermsConditions from '../../api/termsConditions';

export default function* watcher() {
  yield takeLatest(GET_TERMS_AND_CONDITIONS_REQUEST, getTermsConditions);
  yield takeLatest(
    GET_SINGLE_TERMS_AND_CONDITION_REQUEST,
    getSingleTermsCondition,
  );
  yield takeLatest(ADD_TERMS_AND_CONDITION_REQUEST, addTermsCondition);
  yield takeLatest(UPDATE_TERMS_AND_CONDITION_REQUEST, updateTermsCondition);
  yield takeLatest(DELETE_TERMS_AND_CONDITION_REQUEST, deleteTermsCondition);
  yield takeLatest(GET_ALL_TERMS_AND_CONDITIONS_REQUEST, getAllTermsConditions);
}

function* getTermsConditions(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      TermsConditions.getTermsConditions,
      page,
      limit,
      search,
      filter,
      sort,
    );
    yield put({
      type: GET_TERMS_AND_CONDITIONS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_TERMS_AND_CONDITIONS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* addTermsCondition(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(TermsConditions.addTermsCondition, formData);
    yield put({
      type: ADD_TERMS_AND_CONDITION_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: ADD_TERMS_AND_CONDITION_FAIL,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}
function* updateTermsCondition(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(TermsConditions.updateTermsCondition, formData);
    yield put({
      type: UPDATE_TERMS_AND_CONDITION_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_TERMS_AND_CONDITION_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}
function* deleteTermsCondition(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(TermsConditions.deleteTermsCondition, id);
    yield put({
      type: DELETE_TERMS_AND_CONDITION_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_TERMS_AND_CONDITION_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}
function* getSingleTermsCondition(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(TermsConditions.getSingleTermsCondition, id);
    yield put({
      type: GET_SINGLE_TERMS_AND_CONDITION_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data?.termsAndCondition);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_TERMS_AND_CONDITION_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* getAllTermsConditions(action) {
  try {
    const { data } = yield call(TermsConditions.getAllTermsConditions);
    yield put({
      type: GET_ALL_TERMS_AND_CONDITIONS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_TERMS_AND_CONDITIONS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
