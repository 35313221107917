import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_DRIVERS_REQUEST,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_FAIL,
  ADD_DRIVER_REQUEST,
  ADD_DRIVER_SUCCESS,
  ADD_DRIVER_FAIL,
  GET_SINGLE_DRIVER_REQUEST,
  GET_SINGLE_DRIVER_SUCCESS,
  GET_SINGLE_DRIVER_FAIL,
  UPDATE_DRIVER_REQUEST,
  UPDATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_FAIL,
  STATUS_DRIVER_REQUEST,
  STATUS_DRIVER_SUCCESS,
  STATUS_DRIVER_FAIL,
  DELETE_DRIVER_REQUEST,
  DELETE_DRIVER_SUCCESS,
  DELETE_DRIVER_FAIL,
  GET_ALL_DRIVERS_REQUEST,
  GET_ALL_DRIVERS_SUCCESS,
  GET_ALL_DRIVERS_FAIL,
  EXPORT_DRIVERS_REQUEST,
  EXPORT_DRIVERS_SUCCESS,
  EXPORT_DRIVERS_FAIL,
} from '../actions/drivers';
import Drivers from '../../api/drivers';

export default function* watcher() {
  yield takeLatest(GET_DRIVERS_REQUEST, handleGetDrivers);
  yield takeLatest(DELETE_DRIVER_REQUEST, deleteDriver);
  yield takeLatest(STATUS_DRIVER_REQUEST, handleChangeStatus);
  yield takeLatest(ADD_DRIVER_REQUEST, addDriver);
  yield takeLatest(GET_SINGLE_DRIVER_REQUEST, getSingleDriver);
  yield takeLatest(UPDATE_DRIVER_REQUEST, updateDriver);
  // yield takeLatest(DELETE_SELECTED_COMPANIES_REQUEST, deleteSelectedCompanies);
  yield takeLatest(EXPORT_DRIVERS_REQUEST, handleExportExcel);
}

function* addDriver(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Drivers.addDrivers, formData);
    yield put({
      type: ADD_DRIVER_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: ADD_DRIVER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetDrivers(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      Drivers.getDrivers,
      page,
      limit,
      search,
      filter,
      sort,
    );

    yield put({
      type: GET_DRIVERS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_DRIVERS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* deleteDriver(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Drivers.deleteDrivers, id);
    yield put({
      type: DELETE_DRIVER_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_DRIVER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* handleChangeStatus(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Drivers.changeDriverStatus, id);
    yield put({
      type: STATUS_DRIVER_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_DRIVER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

// function* deleteSelectedCompanies(action) {
//   try {
//     const { selectedRows } = action.payload;
//     const { data } = yield call(Tours.deleteSelectedDrivers, selectedRows);
//     yield put({
//       type: DELETE_SELECTED_COMPANIES_SUCCESS,
//       payload: {
//         data: data.company,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(null, data);
//     }
//   } catch (e) {
//     console.log(e);
//     yield put({
//       type: DELETE_SELECTED_COMPANIES_FAIL,
//       payload: {
//         massage: e.massage,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(e, null);
//     }
//   }
// }

function* getSingleDriver(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Drivers.getSingleDrivers, id);
    yield put({
      type: GET_SINGLE_DRIVER_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_DRIVER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* updateDriver(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Drivers.updateDrivers, formData);
    yield put({
      type: UPDATE_DRIVER_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_DRIVER_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      Drivers.handleExportDriversExcel,
      page,
      search,
      filter,
      sort,
    );
    yield put({
      type: EXPORT_DRIVERS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: 'application/vnd.ms-excel',
    });
    saveAs(blob, `Drivers.xlsx`);
  } catch (e) {
    console.log(e);
    yield put({
      type: EXPORT_DRIVERS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
