import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_ALL_LOGS_REQUEST,
  GET_ALL_LOGS_SUCCESS,
  GET_ALL_LOGS_FAIL,
} from "../actions/logs";
import Logs from "../../api/logs";

export default function* watcher() {
  yield takeLatest(GET_ALL_LOGS_REQUEST, getAllLogs);
}

function* getAllLogs(action) {
  try {
    const { page, limit, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      Logs.getLogs,
      page,
      limit,
      search,
      filter,
      sort
    );
    yield put({
      type: GET_ALL_LOGS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_ALL_LOGS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}
