import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Form, InputGroup } from "react-bootstrap";
import { loginRequest } from "../store/actions/users";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import logoSVG from "../../src/assets/images/white-logo.svg";
import { Link } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { titleFromEnv } from "../services/utils";

const { REACT_APP_TYPE, REACT_APP_SINGLE_NAME } = process.env;

function Login() {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(true);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const onSubmit = (formData) => {
    const newData = { ...formData, company_name: params?.company_name };
    setIsLoading(true);
    dispatch(
      loginRequest(newData, (err, data) => {
        if (err) {
          toast.error(err, {
            autoClose: 5000,
          });
        } else if (data?.status === 200) {
          reset();
          localStorage.setItem("token", data?.token);
          localStorage.setItem("account", JSON.stringify(data?.user));
        }
        setIsLoading(false);
      })
    );
  };
  const handleLoginBokun = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    window.location.href = "https://extranet.bokun.io/app/signin";
    // window.open("https://extranet.bokun.io/app/signin", "_blank");
  };
  return (
    <div className="login-page parent clearfix">
      <div className="login_page_overlay">
        <div className="login">
          <div className="container">
            {REACT_APP_TYPE === "single" && REACT_APP_SINGLE_NAME ? (
              <h3 className="login-page_title">
                {titleFromEnv(REACT_APP_SINGLE_NAME)}
              </h3>
            ) : (
              <h3 className="login-page_logo">
                <img src={logoSVG} width={"290px"} />
              </h3>
            )}
            <h4 className="login-page_title">Login</h4>
            <div className="login-form">
              <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email or username *"
                    style={errors.email && { borderColor: "#EE204D" }}
                    {...register("email", {
                      required: "Required Field",
                      // pattern: {
                      //   value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      //   message: "Invalid email format",
                      // },
                    })}
                  />
                  {errors?.email?.message && (
                    <span className="required-message">
                      {errors?.email?.message}
                    </span>
                  )}
                </InputGroup>
                <InputGroup className="mb-1">
                  <Form.Control
                    type={isPasswordVisible ? "password" : "text"}
                    className="input"
                    id="password"
                    placeholder="Password *"
                    autoComplete="new_password"
                    style={errors.password && { borderColor: "#EE204D" }}
                    {...register("password", {
                      required: "Required Field",
                      // minLength: {
                      //   value: 4,
                      //   message: "Password must be at least 8 characters long",
                      // },
                    })}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    onClick={() => togglePasswordVisibility()}
                  >
                    {isPasswordVisible ? (
                      <FiEyeOff cursor="pointer" />
                    ) : (
                      <FiEye cursor="pointer" />
                    )}
                  </InputGroup.Text>
                  {errors.password && (
                    <span className="required-message">
                      {errors?.password?.message}
                    </span>
                  )}
                </InputGroup>
                <small
                  className="forgot_button"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forgot Password ?
                </small>
                <div className="login-button-div">
                  <LoadingButton
                    size="big"
                    // color="primary"
                    className="login_submit_btn btn btn-primary login_button"
                    loading={isLoading}
                    loadingPosition="start"
                    variant="contained"
                    type="submit"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    <span>Login</span>
                  </LoadingButton>
                  {REACT_APP_TYPE !== "single" && (
                    <>
                      <span className="or_span">Or</span>
                      <button
                        className="login_submit_btn btn btn-primary login_button"
                        onClick={handleLoginBokun}
                      >
                        Continue with BOKUN
                      </button>
                      <small className="login_text_p">
                        Don't have account yet?{" "}
                        <strong>
                          <small className="login_btn_small">
                            <Link to="/signup">Sign Up</Link>
                          </small>
                        </strong>
                      </small>
                    </>
                  )}
                </div>
              </Form>
            </div>
          </div>
          {REACT_APP_TYPE !== "single" && (
            <div className="footer_container">
              <div className="container">
                <p className="link_styles">
                  <a
                    className="website_links"
                    target="_blank"
                    href="https://intravelsync.com/privacy-policy/"
                  >
                    Privacy Policy
                  </a>
                  <a
                    className="website_links"
                    target="_blank"
                    href="https://intravelsync.com/refund-policy/"
                  >
                    Refund Policy
                  </a>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
