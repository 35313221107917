import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { blockPageAction } from "../../store/actions/users";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { TrimmedData } from "../../services/TrimObjectFields";
import { FaCopy } from "react-icons/fa";

function EmailSetupsForm({ submit, footer, formData }) {
  const setupTypes = [
    { id: 1, type: "Tour" },
    { id: 2, type: "Transfer" },
  ];
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    watch,
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm({
    defaultValues: {
      type: "Tour",
    },
  });

  useEffect(() => {
    if (id && formData) {
      reset(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/setup/email_setup/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const onSubmit = (data) => {
    const trimmedData = TrimmedData(data);
    submit(trimmedData);
  };

  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };

  const getCustomStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;

    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#dc3545" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Name
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="email_setup_name"
                    name="email_setup_name"
                    className={errors?.email_setup_name && "is-invalid"}
                    isInvalid={isSubmitted && errors.email_setup_name}
                    isValid={isSubmitted && watch("email_setup_name")}
                    {...register("email_setup_name", {
                      required: "Required field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                    maxLength={254}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email_setup_name
                      ? errors.email_setup_name.message
                      : "Required Field"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Type</Form.Label>
                  <Controller
                    control={control}
                    name="type"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={setupTypes}
                        getOptionValue={(i) => i.type}
                        getOptionLabel={(i) => i.type}
                        styles={getCustomStyles("order")}
                        value={
                          setupTypes.find((i) => i.type === field.value) ||
                          setupTypes[0]
                        }
                        {...register("type")}
                        onChange={(ev) => field.onChange(ev?.type)}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                      maxLength={254}
                      type="text"
                      id="email_setup_subject"
                      name="email_subject"
                      isInvalid={isSubmitted && !!errors.email_subject}
                      isValid={isSubmitted && !errors.email_subject}
                      {...register("email_subject", {
                        pattern: {
                          value: /^(?!\s*$).+/,
                          message: "Only spaces are not allowed",
                        },
                      })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email_subject?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {/*<Row>*/}
              {/*<Col sm={6}>*/}
              {/*  <Form.Group className="mb-3">*/}
              {/*    <Form.Label>Email</Form.Label>*/}
              {/*    <Form.Control*/}
              {/*      name="email_to_send"*/}
              {/*      id="email_setup_to_send"*/}
              {/*      {...register("email_to_send", {*/}
              {/*        pattern: {*/}
              {/*          value:*/}
              {/*            /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}(,\s*[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7})*$/,*/}
              {/*          message: "Invalid Format",*/}
              {/*        },*/}
              {/*      })}*/}
              {/*      maxLength={254}*/}
              {/*      style={getCustomStylesForInput("email_to_send")}*/}
              {/*    />*/}
              {/*    {errors?.email_to_send?.message && (*/}
              {/*      <span className="custom-invalid-feedback">*/}
              {/*        {errors.email_to_send.message}*/}
              {/*      </span>*/}
              {/*    )}*/}
              {/*  </Form.Group>*/}
              {/*</Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*  <Col sm={6}>*/}
            {/*    <Form.Group className="mb-3">*/}
            {/*      <Form.Label>CC</Form.Label>*/}
            {/*      <Form.Control*/}
            {/*        id="email_setup_cc"*/}
            {/*        name="email_cc"*/}
            {/*        className={errors?.email_cc && "is-invalid"}*/}
            {/*        {...register("email_cc", {*/}
            {/*          pattern: {*/}
            {/*            value:*/}
            {/*              /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}(,\s*[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7})*$/,*/}
            {/*            message: "Invalid Format",*/}
            {/*          },*/}
            {/*        })}*/}
            {/*        style={getCustomStylesForInput("email_cc")}*/}
            {/*        maxLength={254}*/}
            {/*      />*/}
            {/*      {errors?.email_cc && (*/}
            {/*        <span className="custom-invalid-feedback">*/}
            {/*          {errors.email_cc.message}*/}
            {/*        </span>*/}
            {/*      )}*/}
            {/*    </Form.Group>*/}
            {/*  </Col>*/}
            {/*  <Col sm={6}>*/}
            {/*    <Form.Group className="mb-3">*/}
            {/*      <Form.Label>BCC</Form.Label>*/}
            {/*      <Form.Control*/}
            {/*        id="email_setup_bcc"*/}
            {/*        name="email_bcc"*/}
            {/*        className={errors?.email_bcc && "is-invalid"}*/}
            {/*        {...register("email_bcc", {*/}
            {/*          pattern: {*/}
            {/*            value:*/}
            {/*              /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}(,\s*[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7})*$/,*/}
            {/*            message: "Invalid Format",*/}
            {/*          },*/}
            {/*        })}*/}
            {/*        style={getCustomStylesForInput("email_bcc")}*/}
            {/*        maxLength={254}*/}
            {/*      />*/}
            {/*      {errors?.email_bcc && (*/}
            {/*        <span className="custom-invalid-feedback">*/}
            {/*          {errors.email_bcc.message}*/}
            {/*        </span>*/}
            {/*      )}*/}
            {/*    </Form.Group>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Text for Preview Link</Form.Label>
                  <Form.Control
                    maxLength={254}
                    type="text"
                    id="email_setup_text_preview_link"
                    name="email_setup_preview_link"
                    isInvalid={isSubmitted && !!errors.email_setup_preview_link}
                    isValid={isSubmitted && !errors.email_setup_preview_link}
                    {...register("email_setup_preview_link", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email_setup_preview_link?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Voucher Preview Link</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="voucher_preview_link"
                      id="email_setup_voucher_preview_link"
                      value={"{{voucherPreviewLink}}"}
                      readOnly={true}
                    />
                    <InputGroup.Text
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.navigator?.clipboard?.writeText(
                          "{{voucherPreviewLink}}"
                        );
                        toast.success("Successfully copied.");
                      }}
                    >
                      <FaCopy />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Group className={"mb-3"}>
                  <Form.Label>
                    Body
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="email_setup_body"
                    name="email_body"
                    as="textarea"
                    rows={4}
                    className={
                      errors?.email_body
                        ? "is-invalid text_area_input"
                        : "text_area_input"
                    }
                    maxLength={4096}
                    isInvalid={isSubmitted && !watch("email_body")}
                    isValid={isSubmitted && watch("email_body")}
                    {...register("email_body", { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                  <p className="note_for_preview">
                    <strong>Note:</strong>Put the code displayed in 'Voucher
                    Preview Link' field into Body text if you want to have a
                    link to Voucher Preview with the 'Text for Preview Link' you
                    specified.
                  </p>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        {/*<Modal.Footer>{footer && footer}</Modal.Footer>*/}
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default EmailSetupsForm;
