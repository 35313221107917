export const GET_DRIVERS_REQUEST = 'GET_DRIVERS_REQUEST';
export const GET_DRIVERS_SUCCESS = 'GET_DRIVERS_SUCCESS';
export const GET_DRIVERS_FAIL = 'GET_DRIVERS_FAIL';

export function getDriversAction(searchConfig, cb) {
  return {
    type: GET_DRIVERS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const ADD_DRIVER_REQUEST = 'ADD_DRIVER_REQUEST';
export const ADD_DRIVER_SUCCESS = 'ADD_DRIVER_SUCCESS';
export const ADD_DRIVER_FAIL = 'ADD_DRIVER_FAIL';

export function addDriverAction(formData, cb) {
  return {
    type: ADD_DRIVER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_DRIVER_REQUEST = 'DELETE_DRIVER_REQUEST';
export const DELETE_DRIVER_SUCCESS = 'DELETE_DRIVER_SUCCESS';
export const DELETE_DRIVER_FAIL = 'DELETE_DRIVER_FAIL';

export function deleteDriver(id, cb) {
  return {
    type: DELETE_DRIVER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_SELECTED_DRIVERS_REQUEST =
  'DELETE_SELECTED_DRIVERS_REQUEST';
export const DELETE_SELECTED_DRIVERS_SUCCESS =
  'DELETE_SELECTED_DRIVERS_SUCCESS';
export const DELETE_SELECTED_DRIVERS_FAIL = 'DELETE_SELECTED_DRIVERS_FAIL';

export function deleteSelectedDrivers(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_DRIVERS_REQUEST,
    payload: {
      selectedRows,
      cb,
    },
  };
}

export const UPDATE_DRIVER_REQUEST = 'UPDATE_DRIVER_REQUEST';
export const UPDATE_DRIVER_SUCCESS = 'UPDATE_DRIVER_SUCCESS';
export const UPDATE_DRIVER_FAIL = 'UPDATE_DRIVER_FAIL';

export function updateDriver(formData, cb) {
  return {
    type: UPDATE_DRIVER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const STATUS_DRIVER_REQUEST = 'STATUS_DRIVER_REQUEST';
export const STATUS_DRIVER_SUCCESS = 'STATUS_DRIVER_SUCCESS';
export const STATUS_DRIVER_FAIL = 'STATUS_DRIVER_FAIL';

export function changeDriverStatusAction(id, cb) {
  return {
    type: STATUS_DRIVER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_SINGLE_DRIVER_REQUEST = 'GET_SINGLE_DRIVER_REQUEST';
export const GET_SINGLE_DRIVER_SUCCESS = 'GET_SINGLE_DRIVER_SUCCESS';
export const GET_SINGLE_DRIVER_FAIL = 'GET_SINGLE_DRIVER_FAIL';

export function getSingleDriver(id, cb) {
  return {
    type: GET_SINGLE_DRIVER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_DRIVERS_REQUEST = 'EXPORT_DRIVERS_REQUEST';
export const EXPORT_DRIVERS_SUCCESS = 'EXPORT_DRIVERS_SUCCESS';
export const EXPORT_DRIVERS_FAIL = 'EXPORT_DRIVERS_FAIL';

export function exportExcelDriversAction(searchConfig, cb) {
  return {
    type: EXPORT_DRIVERS_REQUEST,
    payload: { searchConfig, cb },
  };
}
