import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFormContext, Controller } from "react-hook-form";
// import { useParams } from "react-router-dom";
// import { FiEye, FiEyeOff } from "react-icons/fi";

function UsersForm({ control, singleUser, setValue, driver, isSubmitted }) {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  // const [pass, setPass] = React.useState("password");
  // const [oldPass, setOldPass] = React.useState("password");
  // const formData = watch();
  // const { id } = useParams();

  // const handlePassVis = (key) => {
  //   if (key === "old") setOldPass(oldPass === "password" ? "text" : "password");
  //   else setPass(pass === "password" ? "text" : "password");
  // };
  // const in_preview = id && singleUser?.user_name;
  return (
    <Row>
      <Col sm={6}>
        <Form.Group className="mb-3">
          <Form.Label>
            Username<span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Form.Control
            id="user_name"
            name="user_name"
            {...register("user_name", {
              required: true,
              pattern: /^[A-Za-z]{2,}/,
            })}
            isInvalid={isSubmitted && !watch("user_name")}
            isValid={isSubmitted && watch("user_name")}
            onChange={(ev) => {
              setValue(
                "user_name",
                ev?.target?.value ? ev.target.value?.replaceAll(" ", "") : null,
                {
                  shouldDirty: true,
                }
              );
            }}
          />
          {isSubmitted && !watch("user_name") && (
            <span className="custom-invalid-feedback">Required Field</span>
          )}
        </Form.Group>
      </Col>
      {driver ? (
        <Col>
          <Form.Label>
            Email
            <span style={{ color: "red" }}> *</span>
          </Form.Label>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Form.Control
                id="driver_email"
                {...field}
                isInvalid={(isSubmitted && !watch("email")) || errors.email}
                isValid={isSubmitted && watch("email") && !errors.email}
                {...register("email")}
                onChange={(ev) => {
                  field.onChange(ev.target.value);
                }}
              />
            )}
          />
          {isSubmitted && !watch("email") && (
            <span className="custom-invalid-feedback">Required Field</span>
          )}
          {isSubmitted && errors.email && watch("email") && (
            <span className="custom-invalid-feedback">
              {errors.email?.message}
            </span>
          )}
        </Col>
      ) : null}
      {/*<Col sm={6}>*/}
      {/*  <Form.Group className="mb-3 pass_input">*/}
      {/*    <Form.Group className="mb-3">*/}
      {/*      <Form.Label>*/}
      {/*        {id && singleUser?.user_name ? 'New Password' : 'Password'}*/}
      {/*      </Form.Label>*/}
      {/*      <InputGroup className="input_block">*/}
      {/*        <Form.Control*/}
      {/*          id="user_password"*/}
      {/*          name="password"*/}
      {/*          type={pass}*/}
      {/*          className={errors?.password && 'is-invalid'}*/}
      {/*          autoComplete="off"*/}
      {/*          value={watch('password') || ''}*/}
      {/*          {...register('password')}*/}
      {/*        />*/}
      {/*        <InputGroup.Text*/}
      {/*          id="basic-addon1"*/}
      {/*          onClick={() => handlePassVis('new')}*/}
      {/*        >*/}
      {/*          {pass === 'password' ? (*/}
      {/*            <FiEyeOff cursor="pointer" />*/}
      {/*          ) : (*/}
      {/*            <FiEye cursor="pointer" />*/}
      {/*          )}*/}
      {/*        </InputGroup.Text>*/}
      {/*      </InputGroup>*/}
      {/*      <span className="custom-invalid-feedback">*/}
      {/*        {errors.password?.message}*/}
      {/*      </span>*/}
      {/*    </Form.Group>*/}
      {/*  </Form.Group>*/}
      {/*</Col>*/}
    </Row>
  );
}

export default UsersForm;
