import { api } from './Api';
import axios from 'axios';
import Account from '../services/Account';
const { REACT_APP_API_URL } = process.env;

export default class Directions {
  static addDirection(formData) {
    return api.post(`/directions`, formData);
  }

  static getDirections(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/directions`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static getAllDirections() {
    return api.get(`/directions/all`);
  }
  static deleteDirection(id) {
    return api.delete(`/directions/${id}`);
  }
  static changeDirectionStatus(id, status) {
    return api.put(`/directions/status/${id}`, {status: status});
  }
  static updateDirection(formData) {
    return api.put(`/directions/${formData.id}`, formData);
  }
  static getSingleDirection(id) {
    return api.get(`/directions/single/${id}`);
  }

  static deleteSelectedDirections(selectedRows) {
    return api.post(`/directions/delete_selected_directions`, {
      selectedRows,
    });
  }

  static handleExportDirectionsExcel(page, search, filter, [{ sort, field }]) {
    return api({
      url: `/directions/excel`,
      method: 'GET',
      responseType: 'blob',
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }
}
