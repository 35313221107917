import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authUserAction, exchangeUserRequest } from "../store/actions/users";
import { isEmpty } from "lodash";
import logoSVG from "../../src/assets/images/white-logo.svg";
import { toast } from "react-toastify";

function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryObj = getQueryParams(location.search);
    if (queryObj?.code) {
      dispatch(
        exchangeUserRequest(queryObj, (err, result) => {
          if (
            result?.findUser &&
            result?.findUser?.status === "Pending" &&
            result?.mailHash
          ) {
            setTimeout(() => {
              window.location.href = `/user/verify/${result?.mailHash}`;
            }, 3000);
          } else if (isEmpty(result?.findUser) && !result?.mailHash) {
            setTimeout(() => {
              window.location.href = `/signup?domain=${queryObj.domain}&user=${queryObj.user}`;
            }, 3000);
          } else if (
            result?.findUser &&
            result?.findUser?.status === "Inactive" &&
            !result?.token
          ) {
            toast.error("This user has been blocked by administration.", {
              autoClose: 3000,
            });
            setTimeout(() => {
              navigate(`/login`);
            }, 3500);
          } else if (
            result?.findUser &&
            result?.findUser?.status === "Active" &&
            result?.token
          ) {
            setTimeout(() => {
              localStorage.setItem("token", result?.token);
              localStorage.setItem("account", JSON.stringify(result?.findUser));
              window.location.href = "/dashboard";
            }, 3000);
          }
        })
      );
    }
    if (queryObj?.domain && queryObj?.user && !queryObj.code) {
      dispatch(
        authUserAction(queryObj, (err, data) => {
          if (data && data?.authUrl) {
          }
        })
      );
    }
  }, []);

  const getQueryParams = (search) => {
    return Object.fromEntries(new URLSearchParams(search));
  };

  return (
    <div className="login-page parent clearfix">
      <div className="login_page_overlay">
        <div className="login">
          <div className="container">
            <h3 className="login-page_logo">
              <img src={logoSVG} width={"290px"} />
            </h3>
            <div className="login-form">
              <div className="login-button-div">
                <span className="auth-text">
                  Please wait for authentication <div class="loader"></div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
