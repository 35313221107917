import Account from '../services/Account';
import axios from 'axios';
import { api } from './Api';
const { REACT_APP_API_URL } = process.env;

export default class Advertisements {
  static addAdvertisement(formData) {
    return api.post(`/ads`, {
      ...formData,
    });
  }

  static updateAdvertisement(formData) {
    return api.put(`/ads/${formData?.id}`, {
      ...formData,
    });
  }

  static getAdvertisements(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/ads`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static deleteAdvertisement(id) {
    return api.delete(`/ads/${id}`);
  }

  static getSingleAdvertisement(id) {
    return api.get(`/ads/single/${id}`);
  }
  static deleteSelectedAdvertisements(selectedRows) {
    return api.post(`/ads/delete_selected_ads`, {
      selectedRows,
    });
  }

  static handleExportAdvertisementsExcel(
    page,
    search,
    filter,
    [{ sort, field }],
  ) {
    return api({
      url: `/ads/excel`,
      method: 'GET',
      responseType: 'blob',
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }
  static updateStatus(id) {
    return api.put(`/ads/status/${id}`);
  }

  static getWebAds(hash) {
    return api.get(`/ads/all_web/`, {
      params: {
        hash
      }
    });
  }
  static getAllAdvertisements() {
    return api.get(`/ads/all`);
  }
}
