import { api } from './Api';
const { REACT_APP_API_URL } = process.env;

export default class Roles {
  static addRoles(formData) {
    return api.post(`/roles/`, formData);
  }

  static getRoles(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/roles/`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static getAllRoles() {
    return api.get(`/users/roles`);
  }
  static deleteRoles(id) {
    return api.delete(`/roles/${id}`);
  }
  static changeRoleStatus(id) {
    return api.put(`/roles/status/${id}`);
  }
  static updateRoles(formData) {
    return api.put(`/roles/${formData.id}`, formData);
  }
  static getSingleRoles(id) {
    return api.get(`/roles/single/${id}`);
  }

  static deleteSelectedRoles(selectedRows) {
    return api.post(`/roles/delete_selected_Roles`, {
      selectedRows,
    });
  }

  static handleExportRolesExcel(page, search, filter, [{ sort, field }]) {
    return api({
      url: `/roles/excel`,
      method: 'GET',
      responseType: 'blob',
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }
}
