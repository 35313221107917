import React, { useEffect, useState } from "react";
import "./myAccount.scss";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { userForm } from "../../services/formRequiredFields";
import DriversForm from "./components/DriversForm";
import SuppliersForm from "./components/SuppliersForm";
import UsersForm from "./components/UsersForm.js";
import { getMyAccountAction } from "../../store/actions/users";
import TableTitle from "../../components/table_title/TableTitle";
import PageTitle from "../../components/PageTitle";
import ModalButtonsReset from "../../components/modals/modalButtons/ModalButtonsReset";

function MyAccount() {
  const actionStatus = useSelector((store) => store.users.actionStatus);
  const [formData, setFormData] = useState(userForm);
  const [siteName, setSiteName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getMyAccountData();
  }, []);

  const getMyAccountData = () => {
    dispatch(
      getMyAccountAction((err, data) => {
        if (!err && data) {
          setFormData(data?.user);
          setSiteName(data?.user?.full_name || data?.user?.user_name);
        }
      })
    );
  };

  return (
    <div className="admin_tables_block">
      <PageTitle
        parentTitle={"My Profile"}
        childTitle={siteName}
        parentLink={"/dashboard"}
      />
      <div className="form-block">
        {!isEmpty(formData) && formData?.role === "Driver" ? (
          <DriversForm
            singleDriver={formData}
            actionStatus={actionStatus}
            getMyAccountData={getMyAccountData}
          />
        ) : formData?.role === "Supplier" ? (
          <SuppliersForm
            single={formData}
            actionStatus={actionStatus}
            getMyAccountData={getMyAccountData}
          />
        ) : (
          <UsersForm
            user={formData}
            actionStatus={actionStatus}
            getMyAccountData={getMyAccountData}
          />
        )}
      </div>
    </div>
  );
}

export default MyAccount;
