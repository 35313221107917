import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object({
  comment: yup
      .string()

      .test("not-only-spaces", "Comment cannot be only spaces", (value) => {

        if (value) {
          return value.trim().length > 0;
        }
        return true;
      }),
});

function CommentModal({ comment, submit, footer, hide }) {
  const {
    reset,
    watch,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });


  useEffect(() => {
    if (comment) {
      reset({ comment: comment });
    }
  }, [comment, reset]);

  const onSubmit = (data) => {
    const trimmedComment = data.comment.trim();

    if (trimmedComment === "") {
      submit(trimmedComment);
      hide();
      return;
    }
    if (!trimmedComment) {
      setError("comment", {
        type: "custom",
        message: "Comment cannot be only spaces.",
      });
      return;
    }
    submit(trimmedComment);
  };

  return (
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <Form.Control
                rows={5}
                as="textarea"
                name="comment"
                className={"text_area_input"}
                isInvalid={isSubmitted && errors.comment}
                isValid={isSubmitted && watch("comment")}
                {...register("comment")}
                maxLength={1000}
            />
            <span className="custom-invalid-feedback">
            {errors.comment?.message}
          </span>
          </Form.Group>
        </div>
        <Modal.Footer>{footer && footer}</Modal.Footer>
      </Form>
  );
}

export default CommentModal;
