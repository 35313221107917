import React from "react";
import { Button } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";

export default function ModalButtons({ cancel, isLoading, saveDisabled }) {
  return (
    <div className="previewButtons">
      <LoadingButton
        size="big"
        className="login_submit_btn btn btn-primary "
        loading={isLoading}
        disabled={saveDisabled}
        variant="contained"
        type="submit"
        style={{
          textTransform: "capitalize",
          background: "#0d6efd",
          fontSize: "1rem",
        }}
      >
        Save
      </LoadingButton>
      <Button variant="secondary" onClick={cancel}>
        Cancel
      </Button>
    </div>
  );
}
