import React from 'react';
import './data_card.scss';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { Grid } from '@mui/material';
import DataCardList from './DataCardList';
import moment from 'moment';
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

const ExpandMore = styled(props => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function DataCard({
  row,
  columns,
  mobileCol,
  status,
  changeStatusHandler,
  seeMore,
}) {
  const { pathname } = useLocation();
  // const page = pathname.split('/').pop();
  const [more, setMore] = React.useState(false);
  const newCol = columns.filter(
    d => d.field !== 'actions' && d.field !== 'id' && d.field !== 'status',
  );
  const filteredColumns = !more
    ? columns.filter(c => mobileCol?.includes(c.field))
    : newCol;

  if (row.last_login) {
    row.last_login = moment(new Date(row.last_login)).format('L hh:mm');
  }
  if (row.online === 'online') {
    row.last_login = 'online';
  }
  let statusColor;
  if (Array.isArray(status)) {
    statusColor = status?.find(s => s.id === row.status);
  }

  return (
    <Card className="data_card_block">
      <CardHeader
        className="data_card_header"
        action={
          <IconButton aria-label="settings">
            {row.online && <span></span>}
            {row.status && (
              <span
                // onClick={ev => changeStatusHandler(ev, row.id)}
                className="status_icon"
                style={{
                  borderColor: statusColor?.color,
                  color: statusColor?.color,
                }}
              >
                {row?.status}
              </span>
            )}
          </IconButton>
        }
        title={`ID : ${row?.id}`}
        subheader=""
      />
      <CardContent className="data_card_content">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {filteredColumns?.map(col => (
            <DataCardList key={col.id} col={col} row={row} />
          ))}
        </Grid>
      </CardContent>
      {seeMore && seeMore?.length === 0 ? null : (
        <CardActions className="data_card_actions">
          <div className="mobile_see_more" onClick={() => setMore(!more)}>
            {more ? <IoIosArrowDropup /> : <IoIosArrowDropdown />}
            <span className="see_more">{more ? 'See Less' : 'See More'}</span>
          </div>
          <ExpandMore className="mobile_action_block">
            {columns?.find(c => c?.field === 'actions')?.getActions(row)}
          </ExpandMore>
        </CardActions>
      )}
    </Card>
  );
}
