import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_ALL_ROLES_FAIL,
  GET_ALL_ROLES_REQUEST,
  GET_ALL_ROLES_SUCCESS,
} from '../actions/roles';
import Roles from '../../api/roles';

export default function* watcher() {
  yield takeLatest(GET_ALL_ROLES_REQUEST, getAllRoles);
}

function* getAllRoles(action) {
  try {
    const { data } = yield call(Roles.getAllRoles);
    yield put({
      type: GET_ALL_ROLES_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_ALL_ROLES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}
