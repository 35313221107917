export const GET_ALL_LOGS_REQUEST = "GET_ALL_LOGS_REQUEST";
export const GET_ALL_LOGS_SUCCESS = "GET_ALL_LOGS_SUCCESS";
export const GET_ALL_LOGS_FAIL = "GET_ALL_LOGS_FAIL";

export function getLogsAction(searchConfig, cb) {
  return {
    type: GET_ALL_LOGS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}
