import axios from 'axios';
import Account from '../services/Account';
import { api } from './Api';
const { REACT_APP_API_URL } = process.env;

export default class Suppliers {
  static getSuppliersList(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/suppliers`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static getAllSuppliersList() {
    return api.get(`/suppliers/all`);
  }

  static createSupplier(form) {
    const fd = new FormData();
    fd.append('supplier_logo_file', form.supplier_logo_file);
    fd.append('formData', JSON.stringify(form));

    return axios.post(`${REACT_APP_API_URL}/suppliers`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
        Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
      },
    });
  }
  static editSupplier(id, formData) {
    const fd = new FormData();
    fd.append('supplier_logo_file', formData?.supplier_logo_file);
    fd.append('formData', JSON.stringify({ ...formData }));

    return axios.put(`${REACT_APP_API_URL}/suppliers/${id}`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
        Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
      },
    });
  }

  static singleSupplier(id) {
    return api.get(`/suppliers/single/${id}`);
  }

  static deleteSupplier(id) {
    return api.delete(`/suppliers/${id}`);
  }

  static handleExportSupplierExcel(page, search, filter, [{ sort, field }]) {
    return api({
      url: `/suppliers/excel`,
      method: 'GET',
      responseType: 'blob',
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }
  static deleteSelectedSuppliers(selectedRows) {
    return api.post(`/suppliers/delete_selected_Suppliers`, {
      selectedRows,
    });
  }
  static updateStatus(id) {
    return api.put(`/suppliers/status/${id}`);
  }
}
