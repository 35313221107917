export const tour_mobile_column = {
  mobileCard: [
    "tour_name",
    "directionId.direction_name",
    "driver_location_url",
    "supplierUserId.name",
    "pick_upId.pick_up_name",
    "service_date",
    "pick_up_time",
    "end_date",
    "start_time",
    "end_time",
    "contact_info",
  ],
  mobileList: [],
};
export const tour_vouchers_mobile_column = {
  mobileCard: [
    "ag_ref_number",
    "original_reference_number",
    "reference_number",
    "passenger_name",
    "tourId.tour_name",
    "accommodation",
  ],
  mobileList: [],
};
export const transfers_mobile_column = {
  mobileCard: [
    "transfer_name",
    "from_location",
    "to_location",
    "service_date",
    "driverUserId.name",
    "pick_up_point.pick_up_name",
    "pick_up_address",
    "transfer.pick_up_point.pick_up_name",
  ],
  mobileList: [],
};
export const transfer_vouchers_mobile_column = {
  mobileCard: [
    "ag_ref_number",
    "reference_number",
    "passenger_name",
    "transfer.transfer_name",
    "tansfer.service_date",
    "transfer.driverUserId.name",
    "state",
    "pick_up_time",
    "pick_up_address",
    "transfer.pick_up_point.pick_up_name",
  ],
  mobileList: [],
};
export const directions_mobile_column = {
  mobileCard: ["direction_name", "direction_notes", "createdAt", "updatedAt"],
  mobileList: [],
};
export const pickup_points_mobile_column = {
  mobileCard: ["pick_up_name", "pick_up_address", "createdAt", "updatedAt"],
  mobileList: [],
};
export const user_mobile_column = {
  mobileCard: [
    "role",
    "name",
    "user_name",
    "email",
    "phone_number",
    "driving_license_number",
  ],
  mobileList: [],
};
export const transfer_types_mobile_column = {
  mobileCard: ["type", "createdAt", "updatedAt"],
  mobileList: [],
};
export const advertisements_mobile_column = {
  mobileCard: ["field", "column", "createdAt", "updatedAt"],
  mobileList: [],
};
export const email_setup_mobile_column = {
  mobileCard: ["email_setup_name", "email_subject", "email_body", "type"],
  mobileList: [],
};
export const terms_conditions_mobile_column = {
  mobileCard: ["name", "description", "createdAt", "updatedAt"],
  mobileList: [],
};

export const reservations_mobile_column = {
    mobileCard: ["name", "createdAt", "updatedAt"],
    mobileList: [],
}

export const website_tours_mobile_column = {
  mobileCard: [
    "tour_name",
    "from",
    "to",
    "language",
    "price",
    "currency",
    "address",
    "zip_code",
    "createdAt",
    "updatedAt",
  ],
  mobileList: [],
};
export const website_hotels_mobile_column = {
  mobileCard: [
    "hotel_name",
    "price",
    "currency",
    "country",
    "state",
    "city",
    "address",
    "zip_code",
    "createdAt",
    "updatedAt",
  ],
  mobileList: [],
};
export const website_villas_mobile_column = {
  mobileCard: [
    "villa_name",
    "price",
    "currency",
    "country",
    "state",
    "city",
    "address",
    "zip_code",
    "createdAt",
    "updatedAt",
  ],
  mobileList: [],
};
export const website_suites_mobile_column = {
  mobileCard: [
    "suite_name",
    "price",
    "currency",
    "country",
    "state",
    "city",
    "address",
    "zip_code",
    "createdAt",
    "updatedAt",
  ],
  mobileList: [],
};
export const website_apartments_mobile_column = {
  mobileCard: [
    "apartment_name",
    "price",
    "currency",
    "country",
    "state",
    "city",
    "address",
    "zip_code",
    "createdAt",
    "updatedAt",
  ],
  mobileList: [],
};
export const website_rooms_mobile_column = {
  mobileCard: [
    "room_name",
    "price",
    "room_size",
    "currency",
    "createdAt",
    "updatedAt",
  ],
  mobileList: [],
};
