import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { blockPageAction } from "../../store/actions/users";
import { TrimmedData } from "../../services/TrimObjectFields";

function TermsConditionsForm({ submit, footer, formData }) {
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm();

  useEffect(() => {
    if (id && formData) {
      reset(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/setup/terms_conditions/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const onSubmit = (data) => {
    const trimmedData = TrimmedData(data);
    submit(trimmedData);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Name
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    id="term_condition_name"
                    className={errors?.name && "is-invalid"}
                    isInvalid={isSubmitted && !!errors?.name}
                    isValid={isSubmitted && !errors?.name}
                    {...register("name", {
                      required: "Required field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Description
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="term_condition_description"
                    name="description"
                    rows={5}
                    as="textarea"
                    className={
                      errors?.description
                        ? "is-invalid text_area_input"
                        : "text_area_input"
                    }
                    isInvalid={isSubmitted && !watch("description")}
                    isValid={isSubmitted && watch("description")}
                    {...register("description", { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        {/*<Modal.Footer>{footer && footer}</Modal.Footer>*/}
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default TermsConditionsForm;
