import React, { useState, useCallback, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { DataGridPro } from "@mui/x-data-grid-pro";
import LoadingCircle from "./loading/LoadingCircle";

export default function Table({
  rows,
  columns,
  status,
  setSort,
  setSelectedRows,
  selectedRows,
  checkboxSelection,
}) {
  const [columnState, setColumnState] = useState(columns);

  useEffect(() => {
    const updatedColumns = columns.map((col) => {
      const existingColumn = columnState.find(
        (stateCol) => stateCol.field === col.field
      );
      return existingColumn ? { ...col, width: existingColumn.width } : col;
    });
    setColumnState(updatedColumns);
  }, [columns]);

  const handleColumnResize = useCallback((params) => {
    setColumnState((prevColumnState) =>
      prevColumnState.map((col) =>
        col.field === params.colDef.field
          ? { ...col, width: params.width }
          : col
      )
    );
  }, []);

  return (
    <>
      <DataGridPro
        checkboxSelection={checkboxSelection}
        autoHeight
        className="web_table"
        disableVirtualization
        rows={rows || []}
        columns={columnState}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
        sortingMode="server"
        onSortModelChange={(model) => {
          setSort(model);
        }}
        rowHeight={40}
        components={{
          LoadingOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <LoadingCircle rows={rows} status={status} />
            </Stack>
          ),
          NoRowsOverlay: () => (
            <div
              style={{
                color: "var(--text_main)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              No Data to Display
            </div>
          ),
        }}
        loading={status}
        selectionModel={selectedRows}
        onSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
        onColumnResize={handleColumnResize}
        columnResizeMode="onChange"
      />
    </>
  );
}
