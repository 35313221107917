import React, { useEffect, useState } from 'react';
import Filter from './Filter';
import { MdAdd } from 'react-icons/md';
import { dateKeys } from '../../staticVariables/serviceTime';
import {
  addFilter,
  addServiceFilter,
  delFilterAction,
  delFilterArrayAction,
  filterAction,
} from '../../store/actions/search';
import { useDispatch, useSelector } from 'react-redux';

export default function FilterBlock({ columns, handleClear }) {
  const filterArr = useSelector(store => store.search.filterArr);
  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState([...filterArr]);

  const onFilterChange = (e, id) => {
    let oldValue = filterList.find(f => f.filterId === id);
    let newValue;
    if (Object.keys(oldValue).length > 1) {
      const key = Object.keys(oldValue)[1];
      delete oldValue[key];
    }
    if (dateKeys.includes(e.field)) {
      newValue = {};
    }
    filterList.find(f => f.filterId === id)[e.field] = newValue || '';
    dispatch(filterAction(filterList));
    setFilterList(filterList);
  };

  const onFilterValueChange = (e, id) => {
    let oldValue = filterList.find(f => f.filterId === id);
    const key = Object.keys(oldValue)[1];
    oldValue[key] = e.target.value;
    dispatch(filterAction(filterList));
    setFilterList([...filterList]);
  };
  const addFilterRow = () => {
    dispatch(addFilter(filterList));
  };

  const deleteFilter = (e, id) => {
    const find = filterList.find(f => f.filterId === id);
    const key = Object.keys(find)[1];
    setFilterList(filterList.filter(f => id !== f.filterId));
    dispatch(delFilterAction(key));
    dispatch(delFilterArrayAction(id));
    dispatch(filterAction(filterList.filter(f => id !== f.filterId)));
  };

  const onDateChange = (e, id, flag) => {
    const find = filterList.find(f => f.filterId === id);
    const key = Object.keys(find)[1];
    find[key][flag] = e;
    dispatch(filterAction(filterList));
  };

  const onStatusChange = (filterId, value, key) => {
    const find = filterList?.find((f, i) => {
      if (f?.filterId === filterId) {
        filterList[i][key] = value;
        setFilterList(filterList);
        dispatch(filterAction(filterList));
      }
    });
  };
  return (
    <>
      <div className="filter_block">
        {filterList.map((f, i) => (
          <Filter
            key={f.filterId}
            filterId={f.filterId}
            currentKey={Object.keys(f)[1]}
            index={i}
            filterList={[...filterList]}
            setFilterList={setFilterList}
            columns={columns.filter(c => c.field !== 'actions')}
            onFilterChange={onFilterChange}
            onFilterValueChange={onFilterValueChange}
            deleteFilter={deleteFilter}
            onDateChange={onDateChange}
            onStatusChange={onStatusChange}
          />
        ))}
      </div>
      <button
        id="button-addon3"
        className="btn filter_btn"
        type="button"
        onClick={addFilterRow}
      >
        <MdAdd />
      </button>
    </>
  );
}
