import {
  GET_DRIVERS_REQUEST,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_FAIL,
  GET_SINGLE_DRIVER_REQUEST,
  GET_SINGLE_DRIVER_SUCCESS,
  EXPORT_DRIVERS_REQUEST,
  EXPORT_DRIVERS_SUCCESS,
  EXPORT_DRIVERS_FAIL,
} from '../actions/drivers';

const initialState = {
  drivers: [],
  allDrivers: [],
  singleDriver: {},
  status: '',
  exportExcelStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DRIVERS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_DRIVERS_SUCCESS: {
      return {
        ...state,
        drivers: action.payload.data.result,
        status: 'success',
        count: action.payload.data.count,
      };
    }
    case GET_DRIVERS_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }

    case GET_SINGLE_DRIVER_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_DRIVER_SUCCESS: {
      return {
        ...state,
        singleDriver: action.payload.data.result,
        status: 'success',
      };
    }
    case EXPORT_DRIVERS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: 'pending',
      };
    }
    case EXPORT_DRIVERS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: 'success',
      };
    }
    case EXPORT_DRIVERS_FAIL: {
      return {
        ...state,
        exportExcelStatus: 'fail',
      };
    }
    // case UPDATE_COMPANY_REQUEST: {
    //   return {
    //     ...state,
    //     // status: 'pending',
    //   };
    // }
    // case UPDATE_COMPANY_SUCCESS: {
    //   return {
    //     ...state,
    //     status: 'success',
    //   };
    // }
    default: {
      return state;
    }
  }
}
