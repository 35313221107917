import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { driverValidation } from "./validationSchema";
import { handleUpdateMyProfile } from "../../../store/actions/users";
import { LoadingButton } from "@mui/lab";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Riple } from "react-loading-indicators";

function DriversForm({ singleDriver, actionStatus, getMyAccountData }) {
  const dispatch = useDispatch();
  const [pass, setPass] = React.useState({
    old: true,
    new: true,
    new_confirm: true,
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(driverValidation),
    defaultValues: { singleDriver, user_name: singleDriver.email } || {},
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty,isSubmitted },
    reset,
    setError,
  } = methods;

  const handlePassVis = (key) => {
    if (pass[key] === true) {
      setPass({ ...pass, [key]: false });
    } else {
      setPass({ ...pass, [key]: true });
    }
  };

  useEffect(() => {
    if (singleDriver) {
      reset(singleDriver);
    }
  }, [singleDriver, reset]);

  const onEdit = (data) => {
    if (data.password && data.confirm_password && !data.old_password) {
      setError("old_password", { type: "custom", message: "Required Field" });
      return;
    }
    if (
      data.user_name === null ||
      data.user_name === undefined ||
      (!data.user_name && data.email)
    ) {
      data.user_name = data.email;
    }
    dispatch(
      handleUpdateMyProfile(data, (err, data) => {
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            window.location.reload();
            setValue("old_password", "");
            setValue("password", "");
            setValue("confirm_password", "");
          }
        }
      })
    );
  };

  const handleReset = () => {
    reset(singleDriver);
    getMyAccountData();
  };

  return (
    <>
      {actionStatus === "pending" ? (
        <div className="dashboard-empty-div">
          <Riple color="#134074" size="large" text="" textColor="" />
        </div>
      ) : (
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onEdit)} noValidate>
            <div className="form-block">
              <Modal.Body>
                <div>
                  <h5 style={{ fontSize: 17 }}>Personal Information</h5>
                  <Row>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Full Name<span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          minLength={2}
                          maxLength={254}
                          name="full_name"
                          {...register("full_name")}
                          isInvalid={isSubmitted && !!errors.full_name}
                          isValid={isSubmitted && !errors.full_name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.full_name?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          User Name
                          {/*<span style={{ color: "red" }}> *</span>*/}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled={true}
                          value={singleDriver?.user_name}
                          // isInvalid={errors.user_name}
                          //{...register("user_name")}//
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.user_name?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Passport Details
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register("passport_details")}
                          isInvalid={isSubmitted && !!errors.passport_details}
                          isValid={isSubmitted && !errors.passport_details}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.passport_details?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          License Number<span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register("driving_license_number")}
                          isInvalid={isSubmitted && !!errors.driving_license_number}
                          isValid={isSubmitted && !errors.driving_license_number}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.driving_license_number?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Vehicle Type<span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register("vehicle_type")}
                          isInvalid={isSubmitted && !!errors.vehicle_type}
                          isValid={isSubmitted && !errors.vehicle_type}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.vehicle_type?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Registration Number
                          <span style={{ color: "red" }}> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register("registration_number")}
                          isInvalid={isSubmitted && !!errors.registration_number}
                          isValid={isSubmitted && !errors.registration_number}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.registration_number?.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div>
                  <h5 style={{ fontSize: 17 }}>Change Password</h5>
                  <Row>
                    <Col sm={4}>
                      <Form.Group className="mb-3 pass_input">
                        <Form.Group className="mb-3">
                          <Form.Label>Current Password</Form.Label>
                          <InputGroup className="input_block">
                            <Form.Control
                              type={pass.old ? "password" : "text"}
                              autoComplete="off"
                              name="old_password"
                              value={watch("old_password") || ""}
                              {...register("old_password")}
                              className={errors?.old_password && "is-invalid"}
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              onClick={() => handlePassVis("old")}
                            >
                              {pass.old ? (
                                <FiEyeOff cursor="pointer" />
                              ) : (
                                <FiEye cursor="pointer" />
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                          <span className="custom-invalid-feedback">
                            {errors.old_password?.message}
                          </span>
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3 pass_input">
                        <Form.Group className="mb-3">
                          <Form.Label>New Password</Form.Label>
                          <InputGroup className="input_block">
                            <Form.Control
                              type={pass.new ? "password" : "text"}
                              name="password"
                              className={errors?.password && "is-invalid"}
                              autoComplete="off"
                              value={watch("password") || ""}
                              {...register("password")}
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              onClick={() => handlePassVis("new")}
                            >
                              {pass.new ? (
                                <FiEyeOff cursor="pointer" />
                              ) : (
                                <FiEye cursor="pointer" />
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                          <span className="custom-invalid-feedback">
                            {errors.password?.message}
                          </span>
                        </Form.Group>
                      </Form.Group>
                    </Col>
                    <Col sm={4}>
                      <Form.Group className="mb-3 pass_input">
                        <Form.Group className="mb-3">
                          <Form.Label>Confirm Password</Form.Label>
                          <InputGroup className="input_block">
                            <Form.Control
                              type={pass.new_confirm ? "password" : "text"}
                              name="confirm_password"
                              className={
                                errors?.confirm_password && "is-invalid"
                              }
                              autoComplete="off"
                              value={watch("confirm_password") || ""}
                              {...register("confirm_password")}
                            />
                            <InputGroup.Text
                              id="basic-addon1"
                              onClick={() => handlePassVis("new_confirm")}
                            >
                              {pass.new_confirm ? (
                                <FiEyeOff cursor="pointer" />
                              ) : (
                                <FiEye cursor="pointer" />
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                          <span className="custom-invalid-feedback">
                            {errors.confirm_password?.message}
                          </span>
                        </Form.Group>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="previewButtonsID">
                  <Button
                    variant={!isDirty ? "secondary" : "primary"}
                    onClick={handleReset}
                    disabled={!isDirty}
                  >
                    Reset
                  </Button>
                  <input
                    value="Save"
                    type="submit"
                    disabled={!isDirty}
                    className={`submit_button btn ${
                      !isDirty ? "btn-secondary" : "btn-primary"
                    }`}
                  />
                </div>
              </Modal.Footer>
            </div>
          </Form>
        </FormProvider>
      )}
    </>
  );
}

export default DriversForm;
