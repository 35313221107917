import {
  GET_ROOM_RESERVATIONS_REQUEST,
  GET_ROOM_RESERVATIONS_SUCCESS,
  GET_SINGLE_ROOM_RESERVATION_REQUEST,
  GET_SINGLE_ROOM_RESERVATION_SUCCESS,
  GET_ROOM_RESERVATIONS_FAIL,
  GET_ALL_ROOM_RESERVATIONS_REQUEST,
  GET_ALL_ROOM_RESERVATIONS_SUCCESS,
  EXPORT_ROOM_RESERVATIONS_REQUEST,
  EXPORT_ROOM_RESERVATIONS_SUCCESS,
  EXPORT_ROOM_RESERVATIONS_FAIL,
  ADD_ROOM_RESERVATION_REQUEST,
  ADD_ROOM_RESERVATION_FAIL,
  ADD_ROOM_RESERVATION_SUCCESS,
  DELETE_ROOM_RESERVATION_FAIL,
  DELETE_ROOM_RESERVATION_REQUEST,
  DELETE_ROOM_RESERVATION_SUCCESS,
  UPDATE_ROOM_RESERVATION_FAIL,
  UPDATE_ROOM_RESERVATION_REQUEST,
  UPDATE_ROOM_RESERVATION_SUCCESS,
  UPDATE_STATUS_ROOM_RESERVATION_FAIL,
  UPDATE_STATUS_ROOM_RESERVATION_REQUEST,
  UPDATE_STATUS_ROOM_RESERVATION_SUCCESS,
} from "../actions/roomReservations";

const initialState = {
  roomReservations: [],
  singleDirection: {},
  allRoomReservations: [],
  status: "",
  exportExcelStatus: "",
  actionStatus: "",
  room: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROOM_RESERVATIONS_REQUEST: {
      return {
        ...state,
        roomReservations: [],
        status: "pending",
      };
    }
    case GET_ROOM_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        roomReservations: action.payload.data.reservations?.rows,
        status: "success",
        count: action.payload.data.reservations?.count,
        room: action.payload.data?.room,
      };
    }
    case GET_ROOM_RESERVATIONS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case GET_ALL_ROOM_RESERVATIONS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_ALL_ROOM_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        allRoomReservations: action.payload.data.allRoomReservations,
        status: "success",
      };
    }

    case GET_SINGLE_ROOM_RESERVATION_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_ROOM_RESERVATION_SUCCESS: {
      return {
        ...state,
        singleDirection: action.payload.data.result,
        status: "success",
      };
    }
    case ADD_ROOM_RESERVATION_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_ROOM_RESERVATION_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_ROOM_RESERVATION_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_ROOM_RESERVATION_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_ROOM_RESERVATION_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_ROOM_RESERVATION_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_ROOM_RESERVATION_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_ROOM_RESERVATION_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_ROOM_RESERVATION_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }
    case UPDATE_STATUS_ROOM_RESERVATION_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_STATUS_ROOM_RESERVATION_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_STATUS_ROOM_RESERVATION_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case EXPORT_ROOM_RESERVATIONS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: "pending",
      };
    }
    case EXPORT_ROOM_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: "success",
      };
    }
    case EXPORT_ROOM_RESERVATIONS_FAIL: {
      return {
        ...state,
        exportExcelStatus: "fail",
      };
    }

    default: {
      return state;
    }
  }
}
