import {
  GET_TRANSFER_TYPES_SUCCESS,
  GET_TRANSFER_TYPES_REQUEST,
  GET_SINGLE_TRANSFER_TYPE_REQUEST,
  GET_SINGLE_TRANSFER_TYPE_SUCCESS,
  UPDATE_TRANSFER_TYPE_REQUEST,
  UPDATE_TRANSFER_TYPE_SUCCESS,
  GET_ALL_TRANSFER_TYPES_REQUEST,
  GET_ALL_TRANSFER_TYPES_SUCCESS,
  GET_ALL_TRANSFER_TYPES_FAIL,
  EXPORT_TRANSFER_TYPES_REQUEST,
  EXPORT_TRANSFER_TYPES_SUCCESS,
  EXPORT_TRANSFER_TYPES_FAIL,
  ADD_TRANSFER_TYPE_REQUEST,
  ADD_TRANSFER_TYPE_SUCCESS,
  ADD_TRANSFER_TYPE_FAIL,
  DELETE_TRANSFER_TYPE_REQUEST,
  DELETE_TRANSFER_TYPE_SUCCESS,
  DELETE_TRANSFER_TYPE_FAIL,
  DELETE_SELECTED_TRANSFER_TYPES_REQUEST,
  DELETE_SELECTED_TRANSFER_TYPES_SUCCESS,
  DELETE_SELECTED_TRANSFER_TYPES_FAIL,
  UPDATE_TRANSFER_TYPE_FAIL,
  UPDATE_STATUS_TRANSFER_TYPE_FAIL,
  UPDATE_STATUS_TRANSFER_TYPE_SUCCESS,
  UPDATE_STATUS_TRANSFER_TYPE_REQUEST,
} from "../actions/transferTypes";

const initialState = {
  transferTypes: [],
  singleTransferType: {},
  status: "",
  exportExcelStatus: "",
  actionStatus: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSFER_TYPES_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_TRANSFER_TYPES_SUCCESS: {
      return {
        ...state,
        transferTypes: action.payload.data?.transferTypesList,
        status: "success",
        count: action.payload.data.count,
      };
    }
    case GET_SINGLE_TRANSFER_TYPE_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_TRANSFER_TYPE_SUCCESS: {
      return {
        ...state,
        singleTransferType: action.payload.data.transferType,
        status: "success",
      };
    }
    case UPDATE_TRANSFER_TYPE_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_TRANSFER_TYPE_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_TRANSFER_TYPE_FAIL: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case GET_ALL_TRANSFER_TYPES_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_ALL_TRANSFER_TYPES_SUCCESS: {
      return {
        ...state,
        allTransferTypes: action.payload.data.allTransferTypes,
        status: "success",
      };
    }
    case GET_ALL_TRANSFER_TYPES_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case ADD_TRANSFER_TYPE_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_TRANSFER_TYPE_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_TRANSFER_TYPE_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_TRANSFER_TYPE_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_TRANSFER_TYPE_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_TRANSFER_TYPE_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_SELECTED_TRANSFER_TYPES_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_SELECTED_TRANSFER_TYPES_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_SELECTED_TRANSFER_TYPES_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_STATUS_TRANSFER_TYPE_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_STATUS_TRANSFER_TYPE_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_STATUS_TRANSFER_TYPE_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case EXPORT_TRANSFER_TYPES_REQUEST: {
      return {
        ...state,
        exportExcelStatus: "pending",
      };
    }
    case EXPORT_TRANSFER_TYPES_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: "success",
      };
    }
    case EXPORT_TRANSFER_TYPES_FAIL: {
      return {
        ...state,
        exportExcelStatus: "fail",
      };
    }
    default: {
      return state;
    }
  }
}
