import { GET_ALL_ROLES_REQUEST, GET_ALL_ROLES_SUCCESS } from '../actions/roles';

const initialState = {
  roles: [],
  allRoles: [],
  singleRole: {},
  status: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_ROLES_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_ALL_ROLES_SUCCESS: {
      return {
        ...state,
        status: 'success',
        allRoles: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
