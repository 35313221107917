import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_PICK_UP_POINTS_REQUEST,
  GET_PICK_UP_POINTS_SUCCESS,
  GET_PICK_UP_POINTS_FAIL,
  GET_ALL_PICK_UP_POINTS_REQUEST,
  GET_ALL_PICK_UP_POINTS_SUCCESS,
  GET_ALL_PICK_UP_POINTS_FAIL,
  ADD_PICK_UP_POINT_REQUEST,
  ADD_PICK_UP_POINT_SUCCESS,
  ADD_PICK_UP_POINT_FAIL,
  DELETE_PICK_UP_POINT_REQUEST,
  DELETE_PICK_UP_POINT_SUCCESS,
  DELETE_PICK_UP_POINT_FAIL,
  GET_SINGLE_PICK_UP_POINT_REQUEST,
  GET_SINGLE_PICK_UP_POINT_SUCCESS,
  GET_SINGLE_PICK_UP_POINT_FAIL,
  UPDATE_PICK_UP_POINT_REQUEST,
  UPDATE_PICK_UP_POINT_SUCCESS,
  UPDATE_PICK_UP_POINT_FAIL,
  DELETE_SELECTED_PICK_UP_POINTS_REQUEST,
  DELETE_SELECTED_PICK_UP_POINTS_SUCCESS,
  DELETE_SELECTED_PICK_UP_POINTS_FAIL,
  EXPORT_PICK_UP_POINTS_REQUEST,
  EXPORT_PICK_UP_POINTS_SUCCESS,
  EXPORT_PICK_UP_POINTS_FAIL,
  UPDATE_STATUS_PICK_UP_POINT_REQUEST,
  UPDATE_STATUS_PICK_UP_POINT_SUCCESS,
  UPDATE_STATUS_PICK_UP_POINT_FAIL,
} from '../actions/pickUpPoints';
import PickUpPoints from '../../api/pickUpPoint';

export default function* watcher() {
  yield takeLatest(GET_ALL_PICK_UP_POINTS_REQUEST, getAllPickUpPoints);
  yield takeLatest(DELETE_PICK_UP_POINT_REQUEST, deletePickUpPoint);
  yield takeLatest(ADD_PICK_UP_POINT_REQUEST, addPickUpPoint);
  yield takeLatest(GET_SINGLE_PICK_UP_POINT_REQUEST, getSinglePickUpPoint);
  yield takeLatest(UPDATE_PICK_UP_POINT_REQUEST, updatePickUpPoint);
  yield takeLatest(
    UPDATE_STATUS_PICK_UP_POINT_REQUEST,
    updatePickUpPointStatus,
  );
  yield takeLatest(
    DELETE_SELECTED_PICK_UP_POINTS_REQUEST,
    deleteSelectedPickUpPoints,
  );
  yield takeLatest(GET_PICK_UP_POINTS_REQUEST, getPickUpPoints);
  yield takeLatest(EXPORT_PICK_UP_POINTS_REQUEST, handleExportExcel);
}

function* addPickUpPoint(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(PickUpPoints.addPickUpPoint, formData);
    yield put({
      type: ADD_PICK_UP_POINT_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_PICK_UP_POINT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* getPickUpPoints(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      PickUpPoints.getPickUpPoints,
      page,
      limit,
      search,
      filter,
      sort,
    );
    yield put({
      type: GET_PICK_UP_POINTS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_PICK_UP_POINTS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* deletePickUpPoint(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(PickUpPoints.deletePickUpPoint, id);
    yield put({
      type: DELETE_PICK_UP_POINT_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_PICK_UP_POINT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}
function* deleteSelectedPickUpPoints(action) {
  try {
    const { selectedRows } = action.payload;
    const { data } = yield call(
      PickUpPoints.deleteSelectedPickUpPoints,
      selectedRows,
    );
    yield put({
      type: DELETE_SELECTED_PICK_UP_POINTS_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: DELETE_SELECTED_PICK_UP_POINTS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* getSinglePickUpPoint(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(PickUpPoints.getSinglePickUpPoint, id);
    yield put({
      type: GET_SINGLE_PICK_UP_POINT_SUCCESS,
      payload: {
        data: data.pickupPoint,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SINGLE_PICK_UP_POINT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}
function* updatePickUpPoint(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(PickUpPoints.updatePickUpPoint, formData);
    yield put({
      type: UPDATE_PICK_UP_POINT_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_PICK_UP_POINT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* updatePickUpPointStatus(action) {
  try {
    const { id, status } = action.payload;
    const { data } = yield call(PickUpPoints.changePickUpPointsStatus, id, status);
    yield put({
      type: UPDATE_STATUS_PICK_UP_POINT_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_STATUS_PICK_UP_POINT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* getAllPickUpPoints(action) {
  try {
    const { data } = yield call(PickUpPoints.getAllPickUpPoints);
    yield put({
      type: GET_ALL_PICK_UP_POINTS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_PICK_UP_POINTS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      PickUpPoints.handleExportExcel,
      page,
      search,
      filter,
      sort,
    );
    yield put({
      type: EXPORT_PICK_UP_POINTS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: 'application/vnd.ms-excel',
    });
    saveAs(blob, `PickUpPoints.xlsx`);
  } catch (e) {
    console.log(e);
    yield put({
      type: EXPORT_PICK_UP_POINTS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
