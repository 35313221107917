import React, { useState } from "react";
import "./filter.scss";
import { RxCross1 } from "react-icons/rx";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  dateKeys,
  filterSelects,
  statusKeys,
} from "../../staticVariables/serviceTime";
import { useLocation } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default function Filter({
  filterId,
  columns,
  filterList,
  onFilterChange,
  onFilterValueChange,
  deleteFilter,
  onDateChange,
  onStatusChange,
  currentKey,
}) {
  const filterArr = useSelector((store) => store.search.filterArr);
  const [dis, setDis] = useState(true);
  const searchConfig = useSelector((store) => store.search);
  const [key, setKey] = useState(currentKey || "");
  const { pathname } = useLocation();
  const page = pathname.split("/")[1];
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: `#ced4da !important`,
          },
        },
      },
    },
  });
  return (
    <div
      className={dateKeys.includes(key) ? "filter date-filter" : "filter"}
      key={filterId}
    >
      <Select
        className="react-select filter_select"
        classNamePrefix="react-select filter_select"
        name="filter"
        id={filterId}
        value={
          searchConfig.filterArr &&
          Object.keys(filterList.find((i) => i.filterId === filterId)).length >
            0
            ? columns.find(
                (c) =>
                  c.field ===
                  Object.keys(
                    filterList.find((i) => i.filterId === filterId)
                  )[1]
              )
            : {}
        }
        getOptionLabel={(i) => i?.headerName}
        getOptionValue={(i) => i.field}
        options={columns?.filter((column) => {
          const findInFilter = filterList?.find((filter) =>
            Object.keys(filter).includes(column.field)
          );
          if (!findInFilter) {
            return column;
          }
        })}
        onChange={(e) => {
          onFilterChange(e, filterId);
          setKey(e.field);
          if (e.field) {
            setDis(false);
          }
        }}
      />
      {dateKeys.includes(key) ? (
        <div className="filter-date-block">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack>
              <ThemeProvider theme={filterList ? theme : theme}>
                <DesktopDatePicker
                  className="month_year_picker"
                  views={["year", "month", "day"]}
                  value={
                    filterList.find((f) => f.filterId === filterId) && key
                      ? dayjs(
                          filterList.find((f) => f.filterId === filterId)[key][
                            "$gte"
                          ] || ""
                        )
                      : ""
                  }
                  onChange={(ev) => {
                    onDateChange(
                      dayjs(ev).format("MM/DD/YYYY"),
                      filterId,
                      "$gte"
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={
                        searchConfig.filterArr &&
                        Object.keys(
                          searchConfig.filterArr.find(
                            (i) => i.filterId === filterId
                          )
                        ).length < 2
                      }
                    />
                  )}
                />
              </ThemeProvider>
            </Stack>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack>
              <ThemeProvider theme={filterList ? theme : theme}>
                <DesktopDatePicker
                  className="month_year_picker"
                  views={["year", "month", "day"]}
                  value={
                    filterList.find((f) => f.filterId === filterId) && key
                      ? dayjs(
                          filterList.find((f) => f.filterId === filterId)[key][
                            "$lte"
                          ] || ""
                        )
                      : ""
                  }
                  onChange={(ev) => {
                    onDateChange(
                      dayjs(ev).format("MM/DD/YYYY"),
                      filterId,
                      "$lte"
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder
                      required
                      disabled={
                        searchConfig.filterArr &&
                        Object.keys(
                          searchConfig.filterArr.find(
                            (i) => i.filterId === filterId
                          )
                        ).length < 2
                      }
                    />
                  )}
                />
              </ThemeProvider>
            </Stack>
          </LocalizationProvider>
        </div>
      ) : statusKeys.includes(key) ? (
        <Select
          // menuIsOpen={true}
          id={filterId}
          className={"filter_select status_select"}
          classNamePrefix="filter_select"
          name="filter"
          value={
            filterSelects[`${page}_${key}`]
              ? filterSelects[`${page}_${key}`]?.find((i) => {
                  const filter = filterList?.find((i) =>
                    Object.keys(i).includes(key)
                  );
                  return filter[key] === i.value;
                })
              : {}
          }
          getOptionLabel={(i) => i.label}
          getOptionValue={(i) => i.value}
          options={
            filterSelects[`${page}_${key}`]
              ? filterSelects[`${page}_${key}`]
              : []
          }
          onChange={(e) => {
            onStatusChange(filterId, e.value, key);
            // dispatch(filterAction(Object.assign({}, ...filterList)));
          }}
        />
      ) : (
        <Form.Control
          placeholder="Search"
          aria-label="Search"
          className={"filter_search_input"}
          aria-describedby="basic-addon2"
          disabled={
            searchConfig.filterArr &&
            Object.keys(
              searchConfig.filterArr.find((i) => i.filterId === filterId)
            ).length < 2
          }
          value={
            searchConfig.filterArr &&
            searchConfig.filterArr.find((i) => i.filterId === filterId)
              ? Object.values(
                  searchConfig.filterArr.find((i) => i.filterId === filterId)
                )[1]
              : ""
          }
          onChange={(e) => {
            onFilterValueChange(e, filterId);
          }}
        />
      )}
      {Array.isArray(filterArr) && filterArr.length && (
        <RxCross1
          size={22}
          onClick={(e) => {
            deleteFilter(e, filterId);
          }}
        />
      )}
    </div>
  );
}
