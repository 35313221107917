import React, { useEffect, useState } from 'react';
import './pagination.scss';
import ReactPaginate from 'react-paginate';
import { setPage, setRowLimit } from '../../store/actions/search';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl } from '@mui/material';
import Select from 'react-select';

const limitList = [
  { id: 1, value: 10 },
  { id: 2, value: 20 },
  { id: 3, value: 50 },
  { id: 4, value: 100 },
];

export default function Pagination({ getList, limit, count, rowStatus, rows }) {
  const searchConfig = useSelector(store => store.search);
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [window.innerWidth]);
  const handlePageChange = ({ selected }) => {
    dispatch(setPage(selected + 1));
    searchConfig.page = selected + 1;
    getList(searchConfig);
  };
  useEffect(() => {
    if (rowStatus === 'success') {
      window.scroll(0, 0);
    }
  }, [rowStatus]);

  const handleChange = value => {
    dispatch(setRowLimit(value));
    searchConfig.limit = value;
    dispatch(setPage(1));
    searchConfig.page = 1;
    getList(searchConfig);
  };
  return (
    <>
      {count > 0 && count / limit > 1 ? (
        <div className="pagination_block">
          <div className="count_div">
            <span>{count} Record(s)</span>
          </div>
          <div className="page_div">
            <FormControl>
              <Select
                id={'pagination_select'}
                className="row_control"
                value={
                  handleChange
                    ? limitList.find(i => i.value === searchConfig.limit)
                    : 10
                }
                onChange={ev => handleChange(ev.value)}
                getOptionLabel={i => i.value}
                getOptionValue={i => i.value}
                options={limitList}
                isSearchable={false}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: `var(--primary_light)`,
                    primary: `var(--primary_main)`,
                  },
                })}
              />
            </FormControl>
            {windowWidth < 520 ? (
              <ReactPaginate
                onPageChange={handlePageChange}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                pageCount={Math.ceil(count / searchConfig.limit)}
                renderOnZeroPageCount={null}
                previousLabel="<"
                nextLabel=">"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                forcePage={searchConfig.page - 1}
              />
            ) : (
              <ReactPaginate
                onPageChange={handlePageChange}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={Math.ceil(count / searchConfig.limit)}
                renderOnZeroPageCount={null}
                previousLabel="<"
                nextLabel=">"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                forcePage={searchConfig.page - 1}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="pagination_block">
          <div className="count_div">
            <span>{count} Record(s)</span>
          </div>
          <ReactPaginate
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={Math.ceil(count / searchConfig.limit)}
            renderOnZeroPageCount={null}
            previousLabel="<"
            nextLabel=">"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            forcePage={searchConfig.page - 1}
          />
        </div>
      )}
    </>
  );
}
