import { takeLatest, call, put } from "redux-saga/effects";
import {
  ADD_WEB_TOUR_REQUEST,
  ADD_WEB_TOUR_SUCCESS,
  ADD_WEB_TOUR_FAIL,
  UPDATE_WEB_TOUR_REQUEST,
  UPDATE_WEB_TOUR_SUCCESS,
  UPDATE_WEB_TOUR_FAIL,
  DELETE_WEB_TOUR_REQUEST,
  DELETE_WEB_TOUR_SUCCESS,
  DELETE_WEB_TOUR_FAIL,
  GET_ALL_WEB_TOURS_REQUEST,
  GET_SINGLE_WEB_TOUR_REQUEST,
  GET_SINGLE_WEB_TOUR_SUCCESS,
  GET_ALL_WEB_TOURS_SUCCESS,
  GET_ALL_WEB_TOURS_FAIL,
  GET_SINGLE_WEB_TOUR_FAIL,
  GET_WEB_TOURS_REQUEST,
  GET_WEB_TOURS_SUCCESS,
  GET_WEB_TOURS_FAIL,
  GET_SELECT_WEB_TOURS_SUCCESS,
  GET_SELECT_WEB_TOURS_REQUEST,
  EXPORT_WEB_TOURS_REQUEST,
  EXPORT_WEB_TOURS_SUCCESS,
  EXPORT_WEB_TOURS_FAIL,
  UPDATE_WEB_TOUR_PICKUP_REQUEST,
  UPDATE_WEB_TOUR_PICKUP_SUCCESS,
  UPDATE_WEB_TOUR_PICKUP_FAIL,
  STATUS_WEB_TOUR_REQUEST,
  STATUS_WEB_TOUR_SUCCESS,
  STATUS_WEB_TOUR_FAIL,
  GET_WEB_TOURS_GALLERY_REQUEST,
  GET_WEB_TOURS_GALLERY_SUCCESS,
  GET_WEB_TOURS_GALLERY_FAIL,
  UPDATE_WEB_TOURS_GALLERY_REQUEST,
  UPDATE_WEB_TOURS_GALLERY_SUCCESS,
  UPDATE_WEB_TOURS_GALLERY_FAIL,
  UPDATE_WEB_TOUR_TYPE_REQUEST,
  UPDATE_WEB_TOUR_TYPE_SUCCESS,
  UPDATE_WEB_TOUR_TYPE_FAIL,
} from "../actions/websiteTours";
import WebTours from "../../api/website_management/websiteTours";

export default function* watcher() {
  yield takeLatest(ADD_WEB_TOUR_REQUEST, addTourAction);
  yield takeLatest(UPDATE_WEB_TOUR_REQUEST, updateTourAction);
  yield takeLatest(DELETE_WEB_TOUR_REQUEST, deleteTourAction);
  yield takeLatest(GET_SINGLE_WEB_TOUR_REQUEST, getSingleTourAction);
  yield takeLatest(GET_WEB_TOURS_REQUEST, handleGetToursAction);
  yield takeLatest(GET_ALL_WEB_TOURS_REQUEST, getAllToursAction);
  yield takeLatest(GET_SELECT_WEB_TOURS_REQUEST, getSelectToursAction);
  yield takeLatest(STATUS_WEB_TOUR_REQUEST, handleChangeStatusAction);
  yield takeLatest(UPDATE_WEB_TOUR_PICKUP_REQUEST, updateTourPickUpAction);
  yield takeLatest(EXPORT_WEB_TOURS_REQUEST, handleExportExcelAction);
  yield takeLatest(GET_WEB_TOURS_GALLERY_REQUEST, handleGetTourGalleryAction);
  yield takeLatest(
    UPDATE_WEB_TOURS_GALLERY_REQUEST,
    handleUpdateTourGalleryAction
  );
  yield takeLatest(UPDATE_WEB_TOUR_TYPE_REQUEST, handleUpdateTourTypeAction);
}

function* addTourAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebTours.addTours, formData);
    yield put({
      type: ADD_WEB_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_WEB_TOUR_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* updateTourAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebTours.updateTours, formData);
    yield put({
      type: UPDATE_WEB_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_TOUR_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* deleteTourAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebTours.deleteTours, id);
    yield put({
      type: DELETE_WEB_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_WEB_TOUR_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* getSingleTourAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebTours.getSingleTours, id);
    yield put({
      type: GET_SINGLE_WEB_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_WEB_TOUR_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetToursAction(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;

    const { data } = yield call(
      WebTours.getTours,
      page,
      limit,
      search,
      filter,
      sort
    );

    yield put({
      type: GET_WEB_TOURS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_TOURS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* getAllToursAction(action) {
  try {
    const { data } = yield call(WebTours.getAllTours);
    yield put({
      type: GET_ALL_WEB_TOURS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_WEB_TOURS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* getSelectToursAction(action) {
  try {
    const { service_date, direction_id } = action.payload;
    const { data } = yield call(
      WebTours.getSelectTours,
      service_date,
      direction_id
    );
    yield put({
      type: GET_SELECT_WEB_TOURS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SELECT_WEB_TOURS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleChangeStatusAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebTours.changeTourStatus, id);
    yield put({
      type: STATUS_WEB_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_WEB_TOUR_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* updateTourPickUpAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebTours.updateTourPickUp, formData);
    yield put({
      type: UPDATE_WEB_TOUR_PICKUP_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_TOUR_PICKUP_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleExportExcelAction(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      WebTours.handleExportToursExcel,
      page,
      search,
      filter,
      sort
    );
    yield put({
      type: EXPORT_WEB_TOURS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, `Website_Tours.xlsx`);
  } catch (e) {
    yield put({
      type: EXPORT_WEB_TOURS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
function* handleGetTourGalleryAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebTours.getGallery, id);
    yield put({
      type: GET_WEB_TOURS_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_TOURS_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateTourGalleryAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebTours.updateGallery, id, formData);
    yield put({
      type: UPDATE_WEB_TOURS_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_TOURS_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateTourTypeAction(action) {
  try {
    const { id, type } = action.payload;
    const { data } = yield call(WebTours.updateType, id, type);
    yield put({
      type: UPDATE_WEB_TOUR_TYPE_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_TOUR_TYPE_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
