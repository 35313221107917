import { api } from './Api';

export default class Reservations {
  static getReservations(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/admin/web/reservations`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static getAllReservations() {
    return api.get(`/admin/web/reservations/all`);
  }

  static addReservation(formData) {
    return api.post(`/admin/web/reservations/add`, { ...formData });
  }

  static deleteReservation(id) {
    return api.delete(`/admin/web/reservations/${id}`);
  }

  static updateReservation(formData) {
    return api.put(`/admin/web/reservations/${formData?.id}`, { ...formData });
  }

  static getSingleReservation(id) {
    return api.get(`/admin/web/reservations/single/${id}`);
  }

  static handleExportReservationsExcel(
    page,
    search,
    filter,
    [{ sort, field }]
  ) {
    return api({
      url: `/admin/web/reservations/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }

  static updateStatus(id) {
    return api.put(`/admin/web/reservations/status/${id}`);
  }

  static getAccommodations(key) {
    return api.get(`/admin/web/reservations/all/${key}`);
  }

  static getAccommodationRooms(id, key) {
    return api.get(`/admin/web/reservations/all/rooms/${key}/${id}`);
  }
  static getRoomReservations(id, key) {
    return api.get(`/admin/web/reservations/all/${key}/rooms/${id}`);
  }
}
