import React from "react";
import "./webHeader.scss";
import Grid from "@mui/material/Unstable_Grid2";
import logoSVG from "../../assets/images/black-logo.svg";
import singleLogoSVG from "./images/single_app_logo.png";
import {Link, useLocation} from "react-router-dom";

const {REACT_APP_TYPE} = process.env;

export default function WebHeader() {
  const location = useLocation();
  const isGeneratePdf = location.pathname.includes('/generate_pdf');
  return (
    <div className="web_header_block">
      <div className="web_container">
        <Grid container spacing={0} className="web_header_grid">
          <Grid md={3} sm={3} xs={6} className="web_header_grid_item first">
            {/*<div className="logo_block">*/}
            {/*  <img src={logoSVG} width={"290px"} alt={"logo"} />*/}
            {/*</div>*/}
            <div className="logo_block">
              {REACT_APP_TYPE === "single" ? (
                <img
                  src={singleLogoSVG}
                  style={{ objectFit: "scale-down" }}
                  alt={"logo"}
                />
              ) : (
                <img src={logoSVG} width={"290px"} alt={"logo"} />
              )}
            </div>
          </Grid>
          {!isGeneratePdf && REACT_APP_TYPE === "single" ? (
               <>
                 <Grid md={6} sm={6} xs={15} className="web_header_grid_item second">
                   <div className="nav_bar">
                     <ul className="nav_ul">
                       <li className="nav_li">
                         <a target="_blank" href="https://www.allcretetours.com">
                           Home
                         </a>
                       </li>
                       <li className="nav_li">
                         <a target="_blank" href="https://www.allcretetours.com/">
                           Tours
                         </a>
                       </li>
                       <li className="nav_li">
                         <a
                             target="_blank"
                             href="https://www.allcretetours.com/contact"
                         >
                           Contact
                         </a>
                       </li>
                       <li className="nav_li">
                         <Link target="_blank" to="/voucher">
                           Tour Vouchers
                         </Link>
                       </li>
                       <li className="nav_li">
                         <Link target="_blank" to="/transfer">
                           Transfer Vouchers
                         </Link>
                       </li>
                     </ul>
                   </div>
                 </Grid>
                 <Grid md={3} sm={3} xs={6} className="web_header_grid_item last">
                   <div className="button_block">
                     <a href="https://www.allcretetours.com/" target="_blank">
                       <button className="button">{'Book Now'}</button>
                     </a>
                   </div>
                 </Grid>
               </>
          ) : null }
        </Grid>
      </div>
    </div>
  );
}
