import { api } from "../Api";
import axios from "axios";
import Account from "../../services/Account";

const { REACT_APP_API_URL } = process.env;

export default class Villas {
  static addVilla(formData) {
    return api.post(`/admin/web/villas`, formData);
  }

  static updateVilla(id, formData) {
    return api.put(`/admin/web/villas/${id}`, formData);
  }

  static deleteVilla(id) {
    return api.delete(`/admin/web/villas/${id}`);
  }

  static getSingleVilla(id) {
    return api.get(`/admin/web/villas/${id}`);
  }

  static getVillas(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/admin/web/villas/`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static changeVillaStatus(id) {
    return api.put(`/admin/web/villas/change/status/${id}`);
  }

  static handleExportVillasExcel(page, search, filter, [{ sort, field }]) {
    let service_date = {};
    if (filter.service_date) {
      if (filter.service_date["$gte"]) {
        service_date.startDate = filter.service_date["$gte"];
      }
      if (filter.service_date["$lte"]) {
        service_date.endDate = filter.service_date["$lte"];
      }
    }
    return api({
      url: `/admin/web/villas/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        search,
        service_date,
        page,
        sort,
        field,
      },
    });
  }
  static getGallery(id) {
    return api.get(`/admin/web/villas/gallery/images/${id}`);
  }

  static updateGallery(id, formData) {
    const fd = new FormData();

    fd.append("main_image", formData?.main_image_file);
    if (formData.images && formData.images.length > 0) {
      formData.images.forEach((image) => {
        fd.append("images", image.file);
      });
    }
    fd.append("formData", JSON.stringify({ ...formData }));

    const res = axios.put(
      `${REACT_APP_API_URL}/admin/web/villas/gallery/images/${id}`,
      fd,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
          Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
        },
      }
    );

    return res;
  }

  static updateType(id) {
    return api.put(`/admin/web/featured/villas/${id}`);
  }

  //Villa Rooms

  static getVillaRooms(id, page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/admin/web/villa_rooms/${id}`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static addVillaRoom(id, formData) {
    return api.post(`/admin/web/villa_rooms/${id}`, formData);
  }

  static getSingleVillaRoom(id) {
    return api.get(`/admin/web/villa_rooms/single/${id}`);
  }

  static changeVillaRoomStatus(id) {
    return api.put(`/admin/web/villa_rooms/change/status/${id}`);
  }

  static updateVillaRoom(formData) {
    return api.put(`/admin/web/villa_rooms/${formData.id}`, formData);
  }

  static deleteVillaRoom(id) {
    return api.delete(`/admin/web/villa_rooms/${id}`);
  }

  static getRoomGallery(id) {
    return api.get(`/admin/web/villa_rooms/gallery/images/${id}`);
  }

  static updateRoomGallery(id, formData) {
    const fd = new FormData();

    fd.append("main_image", formData?.main_image_file);
    if (formData.images && formData.images.length > 0) {
      formData.images.forEach((image) => {
        fd.append("images", image.file);
      });
    }
    fd.append("formData", JSON.stringify({ ...formData }));

    const res = axios.put(
      `${REACT_APP_API_URL}/admin/web/villa_rooms/gallery/images/${id}`,
      fd,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
          Authorization: Account.getToken() && `Bearer ${Account.getToken()}`,
        },
      }
    );

    return res;
  }
}
