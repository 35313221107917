import React from "react";
import "./webFooter.scss";
import Grid from "@mui/material/Unstable_Grid2";
import { FaTripadvisor } from "react-icons/fa";
import { CgFacebook } from "react-icons/cg";

export default function WebFooter() {
  return (
    <div className="web_footer_block">
      <div className="web_container">
        <Grid container spacing={5} className="web_footer_grid">
          <Grid xs={4} className="footer_flex">
            <div className="nav_block">
              <h5 className="footer_bar">CONTACT US</h5>
              <a className="footer_links" href="tel:+306932446436">
                Phone: +306932446436
              </a>
              {/*<a className="footer_links" href="mailto:info@intravelsync.com">*/}
              {/*  Email: info@intravelsync.com*/}
              {/*</a>*/}
              <a className="footer_links" href="mailto:allcretetours@gmail.com">
                Email: allcretetours@gmail.com
              </a>
              <a
                className="footer_links"
                href="https://www.google.com/maps/@35.366359,24.4780142,3a,90y,203.24h,85.97t/data=!3m6!1e1!3m4!1sm-UyBPbMWAKbAcrxm6ZzOA!2e0!7i16384!8i8192?entry=ttu"
                target="_blank"
              >
                Address: Gerakari 65 Rethymno 74100,Gr
              </a>
            </div>
          </Grid>
          {/*For intravelsync*/}
          {/*<Grid xs={4} className="footer_flex">*/}
          {/*  <div className="nav_block">*/}
          {/*    <h5 className="footer_bar">USEFUL LINKS </h5>*/}
          {/*    <a*/}
          {/*      className="footer_links"*/}
          {/*      target="_blank"*/}
          {/*      href="https://intravelsync.com/"*/}
          {/*    >*/}
          {/*      HOME*/}
          {/*    </a>*/}
          {/*    <a*/}
          {/*      className="footer_links"*/}
          {/*      target="_blank"*/}
          {/*      href="https://intravelsync.com/product/"*/}
          {/*    >*/}
          {/*      PRODUCT*/}
          {/*    </a>*/}
          {/*    <a*/}
          {/*      className="footer_links"*/}
          {/*      target="_blank"*/}
          {/*      href="https://intravelsync.com/contact/"*/}
          {/*    >*/}
          {/*      CONTACT*/}
          {/*    </a>*/}
          {/*    <a*/}
          {/*      className="footer_links"*/}
          {/*      target="_blank"*/}
          {/*      href="https://intravelsync.com/pricing/"*/}
          {/*    >*/}
          {/*      PRICING*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</Grid>*/}
          <Grid xs={4} className="footer_flex">
            <div className="nav_block">
              <h5 className="footer_bar">USEFUL LINKS </h5>
              <a
                className="footer_links"
                target="_blank"
                href="https://www.allcretetours.com/"
              >
                HOME
              </a>
              <a
                className="footer_links"
                target="_blank"
                href="https://www.allcretetours.com/tours"
              >
                TOURS
              </a>
              <a
                className="footer_links"
                target="_blank"
                href="https://www.allcretetours.com/contact"
              >
                CONTACT
              </a>
              <a
                className="footer_links"
                target="_blank"
                href="https://www.allcretetours.com/terms-and-conditions"
              >
                TERMS & CONDITIONS
              </a>
            </div>
          </Grid>
          <Grid xs={4} className="footer_flex">
            <div className="nav_block">
              <h5 className="footer_bar">FOLLOW US</h5>
              <div className="soc_block">
                <a
                  className="footer_links_soc facebookCustom"
                  target="_blank"
                  href="https://www.facebook.com"
                >
                  <CgFacebook className="socialSvgFB" />
                </a>
                <a
                  className="footer_links_soc tripAdvisor"
                  target="_blank"
                  href="https://www.tripadvisor.com/Attraction_Review-g189413-d20348241-Reviews-Tours_Tranfers_On_Crete_Island-Crete.html"
                >
                  <FaTripadvisor className="socialSvgTR" />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
