import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineClear } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { Checkbox } from "@mui/material";
import { FormControlLabel } from "@material-ui/core";
import UsersForm from "./UsersForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { supplierValidation } from "./validationSchema";
import {
  blockPageAction,
  registerRequest,
  updateUser,
} from "../../../store/actions/users";
import { LoadingButton } from "@mui/lab";

function SuppliersForm({
  handleClose,
  single,
  getUsersList,
  add_new_modal,
  actionStatus,
}) {
  let searchConfig = useSelector((store) => store.search);
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    setError,
    formState: { errors, isValid, isDirty, isSubmitted },
    clearErrors,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(supplierValidation),
    defaultValues: id
      ? { ...single, add_user: !!single?.user_name }
      : { add_user: false, role: "Supplier" },
  });

  useEffect(() => {
    if (id && single) {
      reset({
        ...single,
        add_user: !!single.user_name,
      });
    }
  }, [id, single, reset]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/users/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const handlePhotoChange = (key, val) => {
    if (val && val.type.startsWith("image/") && val.type !== "image/svg+xml") {
      setValue(key, val.name, { shouldDirty: true });
      setValue(`${key}_file`, val, { shouldDirty: true });
      clearErrors(key);
      trigger(key);
    } else if (val && val.type === "image/svg+xml") {
      setError(key, {
        type: "svg_support",
        message: "SVG files are not supported.",
      });
    }
  };

  const handleRemoveImg = () => {
    setValue("supplier_logo", null, { shouldDirty: true });
    setValue("supplier_logo_file", null, { shouldDirty: true });
  };

  const onSubmit = (data) => {
    setDisabled(true);
    dispatch(
      registerRequest(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        } else {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          getUsersList(searchConfig);
          handleClose();
          setTimeout(() => {
            setDisabled(false);
          }, 400);
        }
      })
    );
  };

  const onEdit = (data) => {
    dispatch(
      updateUser(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            navigate("/users");
          }
        }
      })
    );
  };
  const formData = watch();

  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };

  return (
    <>
      <FormProvider
        {...{
          control,
          register,
          handleSubmit,
          setValue,
          watch,
          formState: { errors, isValid, isDirty },
        }}
      >
        <Form onSubmit={handleSubmit(id ? onEdit : onSubmit)} noValidate>
          <div className="form-block">
            <Modal.Body>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Full Name<span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      id="full_name"
                      name="full_name"
                      type="text"
                      minLength={2}
                      maxLength={254}
                      {...register("full_name", { required: true })}
                      isInvalid={isSubmitted && !!errors.full_name}
                      isValid={isSubmitted && !errors.full_name}
                      // onChange={(ev) => {
                      //   setValue("full_name", ev?.target?.value || null, { shouldDirty: true });
                      // }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.full_name?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Supplier Logo</Form.Label>
                    <div className="file-input file-upload">
                      <label htmlFor="supplier_logo">
                        <input
                          className="choose-btn"
                          type="button"
                          value="Choose"
                          onClick={() => fileInputRef.current.click()}
                        />
                      </label>
                      <input
                        className="border-none"
                        type="text"
                        value={formData.supplier_logo || ""}
                        style={getCustomStylesForInput("supplier_logo")}
                        readOnly
                      />
                      <input
                        type="file"
                        id="supplier_logo"
                        accept=".jpg, .png, image/jpeg, image/png"
                        ref={fileInputRef}
                        onChange={(e) =>
                          handlePhotoChange("supplier_logo", e.target.files[0])
                        }
                      />
                      {formData.supplier_logo && (
                        <MdOutlineClear
                          className="remove_img"
                          onClick={handleRemoveImg}
                        />
                      )}
                    </div>
                    {errors?.supplier_logo &&
                    errors?.supplier_logo.type === "svg_support" ? (
                      <p className="custom-invalid-feedback">
                        {errors?.supplier_logo?.message}
                      </p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Email<span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Form.Control
                      id="supplier_email"
                      type="email"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}(,\s*[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7})*$/,
                          message: "Invalid Format",
                        },
                      })}
                      className={errors?.email && "is-invalid"}
                      isInvalid={isSubmitted && !watch("email")}
                      isValid={isSubmitted && watch("email")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Phone Number<span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <PhoneInput
                      inputProps={{
                        id: "supplier_phone_number",
                        name: "phone_number",
                      }}
                      buttonStyle={
                        isSubmitted && !watch("phone_number")
                          ? { borderColor: "#dc3545" }
                          : isSubmitted && watch("phone_number")
                          ? { borderColor: "#198754" }
                          : { borderColor: "#ced4da" }
                      }
                      inputStyle={
                        isSubmitted && !watch("phone_number")
                          ? { borderColor: "#dc3545" }
                          : isSubmitted && watch("phone_number")
                          ? { borderColor: "#198754" }
                          : { borderColor: "#ced4da" }
                      }
                      value={watch("phone_number") || ""}
                      onChange={(value) => {
                        setValue("phone_number", value, { shouldDirty: true });
                        clearErrors("phone_number");
                      }}
                    />
                    {errors.phone_number?.message && (
                      <span className="invalid_message">
                        {errors.phone_number?.message}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      id="supplier_description"
                      name="description"
                      as="textarea"
                      rows={5}
                      maxLength={4000}
                      {...register("description")}
                      value={watch("description") || ""}
                      style={getCustomStylesForInput("description")}
                    />
                    <div className="max_style">
                      Max length of description is 4000 characters
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  {id && !!single?.user_name ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          defaultChecked={true}
                          checked={true}
                        />
                      }
                      label="Create Account ?"
                    />
                  ) : (
                    <Controller
                      name="add_user"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="supplier_create_account"
                              {...field}
                              disabled={id && single?.user_name}
                              defaultChecked={id && single?.user_name}
                              checked={
                                id && !!single?.user_name
                                  ? true
                                  : watch("add_user")
                              }
                              onChange={(ev) => {
                                setValue("add_user", ev.target.checked, {
                                  shouldDirty: true,
                                });
                              }}
                            />
                          }
                          label="Create Account ?"
                        />
                      )}
                    />
                  )}
                </Col>
              </Row>
              {watch("add_user") || (!add_new_modal && single?.user_name) ? (
                <UsersForm
                  setValue={setValue}
                  control={control}
                  singleUser={single}
                  isSubmitted={isSubmitted}
                />
              ) : null}
            </Modal.Body>
          </div>
          <Modal.Footer>
            {id ? (
              <div className="previewButtonsID">
                <Button
                  variant={isFormDisabled ? "secondary" : "primary"}
                  disabled={isFormDisabled}
                  onClick={() => reset({ ...single })}
                >
                  Reset
                </Button>
                <LoadingButton
                    size="big"
                    className={`preview_submit_btn btn ${
                        isFormDisabled ? "btn-secondary" : "btn-primary"
                    }`}
                    style={{ height: "100%", fontSize: "1rem", lineHeight: "1.55" }}
                    loading={actionStatus === "pending"}
                    disabled={isFormDisabled}
                    variant="contained"
                    type="submit"
                >
                  Save
                </LoadingButton>
              </div>
            ) : (
              <>
                <div className="previewButtons">
                  <LoadingButton
                    size="small"
                    className="login_submit_btn btn btn-primary "
                    loadingPosition="center"
                    variant="contained"
                    loading={actionStatus === "pending"}
                    type={"submit"}
                    disabled={disabled}
                  >
                    <span>Save</span>
                  </LoadingButton>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </Modal.Footer>
        </Form>
      </FormProvider>
    </>
  );
}

export default SuppliersForm;
