import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Table from "../../components/table";
import Pagination from "../../components/pagination/Pagination";
import SearchFilterBar from "../../components/search_filter_bar/SearchFilterBar";
import TableTitle from "../../components/table_title/TableTitle";
import {getHashedIdAction, setPage, sortAction} from "../../store/actions/search";
import DeleteModal2 from "../../components/modals/DeleteModal2";
import { getColumns } from "./column";
import TermsConditionsForm from "./TermsConditionsForm";
import UpdateStatusModal from "../../components/modals/UpdateStatusModal";
import {
  getTermsConditionsAction,
  deleteTermsConditionAction,
  addTermsConditionAction,
} from "../../store/actions/termsConditions";
import MobileLayout from "../../components/mobileLayout/MobileLayout";
import { terms_conditions_mobile_column } from "../../staticVariables/mobileColumns";
import DynamicModal from "../../components/modals/DynamicModal";
import ModalButtons from "../../components/modals/modalButtons/ModalButtons";

export const termsConditionsStatus = [
  {
    id: "Active",
    color: "green",
  },
  {
    id: "Inactive",
    color: "#dc3545",
  },
];

function TermsConditions() {
  let rows = useSelector((store) => store?.termsConditions?.termsConditions);
  let rowStatus = useSelector((store) => store?.termsConditions?.status);
  let actionStatus = useSelector(
    (store) => store?.termsConditions?.actionStatus
  );
  const count = useSelector((store) => store.termsConditions?.count);
  const accessList = useSelector((store) => store.users.accessList);
  let searchConfig = useSelector((store) => store.search);

  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowId, setRowId] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getTermsConditionsList(searchConfig);
  }, []);
  const getTermsConditionsList = (searchConfig) => {
    dispatch(getTermsConditionsAction(searchConfig));
  };

  const redirectToPreview = useCallback((id) => {
    dispatch(
        getHashedIdAction(id, (err, data) => {
          if (data?.hash) {
            navigate(`/setup/terms_conditions/${data?.hash}`);
          }
        })
    );
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setShow(false);
    setTimeout(() => {
      setDisabled(false);
    }, 400);
  };

  const handleAddConfirm = async (data) => {
    setDisabled(true);
    dispatch(
      addTermsConditionAction(data, (err, data) => {
        if (data) {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
          dispatch(getTermsConditionsAction(searchConfig));
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        }
      })
    );
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setShowDelete(true);
    setRowId(params);
  };

  const handleConfirmDelete = (e, id) => {
    dispatch(
      deleteTermsConditionAction(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          getTermsConditionsList(searchConfig);
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
      })
    );
  };

  const handleShow = (title) => {
    setShow(true);
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getTermsConditionsList(searchConfig);
  };

  const columns = getColumns({
    rows,
    accessList,
    redirectToPreview,
    handleDelete,
  });

  return (
    <div className="admin_tables_block">
      <TableTitle
        handleShow={handleShow}
        title={"Terms and Conditions"}
        addNew={true}
      />
      <SearchFilterBar
        getList={getTermsConditionsList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows}
        columns={columns}
        setShow={setShow}
        redirectToPages={redirectToPreview}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkboxSelection={accessList && accessList.includes("176")}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        redirectToPages={redirectToPreview}
        // changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={"Terms and Conditions"}
        link="/setup/terms_conditions"
        mobileCol={terms_conditions_mobile_column}
        status={termsConditionsStatus}
        search={getTermsConditionsList}
      />
      <DynamicModal
        show={show}
        hide={() => setShow(false)}
        title={"Add New Terms and Conditions"}
        body={
          <TermsConditionsForm
            submit={handleAddConfirm}
            footer={
              <ModalButtons
                submit={handleAddConfirm}
                cancel={() => setShow(false)}
                isLoading={actionStatus === "pending"}
                saveDisabled={disabled}
              />
            }
          />
        }
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={(e) => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId.id} terms and conditions. Are you sure?`}
        title={"Delete"}
        actionStatus={actionStatus}
      />

      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getTermsConditionsList}
      />
    </div>
  );
}

export default TermsConditions;
