import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle.js";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import EmailSetupsForm from "./EmailSetupsForm";
import {
  editEmailSetupAction,
  getSingleEmailSetupAction,
} from "../../store/actions/emailSetups";
import { blockPageAction } from "../../store/actions/users";
import ModalButtonsReset from "../../components/modals/modalButtons/ModalButtonsReset";
import { toast } from "react-toastify";
import { TrimmedData } from "../../services/TrimObjectFields";
import DynamicModal from "../../components/modals/DynamicModal";

function PreviewEmailSetups() {
  const rowStatus = useSelector((store) => store?.emailSetups?.status);
  const actionStatus = useSelector((store) => store?.emailSetups?.actionStatus);
  const [openEmailPreview, setOpenEmailPreview] = useState(false);
  const [email_body, setEmail_body] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    handleGetSingle(id);
    return () => {
      dispatch(blockPageAction(""));
    };
  }, []);

  // Get the row data to edit
  const handleGetSingle = (id) => {
    dispatch(
      getSingleEmailSetupAction(id, (err, data) => {
        if (data) {
          setFormData({ ...data });
        } else if (err) {
          navigate(`/dashboard`);
        }
      })
    );
  };

  const handleEditConfirm = async (data) => {
    const trimmedData = TrimmedData(data);
    setDisabled(true);
    dispatch(
      editEmailSetupAction(trimmedData, (err, data) => {
        if (data) {
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          navigate("/setup/email_setup");
          setDisabled(false);
        } else {
          setTimeout(() => {
            setDisabled(false);
          }, 400);
        }
      })
    );
  };

  const handlePreviewEmail = (formData) => {
    if (formData?.email_body) {
      const linkTag = `<span  style="color: #0656c8">${
        formData?.email_setup_preview_link || "Voucher"
      }</span>`;
      const body = formData.email_body
        .replace(/\n/g, "<br />")
        .replaceAll("{{voucherPreviewLink}}", linkTag);
      setEmail_body(body);
    }
    setOpenEmailPreview(!openEmailPreview);
  };

  return (
    <>
      <PageTitle
        parentTitle={"Email Setup"}
        childTitle={formData?.email_setup_name || ""}
        parentLink={"/setup/email_setup"}
      />
      <div className="form-block">
        {rowStatus === "success" ? (
          <EmailSetupsForm
            submit={handleEditConfirm}
            formData={formData}
            footer={
              <ModalButtonsReset
                disabled={disabled}
                isLoading={actionStatus === "pending"}
                submit={handleEditConfirm}
                reset={() => {
                  handleGetSingle(id);
                }}
                preview={() => {
                  handlePreviewEmail(formData);
                }}
              />
            }
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === "pending"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
      {openEmailPreview && (
        <DynamicModal
          size={"lg"}
          show={openEmailPreview}
          hide={() => setOpenEmailPreview(false)}
          title={"Email Preview"}
          body={
            <div className="p-3">
              <div
                dangerouslySetInnerHTML={{ __html: email_body }}
                style={{ wordBreak: "break-word" }}
              />
            </div>
          }
        />
      )}
    </>
  );
}

export default PreviewEmailSetups;
