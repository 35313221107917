import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { blockPageAction } from "../../store/actions/users";
import { useForm } from "react-hook-form";
import { TrimmedData } from "../../services/TrimObjectFields";

function TransferTypesForm({ submit, footer, formData }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm();

  useEffect(() => {
    if (id && formData) {
      reset(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/setup/transfer_types/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const onSubmit = (data) => {
    const trimmedData = TrimmedData(data);
    submit(trimmedData);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Type
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="transfer_type"
                    name="type"
                    maxLength={254}
                    isInvalid={isSubmitted && !!errors.type}
                    isValid={isSubmitted && !errors.type}
                    {...register("type", {
                      required: "Required field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.type ? errors.type.message : "Required Field"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        {/*<Modal.Footer>{footer && footer}</Modal.Footer>*/}
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default TransferTypesForm;
