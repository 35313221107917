import { api } from './Api';
import axios from 'axios';
import Account from '../services/Account';
const { REACT_APP_API_URL } = process.env;

export default class TourTransfers {
  static addTourTransfers(formData) {
    return api.post(`/transfers/`, formData);
  }

  static getTourTransfers(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/transfers/list`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static getAllTourTransfers() {
    return api.get(`/transfers/`);
  }
  static deleteTourTransfers(id) {
    return api.delete(`/transfers/${id}`);
  }
  static changeTourTransferStatus(id) {
    return api.put(`/transfers/status/${id}`);
  }
  static updateTourTransfers(formData) {
    return api.put(`/transfers/${formData.id}`, formData);
  }
  static getSingleTourTransfers(id) {
    return api.get(`/transfers/single/${id}`);
  }

  static deleteSelectedTourTransfers(selectedRows) {
    return api.post(`/transfers/delete_selected_TourTransfers`, {
      selectedRows,
    });
  }

  static addComment(id, hidden_note) {
    return api.put(`/transfers/comment/${id}`, { hidden_note });
  }

  static copyTourTransfer(id) {
    return api.post(`/transfers/copy/${id}`);
  }
  static sendTourTransferEmail(id, formData) {
    return api.post(`/transfers/send_email/${id}`, formData);
  }

  static handleExportExcel(page, search, filter, [{ sort, field }]) {
    let service_date = {};
    if (filter.service_date) {
      if (filter.service_date['$gte']) {
        service_date.startDate = filter.service_date['$gte'];
      }
      if (filter.service_date['$lte']) {
        service_date.endDate = filter.service_date['$lte'];
      }
    }
    return api({
      url: `/transfers/excel`,
      method: 'GET',
      responseType: 'blob',
      params: {
        search,
        filter,
        service_date,
        page,
        sort,
        field,
      },
    });
  }
  static checkTourTransfer({ reference_number }) {
    return api.get(`/transfers/check/t`, {
      params: {
        reference_number,
      },
    });
  }
  static downloadTransfer(id) {
    return api.get(`/transfers/download_pdf/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    });
  }
  static getSelectTransfers(service_date) {
    return api.get(`/transfers/select`, {
      params: {
        service_date,
      },
    });
  }
}
