import React, { useEffect, useState } from "react";
import "./wrapper.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Account from "../../services/Account";
import { clearSearchAction, setPage } from "../../store/actions/search";
import AdminSideBar from "../AdminSideBar/AdminSideBar";
import { Outlet, Navigate } from "react-router-dom";
import SwipeMenu from "../AdminSideBar/SwipeMenu";
import TabMenu2 from "../tabMenue/TabMenu2";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import { initial, last, nth } from "lodash";
import Cookie from "js-cookie";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { myAccountRequest } from "../../store/actions/users";
import SkeletonLoading from "../skeletonLoading/SkeletonLoading";
import { socketInit } from "../../store/actions/socket";
import { titleFromEnv } from "../../services/utils";

let timer;
const { REACT_APP_TYPE, REACT_APP_SINGLE_NAME } = process.env;

function Wrapper() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const blockedPage = useSelector((store) => store.search.blockedPage);
  const accountStatus = useSelector((store) => store.users.getMyAccountStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [socket, setSocket] = useState(false);
  const [conn, setConn] = useState(false);
  const [history, setHistory] = useState([]);
  const token = Account.getToken();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    dispatch(myAccountRequest());
    return () => {
      if (socket) {
        socket.emit("logOut");
      }
      window.history.pushState("/login", "", "/login");
    };
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(
        socketInit(token, () => {
          setSocket(true);
        })
      );
    }
  }, [token]);

  // useEffect(() => {
  //   if (!conn && token) {
  //     setConn(true);
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {}, 2000);
  //   }
  // }, [socket]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    if (blockedPage) {
      window.onpopstate = () => {
        window.history.pushState(null, "", window.location.href);
        toast.error(
          'You have unsaved changes on this form. Please save them or click "Reset" to undo.',
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          }
        );
      };
    } else {
      window.onpopstate = (ev) => {
        ev.preventDefault();
        if (Array.isArray(history)) {
          const penultimate = nth(history, -2) || last(history);
          if (history.length > 1) {
            setHistory([...initial(history)]);
          }
          navigate(penultimate);
        } else {
          navigate(window.location.pathname);
        }
      };
    }
  }, [blockedPage, history]);

  useEffect(() => {
    const handleBeforeUnload = (ev) => {
      ev.preventDefault();
      ev.returnValue = "";
    };

    if (blockedPage) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [blockedPage]);

  useEffect(() => {
    if (drawerOpen) {
      setDrawerOpen(false);
    }
    if (
      history &&
      Array.isArray(history) &&
      last(history) !== location.pathname
    ) {
      Cookie.set("history", history.length);
      setHistory([...history, window.location.pathname]);
    }
    dispatch(clearSearchAction());
  }, [location.pathname]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window.innerWidth]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  useEffect(() => {
    const webRow = document?.getElementsByClassName(
      "MuiDataGrid-virtualScroller"
    );
  }, [handleChangePage]);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    // document.body.classList.toggle('no-scroll');
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {REACT_APP_TYPE === "single" && REACT_APP_SINGLE_NAME
              ? titleFromEnv(REACT_APP_SINGLE_NAME)
              : "InTravelSync"}
          </title>
          <link
            rel="icon"
            href={
              REACT_APP_TYPE === "single"
                ? "/single_app_favicon.ico"
                : "/favicon.ico"
            }
          />
        </Helmet>
      </HelmetProvider>
      <div className="wrapper">
        {token ? (
          <>
            {accountStatus === "pending" ? (
              <SkeletonLoading />
            ) : (
              <>
                <Header toggleDrawer={toggleDrawer} />
                <div style={{ height: "auto" }}>
                  <div className="admin_banner main_container">
                    <div className="body_html">
                      <SwipeMenu
                        toggleDrawer={toggleDrawer}
                        drawerOpen={drawerOpen}
                      />
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={2.3}>
                          <div className="admin_sidebar_block">
                            <AdminSideBar />
                          </div>
                        </Grid>
                        <Grid item xs={12} lg={9.7}>
                          <Outlet history={history} />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
                <Footer />
              </>
            )}
          </>
        ) : (
          <Navigate to="/login" />
        )}

        <TabMenu2 />
      </div>
    </>
  );
}

export default Wrapper;
