import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle";
import "./settings.scss";
import SettingsForm from "./SettingsForm";
import { getCompanySettingsAction } from "../../store/actions/settings";

function CompanySettings() {
  const actionStatus = useSelector((store) => store.settings.status);
  const [settings, setSettings] = useState();
  const [siteName, setSiteName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getMyCompanyData();
  }, []);

  const getMyCompanyData = () => {
    dispatch(
      getCompanySettingsAction((err, data) => {
        if (!err && data) {
          setSettings(data?.settings);
          setSiteName(
            data?.settings?.company_name || data?.settings?.company_email
          );
        }
      })
    );
  };

  return (
    <div className="admin_tables_block">
      <PageTitle
        parentTitle={"Settings"}
        childTitle={siteName}
        parentLink={"/dashboard"}
      />
      <div className="form-block">
        <SettingsForm
          settings={settings}
          getCompanyDataAction={getMyCompanyData}
        />
      </div>
    </div>
  );
}

export default CompanySettings;
