import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logoSVG from "../../src/assets/images/white-logo.svg";
import CompanyDetails from "../components/SignUp_components/CompanyDetails";
import UserDetails from "../components/SignUp_components/UserDetails";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { registerUserRequest, saveUserData } from "../store/actions/users";

const { REACT_APP_TYPE } = process.env;

function SignUp() {
  const loadingStatus = useSelector((store) => store.users.userRegisterStatus);
  const [step, setStep] = useState(1);
  const [adminData, setAdminData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getQueryParams = (search) => {
    return Object.fromEntries(new URLSearchParams(search));
  };

  const handleAdminSubmit = (data) => {
    setAdminData(data);
    setStep(2);
    dispatch(saveUserData(data));
  };

  const handleGoBack = () => {
    setStep(1);
    setAdminData({ ...adminData });
  };

  const handleCompanySubmit = (data) => {
    const queryObj = getQueryParams(location.search);
    const formData = { ...data, ...adminData, bokun_user_id: queryObj?.user };
    dispatch(
      registerUserRequest(formData, (err, result) => {
        if (err) {
          toast.error(err, {
            autoClose: 5000,
          });
        } else {
          toast.success(
            "Registration successfully initiated. Please check you email to complete.",
            {
              autoClose: 5000,
            }
          );

          navigate("/login");
        }
      })
    );
  };
  return (
    <div className="login-page parent clearfix">
      <div className="login_page_overlay">
        <div className="login">
          <div className="container">
            <h3 className="login-page_logo">
              <img src={logoSVG} width={"290px"} />
            </h3>
            <h4 className="login-page_title">Sign Up</h4>
            <div className="signup-form">
              {step === 1 ? (
                <>
                  <h6 className="signup-page_h6">Administrator Details</h6>
                  <UserDetails
                    onSubmit={handleAdminSubmit}
                    loadingStatus={loadingStatus}
                    adminData={adminData}
                  />
                </>
              ) : (
                <>
                  <h6 className="signup-page_h6">Company Details</h6>
                  <CompanyDetails
                    onSubmit={handleCompanySubmit}
                    goBack={handleGoBack}
                    loadingStatus={loadingStatus}
                  />
                </>
              )}
            </div>
          </div>
          {REACT_APP_TYPE !== "single" && (
              <div className="footer_container">
                <div className="container">
                  <p className="link_styles">
                    <a
                        className="website_links"
                        target="_blank"
                        href="https://intravelsync.com/privacy-policy/"
                    >
                      Privacy Policy
                    </a>
                    <a
                        className="website_links"
                        target="_blank"
                        href="https://intravelsync.com/refund-policy/"
                    >
                      Refund Policy
                    </a>
                  </p>
                </div>
              </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SignUp;
