import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Account from "../services/Account";
import { Outlet } from "react-router-dom";
import { logOut } from "../store/actions/users";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { titleFromEnv } from "../services/utils";
const { REACT_APP_TYPE, REACT_APP_SINGLE_NAME } = process.env;
function WrapperLogOut(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((store) => store.users.token);
  const account = useSelector((store) => store.users.account);

  useEffect(() => {
    if (token && Account.getToken()) {
      const account = Account.get();
      if (account.id === 1 && account.role === "Super Admin") {
        navigate("/users", { redirect: true });
      } else {
        navigate("/dashboard", { redirect: true });
      }
    } else {
      // dispatch(logOut());
      // window.history.pushState("/login", "", "/login");
    }
  }, [token]);

  if (token && localStorage.getItem("account")) {
    return null;
  }
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {REACT_APP_TYPE === "single" && REACT_APP_SINGLE_NAME
              ? titleFromEnv(REACT_APP_SINGLE_NAME)
              : "InTravelSync"}
          </title>
          <link
            rel="icon"
            href={
              REACT_APP_TYPE === "single"
                ? "/single_app_favicon.ico"
                : "/favicon.ico"
            }
          />
        </Helmet>
      </HelmetProvider>
      <Outlet />
    </>
  );
}

export default WrapperLogOut;
