import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { range } from "lodash";
import { useParams } from "react-router-dom";
import { blockPageAction } from "../../store/actions/users";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

function AdvertisementsForm({ submit, formData, footer, rows }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(
    formData?.column || "left"
  );
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const {
    watch,
    reset,
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm({
    defaultValues: {
      column: formData?.column || "left",
      order:
        formData?.order || rows?.filter((r) => r.column === "left").length + 1,
    },
  });

  useEffect(() => {
    if (id && formData) {
      reset(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/setup/advertisements/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  useEffect(() => {
    setFilteredRows(rows?.filter((r) => r.column === selectedColumn) || []);
  }, [selectedColumn, rows]);

  const onSubmit = (data) => {
    submit(data);
  };

  const getCustomStylesForInput = (field) => {
    const isInvalid = !!errors[field];
    const hasValue = watch(field) && watch(field).length > 0;
    const isValid = isSubmitted && !isInvalid;

    return {
      borderColor: isInvalid ? "#dc3545" : isValid ? "#198754" : "#ced4da",
      borderWidth: "1px",
      borderStyle: "solid",
    };
  };
  const getCustomStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;

    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#dc3545" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Html
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="advertisement_html"
                    name="html"
                    rows={5}
                    as="textarea"
                    className={"text_area_input"}
                    isInvalid={isSubmitted && !watch("html")}
                    isValid={isSubmitted && watch("html")}
                    {...register("html", { required: true })}
                  />
                  <Form.Control.Feedback type="invalid">
                    Required Field
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Column</Form.Label>
                  <Form.Select
                    id="advertisement_select_column"
                    name="column"
                    aria-label="column"
                    {...register("column")}
                    value={selectedColumn}
                    style={getCustomStylesForInput("column")}
                    onChange={(e) => {
                      setSelectedColumn(e.target.value);
                      setValue("column", e.target.value, { shouldDirty: true });
                      setValue(
                        "order",
                        rows?.filter((r) => r.column === e.target.value)
                          .length + 1,
                        { shouldDirty: true }
                      );
                    }}
                  >
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Order</Form.Label>
                  <Controller
                    control={control}
                    name="order"
                    render={({ field }) => (
                      <Select
                        {...field}
                        styles={getCustomStyles("order")}
                        options={range(
                          1,
                          filteredRows.length + (id ? 1 : 2)
                        ).map((i) => ({ value: i, label: i }))}
                        value={{ value: field.value, label: field.value }}
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption.value)
                        }
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        {/*<Modal.Footer>{footer && footer}</Modal.Footer>*/}
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default AdvertisementsForm;
