import { takeLatest, call, put } from "redux-saga/effects";
import {
  ADD_WEB_VILLA_REQUEST,
  ADD_WEB_VILLA_SUCCESS,
  ADD_WEB_VILLA_FAIL,
  UPDATE_WEB_VILLA_REQUEST,
  UPDATE_WEB_VILLA_SUCCESS,
  UPDATE_WEB_VILLA_FAIL,
  DELETE_WEB_VILLA_REQUEST,
  DELETE_WEB_VILLA_SUCCESS,
  DELETE_WEB_VILLA_FAIL,
  GET_SINGLE_WEB_VILLA_REQUEST,
  GET_SINGLE_WEB_VILLA_SUCCESS,
  GET_SINGLE_WEB_VILLA_FAIL,
  GET_WEB_VILLAS_REQUEST,
  GET_WEB_VILLAS_SUCCESS,
  GET_WEB_VILLAS_FAIL,
  EXPORT_WEB_VILLAS_REQUEST,
  EXPORT_WEB_VILLAS_SUCCESS,
  EXPORT_WEB_VILLAS_FAIL,
  STATUS_WEB_VILLA_REQUEST,
  STATUS_WEB_VILLA_SUCCESS,
  STATUS_WEB_VILLA_FAIL,
  GET_WEB_VILLAS_GALLERY_REQUEST,
  GET_WEB_VILLAS_GALLERY_SUCCESS,
  GET_WEB_VILLAS_GALLERY_FAIL,
  UPDATE_WEB_VILLAS_GALLERY_REQUEST,
  UPDATE_WEB_VILLAS_GALLERY_SUCCESS,
  UPDATE_WEB_VILLAS_GALLERY_FAIL,
  GET_WEB_VILLA_ROOM_REQUEST,
  GET_WEB_VILLA_ROOM_SUCCESS,
  GET_WEB_VILLA_ROOM_FAIL,
  ADD_WEB_VILLA_ROOM_REQUEST,
  ADD_WEB_VILLA_ROOM_SUCCESS,
  ADD_WEB_VILLA_ROOM_FAIL,
  GET_SINGLE_WEB_VILLA_ROOM_REQUEST,
  GET_SINGLE_WEB_VILLA_ROOM_SUCCESS,
  GET_SINGLE_WEB_VILLA_ROOM_FAIL,
  STATUS_WEB_VILLA_ROOM_REQUEST,
  STATUS_WEB_VILLA_ROOM_SUCCESS,
  STATUS_WEB_VILLA_ROOM_FAIL,
  UPDATE_WEB_VILLA_ROOM_REQUEST,
  UPDATE_WEB_VILLA_ROOM_SUCCESS,
  UPDATE_WEB_VILLA_ROOM_FAIL,
  DELETE_WEB_VILLA_ROOM_REQUEST,
  DELETE_WEB_VILLA_ROOM_SUCCESS,
  DELETE_WEB_VILLA_ROOM_FAIL,
  GET_WEB_VILLA_ROOM_GALLERY_REQUEST,
  GET_WEB_VILLA_ROOM_GALLERY_SUCCESS,
  GET_WEB_VILLA_ROOM_GALLERY_FAIL,
  UPDATE_WEB_VILLA_ROOM_GALLERY_REQUEST,
  UPDATE_WEB_VILLA_ROOM_GALLERY_SUCCESS,
  UPDATE_WEB_VILLA_ROOM_GALLERY_FAIL,
  UPDATE_WEB_VILLA_TYPE_REQUEST,
  UPDATE_WEB_VILLA_TYPE_SUCCESS,
  UPDATE_WEB_VILLA_TYPE_FAIL,
} from "../actions/websiteVillas";
import WebVillas from "../../api/website_management/websiteVillas";

export default function* watcher() {
  //Villa
  yield takeLatest(ADD_WEB_VILLA_REQUEST, addVillaAction);
  yield takeLatest(UPDATE_WEB_VILLA_REQUEST, updateVillaAction);
  yield takeLatest(DELETE_WEB_VILLA_REQUEST, deleteVillaAction);
  yield takeLatest(GET_SINGLE_WEB_VILLA_REQUEST, getSingleVillaAction);
  yield takeLatest(GET_WEB_VILLAS_REQUEST, handleGetVillasAction);
  yield takeLatest(STATUS_WEB_VILLA_REQUEST, handleChangeStatusAction);
  yield takeLatest(EXPORT_WEB_VILLAS_REQUEST, handleExportExcelAction);
  yield takeLatest(GET_WEB_VILLAS_GALLERY_REQUEST, handleGetVillaGalleryAction);
  yield takeLatest(
    UPDATE_WEB_VILLAS_GALLERY_REQUEST,
    handleUpdateVillaGalleryAction
  );
  yield takeLatest(UPDATE_WEB_VILLA_TYPE_REQUEST, handleUpdateVillaTypeAction);
  //Villa Rooms
  yield takeLatest(GET_WEB_VILLA_ROOM_REQUEST, handleGetVillaRoomAction);
  yield takeLatest(ADD_WEB_VILLA_ROOM_REQUEST, addVillaRoomAction);
  yield takeLatest(GET_SINGLE_WEB_VILLA_ROOM_REQUEST, getSingleVillaRoomAction);
  yield takeLatest(STATUS_WEB_VILLA_ROOM_REQUEST, handleChangeRoomStatusAction);
  yield takeLatest(UPDATE_WEB_VILLA_ROOM_REQUEST, updateVillaRoomAction);
  yield takeLatest(DELETE_WEB_VILLA_ROOM_REQUEST, deleteVillaRoomAction);
  yield takeLatest(
    GET_WEB_VILLA_ROOM_GALLERY_REQUEST,
    handleGetVillaRoomGalleryAction
  );
  yield takeLatest(
    UPDATE_WEB_VILLA_ROOM_GALLERY_REQUEST,
    handleUpdateVillaRoomGalleryAction
  );
}

function* addVillaAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebVillas.addVilla, formData);
    yield put({
      type: ADD_WEB_VILLA_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_WEB_VILLA_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* updateVillaAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebVillas.updateVilla, id, formData);
    yield put({
      type: UPDATE_WEB_VILLA_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_VILLA_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* deleteVillaAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebVillas.deleteVilla, id);
    yield put({
      type: DELETE_WEB_VILLA_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_WEB_VILLA_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* getSingleVillaAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebVillas.getSingleVilla, id);
    yield put({
      type: GET_SINGLE_WEB_VILLA_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_WEB_VILLA_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetVillasAction(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;

    const { data } = yield call(
      WebVillas.getVillas,
      page,
      limit,
      search,
      filter,
      sort
    );

    yield put({
      type: GET_WEB_VILLAS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_VILLAS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleChangeStatusAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebVillas.changeVillaStatus, id);
    yield put({
      type: STATUS_WEB_VILLA_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_WEB_VILLA_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleExportExcelAction(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      WebVillas.handleExportVillasExcel,
      page,
      search,
      filter,
      sort
    );
    yield put({
      type: EXPORT_WEB_VILLAS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, `Website_Villas.xlsx`);
  } catch (e) {
    yield put({
      type: EXPORT_WEB_VILLAS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleGetVillaGalleryAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebVillas.getGallery, id);
    yield put({
      type: GET_WEB_VILLAS_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_VILLAS_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateVillaGalleryAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebVillas.updateGallery, id, formData);
    yield put({
      type: UPDATE_WEB_VILLAS_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_VILLAS_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateVillaTypeAction(action) {
  try {
    const { id, type } = action.payload;
    const { data } = yield call(WebVillas.updateType, id, type);
    yield put({
      type: UPDATE_WEB_VILLA_TYPE_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_VILLA_TYPE_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

//Villa Rooms

function* handleGetVillaRoomAction(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { id } = action.payload;

    const { data } = yield call(
      WebVillas.getVillaRooms,
      id,
      page,
      limit,
      search,
      filter,
      sort
    );
    yield put({
      type: GET_WEB_VILLA_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_VILLA_ROOM_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* addVillaRoomAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebVillas.addVillaRoom, id, formData);
    yield put({
      type: ADD_WEB_VILLA_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_WEB_VILLA_ROOM_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* getSingleVillaRoomAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebVillas.getSingleVillaRoom, id);
    yield put({
      type: GET_SINGLE_WEB_VILLA_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SINGLE_WEB_VILLA_ROOM_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleChangeRoomStatusAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebVillas.changeVillaRoomStatus, id);
    yield put({
      type: STATUS_WEB_VILLA_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_WEB_VILLA_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* updateVillaRoomAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebVillas.updateVillaRoom, formData);
    yield put({
      type: UPDATE_WEB_VILLA_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_VILLA_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* deleteVillaRoomAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebVillas.deleteVillaRoom, id);
    yield put({
      type: DELETE_WEB_VILLA_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_WEB_VILLA_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* handleGetVillaRoomGalleryAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebVillas.getRoomGallery, id);
    yield put({
      type: GET_WEB_VILLA_ROOM_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_VILLA_ROOM_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateVillaRoomGalleryAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebVillas.updateRoomGallery, id, formData);
    yield put({
      type: UPDATE_WEB_VILLA_ROOM_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_VILLA_ROOM_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
