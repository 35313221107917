import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_FAIL,
} from "../actions/categories";
import TourCategories from "../../api/website_management/toursCategories";

export default function* watcher() {
  yield takeLatest(GET_ALL_CATEGORIES_REQUEST, handleGetAllCategories);
}

function* handleGetAllCategories() {
  try {
    const { data } = yield call(TourCategories.getAllCategories);
    yield put({
      type: GET_ALL_CATEGORIES_SUCCESS,
      payload: {
        data: data,
      },
    });
  } catch (e) {
    yield put({
      type: GET_ALL_CATEGORIES_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}
