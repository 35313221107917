import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';

function UpdateStatusModal({
  show,
  handleClose,
  handleConfirmUpdate,
  text,
  title,
  actionStatus,
}) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ background: `var(--primary_main)` }}
        className="modal_header"
        closeButton
      >
        <Modal.Title className="modal_title">{title}</Modal.Title>
      </Modal.Header>
      <div className="form-block">
        <Modal.Body>
          <div className="deleteConfirmText">{text}</div>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <div className="previewButtons">
          <LoadingButton
            size="small"
            className="login_submit_btn btn btn-primary "
            loadingPosition="center"
            variant="contained"
            loading={actionStatus === 'pending'}
            onClick={handleConfirmUpdate}
          >
            <span>{actionStatus === 'pending' ? '' : 'Confirm'}</span>
          </LoadingButton>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateStatusModal;
