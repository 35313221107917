import React from "react";
import Typewriter from "react-typewriter-effect";

function NotFoundPdf({ message }) {
  return (
    <div className="d-flex align-items-center justify-content-center">
      {message && (
        <div style={{ width: "100%" }}>
          <Typewriter
            textStyle={{ fontFamily: "Courier", fontSize: "23px" }}
            startDelay={100}
            text={message}
            typeSpeed={50}
            eraseSpeed={100}
            eraseDelay={1500}
            showCursor={false}
            cursorColor="transparent"
            displayTextRenderer={(text, i) => {
              return (
                <h1>
                  {text.split("").map((char, index) => {
                    const key = `${char}-${index}`;
                    return (
                      <span
                        key={key}
                        style={{ color: index % 2 === 0 ? "blue" : "red" }}
                      >
                        {text}
                      </span>
                    );
                  })}
                </h1>
              );
            }}
          />
        </div>
      )}
    </div>
  );
}

export default NotFoundPdf;
