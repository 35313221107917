import {
  GET_PICK_UP_POINTS_SUCCESS,
  GET_PICK_UP_POINTS_REQUEST,
  GET_PICK_UP_POINTS_FAIL,
  GET_ALL_PICK_UP_POINTS_REQUEST,
  GET_ALL_PICK_UP_POINTS_SUCCESS,
  GET_SINGLE_PICK_UP_POINT_REQUEST,
  GET_SINGLE_PICK_UP_POINT_SUCCESS,
  UPDATE_PICK_UP_POINT_REQUEST,
  UPDATE_PICK_UP_POINT_SUCCESS,
  UPDATE_PICK_UP_POINT_FAIL,
  EXPORT_PICK_UP_POINTS_REQUEST,
  EXPORT_PICK_UP_POINTS_SUCCESS,
  EXPORT_PICK_UP_POINTS_FAIL,
  ADD_PICK_UP_POINT_SUCCESS,
  ADD_PICK_UP_POINT_REQUEST,
  ADD_PICK_UP_POINT_FAIL,
  UPDATE_STATUS_PICK_UP_POINT_REQUEST,
  UPDATE_STATUS_PICK_UP_POINT_SUCCESS,
  UPDATE_STATUS_PICK_UP_POINT_FAIL,
  DELETE_PICK_UP_POINT_REQUEST,
  DELETE_PICK_UP_POINT_SUCCESS,
  DELETE_PICK_UP_POINT_FAIL,
} from '../actions/pickUpPoints';

const initialState = {
  pickUpPoints: [],
  allPickUpPoints: [],
  singlePickUpPoint: {},
  status: '',
  exportExcelStatus: '',
  actionStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_PICK_UP_POINTS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_ALL_PICK_UP_POINTS_SUCCESS: {
      return {
        ...state,
        allPickUpPoints: action.payload.data.allPickupPoints,
        status: 'success',
      };
    }
    case GET_SINGLE_PICK_UP_POINT_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_PICK_UP_POINT_SUCCESS: {
      return {
        ...state,
        singlePickUpPoint: action.payload.data.pickupPoint,
        status: 'success',
      };
    }
    case EXPORT_PICK_UP_POINTS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: 'pending',
      };
    }
    case EXPORT_PICK_UP_POINTS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: 'success',
      };
    }
    case EXPORT_PICK_UP_POINTS_FAIL: {
      return {
        ...state,
        exportExcelStatus: 'fail',
      };
    }
    case ADD_PICK_UP_POINT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case ADD_PICK_UP_POINT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case ADD_PICK_UP_POINT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case UPDATE_STATUS_PICK_UP_POINT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case UPDATE_STATUS_PICK_UP_POINT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case UPDATE_STATUS_PICK_UP_POINT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case DELETE_PICK_UP_POINT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_PICK_UP_POINT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_PICK_UP_POINT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case UPDATE_PICK_UP_POINT_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case UPDATE_PICK_UP_POINT_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case UPDATE_PICK_UP_POINT_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case GET_PICK_UP_POINTS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_PICK_UP_POINTS_SUCCESS: {
      return {
        ...state,
        status: 'success',
        pickUpPoints: action.payload.data.pickupPointsList.rows,
        count: action.payload.data.pickupPointsList.count,
      };
    }
    case GET_PICK_UP_POINTS_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
