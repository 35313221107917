import { io } from 'socket.io-client';
import { logOut } from './users';
import { changeFetchPercent, finalizeFetching } from './tourVouchers';
const { REACT_APP_API_URL } = process.env;

export let socket;

export function socketInit(token, login) {
  return dispatch => {
    if (socket && !login) {
      return;
    }
    socket = io(REACT_APP_API_URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
      transport: 'websocket',
    });

    const handleLogout = () => {
      logOut(dispatch);
      socket.off(); // Remove all event listeners
      socket.disconnect(); // Disconnect the socket
      socket = null; // Set the socket variable to null
    };

    socket.on('connect', () => {
      socket.on('fetchProgress', percent => {
        changeFetchPercent(dispatch, percent);
      });
      socket.on('finalizeFetching', () => {
        finalizeFetching(dispatch);
      });
      socket.on('autoLogOut', data => {
        logOut(dispatch);
        socket.off();
        socket.emit('logout');
      });
      socket.on('error', error => {
        console.error('Socket connection error:', error);
      });
    });
  };
}
