import {
  GET_SINGLE_TOUR_TRANSFERS_REQUEST,
  GET_SINGLE_TOUR_TRANSFERS_SUCCESS,
  GET_TOURS_TRANSFERS_FAIL,
  GET_TOURS_TRANSFERS_REQUEST,
  GET_TOURS_TRANSFERS_SUCCESS,
  CHECK_TRANSFER_REQUEST,
  CHECK_TRANSFER_SUCCESS,
  CHECK_TRANSFER_FAIL,
  DOWNLOAD_TRANSFER_REQUEST,
  DOWNLOAD_TRANSFER_SUCCESS,
  DOWNLOAD_TRANSFER_FAIL,
  EXPORT_TOUR_TRANSFERS_REQUEST,
  EXPORT_TOUR_TRANSFERS_SUCCESS,
  EXPORT_TOUR_TRANSFERS_FAIL,
  GET_SELECT_TRANSFERS_REQUEST,
  GET_SELECT_TRANSFERS_SUCCESS,
  ADD_TOUR_TRANSFERS_REQUEST,
  ADD_TOUR_TRANSFERS_SUCCESS,
  ADD_TOUR_TRANSFERS_FAIL,
  DELETE_TOUR_TRANSFERS_REQUEST,
  DELETE_TOUR_TRANSFERS_FAIL,
  DELETE_TOUR_TRANSFERS_SUCCESS,
  UPDATE_TOUR_TRANSFERS_REQUEST,
  UPDATE_TOUR_TRANSFERS_SUCCESS,
  UPDATE_TOUR_TRANSFERS_FAIL,
  STATUS_TOUR_TRANSFERS_REQUEST,
  STATUS_TOUR_TRANSFERS_SUCCESS,
  STATUS_TOUR_TRANSFERS_FAIL,
  ADD_COMMENT_TOUR_TRANSFERS_REQUEST,
  ADD_COMMENT_TOUR_TRANSFERS_SUCCESS,
  ADD_COMMENT_TOUR_TRANSFERS_FAIL,
  COPY_TOUR_TRANSFER_REQUEST,
  COPY_TOUR_TRANSFER_SUCCESS,
  COPY_TOUR_TRANSFER_FAIL,
  SEND_EMAIL_TOUR_TRANSFER_REQUEST,
  SEND_EMAIL_TOUR_TRANSFER_SUCCESS,
  SEND_EMAIL_TOUR_TRANSFER_FAIL,
} from '../actions/toursTransfers';

const initialState = {
  toursTransfers: [],
  allTransferTypes: [],
  selectTransfers: [],
  singleTourTransfer: {},
  searchTourTransfer: {},
  downloadStatus: '',
  exportExcelStatus: '',
  status: '',
  actionStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOURS_TRANSFERS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_TOURS_TRANSFERS_SUCCESS: {
      return {
        ...state,
        status: 'success',
        toursTransfers: action.payload.data.result,
        count: action.payload.data.count,
      };
    }
    case GET_TOURS_TRANSFERS_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    case GET_SINGLE_TOUR_TRANSFERS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_TOUR_TRANSFERS_SUCCESS: {
      return {
        ...state,
        singleTourTransfer: action.payload.data.result,
        status: 'success',
      };
    }
    case GET_SELECT_TRANSFERS_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }

    case GET_SELECT_TRANSFERS_SUCCESS: {
      return {
        ...state,
        selectTransfers: action.payload.data.transfers,
        status: 'success',
      };
    }
    case CHECK_TRANSFER_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case CHECK_TRANSFER_SUCCESS: {
      return {
        ...state,
        status: 'success',
        searchTourTransfer: action?.payload?.data?.result,
      };
    }
    case CHECK_TRANSFER_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }

    case ADD_TOUR_TRANSFERS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case ADD_TOUR_TRANSFERS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case ADD_TOUR_TRANSFERS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case DELETE_TOUR_TRANSFERS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case DELETE_TOUR_TRANSFERS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case DELETE_TOUR_TRANSFERS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case UPDATE_TOUR_TRANSFERS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case UPDATE_TOUR_TRANSFERS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case UPDATE_TOUR_TRANSFERS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case STATUS_TOUR_TRANSFERS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case STATUS_TOUR_TRANSFERS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case STATUS_TOUR_TRANSFERS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case ADD_COMMENT_TOUR_TRANSFERS_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case ADD_COMMENT_TOUR_TRANSFERS_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case ADD_COMMENT_TOUR_TRANSFERS_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case COPY_TOUR_TRANSFER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case COPY_TOUR_TRANSFER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case COPY_TOUR_TRANSFER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case SEND_EMAIL_TOUR_TRANSFER_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }

    case SEND_EMAIL_TOUR_TRANSFER_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }

    case SEND_EMAIL_TOUR_TRANSFER_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case EXPORT_TOUR_TRANSFERS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: 'pending',
      };
    }
    case EXPORT_TOUR_TRANSFERS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: 'success',
      };
    }
    case EXPORT_TOUR_TRANSFERS_FAIL: {
      return {
        ...state,
        exportExcelStatus: 'fail',
      };
    }
    case DOWNLOAD_TRANSFER_REQUEST: {
      return {
        ...state,
        downloadStatus: 'pending',
      };
    }
    case DOWNLOAD_TRANSFER_SUCCESS: {
      return {
        ...state,
        downloadStatus: 'success',
      };
    }
    case DOWNLOAD_TRANSFER_FAIL: {
      return {
        ...state,
        downloadStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
