export const GET_ALL_PICK_UP_POINTS_REQUEST = 'GET_ALL_PICK_UP_POINTS_REQUEST';
export const GET_ALL_PICK_UP_POINTS_SUCCESS = 'GET_ALL_PICK_UP_POINTS_SUCCESS';
export const GET_ALL_PICK_UP_POINTS_FAIL = 'GET_ALL_PICK_UP_POINTS_FAIL';

export function getAllPickUpPointsAction(cb) {
  return {
    type: GET_ALL_PICK_UP_POINTS_REQUEST,
    payload: {
      cb,
    },
  };
}

export const GET_PICK_UP_POINTS_REQUEST = 'GET_PICK_UP_POINTS_REQUEST';
export const GET_PICK_UP_POINTS_SUCCESS = 'GET_PICK_UP_POINTS_SUCCESS';
export const GET_PICK_UP_POINTS_FAIL = 'GET_PICK_UP_POINTS_FAIL';

export function getPickUpPointsAction(searchConfig, cb) {
  return {
    type: GET_PICK_UP_POINTS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const ADD_PICK_UP_POINT_REQUEST = 'ADD_PICK_UP_POINT_REQUEST';
export const ADD_PICK_UP_POINT_SUCCESS = 'ADD_PICK_UP_POINT_SUCCESS';
export const ADD_PICK_UP_POINT_FAIL = 'ADD_PICK_UP_POINT_FAIL';

export function addPickUpPointAction(formData, cb) {
  return {
    type: ADD_PICK_UP_POINT_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_PICK_UP_POINT_REQUEST = 'DELETE_PICK_UP_POINT_REQUEST';
export const DELETE_PICK_UP_POINT_SUCCESS = 'DELETE_PICK_UP_POINT_SUCCESS';
export const DELETE_PICK_UP_POINT_FAIL = 'DELETE_PICK_UP_POINT_FAIL';

export function deletePickUpPoint(id, cb) {
  return {
    type: DELETE_PICK_UP_POINT_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_SELECTED_PICK_UP_POINTS_REQUEST =
  'DELETE_SELECTED_PICK_UP_POINTS_REQUEST';
export const DELETE_SELECTED_PICK_UP_POINTS_SUCCESS =
  'DELETE_SELECTED_PICK_UP_POINTS_SUCCESS';
export const DELETE_SELECTED_PICK_UP_POINTS_FAIL =
  'DELETE_SELECTED_PICK_UP_POINTS_FAIL';

export function deleteSelectedPickUpPoints(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_PICK_UP_POINTS_REQUEST,
    payload: {
      selectedRows,
      cb,
    },
  };
}

export const UPDATE_PICK_UP_POINT_REQUEST = 'UPDATE_PICK_UP_POINT_REQUEST';
export const UPDATE_PICK_UP_POINT_SUCCESS = 'UPDATE_PICK_UP_POINT_SUCCESS';
export const UPDATE_PICK_UP_POINT_FAIL = 'UPDATE_PICK_UP_POINT_FAIL';

export function updatePickUpPoint(formData, cb) {
  return {
    type: UPDATE_PICK_UP_POINT_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_PICK_UP_POINT_REQUEST =
  'GET_SINGLE_PICK_UP_POINT_REQUEST';
export const GET_SINGLE_PICK_UP_POINT_SUCCESS =
  'GET_SINGLE_PICK_UP_POINT_SUCCESS';
export const GET_SINGLE_PICK_UP_POINT_FAIL = 'GET_SINGLE_PICK_UP_POINT_FAIL';

export function getSinglePickUpPoint(id, cb) {
  return {
    type: GET_SINGLE_PICK_UP_POINT_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_PICK_UP_POINTS_REQUEST = 'EXPORT_PICK_UP_POINTS_REQUEST';
export const EXPORT_PICK_UP_POINTS_SUCCESS = 'EXPORT_PICK_UP_POINTS_SUCCESS';
export const EXPORT_PICK_UP_POINTS_FAIL = 'EXPORT_PICK_UP_POINTS_FAIL';

export function exportExcelPickUpPointsAction(searchConfig, cb) {
  return {
    type: EXPORT_PICK_UP_POINTS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const UPDATE_STATUS_PICK_UP_POINT_REQUEST =
  'UPDATE_STATUS_PICK_UP_POINT_REQUEST';
export const UPDATE_STATUS_PICK_UP_POINT_SUCCESS =
  'UPDATE_STATUS_PICK_UP_POINT_SUCCESS';
export const UPDATE_STATUS_PICK_UP_POINT_FAIL =
  'UPDATE_STATUS_PICK_UP_POINT_FAIL';

export function updateStatusPickUpPointAction(id,status, cb) {
  return {
    type: UPDATE_STATUS_PICK_UP_POINT_REQUEST,
    payload: {
      id,
      status,
      cb,
    },
  };
}
