import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { getToursAction } from '../../store/actions/tours';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPickUpPointsAction } from '../../store/actions/pickUpPoints';

function EditPickUpPointModal({
  show,
  confirm,
  handleClose,
  title,
  value,
  setValue,
  tourId,
  setValidate,
  formData,
}) {
  const [pickUpForm, setPickUpForm] = useState(formData ? { ...formData } : {});
  const dispatch = useDispatch();
  const handleChange = (key, value) => {
    setPickUpForm({ ...pickUpForm, [key]: value });
  };

  useEffect(() => {
    return () => {
      setValidate(true);
    };
  }, []);
  useEffect(() => {
    dispatch(getAllPickUpPointsAction());
  }, []);

  const handleConfirm = () => {
    confirm(pickUpForm.pick_up_id, pickUpForm.pick_up_time, formData.id);
  };

  const pickUpPoints = useSelector(state => state.pickUpPoints.allPickUpPoints);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ background: `var(--primary_main)` }}
        className="modal_header"
        closeButton
      >
        <Modal.Title className="modal_title">{title}</Modal.Title>
      </Modal.Header>
      <div className="form-block">
        <Modal.Body>
          <div>
            <Row>
              <Col>
                <Form.Group style={{ marginBottom: '10px' }}>
                  <Form.Label>Pick Up Point</Form.Label>
                  <Select
                    id={'pick_up_point'}
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable={true}
                    styles={{
                      control: styles => ({
                        ...styles,
                        fontSize: '14px',
                      }),
                      option: (styles, { isSelected }) => {
                        return {
                          ...styles,
                          fontSize: '14px',
                          ':active': {
                            ...styles[':active'],
                            backgroundColor: null,
                          },
                        };
                      },
                    }}
                    name="status"
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: `var(--primary_light)`,
                        primary: `var(--primary_main)`,
                      },
                    })}
                    value={
                      pickUpForm
                        ? pickUpPoints.find(i => i.id === pickUpForm.pick_up_id)
                        : null
                    }
                    onChange={ev => {
                      ev
                        ? handleChange('pick_up_id', ev.id)
                        : handleChange('pick_up_id', null);
                    }}
                    getOptionLabel={i => (i.pick_up_name ? i.pick_up_name : '')}
                    getOptionValue={i => (i.id ? i.id : '')}
                    options={pickUpPoints}
                    placeholder={'Select'}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Pick Up Time</Form.Label>
                  <Form.Control
                    type="time"
                    value={pickUpForm.pick_up_time || ''}
                    name="pick_up_time"
                    max={formData.end_time}
                    onChange={event =>
                      handleChange('pick_up_time', event.target.value)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formData.end_time && 'Invalid time'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <div className="previewButtons">
          <Button
            variant="primary"
            onClick={ev => {
              ev.stopPropagation();
              handleConfirm(pickUpForm);
            }}
          >
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EditPickUpPointModal;
