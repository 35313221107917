import { api } from './Api';
import axios from 'axios';
import Account from '../services/Account';
const { REACT_APP_API_URL } = process.env;

export default class Currency {
  static getSingleDrivers(id) {
    return api.get(`/drivers/${id}`);
  }

  static getAllCurrency(selectedRows) {
    return api.get(`/currency/all`);
  }
}
