import { GridActionsCellItem } from "@mui/x-data-grid";
import { MdAttachEmail, MdDelete, MdEdit } from "react-icons/md";
import moment from "moment/moment";
import { BsChatText, BsChatTextFill } from "react-icons/bs";
import { FaFilePdf, FaHistory } from "react-icons/fa";
import React from "react";
import { AiFillCopy } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { LoadingButton } from "@mui/lab";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { Typography } from "@material-ui/core";
import { BiCommentDetail } from "react-icons/bi";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { CircularProgress } from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "400px",
    display: "flex",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const getColumns = ({
  redirectToPreview,
  handleDelete,
  handleUpdateStatus,
  downloadStatus,
  downloadStatusId,
  openCommentModal,
  openCopyModal,
  openEmailModal,
  handleState,
  role,
  handleDownloadPdf,
  getPassengerLocation,
  navigate,
  navigateToWebPreview,
}) => {
  return [
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "id",
      headerName: "#",
      width: 70,
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "ag_ref_number",
      headerName: "Ag. Reference Number",
      width: 200,
      renderCell: ({ row }) =>
        role === "Admin" || role === "Tour Manager" ? (
          <div>
            <span
              onClick={() => navigateToWebPreview(row)}
              className="link-span-in-table"
            >
              {row?.ag_ref_number}
            </span>
          </div>
        ) : (
          <div>{row?.ag_ref_number}</div>
        ),
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "reference_number",
      headerName: "Reference Number",
      width: 200,
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "passenger_name",
      headerName: "Passenger Name",
      width: 180,
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "transfer.transfer_name",
      headerName: "Transfer Name",
      width: 180,
      renderCell: ({ row }) => {
        if (row.transfer?.transfer_name) {
          if (
            role === "Driver" ||
            role === "Sales Agent" ||
            role === "Tour Manager"
          ) {
            return row.transfer?.transfer_name;
          } else {
            return (
              <span
                // onClick={() => navigate(`/transfers/${row?.transfer_id}`)}
                onClick={() => redirectToPreview(row?.transfer_id, "transfers")}
                className="link-span-in-table"
              >
                {row?.transfer?.transfer_name || ""}
              </span>
            );
          }
        }
      },
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "transfer.service_date",
      headerName: "Service Date",
      width: 150,
      renderCell: ({ row }) => {
        if (row?.transfer?.service_date) {
          return moment(row.transfer.service_date).format("MM/DD/YYYY");
        }
      },
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "transfer.driverUser.full_name",
      headerName: "Driver",
      width: 180,
      renderCell: ({ row }) => {
        if (row.transfer?.driverUser?.full_name) {
          if (
            role === "Driver" ||
            role === "Sales Agent" ||
            role === "Tour Manager"
          ) {
            return row.transfer?.driverUser?.full_name;
          } else {
            return (
              <span
                // onClick={(ev) =>
                //   navigate(`/users/${row.transfer.driverUser?.id}`)
                // }
                onClick={() => redirectToPreview(row?.transfer?.driverUser?.id, "users")}
                className="link-span-in-table"
              >
                {row.transfer?.driverUser?.full_name || ""}
              </span>
            );
          }
        }
      },
    },

    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: ({ row }) => {
        if (row?.state === "Cancelled" || row?.state === "Rejected") {
          return (
            <div style={{ display: "flex", cursor: "pointer" }}>
              <HtmlTooltip
                className="loggerTooltip"
                style={{
                  flexWrap: "wrap",
                  backgroundColor: "transparent",
                  width: "300px",
                  cursor: "pointer",
                }}
                title={
                  window?.innerWidth < 1024 ? null : (
                    <React.Fragment>
                      <div
                        style={{
                          overflowY: "hidden",
                        }}
                      >
                        <Typography color="inherit">
                          <p style={{ width: "100%", fontSize: "0.9em" }}>
                            {row?.cancellation_reason}
                          </p>
                        </Typography>
                      </div>
                    </React.Fragment>
                  )
                }
              >
                <div style={{ display: "flex" }}>
                  {row?.state}{" "}
                  <BiCommentDetail size={18} style={{ marginLeft: "10px" }} />
                </div>
              </HtmlTooltip>
            </div>
          );
        } else {
          return row?.state;
        }
      },
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "pick_up_time",
      headerName: "Pick Up Time",
      width: 200,
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "pick_up_address",
      headerName: "Pick Up Point",
      width: 180,
      // renderCell: ({row}) => {
      //     if (row.transfer?.pick_up_point?.pick_up_name) {
      //         if (role === "Driver") {
      //             return row.transfer?.pick_up_point?.pick_up_name
      //         } else {
      //             return (
      //                 <span
      //                     onClick={ev =>
      //                         navigate(`/pick_up_points/${row.transfer?.pick_up_point?.id}`)
      //                     }
      //                     className="link-span-in-table"
      //                 >
      //   {row.transfer?.pick_up_point?.pick_up_name || ''}
      // </span>
      //             );
      //         }

      // }
      // },
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "transfer.pickUpPoint.pick_up_name",
      headerName: "Starting point",
      width: 180,
      renderCell: ({ row }) => {
        return row?.transfer?.pickUpPoint?.pick_up_name;
      },
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "flight_time",
      headerName: "Flight Time",
      width: 150,
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "flight_number",
      headerName: "Flight No",
      width: 150,
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "original_point_of_rental",
      headerName: "Original Point of Rental",
      width: 200,
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "payment_amount",
      headerName: "Payment Amount (€)",
      width: 150,
    },
    // {
    //   access: ["Admin", "Sales Agent", "Tour Manager"],
    //   field: "currency.currency_name",
    //   headerName: "Currency",
    //   width: 80,
    //   renderCell: ({ row }) => {
    //     return row.currency ? (
    //       <span>
    //         {row.currency.currency_name + `(${row.currency.currency_symbol})`}
    //       </span>
    //     ) : (
    //       ""
    //     );
    //   },
    // },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "passenger_passport_details",
      headerName: "Passport/ID No",
      width: 150,
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "phone",
      headerName: "Phone Number",
      width: 150,
      renderCell: ({ row }) => {
        return row.phone ? `+${row.phone}` : "";
      },
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "reservation_date",
      headerName: "Reservation Date",
      width: 150,
      renderCell: ({ row }) => {
        if (row?.reservation_date) {
          return moment(row.reservation_date).format("MM/DD/YYYY");
        }
      },
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "adult_members",
      headerName: "Adults",
      width: 80,
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "child_members",
      headerName: "Children",
      width: 80,
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "infant_members",
      headerName: "Infants",
      width: 80,
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      field: "total_passengers_members",
      headerName: "Total Passengers",
      width: 140,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "salesAgent.full_name",
      headerName: "Sales Agent",
      width: 180,
      renderCell: ({ row }) => {
        if (row?.salesAgent?.full_name) {
          if (role === "Supplier") {
            return row?.salesAgent?.full_name;
          } else {
            return (
              <span
                // onClick={(ev) => navigate(`/users/${row?.salesAgent?.id}`)}
                  onClick={() => redirectToPreview(row?.salesAgent?.id, "users")}
                className="link-span-in-table"
              >
                {row?.salesAgent?.full_name || ""}
              </span>
            );
          }
        }
      },
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "createdAt",
      headerName: "Created Date",
      width: 200,
      renderCell: (params) => {
        if (params?.createdAt || params?.row?.createdAt) {
          return moment(params?.createdAt || params?.row?.createdAt).format(
            "MM/DD/YYYY HH:mm:ss"
          );
        }
      },
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "createdBy.full_name",
      headerName: "Created By",
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.full_name || "",
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "updatedAt",
      headerName: "Modified Date",
      width: 200,
      renderCell: (params) => {
        if (params?.updatedAt || params?.row?.updatedAt) {
          return moment(params?.updatedAt || params?.row?.updatedAt).format(
            "MM/DD/YYYY HH:mm:ss"
          );
        }
      },
    },
    {
      access: ["Admin", "Sales Agent", "Tour Manager"],
      field: "updatedBy.full_name",
      headerName: "Modified By",
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.full_name || "",
    },
    {
      access: ["Admin", "Driver", "Sales Agent", "Tour Manager"],
      headerName: "Actions",
      field: "actions",
      width: role === "Sales Agent" ? 100 : 280,
      cellClassName: "actions",
      type: "actions",
      getActions: (params) =>
        [
          <GridActionsCellItem
            onClick={() => redirectToPreview(params?.id, "transfer_vouchers")}
            className={""}
            icon={<MdEdit className={""} />}
            label="Edit"
            title="View/Edit"
            access={["Admin", "Sales Agent", "Tour Manager"]}
          />,
          <GridActionsCellItem
            onClick={() => {
              if (
                (params?.latitude && params?.longitude) ||
                (params?.row?.longitude && params?.row?.latitude)
              ) {
                getPassengerLocation(params?.row || params);
              }
            }}
            icon={<ImLocation2 />}
            label="Edit"
            title={
              params?.location_date &&
              `Shared At: ${moment(params?.location_date).format("L HH:mm:ss")}`
            }
            className={
              (!params?.latitude || !params?.longitude) &&
              (!params?.row?.latitude || !params?.row?.longitude) &&
              "disabled_action"
            }
            access={["Admin", "Driver", "Tour Manager"]}
          />,
          <GridActionsCellItem
            onClick={() =>
              // row?.status != 'Inactive' &&
              openEmailModal(params)
            }
            // className={row?.status == 'Inactive' && 'disabled'}
            icon={
              <MdAttachEmail
                size={18}
                // className={row?.status == 'Inactive' && 'disabled'}
              />
            }
            label="Send Email"
            title="Send email to supplier"
            access={["Admin", "Tour Manager"]}
            // disabled={row?.status == 'Inactive'}
          />,

          <GridActionsCellItem
            icon={
              downloadStatus === "pending" && downloadStatusId === params.id ? (
                <CircularProgress size={18} />
              ) : (
                <LoadingButton
                  color="primary"
                  onClick={(ev) =>
                    handleDownloadPdf(ev, {
                      id: params?.id,
                      reference_number:
                        params?.reference_number ||
                        params?.row?.reference_number,
                      ag_ref_number:
                        params?.ag_ref_number || params?.row?.ag_ref_number,
                    })
                  }
                  style={{ padding: 0, minWidth: 0 }}
                  loadingPosition="center"
                >
                  <FaFilePdf size={16} />
                  {/*<BsFilePdfFill*/}
                  {/*size={16}*/}
                  {/*/>*/}
                </LoadingButton>
              )
            }
            className="test1"
            label="Download Pdf"
            title="Download Pdf"
            access={["Admin", "Driver", "Tour Manager"]}
          />,

          <GridActionsCellItem
            onClick={() => openCommentModal(params)}
            className="test1"
            icon={
              params?.hidden_note || params?.row?.hidden_note ? (
                <BsChatTextFill size={18} />
              ) : (
                <BsChatText size={18} />
              )
            }
            label="Add Comment"
            title="Add Comment"
            access={["Admin", "Tour Manager"]}
          />,
          // <GridActionsCellItem
          //   onClick={() => openCopyModal(params)}
          //   className="test1"
          //   icon={<AiFillCopy size={18} />}
          //   label="Copy"
          //   title="Copy"
          //   access={["Admin", "Tour Manager"]}
          // />,
          <GridActionsCellItem
            icon={
              <FaHistory
              // className={row?.status == 'Inactive' && 'disabled'}
              />
            }
            label="State"
            title="Change Status"
            onClick={
              // row?.status == 'Inactive'
              //   ? null
              //   :
              (ev) => {
                ev.stopPropagation();
                handleState(ev, params);
              }
            }
            access={["Admin", "Tour Manager"]}
            // disabled={row?.status == 'Inactive'}
          />,
          <GridActionsCellItem
            style={{
              cursor: "pointer",
            }}
            onClick={(ev) => handleUpdateStatus(params)}
            icon={
              params?.status === "Active" ? <FaToggleOn /> : <FaToggleOff />
            }
            label="Status"
            title="Change Status"
          />,
          <GridActionsCellItem
            icon={<MdDelete />}
            label="Delete"
            title="Delete"
            onClick={(ev) => handleDelete(ev, params?.id)}
            access={["Admin", "Tour Manager"]}
          />,
        ].filter(({ props }) => {
          return props?.access?.includes(role);
        }),
    },
  ].filter((r) => r?.access?.includes(role));
};
