import React, { useEffect } from "react";
import "./dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDashboardInfo,
  getDashboardInfo,
} from "../../store/actions/customSettings";

import { Riple } from "react-loading-indicators";
import { isEmpty } from "lodash";
import DashboardCard from "../../components/DashboardCard";

export default function Dashboard() {
  const dispatch = useDispatch();
  const years = useSelector((store) => store.customSettings.dashboardYears);
  const minTourYear = useSelector(
    (store) => store.customSettings.dashboardMinYears.tours
  );
  const minVoucherYear = useSelector(
    (store) => store.customSettings.dashboardMinYears.vouchers
  );
  const minTransferVoucherYear = useSelector(
    (store) => store.customSettings.dashboardMinYears.transfer_vouchers
  );
  const minTransferYear = useSelector(
    (store) => store.customSettings.dashboardMinYears.transfers
  );
  const dashboardInfo = useSelector(
    (store) => store?.customSettings?.dashboardData
  );
  const rowStatus = useSelector((store) => store?.customSettings?.status);
  const account = useSelector((store) => store.users.account);

  useEffect(() => {
    dispatch(getDashboardInfo());
    return () => {
      dispatch(clearDashboardInfo());
    };
  }, []);

  return (
    <div className="admin_tables_block page_loger">
      <div className="admin_tables_title">
        <span className="pageTitle">Dashboard</span>
      </div>
      {rowStatus === "pending" ? (
        <div className="dashboard-empty-div">
          <Riple color="#134074" size="large" text="" textColor="" />
        </div>
      ) : (
        <div className="d-flex flex-wrap">
          {!isEmpty(account) &&
          ["Supplier", "Admin", "Super Admin", "Tour Manager"].includes(
            account.role
          ) &&
          dashboardInfo?.tours ? (
            <div className="dashboard_items">
              <DashboardCard
                name={"Tours"}
                tours={dashboardInfo?.tours}
                iconComponent="FaBus"
                rowStatus={rowStatus}
                minYear={minTourYear}
                model_key={"tours"}
                years={years}
              />
            </div>
          ) : null}
          {!isEmpty(account) &&
          ["Supplier", "Admin", "Sales Agent", "Tour Manager"].includes(
            account.role
          ) &&
          dashboardInfo?.tour_vouchers ? (
            <div className="dashboard_items">
              <DashboardCard
                name={"Tour Vouchers"}
                tours={dashboardInfo?.tour_vouchers}
                iconComponent="FaBus"
                rowStatus={rowStatus}
                minYear={minVoucherYear}
                model_key={"tour_vouchers"}
                years={years}
              />
            </div>
          ) : null}
          {!isEmpty(account) &&
          ["Driver", "Admin", "Super Admin", "Tour Manager"].includes(
            account.role
          ) &&
          dashboardInfo?.transfers ? (
            <div className="dashboard_items">
              <DashboardCard
                name={"Transfers"}
                tours={dashboardInfo?.transfers}
                iconComponent="FaBus"
                rowStatus={rowStatus}
                minYear={minTransferYear}
                model_key={"transfers"}
                years={years}
              />
            </div>
          ) : null}
          {!isEmpty(account) &&
          [
            "Driver",
            "Admin",
            "Sales Agent",
            "Tour Manager",
            "Super Admin",
          ].includes(account.role) &&
          dashboardInfo?.transfer_vouchers ? (
            <div className="dashboard_items">
              <DashboardCard
                name={"Transfer Vouchers"}
                tours={dashboardInfo?.transfer_vouchers}
                iconComponent="FaBus"
                rowStatus={rowStatus}
                minYear={minTransferVoucherYear}
                model_key={"transfer_vouchers"}
                years={years}
              />
            </div>
          ) : null}
        </div>
      )}
      <div>
        {/*{dashboardInfo?.vouchers === 0 || dashboardInfo?.vouchers ?*/}
        {/*    <div style={{width: "35%", cursor: "pointer"}}*/}
        {/*         onClick={() => account?.role === "Admin" ? navigate("/vouchers") : null}>*/}

        {/*        <DashboardCard*/}
        {/*            name={"Tour Vouchers"}*/}
        {/*            count={dashboardInfo?.vouchers}*/}
        {/*            iconComponent="GrDocument"/>*/}
        {/*    </div>*/}
        {/*    : null*/}
        {/*}*/}
        {/*{dashboardInfo?.transfers === 0 || dashboardInfo?.transfers ?*/}
        {/*    <div style={{width: "35%", cursor: "pointer"}}*/}
        {/*         onClick={() => navigate("/transfers")}>*/}

        {/*        <DashboardCard*/}
        {/*            name={"Transfers"}*/}
        {/*            count={dashboardInfo?.transfers}*/}
        {/*            iconComponent="FaCar"/>*/}
        {/*    </div>*/}
        {/*    : null*/}
        {/*}*/}
        {/*{dashboardInfo?.transferVouchers === 0 || dashboardInfo?.transferVouchers ?*/}
        {/*    <div style={{width: "35%", cursor: "pointer"}}*/}
        {/*         onClick={() => account?.role === "Admin" ? navigate("/transfer_vouchers") : null}>*/}

        {/*        <DashboardCard*/}
        {/*            name={"Transfer Vouchers"}*/}
        {/*            count={dashboardInfo?.transferVouchers}*/}
        {/*            iconComponent="GrDocument"/>*/}
        {/*    </div>*/}
        {/*    : null*/}
        {/*}*/}
        {/*{*/}
        {/*    dashboardInfo?.directions === 0 || dashboardInfo?.directions ?*/}
        {/*        <div style={{width: "35%", cursor: "pointer"}}*/}
        {/*             onClick={() => navigate("/directions")}>*/}

        {/*            <DashboardCard*/}
        {/*                name={"Directions"}*/}
        {/*                count={dashboardInfo?.directions}*/}
        {/*                iconComponent="FaRoute"/>*/}
        {/*        </div>*/}
        {/*        : null}*/}
      </div>
    </div>
  );
}
