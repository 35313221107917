import React from 'react';
import { Grid } from '@mui/material';

export default function DataCardList({ col, row, symbol, title, textAlign }) {
  const getRowValue = (col, row) => {
    if (Array.isArray(row[col.field])) {
      let newRow = JSON.parse(JSON.stringify(row[col.field]));
      if (
        row[col.field].includes('Others') ||
        row[col.field].includes('Others ')
      ) {
        if (row[col.field].includes('Others')) {
          newRow.splice(newRow.indexOf('Others'), 1);
          newRow.push(row['invoice_id.other_problems']);
        }
        if (row[col.field].includes('Others ')) {
          newRow.splice(newRow.indexOf('Others '), 1);
          newRow.push(row['invoice_id.other_solutions']);
        }
      }
      newRow = JSON.stringify(newRow)
        .replaceAll('"', '')
        .replaceAll(',', ', ')
        .replaceAll('[', '')
        .replaceAll(']', '');
      return newRow || '-';
    }

    if (col.renderCell) {
      return col.renderCell({ row });
    } else {
      return row[col.field] || '-';
    }
  };

  return (
    <>
      <Grid item={col.headerName.type !== 'hr'} xs={6}>
        <div
          style={{
            fontWeight: '600',
            ...(col.headerName.type === 'hr' ? { padding: '0 0 0 23px' } : {}),
          }}
        >
          {title === 'Price' ? col.headerName + ' ' + symbol : col.headerName}
        </div>
      </Grid>
      <Grid item={col.headerName.type !== 'hr'} xs={6}>
        <div
          className="item-div"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: col.headerName.type === 'hr' ? '0 8px 0 0' : '0 10px',
            textAlign: textAlign && textAlign,
          }}
        >
          <span>{getRowValue(col, row)}</span>
        </div>
      </Grid>
    </>
  );
}
