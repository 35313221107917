import React from 'react';
import { memo } from 'react';
import { FadeLoader } from 'react-spinners';
import './loading.scss';

export default function LoadingCircle({ status, rows }) {
  return (
    <>
      {status && status == 'pending' ? (
        <>
          <FadeLoader
            className="loading_spinner"
            color={`var(--primary_main)`}
            loading={true}
            size={10}
            data-testid="loader"
          />
          {/* <img
            src="/images/animation_llm0xvfa_small.gif"
            className="loading_spinner"
            alt=""
          /> */}
          <div className="loading_overlay"></div>
        </>
      ) : status == 'success' && !rows?.length ? (
        <span style={{ color: 'var(--text_main)' }}>No Data to Display</span>
      ) : (
        ''
      )}
    </>
  );
}
