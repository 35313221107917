export const emailValidate = (email, setIsValid) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (email.startsWith('.', 0)) {
        setIsValid(false);
        return false;
    } else if (emailRegex.test(email)) {
        setIsValid(true);
        return true;
    }
};

export const emailValidateForSend = (
    key,
    value,
    setIsValidEmail,
    setIsValidBCC,
    setIsValidCC,
) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emails = value.split(",")
    emails = emails.map(email => email.trim())
    emails.forEach(email => {
        if (email.startsWith('.', 0) || !emailRegex.test(email)) {
            key === 'email' ? setIsValidEmail(false) : key === 'cc' ? setIsValidCC(false) : setIsValidBCC(false)
            return false
        } else {
            key === 'email' ? setIsValidEmail(true) : key === 'cc' ? setIsValidCC(true) : setIsValidBCC(true)
        }
    })
};
export const emailValidateForSendOld = (
    key,
    value,
    setIsValidEmail,
    setIsValidBCC,
    setIsValidCC,
) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (key === 'email' && value.startsWith('.', 0)) {
        setIsValidEmail(false);
        return false;
    } else if (key === 'email' && emailRegex.test(value)) {
        setIsValidEmail(true);
        return true;
    }
    if (key === 'cc' && value.startsWith('.', 0)) {
        setIsValidCC(false);
        return false;
    } else if (key === 'cc' && emailRegex.test(value)) {
        setIsValidCC(true);
        return true;
    }
    if (key === 'bcc' && value.startsWith('.', 0)) {
        setIsValidBCC(false);
        return false;
    } else if (key === 'bcc' && emailRegex.test(value)) {
        setIsValidBCC(true);
        return true;
    }
};
