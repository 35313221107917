import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_TOURS_REQUEST,
  GET_TOURS_FAIL,
  GET_TOURS_SUCCESS,
  ADD_TOUR_SUCCESS,
  ADD_TOUR_FAIL,
  ADD_TOUR_REQUEST,
  GET_SINGLE_TOUR_REQUEST,
  GET_SINGLE_TOUR_SUCCESS,
  GET_SINGLE_TOUR_FAIL,
  UPDATE_TOUR_SUCCESS,
  UPDATE_TOUR_FAIL,
  UPDATE_TOUR_REQUEST,
  STATUS_TOUR_SUCCESS,
  STATUS_TOUR_FAIL,
  STATUS_TOUR_REQUEST,
  DELETE_SELECTED_TOURS_FAIL,
  DELETE_TOUR_FAIL,
  DELETE_TOUR_REQUEST,
  DELETE_TOUR_SUCCESS,
  GET_ALL_TOURS_REQUEST,
  GET_ALL_TOURS_SUCCESS,
  GET_ALL_TOURS_FAIL,
  EXPORT_TOURS_REQUEST,
  EXPORT_TOURS_SUCCESS,
  EXPORT_TOURS_FAIL,
  GET_SELECT_TOURS_REQUEST,
  GET_SELECT_TOURS_SUCCESS,
  GET_SELECT_TOURS_FAIL,
  UPDATE_TOUR_PICKUP_REQUEST,
  UPDATE_TOUR_PICKUP_SUCCESS,
  UPDATE_TOUR_PICKUP_FAIL,
} from "../actions/tours";
import Tours from "../../api/tours";

export default function* watcher() {
  yield takeLatest(GET_TOURS_REQUEST, handleGetTours);
  yield takeLatest(GET_ALL_TOURS_REQUEST, getAllTours);
  yield takeLatest(GET_SELECT_TOURS_REQUEST, getSelectTours);
  yield takeLatest(DELETE_TOUR_REQUEST, deleteTours);
  yield takeLatest(STATUS_TOUR_REQUEST, handleChangeStatus);
  yield takeLatest(ADD_TOUR_REQUEST, addTour);
  yield takeLatest(GET_SINGLE_TOUR_REQUEST, getSingleTour);
  yield takeLatest(UPDATE_TOUR_REQUEST, updateTour);
  yield takeLatest(UPDATE_TOUR_PICKUP_REQUEST, updateTourPickUp);
  // yield takeLatest(DELETE_SELECTED_COMPANIES_REQUEST, deleteSelectedCompanies);
  yield takeLatest(EXPORT_TOURS_REQUEST, handleExportExcel);
}

function* addTour(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Tours.addTours, formData);
    yield put({
      type: ADD_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_TOUR_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleGetTours(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;

    const { data } = yield call(
      Tours.getTours,
      page,
      limit,
      search,
      filter,
      sort
    );

    yield put({
      type: GET_TOURS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_TOURS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* getAllTours(action) {
  try {
    const { data } = yield call(Tours.getAllTours);
    yield put({
      type: GET_ALL_TOURS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_TOURS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* getSelectTours(action) {
  try {
    const { service_date, direction_id } = action.payload;
    const { data } = yield call(
      Tours.getSelectTours,
      service_date,
      direction_id
    );
    yield put({
      type: GET_SELECT_TOURS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SELECT_TOURS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* deleteTours(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Tours.deleteTours, id);
    yield put({
      type: DELETE_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_TOUR_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* handleChangeStatus(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Tours.changeTourStatus, id);
    yield put({
      type: STATUS_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_TOUR_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

// function* deleteSelectedCompanies(action) {
//   try {
//     const { selectedRows } = action.payload;
//     const { data } = yield call(Tours.deleteSelectedTours, selectedRows);
//     yield put({
//       type: DELETE_SELECTED_COMPANIES_SUCCESS,
//       payload: {
//         data: data.company,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(null, data);
//     }
//   } catch (e) {
//     console.log(e);
//     yield put({
//       type: DELETE_SELECTED_COMPANIES_FAIL,
//       payload: {
//         massage: e.massage,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(e, null);
//     }
//   }
// }

function* getSingleTour(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Tours.getSingleTours, id);
    yield put({
      type: GET_SINGLE_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_TOUR_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* updateTour(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Tours.updateTours, formData);
    yield put({
      type: UPDATE_TOUR_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_TOUR_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}
function* updateTourPickUp(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Tours.updateTourPickUp, formData);
    yield put({
      type: UPDATE_TOUR_PICKUP_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_TOUR_PICKUP_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      Tours.handleExportToursExcel,
      page,
      search,
      filter,
      sort
    );
    yield put({
      type: EXPORT_TOURS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, `Tours.xlsx`);
  } catch (e) {
    yield put({
      type: EXPORT_TOURS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
