export const ADD_WEB_VILLA_REQUEST = "ADD_WEB_VILLA_REQUEST";
export const ADD_WEB_VILLA_SUCCESS = "ADD_WEB_VILLA_SUCCESS";
export const ADD_WEB_VILLA_FAIL = "ADD_WEB_VILLA_FAIL";

export function addVillaAction(formData, cb) {
  return {
    type: ADD_WEB_VILLA_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_WEB_VILLA_REQUEST = "UPDATE_WEB_VILLA_REQUEST";
export const UPDATE_WEB_VILLA_SUCCESS = "UPDATE_WEB_VILLA_SUCCESS";
export const UPDATE_WEB_VILLA_FAIL = "UPDATE_WEB_VILLA_FAIL";

export function updateVilla(id, formData, cb) {
  return {
    type: UPDATE_WEB_VILLA_REQUEST,
    payload: {
      id,
      formData,
      cb,
    },
  };
}

export const DELETE_WEB_VILLA_REQUEST = "DELETE_WEB_VILLA_REQUEST";
export const DELETE_WEB_VILLA_SUCCESS = "DELETE_WEB_VILLA_SUCCESS";
export const DELETE_WEB_VILLA_FAIL = "DELETE_WEB_VILLA_FAIL";

export function deleteVilla(id, cb) {
  return {
    type: DELETE_WEB_VILLA_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_WEB_VILLAS_REQUEST = "GET_WEB_VILLAS_REQUEST";
export const GET_WEB_VILLAS_SUCCESS = "GET_WEB_VILLAS_SUCCESS";
export const GET_WEB_VILLAS_FAIL = "GET_WEB_VILLAS_FAIL";

export function getVillasAction(searchConfig, cb) {
  return {
    type: GET_WEB_VILLAS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const STATUS_WEB_VILLA_REQUEST = "STATUS_WEB_VILLA_REQUEST";
export const STATUS_WEB_VILLA_SUCCESS = "STATUS_WEB_VILLA_SUCCESS";
export const STATUS_WEB_VILLA_FAIL = "STATUS_WEB_VILLA_FAIL";

export function changeVillaStatusAction(id, cb) {
  return {
    type: STATUS_WEB_VILLA_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_SINGLE_WEB_VILLA_REQUEST = "GET_SINGLE_WEB_VILLA_REQUEST";
export const GET_SINGLE_WEB_VILLA_SUCCESS = "GET_SINGLE_WEB_VILLA_SUCCESS";
export const GET_SINGLE_WEB_VILLA_FAIL = "GET_SINGLE_WEB_VILLA_FAIL";

export function getSingleVilla(id, cb) {
  return {
    type: GET_SINGLE_WEB_VILLA_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_WEB_VILLAS_REQUEST = "EXPORT_WEB_VILLAS_REQUEST";
export const EXPORT_WEB_VILLAS_SUCCESS = "EXPORT_WEB_VILLAS_SUCCESS";
export const EXPORT_WEB_VILLAS_FAIL = "EXPORT_WEB_VILLAS_FAIL";

export function exportExcelVillaAction(searchConfig, cb) {
  return {
    type: EXPORT_WEB_VILLAS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const GET_WEB_VILLAS_GALLERY_REQUEST = "GET_WEB_VILLAS_GALLERY_REQUEST";
export const GET_WEB_VILLAS_GALLERY_SUCCESS = "GET_WEB_VILLAS_GALLERY_SUCCESS";
export const GET_WEB_VILLAS_GALLERY_FAIL = "GET_WEB_VILLAS_GALLERY_FAIL";

export function getWebVillaGallery(id, cb) {
  return {
    type: GET_WEB_VILLAS_GALLERY_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_WEB_VILLAS_GALLERY_REQUEST =
  "UPDATE_WEB_VILLAS_GALLERY_REQUEST";
export const UPDATE_WEB_VILLAS_GALLERY_SUCCESS =
  "UPDATE_WEB_VILLAS_GALLERY_SUCCESS";
export const UPDATE_WEB_VILLAS_GALLERY_FAIL = "UPDATE_WEB_VILLAS_GALLERY_FAIL";

export function updateWebVillaGallery(id, formData, cb) {
  return {
    type: UPDATE_WEB_VILLAS_GALLERY_REQUEST,
    payload: { id, formData, cb },
  };
}

export const UPDATE_WEB_VILLA_TYPE_REQUEST = "UPDATE_WEB_VILLA_TYPE_REQUEST";
export const UPDATE_WEB_VILLA_TYPE_SUCCESS = "UPDATE_WEB_VILLA_TYPE_SUCCESS";
export const UPDATE_WEB_VILLA_TYPE_FAIL = "UPDATE_WEB_VILLA_TYPE_FAIL";

export function updateSingleVillaType(id, type, cb) {
  return {
    type: UPDATE_WEB_VILLA_TYPE_REQUEST,
    payload: { id, type, cb },
  };
}

//Villa Rooms

export const GET_WEB_VILLA_ROOM_REQUEST = "GET_WEB_VILLA_ROOM_REQUEST";
export const GET_WEB_VILLA_ROOM_SUCCESS = "GET_WEB_VILLA_ROOM_SUCCESS";
export const GET_WEB_VILLA_ROOM_FAIL = "GET_WEB_VILLA_ROOM_FAIL";

export function getVillaRoomAction(id, searchConfig, cb) {
  return {
    type: GET_WEB_VILLA_ROOM_REQUEST,
    payload: { id, searchConfig, cb },
  };
}

export const ADD_WEB_VILLA_ROOM_REQUEST = "ADD_WEB_VILLA_ROOM_REQUEST";
export const ADD_WEB_VILLA_ROOM_SUCCESS = "ADD_WEB_VILLA_ROOM_SUCCESS";
export const ADD_WEB_VILLA_ROOM_FAIL = "ADD_WEB_VILLA_ROOM_FAIL";

export function addRoomAction(id, formData, cb) {
  return {
    type: ADD_WEB_VILLA_ROOM_REQUEST,
    payload: {
      id,
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_WEB_VILLA_ROOM_REQUEST =
  "GET_SINGLE_WEB_VILLA_ROOM_REQUEST";
export const GET_SINGLE_WEB_VILLA_ROOM_SUCCESS =
  "GET_SINGLE_WEB_VILLA_ROOM_SUCCESS";
export const GET_SINGLE_WEB_VILLA_ROOM_FAIL = "GET_SINGLE_WEB_VILLA_ROOM_FAIL";

export function getSingleVillaRoom(id, cb) {
  return {
    type: GET_SINGLE_WEB_VILLA_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const STATUS_WEB_VILLA_ROOM_REQUEST = "STATUS_WEB_VILLA_ROOM_REQUEST";
export const STATUS_WEB_VILLA_ROOM_SUCCESS = "STATUS_WEB_VILLA_ROOM_SUCCESS";
export const STATUS_WEB_VILLA_ROOM_FAIL = "STATUS_WEB_VILLA_ROOM_FAIL";

export function changeVillaRoomStatusAction(id, cb) {
  return {
    type: STATUS_WEB_VILLA_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const UPDATE_WEB_VILLA_ROOM_REQUEST = "UPDATE_WEB_VILLA_ROOM_REQUEST";
export const UPDATE_WEB_VILLA_ROOM_SUCCESS = "UPDATE_WEB_VILLA_ROOM_SUCCESS";
export const UPDATE_WEB_VILLA_ROOM_FAIL = "UPDATE_WEB_VILLA_ROOM_FAIL";

export function updateVillaRoom(formData, cb) {
  return {
    type: UPDATE_WEB_VILLA_ROOM_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_WEB_VILLA_ROOM_REQUEST = "DELETE_WEB_VILLA_ROOM_REQUEST";
export const DELETE_WEB_VILLA_ROOM_SUCCESS = "DELETE_WEB_VILLA_ROOM_SUCCESS";
export const DELETE_WEB_VILLA_ROOM_FAIL = "DELETE_WEB_VILLA_ROOM_FAIL";

export function deleteVillaRoom(id, cb) {
  return {
    type: DELETE_WEB_VILLA_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_WEB_VILLA_ROOM_GALLERY_REQUEST =
  "GET_WEB_VILLA_ROOM_GALLERY_REQUEST";
export const GET_WEB_VILLA_ROOM_GALLERY_SUCCESS =
  "GET_WEB_VILLA_ROOM_GALLERY_SUCCESS";
export const GET_WEB_VILLA_ROOM_GALLERY_FAIL =
  "GET_WEB_VILLA_ROOM_GALLERY_FAIL";

export function getWebVillaRoomGallery(id, cb) {
  return {
    type: GET_WEB_VILLA_ROOM_GALLERY_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_WEB_VILLA_ROOM_GALLERY_REQUEST =
  "UPDATE_WEB_VILLA_ROOM_GALLERY_REQUEST";
export const UPDATE_WEB_VILLA_ROOM_GALLERY_SUCCESS =
  "UPDATE_WEB_VILLA_ROOM_GALLERY_SUCCESS";
export const UPDATE_WEB_VILLA_ROOM_GALLERY_FAIL =
  "UPDATE_WEB_VILLA_ROOM_GALLERY_FAIL";

export function updateWebVillaRoomGallery(id, formData, cb) {
  return {
    type: UPDATE_WEB_VILLA_ROOM_GALLERY_REQUEST,
    payload: { id, formData, cb },
  };
}
