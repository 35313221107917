export const ADD_WEB_SUITES_REQUEST = "ADD_WEB_SUITES_REQUEST";
export const ADD_WEB_SUITES_SUCCESS = "ADD_WEB_SUITES_SUCCESS";
export const ADD_WEB_SUITES_FAIL = "ADD_WEB_SUITES_FAIL";

export function addSuitesAction(formData, cb) {
  return {
    type: ADD_WEB_SUITES_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const UPDATE_WEB_SUITES_REQUEST = "UPDATE_WEB_SUITES_REQUEST";
export const UPDATE_WEB_SUITES_SUCCESS = "UPDATE_WEB_SUITES_SUCCESS";
export const UPDATE_WEB_SUITES_FAIL = "UPDATE_WEB_SUITES_FAIL";

export function updateSuites(formData, cb) {
  return {
    type: UPDATE_WEB_SUITES_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_WEB_SUITES_REQUEST = "DELETE_WEB_SUITES_REQUEST";
export const DELETE_WEB_SUITES_SUCCESS = "DELETE_WEB_SUITES_SUCCESS";
export const DELETE_WEB_SUITES_FAIL = "DELETE_WEB_SUITES_FAIL";

export function deleteSuites(id, cb) {
  return {
    type: DELETE_WEB_SUITES_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_WEB_SUITES_REQUEST = "GET_WEB_SUITES_REQUEST";
export const GET_WEB_SUITES_SUCCESS = "GET_WEB_SUITES_SUCCESS";
export const GET_WEB_SUITES_FAIL = "GET_WEB_SUITES_FAIL";

export function getSuitesAction(searchConfig, cb) {
  return {
    type: GET_WEB_SUITES_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const STATUS_WEB_SUITES_REQUEST = "STATUS_WEB_SUITES_REQUEST";
export const STATUS_WEB_SUITES_SUCCESS = "STATUS_WEB_SUITES_SUCCESS";
export const STATUS_WEB_SUITES_FAIL = "STATUS_WEB_SUITES_FAIL";

export function changeSuitesStatusAction(id, cb) {
  return {
    type: STATUS_WEB_SUITES_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_SINGLE_WEB_SUITES_REQUEST = "GET_SINGLE_WEB_SUITES_REQUEST";
export const GET_SINGLE_WEB_SUITES_SUCCESS = "GET_SINGLE_WEB_SUITES_SUCCESS";
export const GET_SINGLE_WEB_SUITES_FAIL = "GET_SINGLE_WEB_SUITES_FAIL";

export function getSingleSuites(id, cb) {
  return {
    type: GET_SINGLE_WEB_SUITES_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_WEB_SUITES_REQUEST = "EXPORT_WEB_SUITES_REQUEST";
export const EXPORT_WEB_SUITES_SUCCESS = "EXPORT_WEB_SUITES_SUCCESS";
export const EXPORT_WEB_SUITES_FAIL = "EXPORT_WEB_SUITES_FAIL";

export function exportExcelSuitesAction(searchConfig, cb) {
  return {
    type: EXPORT_WEB_SUITES_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const GET_WEB_SUITES_GALLERY_REQUEST = "GET_WEB_SUITES_GALLERY_REQUEST";
export const GET_WEB_SUITES_GALLERY_SUCCESS = "GET_WEB_SUITES_GALLERY_SUCCESS";
export const GET_WEB_SUITES_GALLERY_FAIL = "GET_WEB_SUITES_GALLERY_FAIL";

export function getWebSuitesGallery(id, cb) {
  return {
    type: GET_WEB_SUITES_GALLERY_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_WEB_SUITES_GALLERY_REQUEST =
  "UPDATE_WEB_SUITES_GALLERY_REQUEST";
export const UPDATE_WEB_SUITES_GALLERY_SUCCESS =
  "UPDATE_WEB_SUITES_GALLERY_SUCCESS";
export const UPDATE_WEB_SUITES_GALLERY_FAIL = "UPDATE_WEB_SUITES_GALLERY_FAIL";

export function updateWebSuitesGallery(id, formData, cb) {
  return {
    type: UPDATE_WEB_SUITES_GALLERY_REQUEST,
    payload: { id, formData, cb },
  };
}

//Suites Rooms

export const GET_WEB_SUITE_ROOM_REQUEST = "GET_WEB_SUITE_ROOM_REQUEST";
export const GET_WEB_SUITE_ROOM_SUCCESS = "GET_WEB_SUITE_ROOM_SUCCESS";
export const GET_WEB_SUITE_ROOM_FAIL = "GET_WEB_SUITE_ROOM_FAIL";

export function getSuiteRoomAction(id, searchConfig, cb) {
  return {
    type: GET_WEB_SUITE_ROOM_REQUEST,
    payload: { id, searchConfig, cb },
  };
}

export const ADD_WEB_SUITE_ROOM_REQUEST = "ADD_WEB_SUITE_ROOM_REQUEST";
export const ADD_WEB_SUITE_ROOM_SUCCESS = "ADD_WEB_SUITE_ROOM_SUCCESS";
export const ADD_WEB_SUITE_ROOM_FAIL = "ADD_WEB_SUITE_ROOM_FAIL";

export function addRoomAction(id, formData, cb) {
  return {
    type: ADD_WEB_SUITE_ROOM_REQUEST,
    payload: {
      id,
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_WEB_SUITE_ROOM_REQUEST =
  "GET_SINGLE_WEB_SUITE_ROOM_REQUEST";
export const GET_SINGLE_WEB_SUITE_ROOM_SUCCESS =
  "GET_SINGLE_WEB_SUITE_ROOM_SUCCESS";
export const GET_SINGLE_WEB_SUITE_ROOM_FAIL = "GET_SINGLE_WEB_SUITE_ROOM_FAIL";

export function getSingleSuiteRoom(id, cb) {
  return {
    type: GET_SINGLE_WEB_SUITE_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const STATUS_WEB_SUITE_ROOM_REQUEST = "STATUS_WEB_SUITE_ROOM_REQUEST";
export const STATUS_WEB_SUITE_ROOM_SUCCESS = "STATUS_WEB_SUITE_ROOM_SUCCESS";
export const STATUS_WEB_SUITE_ROOM_FAIL = "STATUS_WEB_SUITE_ROOM_FAIL";

export function changeSuiteRoomStatusAction(id, cb) {
  return {
    type: STATUS_WEB_SUITE_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const UPDATE_WEB_SUITE_ROOM_REQUEST = "UPDATE_WEB_SUITE_ROOM_REQUEST";
export const UPDATE_WEB_SUITE_ROOM_SUCCESS = "UPDATE_WEB_SUITE_ROOM_SUCCESS";
export const UPDATE_WEB_SUITE_ROOM_FAIL = "UPDATE_WEB_SUITE_ROOM_FAIL";

export function updateSuiteRoom(formData, cb) {
  return {
    type: UPDATE_WEB_SUITE_ROOM_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_WEB_SUITE_ROOM_REQUEST = "DELETE_WEB_SUITE_ROOM_REQUEST";
export const DELETE_WEB_SUITE_ROOM_SUCCESS = "DELETE_WEB_SUITE_ROOM_SUCCESS";
export const DELETE_WEB_SUITE_ROOM_FAIL = "DELETE_WEB_SUITE_ROOM_FAIL";

export function deleteSuiteRoom(id, cb) {
  return {
    type: DELETE_WEB_SUITE_ROOM_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_WEB_SUITE_ROOM_GALLERY_REQUEST =
  "GET_WEB_SUITE_ROOM_GALLERY_REQUEST";
export const GET_WEB_SUITE_ROOM_GALLERY_SUCCESS =
  "GET_WEB_SUITE_ROOM_GALLERY_SUCCESS";
export const GET_WEB_SUITE_ROOM_GALLERY_FAIL =
  "GET_WEB_SUITE_ROOM_GALLERY_FAIL";

export function getWebSuiteRoomGallery(id, cb) {
  return {
    type: GET_WEB_SUITE_ROOM_GALLERY_REQUEST,
    payload: { id, cb },
  };
}

export const UPDATE_WEB_SUITE_ROOM_GALLERY_REQUEST =
  "UPDATE_WEB_SUITE_ROOM_GALLERY_REQUEST";
export const UPDATE_WEB_SUITE_ROOM_GALLERY_SUCCESS =
  "UPDATE_WEB_SUITE_ROOM_GALLERY_SUCCESS";
export const UPDATE_WEB_SUITE_ROOM_GALLERY_FAIL =
  "UPDATE_WEB_SUITE_ROOM_GALLERY_FAIL";

export function updateWebSuiteRoomGallery(id, formData, cb) {
  return {
    type: UPDATE_WEB_SUITE_ROOM_GALLERY_REQUEST,
    payload: { id, formData, cb },
  };
}
