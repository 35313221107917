export const GET_SUPPLIERS_REQUEST = 'GET_SUPPLIERS_REQUEST';
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS';
export const GET_SUPPLIERS_FAIL = 'GET_SUPPLIERS_FAIL';

export function getSuppliersList(searchConfig, cb) {
  return {
    type: GET_SUPPLIERS_REQUEST,
    payload: {
      searchConfig,
      cb,
    },
  };
}

export const CREATE_SUPPLIER_REQUEST = 'CREATE_SUPPLIER_REQUEST';
export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS';
export const CREATE_SUPPLIER_FAIL = 'CREATE_SUPPLIER_FAIL';

export function createSupplier(formData, cb) {
  return {
    type: CREATE_SUPPLIER_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_SUPPLIER_REQUEST = 'GET_SINGLE_SUPPLIER_REQUEST';
export const GET_SINGLE_SUPPLIER_SUCCESS = 'GET_SINGLE_SUPPLIER_SUCCESS';
export const GET_SINGLE_SUPPLIER_FAIL = 'GET_SINGLE_SUPPLIER_FAIL';

export function getSingleSupplier(id, cb) {
  return {
    type: GET_SINGLE_SUPPLIER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EDIT_SUPPLIER_REQUEST = 'EDIT_SUPPLIER_REQUEST';
export const EDIT_SUPPLIER_SUCCESS = 'EDIT_SUPPLIER_SUCCESS';
export const EDIT_SUPPLIER_FAIL = 'EDIT_SUPPLIER_FAIL';

export function editSupplier(id, formData, cb) {
  return {
    type: EDIT_SUPPLIER_REQUEST,
    payload: {
      id,
      formData,
      cb,
    },
  };
}

export const DELETE_SUPPLIER_REQUEST = 'DELETE_SUPPLIER_REQUEST';
export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS';
export const DELETE_SUPPLIER_FAIL = 'DELETE_SUPPLIER_FAIL';

export function deleteSupplier(id, cb) {
  return {
    type: DELETE_SUPPLIER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}
export const EXPORT_SUPPLIERS_REQUEST = 'EXPORT_SUPPLIERS_REQUEST';
export const EXPORT_SUPPLIERS_SUCCESS = 'EXPORT_SUPPLIERS_SUCCESS';
export const EXPORT_SUPPLIERS_FAIL = 'EXPORT_SUPPLIERS_FAIL';

export function exportExcelSupplierAction(searchConfig, cb) {
  return {
    type: EXPORT_SUPPLIERS_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const DELETE_SELECTED_SUPPLIERS_REQUEST =
  'DELETE_SELECTED_SUPPLIERS_REQUEST';
export const DELETE_SELECTED_SUPPLIERS_SUCCESS =
  'DELETE_SELECTED_SUPPLIERS_SUCCESS';
export const DELETE_SELECTED_SUPPLIERS_FAIL = 'DELETE_SELECTED_SUPPLIERS_FAIL';

export function deleteSelectedSuppliersAction(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_SUPPLIERS_REQUEST,
    payload: { selectedRows, cb },
  };
}

export const UPDATE_STATUS_SUPPLIER_REQUEST = 'UPDATE_STATUS_SUPPLIER_REQUEST';
export const UPDATE_STATUS_SUPPLIER_SUCCESS = 'UPDATE_STATUS_SUPPLIER_SUCCESS';
export const UPDATE_STATUS_SUPPLIER_FAIL = 'UPDATE_STATUS_SUPPLIER_FAIL';

export function updateStatusSupplierAction(id, cb) {
  return {
    type: UPDATE_STATUS_SUPPLIER_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}
