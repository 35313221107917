import {
  GET_RESERVATIONS_REQUEST,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATIONS_FAIL,
  GET_ALL_RESERVATIONS_REQUEST,
  GET_ALL_RESERVATIONS_SUCCESS,
  GET_ALL_RESERVATIONS_FAIL,
  ADD_RESERVATIONS_REQUEST,
  ADD_RESERVATIONS_SUCCESS,
  ADD_RESERVATIONS_FAIL,
  DELETE_RESERVATION_REQUEST,
  DELETE_RESERVATION_SUCCESS,
  DELETE_RESERVATION_FAIL,
  UPDATE_RESERVATION_REQUEST,
  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_FAIL,
  GET_SINGLE_RESERVATION_REQUEST,
  GET_SINGLE_RESERVATION_SUCCESS,
  GET_SINGLE_RESERVATION_FAIL,
  EXPORT_RESERVATIONS_REQUEST,
  EXPORT_RESERVATIONS_SUCCESS,
  EXPORT_RESERVATIONS_FAIL,
  UPDATE_STATUS_RESERVATIONS_REQUEST,
  UPDATE_STATUS_RESERVATIONS_SUCCESS,
  UPDATE_STATUS_RESERVATIONS_FAIL,
  HANDLE_GET_ACCOMMODATIONS_REQUEST,
  HANDLE_GET_ACCOMMODATIONS_SUCCESS,
  HANDLE_GET_ACCOMMODATIONS_FAIL,
  HANDLE_GET_ACCOMMODATION_ROOMS_REQUEST,
  HANDLE_GET_ACCOMMODATION_ROOMS_SUCCESS,
  HANDLE_GET_ACCOMMODATION_ROOMS_FAIL,
  HANDLE_GET_ROOM_RESERVATIONS_REQUEST,
  HANDLE_GET_ROOM_RESERVATIONS_SUCCESS,
  HANDLE_GET_ROOM_RESERVATIONS_FAIL,
} from "../actions/reservations";
import { normalizeAccommodations } from "../../services/utils";

const initialState = {
  status: "",
  actionStatus: "",
  reservations: [],
  allReservations: [],
  singleReservation: {},
  accommodations: [],
  rooms: [],
  exportExcelStatus: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESERVATIONS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        status: "success",
        count: action.payload.data.count,
        reservations: action.payload.data?.reservations,
      };
    }
    case GET_RESERVATIONS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case GET_ALL_RESERVATIONS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_ALL_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        status: "success",
        allReservations: action.payload.data.allReservations,
      };
    }
    case GET_ALL_RESERVATIONS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case ADD_RESERVATIONS_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_RESERVATIONS_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_RESERVATION_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_RESERVATION_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_RESERVATION_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_RESERVATION_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_RESERVATION_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_RESERVATION_FAIL: {
      return {
        ...state,
        actionStatus: "success",
      };
    }

    case GET_SINGLE_RESERVATION_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_RESERVATION_SUCCESS: {
      let accommodations = [];
      if (action.payload.data.accommodations) {
        accommodations = normalizeAccommodations(
          action.payload.data.accommodations
        );
      }
      const reservation = action.payload.data.reservation;
      return {
        ...state,
        status: "success",
        singleReservation: {
          ...reservation,
          room_id:
            reservation?.hotel_room_id ||
            reservation?.suite_room_id ||
            reservation?.villa_room_id,
        },
        accommodations: accommodations,
        rooms: action.payload.data?.rooms,
      };
    }
    case GET_SINGLE_RESERVATION_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case EXPORT_RESERVATIONS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: "pending",
      };
    }
    case EXPORT_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: "success",
      };
    }
    case EXPORT_RESERVATIONS_FAIL: {
      return {
        ...state,
        exportExcelStatus: "fail",
      };
    }

    case UPDATE_STATUS_RESERVATIONS_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_STATUS_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_STATUS_RESERVATIONS_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case HANDLE_GET_ACCOMMODATIONS_REQUEST: {
      return {
        ...state,
        rooms: [],
        accommodations: [],
        actionStatus: "pending",
      };
    }
    case HANDLE_GET_ACCOMMODATIONS_SUCCESS: {
      let accommodations = [];
      if (action.payload.data) {
        accommodations = normalizeAccommodations(action.payload.data);
      }
      return {
        ...state,
        actionStatus: "success",
        accommodations: accommodations,
      };
    }
    case HANDLE_GET_ACCOMMODATIONS_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case HANDLE_GET_ACCOMMODATION_ROOMS_REQUEST: {
      return {
        ...state,
        rooms: [],
        actionStatus: "pending",
      };
    }
    case HANDLE_GET_ACCOMMODATION_ROOMS_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
        rooms: action.payload.data?.rooms,
      };
    }
    case HANDLE_GET_ACCOMMODATION_ROOMS_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case HANDLE_GET_ROOM_RESERVATIONS_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case HANDLE_GET_ROOM_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case HANDLE_GET_ROOM_RESERVATIONS_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    default: {
      return state;
    }
  }
}
