import React, { useEffect } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Drawer from '@mui/material/Drawer';
import AdminSideBar from './AdminSideBar';

export default function SwipeMenu({ toggleDrawer, drawerOpen }) {
  useEffect(() => {
    if (drawerOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [drawerOpen]);
  return (
    <Drawer
      className="mobile_side_bar"
      anchor="right"
      open={drawerOpen}
      onClose={e => toggleDrawer(e)}
      disableScrollLock={false}
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        style: {
          top: '100px',
          zIndex: 999,
        },
      }}
    >
      <AdminSideBar />
    </Drawer>
  );
}
