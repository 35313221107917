import {
  ADD_WEB_VILLA_REQUEST,
  ADD_WEB_VILLA_SUCCESS,
  ADD_WEB_VILLA_FAIL,
  UPDATE_WEB_VILLA_REQUEST,
  UPDATE_WEB_VILLA_SUCCESS,
  UPDATE_WEB_VILLA_FAIL,
  DELETE_WEB_VILLA_REQUEST,
  DELETE_WEB_VILLA_SUCCESS,
  DELETE_WEB_VILLA_FAIL,
  GET_SINGLE_WEB_VILLA_REQUEST,
  GET_SINGLE_WEB_VILLA_SUCCESS,
  GET_SINGLE_WEB_VILLA_FAIL,
  GET_WEB_VILLAS_REQUEST,
  GET_WEB_VILLAS_SUCCESS,
  GET_WEB_VILLAS_FAIL,
  EXPORT_WEB_VILLAS_REQUEST,
  EXPORT_WEB_VILLAS_SUCCESS,
  EXPORT_WEB_VILLAS_FAIL,
  STATUS_WEB_VILLA_REQUEST,
  STATUS_WEB_VILLA_SUCCESS,
  STATUS_WEB_VILLA_FAIL,
  GET_WEB_VILLAS_GALLERY_REQUEST,
  GET_WEB_VILLAS_GALLERY_SUCCESS,
  GET_WEB_VILLAS_GALLERY_FAIL,
  UPDATE_WEB_VILLAS_GALLERY_REQUEST,
  UPDATE_WEB_VILLAS_GALLERY_SUCCESS,
  UPDATE_WEB_VILLAS_GALLERY_FAIL,
  GET_WEB_VILLA_ROOM_REQUEST,
  GET_WEB_VILLA_ROOM_SUCCESS,
  GET_WEB_VILLA_ROOM_FAIL,
  ADD_WEB_VILLA_ROOM_REQUEST,
  ADD_WEB_VILLA_ROOM_SUCCESS,
  ADD_WEB_VILLA_ROOM_FAIL,
  GET_SINGLE_WEB_VILLA_ROOM_REQUEST,
  GET_SINGLE_WEB_VILLA_ROOM_SUCCESS,
  GET_SINGLE_WEB_VILLA_ROOM_FAIL,
  STATUS_WEB_VILLA_ROOM_REQUEST,
  STATUS_WEB_VILLA_ROOM_SUCCESS,
  STATUS_WEB_VILLA_ROOM_FAIL,
  UPDATE_WEB_VILLA_ROOM_REQUEST,
  UPDATE_WEB_VILLA_ROOM_SUCCESS,
  UPDATE_WEB_VILLA_ROOM_FAIL,
  DELETE_WEB_VILLA_ROOM_REQUEST,
  DELETE_WEB_VILLA_ROOM_SUCCESS,
  DELETE_WEB_VILLA_ROOM_FAIL,
  GET_WEB_VILLA_ROOM_GALLERY_REQUEST,
  GET_WEB_VILLA_ROOM_GALLERY_SUCCESS,
  GET_WEB_VILLA_ROOM_GALLERY_FAIL,
  UPDATE_WEB_VILLA_ROOM_GALLERY_REQUEST,
  UPDATE_WEB_VILLA_ROOM_GALLERY_SUCCESS,
  UPDATE_WEB_VILLA_ROOM_GALLERY_FAIL,
  UPDATE_WEB_VILLA_TYPE_REQUEST,
  UPDATE_WEB_VILLA_TYPE_SUCCESS,
  UPDATE_WEB_VILLA_TYPE_FAIL,
} from "../actions/websiteVillas";

const initialState = {
  rooms: [],
  villas: [],
  status: "",
  roomName: "",
  allVillas: [],
  singleVilla: {},
  actionStatus: "",
  villaGallery: [],
  singleVillaRoom: {},
  villaRoomGallery: [],
  exportExcelStatus: "",
  getSelectionVillasStatus: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_WEB_VILLA_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_WEB_VILLA_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_WEB_VILLA_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_WEB_VILLA_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_VILLA_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_VILLA_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_WEB_VILLA_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_WEB_VILLA_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_WEB_VILLA_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_SINGLE_WEB_VILLA_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_WEB_VILLA_SUCCESS: {
      return {
        ...state,
        status: "success",
        singleVilla: action.payload.data.villa,
      };
    }
    case GET_SINGLE_WEB_VILLA_FAIL: {
      return {
        ...state,
        status: "pending",
      };
    }

    case GET_WEB_VILLAS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_VILLAS_SUCCESS: {
      return {
        ...state,
        status: "success",
        villas: action.payload.data.villas,
        count: action.payload.data.count,
      };
    }
    case GET_WEB_VILLAS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case STATUS_WEB_VILLA_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case STATUS_WEB_VILLA_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case STATUS_WEB_VILLA_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case EXPORT_WEB_VILLAS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: "pending",
      };
    }
    case EXPORT_WEB_VILLAS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: "success",
      };
    }
    case EXPORT_WEB_VILLAS_FAIL: {
      return {
        ...state,
        exportExcelStatus: "fail",
      };
    }

    case GET_WEB_VILLAS_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_VILLAS_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
        villaGallery: action.payload.data?.gallery,
      };
    }
    case GET_WEB_VILLAS_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_VILLAS_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_VILLAS_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_VILLAS_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_VILLA_TYPE_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_VILLA_TYPE_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_VILLA_TYPE_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    //Villa Rooms
    case GET_WEB_VILLA_ROOM_REQUEST: {
      return {
        ...state,
        hotelName: "",
        status: "pending",
      };
    }
    case GET_WEB_VILLA_ROOM_SUCCESS: {
      return {
        ...state,
        status: "success",
        count: action.payload.data.count,
        rooms: action.payload.data?.villaRooms,
        villaName: action.payload.data?.villa?.villa_name,
      };
    }
    case GET_WEB_VILLA_ROOM_FAIL: {
      return {
        ...state,
        hotelName: "",
        status: "fail",
      };
    }

    case ADD_WEB_VILLA_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_WEB_VILLA_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_WEB_VILLA_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_SINGLE_WEB_VILLA_ROOM_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_WEB_VILLA_ROOM_SUCCESS: {
      return {
        ...state,
        status: "success",
        singleVillaRoom: action.payload.data.room,
      };
    }
    case GET_SINGLE_WEB_VILLA_ROOM_FAIL: {
      return {
        ...state,
        status: "pending",
      };
    }

    case STATUS_WEB_VILLA_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case STATUS_WEB_VILLA_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case STATUS_WEB_VILLA_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_WEB_VILLA_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_VILLA_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_VILLA_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_WEB_VILLA_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_WEB_VILLA_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_WEB_VILLA_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_WEB_VILLA_ROOM_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_VILLA_ROOM_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
        villaRoomGallery: action.payload.data?.gallery,
      };
    }
    case GET_WEB_VILLA_ROOM_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_VILLA_ROOM_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_VILLA_ROOM_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_VILLA_ROOM_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    default: {
      return state;
    }
  }
}
