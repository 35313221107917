import { all, fork } from "redux-saga/effects";
import suppliers from "./suppliers";
import settings from "./settings";
import pickUpPoints from "./pickUpPoints";
import transferTypes from "./transferTypes";
import drivers from "./drivers";
import users from "./users";
import tours from "./tours";
import toursTransfers from "./toursTransfers";
import tourVouchers from "./tourVouchers";
import currency from "./currency";
import advertisements from "./advertisements";
import directions from "./directions";
import roles from "./roles";
import transferVouchers from "./transferVouchers";
import customSettings from "./customSettings";
import emailSetups from "./emailSetups";
import termsConditions from "./termsConditions";
import logs from "./logs";
import categories from "./categories";
import websiteTours from "./websiteTours";
import websiteHotels from "./websiteHotels";
import websiteVillas from "./websiteVillas";
import websiteSuites from "./websiteSuites";
import websiteApartments from "./websiteApartments";
import facilities from "./facilities";
import reservations from "./reservations";
import roomReservations from "./roomReservations";

export default function* watchers() {
  yield all(
    [
      suppliers,
      settings,
      advertisements,
      pickUpPoints,
      transferTypes,
      drivers,
      users,
      tours,
      toursTransfers,
      currency,
      tourVouchers,
      directions,
      roles,
      transferVouchers,
      customSettings,
      emailSetups,
      termsConditions,
      logs,
      categories,
      websiteTours,
      websiteHotels,
      websiteVillas,
      websiteSuites,
      websiteApartments,
      facilities,
      reservations,
      roomReservations,
    ].map(fork)
  );
}
