import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getColumns } from "./column";
import { getLogsAction } from "../../store/actions/logs";
import moment from "moment";
import { sortAction } from "../../store/actions/search";
import Pagination from "../../components/pagination/Pagination";
import SearchFilterBar from "../../components/search_filter_bar/SearchFilterBar";
import TableTitle from "../../components/table_title/TableTitle";

function Logs() {
  const logs = useSelector((store) => store?.logs?.allLogs);
  const count = useSelector((store) => store?.logs?.count);
  const rowStatus = useSelector((store) => store?.logs?.status);
  const searchConfig = useSelector((store) => store.search);
  const dispatch = useDispatch();

  useEffect(() => {
    getLogs();
  }, [dispatch]);

  const getLogs = () => {
    dispatch(getLogsAction(searchConfig));
  };

  useEffect(() => {
    if (logs && logs?.length > 0) {
      const formattedLogs = logs.map((log) => {
        if (log.description) {
          log.description = formatDateInDescription(log.description);
        }
        return log;
      });
    }
  }, [logs]);

  const formatDateInDescription = (description) => {
    const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z/g;
    const matches = description.match(regex);
    if (matches) {
      for (let match of matches) {
        const formattedDate = moment(match).format("YYYY-MM-DD HH:mm:ss");
        description = description.replace(match, formattedDate);
      }
    }
    return description;
  };

  const columns = getColumns();

  // const handleKpiReport = () => {
  //   dispatch(exportKpiAction());
  // };
  //
  const handleSort = (model) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getLogs();
  };

  return (
    <div className="admin_tables_block">
      {/*<div className="admin_tables_title">*/}
      {/*  /!*<div className="pageTitle">*!/*/}
      {/*  /!*  <span>Logs</span>*!/*/}
      {/*  /!*</div>*!/*/}
      {/* */}
      {/*</div>*/}
      <TableTitle
        rows={logs}
        title={"Logs"}
        getList={getLogs}
        rowStatus={rowStatus}
        // exportExcel={exportExcel}
        // exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getLogs}
        columns={columns}
        setShowFilter={false}
        showFilter={false}
      />
      <DataGridPro
        autoHeight
        className="loggerTable web_table"
        loading={rowStatus === "Pending"}
        initialState={{ pinnedColumns: { right: ["actions"] } }}
        disableVirtualization
        rows={logs || []}
        columns={[...columns]}
        sortingMode="server"
        rowHeight={120}
        isRowSelectable={false}
        onSortModelChange={(model) => {
          handleSort(model);
        }}
        disableColumnMenu={true}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getLogs}
      />
    </div>
  );
}

export default Logs;
