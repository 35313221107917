import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle.js";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import {
  getSingleTermsConditionsAction,
  updateTermsConditionAction,
} from "../../store/actions/termsConditions.js";
import TermsConditionsForm from "./TermsConditionsForm.js";
import { blockPageAction } from "../../store/actions/users";
import { toast } from "react-toastify";
import ModalButtonsReset from "../../components/modals/modalButtons/ModalButtonsReset";
import { TrimmedData } from "../../services/TrimObjectFields";

function PreviewTermsConditions() {
  const rowStatus = useSelector((store) => store?.termsConditions?.status);
  const actionStatus = useSelector(
    (store) => store?.termsConditions?.actionStatus
  );
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({});
  const [siteName, setSiteName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    handleEdit(id);
    return () => {
      dispatch(blockPageAction(""));
    };
  }, []);

  // Get the row data to edit
  const handleEdit = (id) => {
    dispatch(
      getSingleTermsConditionsAction(id, (err, data) => {
        if (!err && data) {
          setFormData(data);
          setSiteName(
            data?.name ? `ID: ${data?.id} ${data?.name}` : `ID: ${data?.id}`
          );
        } else if (err) {
          navigate(`/dashboard`);
        }
      })
    );
    return () => {};
  };

  const handleEditConfirm = async (data) => {
    const trimmedData = TrimmedData(data);
    setDisabled(true);
    dispatch(
      updateTermsConditionAction(trimmedData, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        } else {
          if (data) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setTimeout(() => {
              setDisabled(false);
            }, 400);
            navigate("/setup/terms_conditions");
          }
        }
      })
    );
  };

  return (
    <>
      <PageTitle
        parentTitle={"Terms and Conditions"}
        childTitle={siteName}
        parentLink={"/setup/terms_conditions"}
      />
      <div className="form-block">
        {rowStatus === "success" ? (
          <TermsConditionsForm
            submit={handleEditConfirm}
            formData={formData}
            footer={
              <ModalButtonsReset
                submit={handleEditConfirm}
                disabled={disabled}
                isLoading={actionStatus === "pending"}
                reset={() => {
                  handleEdit(id);
                }}
              />
            }
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === "pending"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewTermsConditions;
