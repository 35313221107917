import React from "react";
import { Modal } from "react-bootstrap";
import DynamicEmailForm from "../../pages/generatePdf/DynamicEmailForm";

function EmailModal({
  id,
  show,
  page,
  confirm,
  disabled,
  handleClose,
  actionStatus,
  handlePreviewEmail,
}) {
  const close = () => {
    handleClose();
  };

  const handleSubmit = (data) => {
    confirm(data);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={close}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <DynamicEmailForm
        id={id}
        pageKey={page === "Voucher" ? "tour" : "transfer"}
        forAdmin={true}
        handleClose={close}
        disabled={disabled}
        submit={handleSubmit}
        actionStatus={actionStatus}
        handlePreviewEmail={handlePreviewEmail}
        page={page === "Voucher" ? "Tour" : page}
      />
    </Modal>
  );
}

export default EmailModal;
