import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import UsersForm from "./UsersForm";
import { driverValidation } from "./validationSchema";
import {
  blockPageAction,
  registerRequest,
  updateUser,
} from "../../../store/actions/users";
import { LoadingButton } from "@mui/lab";
import { TrimmedData } from "../../../services/TrimObjectFields";

function DriversForm({
  handleClose,
  singleDriver,
  getUsersList,
  add_new_modal,
  actionStatus,
}) {
  const { id } = useParams();
  const isEditing = Boolean(id);
  const searchConfig = useSelector((store) => store.search);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultValues =
    isEditing && singleDriver
      ? {
          ...singleDriver,
          add_user: !!singleDriver.user_name,
          new_password: !!singleDriver.user_name,
        }
      : {
          role: "Driver",
          add_user: false,
        };

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(driverValidation),
    defaultValues: defaultValues,
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isDirty, isSubmitted },
    reset,
  } = methods;

  const isUserAdded = watch("add_user");

  const isFormDisabled = isEditing ? !isDirty : false;

  useEffect(() => {
    if (isEditing && singleDriver) {
      reset({
        ...singleDriver,
        add_user: !!singleDriver.user_name,
        new_password: !!singleDriver.user_name,
      });
    } else {
      reset({
        role: "Driver",
        add_user: false,
      });
    }
  }, [isEditing, singleDriver, reset]);

  useEffect(() => {
    if (isEditing) {
      if (isDirty) {
        dispatch(blockPageAction(`/users/${id}`));
      } else {
        dispatch(blockPageAction(""));
      }
    }
  }, [isEditing, isDirty, id, dispatch]);

  const onSubmit = (data) => {
    setDisabled(true);
    const trimmedData = TrimmedData(data);
    dispatch(
      registerRequest(trimmedData, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        } else {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          getUsersList(searchConfig);
          handleClose();
          setTimeout(() => {
            setDisabled(false);
          }, 400);
        }
      })
    );
  };

  const onEdit = (data) => {
    dispatch(
      updateUser(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            navigate("/users");
          }
        }
      })
    );
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(isEditing ? onEdit : onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Full Name<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_name"
                    name="full_name"
                    type="text"
                    {...register("full_name", {
                      required: "Full name is required",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message:
                          "Full name must contain only letters and spaces",
                      },
                    })}
                    isInvalid={isSubmitted && !!errors.full_name}
                    isValid={isSubmitted && !errors.full_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.full_name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Passport Details<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_passport_details"
                    name="passport_details"
                    type="text"
                    {...register("passport_details", { required: true })}
                    isInvalid={isSubmitted && !!errors.passport_details}
                    isValid={isSubmitted && !errors.passport_details}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passport_details?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    License Number<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_driving_license_number"
                    name="driving_license_number"
                    type="text"
                    {...register("driving_license_number", { required: true })}
                    isInvalid={isSubmitted && !!errors.driving_license_number}
                    isValid={isSubmitted && !errors.driving_license_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.driving_license_number?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Vehicle Type<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_vehicle_type"
                    name="vehicle_type"
                    type="text"
                    {...register("vehicle_type", { required: true })}
                    isInvalid={isSubmitted && !!errors.vehicle_type}
                    isValid={isSubmitted && !errors.vehicle_type}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.vehicle_type?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Registration Number<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="driver_registration_number"
                    name="registration_number"
                    type="text"
                    {...register("registration_number", { required: true })}
                    isInvalid={isSubmitted && !!errors.registration_number}
                    isValid={isSubmitted && !errors.registration_number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.registration_number?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Controller
                  name="add_user"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="driver_create_account"
                          checked={field.value}
                          disabled={isEditing && singleDriver?.user_name}
                          onChange={(ev) => {
                            field.onChange(ev.target.checked);
                            // if (!ev.target.checked) {
                            //   setValue("password", null );
                            //   setValue("user_name", null );
                            // }
                          }}
                        />
                      }
                      label="Create Account?"
                    />
                  )}
                />
              </Col>
            </Row>

            {isUserAdded || (!add_new_modal && singleDriver?.user_name) ? (
              <UsersForm
                setValue={setValue}
                control={control}
                singleUser={singleDriver}
                driver={true}
                isSubmitted={isSubmitted}
              />
            ) : null}
          </Modal.Body>
        </div>

        <Modal.Footer>
          {isEditing ? (
            <div className="previewButtonsID">
              <Button
                variant={isFormDisabled ? "secondary" : "primary"}
                onClick={() =>
                  reset({
                    ...singleDriver,
                    add_user: !!singleDriver.user_name,
                    new_password: !!singleDriver.user_name,
                  })
                }
                disabled={isFormDisabled}
              >
                Reset
              </Button>
              <LoadingButton
                  size="big"
                  className={`preview_submit_btn btn ${
                      isFormDisabled ? "btn-secondary" : "btn-primary"
                  }`}
                  style={{ height: "100%", fontSize: "1rem", lineHeight: "1.55" }}
                  loading={actionStatus === "pending"}
                  disabled={isFormDisabled}
                  variant="contained"
                  type="submit"
              >
                Save
              </LoadingButton>
            </div>
          ) : (
            <div className="previewButtons">
              <LoadingButton
                size="small"
                className="login_submit_btn btn btn-primary"
                loadingPosition="center"
                variant="contained"
                loading={actionStatus === "pending"}
                type="submit"
                disabled={disabled}
              >
                <span>Save</span>
              </LoadingButton>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Form>
    </FormProvider>
  );
}

export default DriversForm;
