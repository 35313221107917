import { GridActionsCellItem } from "@mui/x-data-grid";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import React from "react";
import moment from "moment";

const { REACT_APP_API_URL } = process.env;

export const globalUsersColumns = ({
  handleUpdateStatus,
  redirectToPreview,
  handleDelete,
}) => {
  return [
    {
      access: ["Super Admin", "Admin"],
      field: "id",
      headerName: "#",
      width: 60,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      access: ["Super Admin"],
      field: "company_name",
      headerName: "Company Name",
      width: 150,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "role",
      headerName: "Role",
      width: 150,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "supplier_logo",
      headerName: "Logo",
      width: 100,
      renderCell: ({ row }) =>
        row?.supplier_logo && (
          <img
            style={{ objectFit: "contain" }}
            src={`${REACT_APP_API_URL}/supplier_logos/${row?.supplier_logo}`}
            alt=""
          />
        ),
    },
    {
      access: ["Super Admin", "Admin"],
      field: "full_name",
      headerName: "Full Name",
      width: 200,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "user_name",
      headerName: "Username",
      width: 200,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "email",
      headerName: "Email",
      width: 200,
    },
    {
      access: ["Super Admin", "Admin"],
      field: "phone_number",
      headerName: "Phone Number",
      width: 160,
      renderCell: ({ row }) => (
        <span style={{ cursor: "pointer", color: "#0d6efd" }}>
          <a
            onClick={(e) => e.stopPropagation()}
            style={{ color: "#0d6efd", textDecoration: "none" }}
            href={`tel:${row["phone_number"]}`}
          >
            {row["phone_number"]
              ? !row["phone_number"].startsWith("+")
                ? `+${row["phone_number"]}`
                : row["phone_number"]
              : ""}
          </a>
        </span>
      ),
    },
    {
      access: ["Super Admin", "Admin"],
      field: "createdAt",
      headerName: "Created Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.createdAt || params?.row.createdAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      access: ["Super Admin", "Admin"],
      field: "createdBy.full_name",
      headerName: "Created By",
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.full_name || "",
    },
    {
      access: ["Super Admin", "Admin"],
      field: "updatedAt",
      headerName: "Modified Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.updatedAt || params?.row.updatedAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      access: ["Super Admin", "Admin"],
      field: "updatedBy.full_name",
      headerName: "Modified By",
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.full_name || "",
    },
  ];
};
