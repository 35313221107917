import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Table from "../../components/table";
import Pagination from "../../components/pagination/Pagination";
import SearchFilterBar from "../../components/search_filter_bar/SearchFilterBar";
import TableTitle from "../../components/table_title/TableTitle";
import {
  getHashedIdAction,
  setPage,
  sortAction,
} from "../../store/actions/search";
import DeleteModal2 from "../../components/modals/DeleteModal2";
import { getColumns } from "./column";
import {
  addDirectionAction,
  changeDirectionStatusAction,
  deleteDirection,
  deleteSelectedDirections,
  exportExcelDirectionsAction,
  getDirectionsAction,
} from "../../store/actions/directions";
import DirectionsForm from "./DirectionsForm";
import { isEmpty } from "lodash";
import UpdateStatusModal from "../../components/modals/UpdateStatusModal";
import MobileLayout from "../../components/mobileLayout/MobileLayout";
import { directions_mobile_column } from "../../staticVariables/mobileColumns";
import DynamicModal from "../../components/modals/DynamicModal";
import ModalButtons from "../../components/modals/modalButtons/ModalButtons";

export const directionStatus = [
  {
    id: "Active",
    color: "green",
  },
  {
    id: "Inactive",
    color: "#dc3545",
  },
];

function Directions() {
  let rows = useSelector((store) => store?.directions?.directions);
  let rowStatus = useSelector((store) => store?.directions?.status);
  let actionStatus = useSelector((store) => store?.directions?.actionStatus);
  let exportExcelStatus = useSelector(
    (store) => store?.directions?.exportExcelStatus
  );
  let searchConfig = useSelector((store) => store.search);
  const count = useSelector((store) => store.directions.count);
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [showStatus, setShowStatus] = useState(false);
  const [showSelectedDelete, setShowSelectedDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [popupTitle, setPopupTitle] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [rowId, setRowId] = useState("");
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [noteValidate, setNoteValidate] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getDirectionsList(searchConfig);
  }, []);

  // Redirect to preview page on click the data of the table
  const redirectToPages = (event) => {
    if (event.field == "recovery_email" && event.row.recovery_email != null) {
      for (var value of rows) {
        if (value.email == event.formattedValue) {
          navigate(`/directions/${value.id}`);
        }
      }
    }
  };

  const redirectToPreview = useCallback((id) => {
    dispatch(
      getHashedIdAction(id, (err, data) => {
        if (data?.hash) {
          navigate(`/directions/${data?.hash}`);
        }
      })
    );
  }, []);

  const handleClose = () => {
    setShowDelete(false);
    setShow(false);
    setPopupTitle("");
    setFormData({});
    setShowStatus(false);
    setNoteValidate(true);
    setTimeout(() => {
      setDisabled(false);
    }, 400);
  };

  const handleDelete = (ev, params) => {
    ev.stopPropagation();
    setShowDelete(true);
    setRowId(params);
  };

  const handleConfirmDelete = (e, id) => {
    setDisabled(true);
    dispatch(
      deleteDirection(rowId?.id, (err, data) => {
        if (data) {
          toast.success("Successfully deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          if (rows && rows.length === 1 && searchConfig.page !== 1) {
            searchConfig.page = searchConfig.page - 1;
            dispatch(setPage(searchConfig.page));
          }
          setRowId(null);
          setShowDelete(false);
          getDirectionsList(searchConfig);
        }
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          handleClose();
        }
      })
    );
  };

  const handleAddConfirm = async (data) => {
    setDisabled(true);
    dispatch(
      addDirectionAction(data, (err, data) => {
        if (data) {
          toast.success("Successfully created.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          dispatch(getDirectionsAction(searchConfig));
          handleClose();
        } else if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        }
      })
    );
  };

  const handleShow = (title) => {
    setShow(true);
    setPopupTitle(title);
  };

  const handleSort = (model, page) => {
    if (model.length) {
      searchConfig.sort = model;
      dispatch(sortAction(model));
    } else {
      model = searchConfig.sort;
    }
    getDirectionsList(searchConfig);
  };

  const getDirectionsList = (searchConfig) => {
    dispatch(getDirectionsAction(searchConfig));
  };

  const exportExcel = (params) => {
    dispatch(exportExcelDirectionsAction(searchConfig));
  };

  const deleteSelectedRows = () => {
    setShowSelectedDelete(true);
  };
  const deleteSelectedRowsConfirm = (reason) => {
    dispatch(
      deleteSelectedDirections(selectedRows, (err, data) => {
        if (data) {
          setSelectedRows([]);
          getDirectionsAction(searchConfig);
          isEmpty(data)
            ? toast.success(`Successfully deleted.`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
              })
            : toast.success(
                `Successfully deleted. Some of selected directions can't be deleted.`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 5000,
                }
              );

          setShowSelectedDelete(false);
        }
      })
    );
  };

  const handleUpdateStatus = (params) => {
    setRowId(params);
    setShowStatus(true);
  };

  const handleConfirmUpdateStatus = () => {
    let status = rowId.status === "Active" ? "Inactive" : "Active";
    dispatch(
      changeDirectionStatusAction(rowId?.id, status, (err, data) => {
        if (data) {
          toast.success("Successfully updated.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          getDirectionsList(searchConfig);
        } else if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        }
        setShowStatus(false);
      })
    );
  };

  const columns = getColumns({
    handleUpdateStatus,
    rows,
    redirectToPreview,
    handleDelete,
    navigate,
  });

  return (
    <div className="admin_tables_block">
      <TableTitle
        handleShow={handleShow}
        title={"Directions"}
        addNew={true}
        exportExcel={exportExcel}
        batchAccess={"1103"}
        batchDelete={"1108"}
        selectedRows={selectedRows}
        deleteSelectedRows={deleteSelectedRows}
        exportExcelStatus={exportExcelStatus}
      />
      <SearchFilterBar
        getList={getDirectionsList}
        columns={columns}
        setShowFilter={setShowFilter}
        showFilter={showFilter}
      />
      <Table
        status={rowStatus}
        rows={rows || []}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        setSort={handleSort}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        checkboxSelection={false}
      />
      <MobileLayout
        rows={rows || []}
        rowStatus={rowStatus}
        columns={columns}
        setShow={setShow}
        setPopupTitle={setPopupTitle}
        redirectToPages={redirectToPages}
        changeStatusHandler={handleConfirmUpdateStatus}
        parentTitle={"Directions"}
        link="/directions"
        mobileCol={directions_mobile_column}
        status={directionStatus}
        search={getDirectionsList}
      />
      <DynamicModal
        show={show}
        hide={() => setShow(false)}
        title={"Add New Direction"}
        body={
          <DirectionsForm
            submit={handleAddConfirm}
            footer={
              <ModalButtons
                submit={handleAddConfirm}
                cancel={() => setShow(false)}
                isLoading={actionStatus === "pending"}
                saveDisabled={disabled}
              />
            }
          />
        }
      />
      <DeleteModal2
        show={showSelectedDelete}
        handleClose={() => setShowSelectedDelete(false)}
        confirm={() => deleteSelectedRowsConfirm()}
        content={"You are about to delete selected items. Are you sure?"}
        title={"Delete"}
        actionStatus={actionStatus}
      />
      <DeleteModal2
        show={showDelete}
        handleClose={() => handleClose()}
        confirm={(e) => handleConfirmDelete(e, rowId)}
        content={`You are about to delete the #${rowId?.id} direction. Are you sure?`}
        title={"Delete"}
        actionStatus={actionStatus}
      />
      <UpdateStatusModal
        show={showStatus}
        handleConfirmUpdate={handleConfirmUpdateStatus}
        handleClose={handleClose}
        text={`You're about to ${
          rowId?.status === "Active" ? "de-activate" : "activate"
        }  the '${rowId?.direction_name}' direction. Are you sure?`}
        title={"Update Direction"}
        actionStatus={actionStatus}
      />
      <Pagination
        rowStatus={rowStatus}
        count={count}
        limit={10}
        getList={getDirectionsList}
      />
    </div>
  );
}

export default Directions;
