import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_DIRECTIONS_REQUEST,
  GET_DIRECTIONS_FAIL,
  GET_DIRECTIONS_SUCCESS,
  ADD_DIRECTION_SUCCESS,
  ADD_DIRECTION_FAIL,
  ADD_DIRECTION_REQUEST,
  GET_SINGLE_DIRECTION_REQUEST,
  GET_SINGLE_DIRECTION_SUCCESS,
  GET_SINGLE_DIRECTION_FAIL,
  UPDATE_DIRECTION_SUCCESS,
  UPDATE_DIRECTION_FAIL,
  UPDATE_DIRECTION_REQUEST,
  STATUS_DIRECTION_SUCCESS,
  STATUS_DIRECTION_FAIL,
  STATUS_DIRECTION_REQUEST,
  DELETE_SELECTED_DIRECTIONS_FAIL,
  DELETE_DIRECTION_FAIL,
  DELETE_DIRECTION_REQUEST,
  DELETE_DIRECTION_SUCCESS,
  GET_ALL_DIRECTIONS_REQUEST,
  GET_ALL_DIRECTIONS_SUCCESS,
  GET_ALL_DIRECTIONS_FAIL,
  EXPORT_DIRECTIONS_REQUEST,
  EXPORT_DIRECTIONS_SUCCESS,
  EXPORT_DIRECTIONS_FAIL,
} from '../actions/directions';
import Directions from '../../api/directions';

export default function* watcher() {
  yield takeLatest(GET_DIRECTIONS_REQUEST, handleGetDirections);
  yield takeLatest(GET_ALL_DIRECTIONS_REQUEST, getAllDirections);
  yield takeLatest(DELETE_DIRECTION_REQUEST, deleteDirections);
  yield takeLatest(STATUS_DIRECTION_REQUEST, handleChangeStatus);
  yield takeLatest(ADD_DIRECTION_REQUEST, addDirection);
  yield takeLatest(GET_SINGLE_DIRECTION_REQUEST, getSingleDirection);
  yield takeLatest(UPDATE_DIRECTION_REQUEST, updateDirection);
  // yield takeLatest(DELETE_SELECTED_COMPANIES_REQUEST, deleteSelectedCompanies);
  yield takeLatest(EXPORT_DIRECTIONS_REQUEST, handleExportExcel);
}

function* addDirection(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Directions.addDirection, formData);
    yield put({
      type: ADD_DIRECTION_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_DIRECTION_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleGetDirections(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      Directions.getDirections,
      page,
      limit,
      search,
      filter,
      sort,
    );

    yield put({
      type: GET_DIRECTIONS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_DIRECTIONS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* getAllDirections(action) {
  try {
    const { data } = yield call(Directions.getAllDirections);
    yield put({
      type: GET_ALL_DIRECTIONS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_DIRECTIONS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* deleteDirections(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Directions.deleteDirection, id);
    yield put({
      type: DELETE_DIRECTION_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_DIRECTION_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* handleChangeStatus(action) {
  try {
    const { id, status } = action.payload;
    const { data } = yield call(Directions.changeDirectionStatus, id, status);
    yield put({
      type: STATUS_DIRECTION_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_DIRECTION_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

// function* deleteSelectedCompanies(action) {
//   try {
//     const { selectedRows } = action.payload;
//     const { data } = yield call(directions.deleteSelecteddirections, selectedRows);
//     yield put({
//       type: DELETE_SELECTED_COMPANIES_SUCCESS,
//       payload: {
//         data: data.company,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(null, data);
//     }
//   } catch (e) {
//     console.log(e);
//     yield put({
//       type: DELETE_SELECTED_COMPANIES_FAIL,
//       payload: {
//         massage: e.massage,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(e, null);
//     }
//   }
// }

function* getSingleDirection(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Directions.getSingleDirection, id);
    yield put({
      type: GET_SINGLE_DIRECTION_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_DIRECTION_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* updateDirection(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Directions.updateDirection, formData);
    yield put({
      type: UPDATE_DIRECTION_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_DIRECTION_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      Directions.handleExportDirectionsExcel,
      page,
      search,
      filter,
      sort,
    );

    yield put({
      type: EXPORT_DIRECTIONS_SUCCESS,
    });

    const blob = new Blob([data], {
      type: 'application/vnd.ms-excel',
    });
    saveAs(blob, `Directions.xlsx`);
  } catch (e) {
    yield put({
      type: EXPORT_DIRECTIONS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
