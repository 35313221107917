import { api } from './Api';
import axios from 'axios';
import Account from '../services/Account';
const { REACT_APP_API_URL } = process.env;

export default class Drivers {
  static addDrivers(formData) {
    return api.post(`/drivers/`, formData);
  }

  static getDrivers(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/drivers/`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static getAllDrivers() {
    return api.get(`/drivers/all`);
  }
  static deleteDrivers(id) {
    return api.delete(`/drivers/${id}`);
  }
  static changeDriverStatus(id) {
    return api.put(`/drivers/status/${id}`);
  }
  static updateDrivers(formData) {
    return api.put(`/drivers/${formData.id}`, formData);
  }
  static getSingleDrivers(id) {
    return api.get(`/drivers/single/${id}`);
  }

  static deleteSelectedDrivers(selectedRows) {
    return api.post(`/drivers/delete_selected_Drivers`, {
      selectedRows,
    });
  }

  static handleExportDriversExcel(page, search, filter, [{ sort, field }]) {
    return api({
      url: `/drivers/excel`,
      method: 'GET',
      responseType: 'blob',
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }
}
