import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

function UserDetails({ onSubmit, adminData, loadingStatus }) {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: adminData.name,
      user_name: adminData.user_name,
      email: adminData.email,
      terms: adminData.terms,
    },
  });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate className="sign_up">
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                id="name"
                name="name"
                placeholder="Full Name *"
                style={errors.name && { borderColor: "#EE204D" }}
                {...register("name", {
                  required: "Required Field",
                })}
              />
              {errors?.name?.message && (
                <span className="required-message">
                  {errors?.name?.message}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-2">
              <Form.Control
                type="email"
                id="email"
                name="email"
                placeholder="Email address *"
                style={errors.email && { borderColor: "#EE204D" }}
                {...register("email", {
                  required: "Required Field",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email format",
                  },
                })}
              />
              {errors.email && (
                <span className="required-message">
                  {errors?.email?.message}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Group className="mb-2">
              <Form.Check
                type="checkbox"
                id="terms"
                className={`terms_conditions ${errors.terms ? "required-terms" : ""}`}
                label={
                  <>
                    I have read and accepted the {"  "}
                    <a
                      href="https://intravelsync.com/terms-and-conditions/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link_terms_condition"
                    >
                      Terms and Conditions
                    </a>{" "}
                    *
                  </>
                }
                {...register("terms", {
                  required: true,
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="login-button-div">
          <LoadingButton
            size="big"
            className="login_submit_btn btn btn-primary login_button"
            loading={loadingStatus === "pending"}
            loadingPosition="start"
            variant="contained"
            type="submit"
            style={{
              textTransform: "capitalize",
            }}
          >
            <span>Next</span>
          </LoadingButton>
          <small className="login_text_p">
            Have an Account?
            <strong>
              <small className="login_btn_small">
                <Link to="/login"> Login Here</Link>
              </small>
            </strong>
          </small>
        </div>
      </Form>
    </>
  );
}

export default UserDetails;
