export const ADD_CUSTOM_SETTING_REQUEST = "ADD_CUSTOM_SETTING_REQUEST";
export const ADD_CUSTOM_SETTING_SUCCESS = "ADD_CUSTOM_SETTING_SUCCESS";
export const ADD_CUSTOM_SETTING_FAIL = "ADD_CUSTOM_SETTING_FAIL";

export function addCustomSettingAction(formData, cb) {
  return {
    type: ADD_CUSTOM_SETTING_REQUEST,
    payload: { formData, cb },
  };
}

export const EDIT_CUSTOM_SETTING_REQUEST = "EDIT_CUSTOM_SETTING_REQUEST";
export const EDIT_CUSTOM_SETTING_SUCCESS = "EDIT_CUSTOM_SETTING_SUCCESS";
export const EDIT_CUSTOM_SETTING_FAIL = "EDIT_CUSTOM_SETTING_FAIL";

export function editCustomSettingAction(formData, cb) {
  return {
    type: EDIT_CUSTOM_SETTING_REQUEST,
    payload: { formData, cb },
  };
}

export const GET_SINGLE_CUSTOM_SETTING_REQUEST =
  "GET_SINGLE_CUSTOM_SETTING_REQUEST";
export const GET_SINGLE_CUSTOM_SETTING_SUCCESS =
  "GET_SINGLE_CUSTOM_SETTING_SUCCESS";
export const GET_SINGLE_CUSTOM_SETTING_FAIL = "GET_SINGLE_CUSTOM_SETTING_FAIL";

export function getSingleCustomSettingAction(formData, cb) {
  return {
    type: GET_SINGLE_CUSTOM_SETTING_REQUEST,
    payload: { formData, cb },
  };
}

export const GET_CUSTOM_SETTINGS_LIST_REQUEST =
  "GET_CUSTOM_SETTINGS_LIST_REQUEST";
export const GET_CUSTOM_SETTINGS_LIST_SUCCESS =
  "GET_CUSTOM_SETTINGS_LIST_SUCCESS";
export const GET_CUSTOM_SETTINGS_LIST_FAIL = "GET_CUSTOM_SETTINGS_LIST_FAIL";

export function getCustomSettingsListAction(formData, cb) {
  return {
    type: GET_CUSTOM_SETTINGS_LIST_REQUEST,
    payload: { formData, cb },
  };
}

export const DELETE_CUSTOM_SETTING_REQUEST = "DELETE_CUSTOM_SETTING_REQUEST";
export const DELETE_CUSTOM_SETTING_SUCCESS = "DELETE_CUSTOM_SETTING_SUCCESS";
export const DELETE_CUSTOM_SETTING_FAIL = "DELETE_CUSTOM_SETTING_FAIL";

export function deleteCustomSettingAction(formData, cb) {
  return {
    type: DELETE_CUSTOM_SETTING_REQUEST,
    payload: { formData, cb },
  };
}

export const GET_DASHBOARD_INFO_REQUEST = "GET_DASHBOARD_INFO_REQUEST";
export const GET_DASHBOARD_INFO_SUCCESS = "GET_DASHBOARD_INFO_SUCCESS";
export const GET_DASHBOARD_INFO_FAIL = "GET_DASHBOARD_INFO_FAIL";

export function getDashboardInfo(years, cb) {
  return {
    type: GET_DASHBOARD_INFO_REQUEST,
    payload: { years, cb },
  };
}

export const GET_DASHBOARD_SELECTED_YEAR = "GET_DASHBOARD_SELECTED_YEAR";
export const GET_DASHBOARD_SELECTED_YEAR_FAIL =
  "GET_DASHBOARD_SELECTED_YEAR_FAIL";
export const GET_DASHBOARD_SELECTED_YEAR_SUCCESS =
  "GET_DASHBOARD_SELECTED_YEAR_SUCCESS";

export function getDashboardInfoByYear(model, year, cb) {
  return {
    type: GET_DASHBOARD_SELECTED_YEAR,
    payload: { model, year, cb },
  };
}

export const CLEAR_DASHBOARD_INFO = "CLEAR_DASHBOARD_INFO";

export function clearDashboardInfo() {
  return {
    type: {
      type: CLEAR_DASHBOARD_INFO,
    },
  };
}
