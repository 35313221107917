import {
  SET_SEARCH,
  SET_SORT,
  SET_FILTER,
  SET_PAGE,
  SET_DEFAULT_CONFIGS,
  CLEAR_SEARCH,
  DEL_FILTER,
  CLEAR_FILTER,
  SET_ROW_LIMIT,
  SET_DEFAULT_FILTER,
  ADD_SERVICE_DATE_FILTER,
  DEL_FILTER_ARR,
  ADD_FILTER,
} from '../actions/search';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { BLOCK_PAGE } from '../actions/users';

const initialState = {
  search: '',
  filter: {},
  sort: [
    {
      field: 'updatedAt',
      sort: 'desc',
    },
  ],
  defaultFilter: false,
  filterArr: [],
  page: 1,
  limit: 10,
  blockedPage: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload.search,
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.payload.page,
      };
    }
    case SET_ROW_LIMIT: {
      return {
        ...state,
        limit: action.payload.limit,
      };
    }
    case SET_SORT: {
      return {
        ...state,
        sort: action.payload.sort,
      };
    }

    case SET_FILTER: {
      return {
        ...state,
        filter: { ...action.payload.filter },
      };
    }
    case DEL_FILTER: {
      delete state.filter[action.payload.key];
      return {
        ...state,
      };
    }
    case DEL_FILTER_ARR: {
      return {
        ...state,
        filterArr: state.filterArr.filter(i => i.filterId != action.payload.id),
      };
    }
    case SET_DEFAULT_CONFIGS: {
      return {
        ...state,
        search: '',
        filter: {},
        sort: action.payload?.searchConfig?.sort || [
          {
            field: 'updatedAt',
            sort: 'desc',
          },
        ],
        defaultFilter: false,
      };
    }
    case CLEAR_SEARCH: {
      return {
        search: '',
        filter: {},
        sort: [
          {
            field: 'updatedAt',
            sort: 'desc',
          },
        ],
        page: 1,
        limit: 10,
        defaultFilter: false,
      };
    }
    case CLEAR_FILTER: {
      return {
        ...state,
        search: '',
        filter: {},
        filterArr: [],
        sort: [
          {
            field: 'updatedAt',
            sort: 'desc',
          },
        ],
      };
    }
    case SET_DEFAULT_FILTER: {
      return {
        ...state,
        defaultFilter: action.payload.defFilter,
      };
    }
    case ADD_SERVICE_DATE_FILTER: {
      action.payload.filterList.push({
        filterId: uuidv4(),
        service_date: {},
      });
      return {
        ...state,
        filterArr: action.payload.filterList,
      };
    }

    case ADD_FILTER: {
      action.payload.filterList.push({ filterId: uuidv4() });
      return {
        ...state,
        filterArr: action.payload.filterList,
      };
    }
    case BLOCK_PAGE: {
      return {
        ...state,
        blockedPage: action.payload.page,
      };
    }
    default: {
      return state;
    }
  }
}
