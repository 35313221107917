import React from "react";
import { Modal } from "react-bootstrap";

export default function DynamicModal({
  title,
  body,
  show,
  hide,
  hideCloseButton,
  size,
}) {
  return (
    <Modal
      size={size || "xl"}
      show={show}
      onHide={hide}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton={!hideCloseButton}>
        <Modal.Title>{title && title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body && body}</Modal.Body>
    </Modal>
  );
}
