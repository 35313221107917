import { GridActionsCellItem } from "@mui/x-data-grid";
import { MdDelete, MdEdit, MdMyLocation } from "react-icons/md";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import React from "react";
import moment from "moment";
import { TbTableExport } from "react-icons/tb";

export const getColumns = ({
  redirectToPreview,
  handleDelete,
  handleUpdateStatus,
  redirectToPreviewTransferVoucher,
  role,
  navigate,
  handleOpenDriverModal,
}) => {
  return [
    {
      field: "id",
      headerName: "#",
      width: 70,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "transfer_name",
      headerName: "Transfer Name",
      width: 200,
    },
    {
      field: "from_location",
      headerName: "From",
      width: 200,
    },
    {
      field: "to_location",
      headerName: "To",
      width: 200,
    },
    {
      field: "service_date",
      headerName: "Service Date",
      width: 200,
      renderCell: ({ row }) => {
        if (row?.service_date) {
          return moment(row.service_date).format("MM/DD/YYYY");
        }
      },
    },
    {
      field: "driverUser.full_name",
      headerName: "Driver",
      width: 200,
      renderCell: ({ row }) => {
        if (row?.driverUser?.full_name) {
          if (role === "Admin" || role === "Tour Manager") {
            return (
              <span
                onClick={() => redirectToPreview(row?.driverUser?.id, "users")}
                className="link-span-in-table"
              >
                {row?.driverUser?.full_name || ""}
              </span>
            );
          } else {
            return row?.driverUser?.full_name;
          }
        }
      },
    },
    {
      field: "pickUpPoint.pick_up_name",
      headerName: "Starting Point",
      width: 200,
      renderCell: ({ row }) => {
        if (row?.pickUpPoint?.pick_up_name) {
          if (role === "Admin") {
            return (
              <span
                onClick={() =>
                  redirectToPreview(row?.pickUpPoint?.id, "pick_up_points")
                }
                className="link-span-in-table"
              >
                {row?.pickUpPoint?.pick_up_name || ""}
              </span>
            );
          } else {
            return <span>{row?.pickUpPoint?.pick_up_name || ""}</span>;
          }
        }
      },
    },
    {
      field: "duration",
      headerName: "Duration (h)",
      width: 200,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "net_price",
      headerName: "Net Price (€)",
      width: 150,
    },
    // {
    //   access: ["Admin", "Supplier", "Tour Manager"],
    //   field: "currency.currency_name",
    //   headerName: "Currency",
    //   width: 80,
    //   renderCell: ({ row }) => {
    //     return row.currency ? (
    //       <span>
    //         {row.currency.currency_name + `(${row.currency.currency_symbol})`}
    //       </span>
    //     ) : (
    //       ""
    //     );
    //   },
    // },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
    },
    {
      field: "start_time",
      headerName: "Start Time",
      width: 200,
      renderCell: ({ row }) => {
        if (row?.start_time) {
          const formattedTime = moment(row.start_time, "HH:mm").format(
            "hh:mm A"
          );
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      field: "end_time",
      headerName: "End Time",
      width: 200,
      renderCell: ({ row }) => {
        if (row?.end_time) {
          const formattedTime = moment(row.end_time, "HH:mm").format("hh:mm A");
          return <span>{formattedTime}</span>;
        }
      },
    },
    {
      field: "emergency_number",
      headerName: "Emergency Number",
      width: 200,
      renderCell: ({ row }) => {
        return row.emergency_number
          ? `${
              row.emergency_number.startsWith("+")
                ? row.emergency_number
                : "+" + row.emergency_number
            }`
          : "";
      },
    },
    {
      field: "transferType.type",
      headerName: "Transfer Type",
      width: 200,
      renderCell: (params) => (
        <span>
          {params.row?.transferType ? params.row?.transferType?.type : ""}
        </span>
      ),
    },
    {
      field: "including_service_info",
      headerName: "Including Service",
      width: 200,
    },
    {
      field: "including_service_type",
      headerName: "Including Service Type",
      width: 200,
    },
    {
      field: "driver_location_url",
      headerName: "Driver Location Url",
      width: 200,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "adult_sum",
      headerName: "Adults",
      width: 80,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "child_sum",
      headerName: "Children",
      width: 80,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "infant_sum",
      headerName: "Infants",
      width: 80,
    },
    {
      access: ["Admin", "Supplier", "Tour Manager"],
      field: "total_passengers",
      headerName: "Total Passengers",
      width: 100,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.createdAt || params?.row.createdAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      field: "createdBy.full_name",
      headerName: "Created By",
      width: 150,
      renderCell: ({ row }) => row?.createdBy?.full_name || "",
    },
    {
      field: "updatedAt",
      headerName: "Modified Date",
      width: 200,
      renderCell: (params) =>
        moment(params?.updatedAt || params?.row.updatedAt).format(
          "MM/DD/YYYY HH:mm:ss"
        ),
    },
    {
      field: "updatedBy.full_name",
      headerName: "Modified By",
      width: 150,
      renderCell: ({ row }) => row?.updatedBy?.full_name || "",
    },
    {
      headerName: "Actions",
      field: "actions",
      cellClassName: "actions",
      type: "actions",
      access: ["Admin", "Supplier", "Tour Manager"],
      width: 200,
      getActions: (params) =>
        [
          <GridActionsCellItem
            onClick={() =>
              redirectToPreviewTransferVoucher(
                params?.row?.id || params?.id,
                "transfer"
              )
            }
            className="test1"
            icon={<TbTableExport size={21} style={{ fill: "none" }} />}
            label="Edit"
            title="Transfers"
            access={["Admin", "Driver", "Tour Manager"]}
            width={50}
          />,
          // <GridActionsCellItem
          //   onClick={() =>
          //     handleEditPickUpPoint(
          //       params?.row?.pick_up_point_id,
          //       params?.row?.id,
          //     )
          //   }
          //   icon={<MdOutlineEditLocation size={19} />}
          //   label="Edit Pick-Up Point"
          //   title="Edit Pick-Up Point"
          //   className="test1"
          //   access={['Supplier']}
          // />,
          <GridActionsCellItem
            onClick={() =>
              redirectToPreview(params?.row?.id || params?.id, "transfers")
            }
            className="test1"
            icon={<MdEdit />}
            label="Edit"
            title="View/Edit"
            access={["Admin", "Tour Manager"]}
            width={50}
          />,
          <GridActionsCellItem
            onClick={() => handleOpenDriverModal(params?.row)}
            icon={<MdMyLocation size={19} />}
            label="Edit Driver Location Url"
            title="Edit Driver Location Url"
            className="test1"
            access={["Driver"]}
          />,
          <GridActionsCellItem
            style={
              // params.status || params?.row.status?
              {
                cursor: "pointer",
              }
              // : {
              //     cursor: 'auto',
              //     opacity: 0.4,
              //   }
            }
            onClick={(ev) => handleUpdateStatus(params?.row || params)}
            icon={
              params?.status === "Active" ||
              params?.row?.status === "Active" ? (
                <FaToggleOn />
              ) : (
                <FaToggleOff />
              )
            }
            label="Status"
            title="Change Status"
            access={["Admin", "Tour Manager"]}
            width={50}
          />,
          <GridActionsCellItem
            icon={<MdDelete />}
            label="Delete"
            title="Delete"
            onClick={(ev) => handleDelete(ev, params?.row || params)}
            access={["Admin", "Tour Manager"]}
            width={50}
          />,
        ].filter(({ props }) => {
          return props.access.includes(role);
        }),
    },
  ];
};
