import {
  GET_TOUR_VOUCHERS_SUCCESS,
  GET_TOUR_VOUCHERS_REQUEST,
  GET_SINGLE_TOUR_VOUCHER_REQUEST,
  GET_SINGLE_TOUR_VOUCHER_SUCCESS,
  UPDATE_TOUR_VOUCHER_REQUEST,
  UPDATE_TOUR_VOUCHER_SUCCESS,
  GET_ALL_TOUR_VOUCHERS_SUCCESS,
  GET_ALL_TOUR_VOUCHERS_REQUEST,
  GET_ALL_TOUR_VOUCHERS_FAIL,
  CHECK_VOUCHER_REQUEST,
  CHECK_VOUCHER_SUCCESS,
  CHECK_VOUCHER_FAIL,
  DOWNLOAD_VOUCHER_REQUEST,
  DOWNLOAD_VOUCHER_SUCCESS,
  DOWNLOAD_VOUCHER_FAIL,
  FETCH_TOUR_VOUCHER_REQUEST,
  FETCH_TOUR_VOUCHER_SUCCESS,
  FETCH_TOUR_VOUCHER_FAIL,
  GET_TOUR_PREVIEW_VOUCHERS_SUCCESS,
  GET_TOUR_PREVIEW_VOUCHERS_REQUEST,
  SHARE_MY_LOCATION_REQUEST,
  SHARE_MY_LOCATION_SUCCESS,
  SHARE_MY_LOCATION_FAIL,
  EXPORT_TOUR_VOUCHERS_REQUEST,
  EXPORT_TOUR_VOUCHERS_SUCCESS,
  EXPORT_TOUR_VOUCHERS_FAIL,
  ADD_TOUR_VOUCHER_REQUEST,
  ADD_TOUR_VOUCHER_SUCCESS,
  ADD_TOUR_VOUCHER_FAIL,
  DELETE_TOUR_VOUCHER_REQUEST,
  DELETE_TOUR_VOUCHER_SUCCESS,
  DELETE_TOUR_VOUCHER_FAIL,
  UPDATE_TOUR_VOUCHER_FAIL,
  ADD_COMMENT_TOUR_VOUCHER_REQUEST,
  ADD_COMMENT_TOUR_VOUCHER_SUCCESS,
  ADD_COMMENT_TOUR_VOUCHER_FAIL,
  COPY_TOUR_VOUCHER_REQUEST,
  COPY_TOUR_VOUCHER_SUCCESS,
  COPY_TOUR_VOUCHER_FAIL,
  CHANGE_FETCH_PERCENT,
  DOWNLOAD_TOUR_VOUCHERS_REPORT_REQUEST,
  DOWNLOAD_TOUR_VOUCHERS_REPORT_SUCCESS,
  DOWNLOAD_TOUR_VOUCHERS_REPORT_FAIL,
  DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_REQUEST,
  DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_SUCCESS,
  DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_FAIL,
  GET_TOUR_VOUCHERS_FOR_KPI_REPORT_REQUEST,
  GET_TOUR_VOUCHERS_FOR_KPI_REPORT_SUCCESS,
  GET_TOUR_VOUCHERS_FOR_KPI_REPORT_FAIL,
  CHECK_PREVIEW_VOUCHER_REQUEST,
  CHECK_PREVIEW_VOUCHER_SUCCESS,
  CHECK_PREVIEW_VOUCHER_FAIL,
} from "../actions/tourVouchers";

const initialState = {
  tourVouchers: [],
  singleTourVoucher: {},
  searchTourVoucher: {},
  downloadStatus: "",
  downloadStatusId: null,
  status: "",
  fetchStatus: "",
  fetchingPercent: null,
  shareLocationStatus: "",
  tableTitle: "",
  exportExcelStatus: "",
  vouchersWithLocation: [],
  coordinates: {},
  actionStatus: "",
  downloadReportStatus: ""
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TOUR_VOUCHERS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_TOUR_PREVIEW_VOUCHERS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_TOUR_VOUCHERS_SUCCESS: {
      return {
        ...state,
        status: "success",
        tourVouchers: action?.payload?.data?.rows,
        count: action?.payload?.data?.count,
        fetchStatus: "success",
        fetchingPercent: null,
      };
    }
    case GET_TOUR_PREVIEW_VOUCHERS_SUCCESS: {
      return {
        ...state,
        status: "success",
        tourVouchers: action?.payload?.data?.result,
        count: action?.payload?.data?.count,
        tableTitle: action?.payload?.data?.tour_name,
        coordinates: action?.payload?.data?.coordinates,
        vouchersWithLocation: action?.payload?.data?.vouchersWithLocation,
      };
    }
    case GET_SINGLE_TOUR_VOUCHER_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_TOUR_VOUCHER_SUCCESS: {
      return {
        ...state,
        singleTourVoucher: action.payload.data,
        status: "success",
      };
    }
    case EXPORT_TOUR_VOUCHERS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: "pending",
      };
    }
    case EXPORT_TOUR_VOUCHERS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: "success",
      };
    }
    case EXPORT_TOUR_VOUCHERS_FAIL: {
      return {
        ...state,
        exportExcelStatus: "fail",
      };
    }
    case UPDATE_TOUR_VOUCHER_REQUEST: {
      return {
        ...state,
        status: "pending",
        actionStatus: "pending",
      };
    }
    case UPDATE_TOUR_VOUCHER_SUCCESS: {
      return {
        ...state,
        status: "success",
        actionStatus: "success",
      };
    }
    case UPDATE_TOUR_VOUCHER_FAIL: {
      return {
        ...state,
        status: "fail",
        actionStatus: "fail",
      };
    }
    case COPY_TOUR_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case COPY_TOUR_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case COPY_TOUR_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }
    case GET_ALL_TOUR_VOUCHERS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_ALL_TOUR_VOUCHERS_SUCCESS: {
      return {
        ...state,
        allTourVouchers: action?.payload?.data?.result,
        status: "success",
      };
    }
    case GET_ALL_TOUR_VOUCHERS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }
    case CHECK_VOUCHER_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case CHECK_VOUCHER_SUCCESS: {
      return {
        ...state,
        status: "success",
        searchTourVoucher: action?.payload?.data?.result,
      };
    }
    case CHECK_VOUCHER_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case CHECK_PREVIEW_VOUCHER_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case CHECK_PREVIEW_VOUCHER_SUCCESS: {
      return {
        ...state,
        status: "success",
        searchTourVoucher: action?.payload?.data?.result,
      };
    }
    case CHECK_PREVIEW_VOUCHER_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case DOWNLOAD_VOUCHER_REQUEST: {
      return {
        ...state,
        downloadStatus: "pending",
        downloadStatusId: action.payload.id,
      };
    }
    case DOWNLOAD_VOUCHER_SUCCESS: {
      return {
        ...state,
        downloadStatus: "success",
      };
    }
    case DOWNLOAD_VOUCHER_FAIL: {
      return {
        ...state,
        downloadStatus: "fail",
      };
    }
    case FETCH_TOUR_VOUCHER_REQUEST: {
      return {
        ...state,
        // tourVouchers: [],
        status: "pending",
        fetchStatus: "pending",
      };
    }
    case FETCH_TOUR_VOUCHER_SUCCESS: {
      return {
        ...state,
        status: "success",
        fetchStatus: "success",
        fetchingPercent: null,
      };
    }
    case FETCH_TOUR_VOUCHER_FAIL: {
      return {
        ...state,
        status: "fail",
        fetchStatus: "fail",
        fetchingPercent: null,
      };
    }
    case SHARE_MY_LOCATION_REQUEST: {
      return {
        ...state,
        shareLocationStatus: "pending",
      };
    }
    case SHARE_MY_LOCATION_SUCCESS: {
      return {
        ...state,
        shareLocationStatus: "success",
      };
    }
    case SHARE_MY_LOCATION_FAIL: {
      return {
        ...state,
        shareLocationStatus: "fail",
      };
    }
    case ADD_TOUR_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_TOUR_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_TOUR_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_TOUR_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_TOUR_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_TOUR_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case ADD_COMMENT_TOUR_VOUCHER_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_COMMENT_TOUR_VOUCHER_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_COMMENT_TOUR_VOUCHER_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case CHANGE_FETCH_PERCENT: {
      return {
        ...state,
        fetchingPercent: action.payload.percent,
        status: "pending",
        fetchStatus: "pending",
      };
    }
    case DOWNLOAD_TOUR_VOUCHERS_REPORT_REQUEST: {
      return {
        ...state,
        downloadReportStatus: 'pending',
      };
    }
    case DOWNLOAD_TOUR_VOUCHERS_REPORT_SUCCESS: {
      return {
        ...state,
        downloadReportStatus: 'success',
      };
    }
    case DOWNLOAD_TOUR_VOUCHERS_REPORT_FAIL: {
      return {
        ...state,
        downloadReportStatus: 'fail',
      };
    }
    case GET_TOUR_VOUCHERS_FOR_KPI_REPORT_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_TOUR_VOUCHERS_FOR_KPI_REPORT_SUCCESS: {
      return {
        ...state,
        status: 'success',
      };
    }
    case GET_TOUR_VOUCHERS_FOR_KPI_REPORT_FAIL: {
      return {
        ...state,
        status: 'fail',
      };
    }
    case DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_REQUEST: {
      return {
        ...state,
        downloadReportStatus: 'pending',
      };
    }
    case DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_SUCCESS: {
      return {
        ...state,
        downloadReportStatus: 'success',
      };
    }
    case DOWNLOAD_TOUR_VOUCHERS_KPI_REPORT_FAIL: {
      return {
        ...state,
        downloadReportStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
