export const GET_TERMS_AND_CONDITIONS_REQUEST =
  'GET_TERMS_AND_CONDITIONS_REQUEST';
export const GET_TERMS_AND_CONDITIONS_SUCCESS =
  'GET_TERMS_AND_CONDITIONS_SUCCESS';
export const GET_TERMS_AND_CONDITIONS_FAIL = 'GET_TERMS_AND_CONDITIONS_FAIL';

export function getTermsConditionsAction(searchConfig) {
  return {
    type: GET_TERMS_AND_CONDITIONS_REQUEST,
    payload: {
      searchConfig,
    },
  };
}

// export const GET_ALL_TERMS_AND_CONDITIONS_REQUEST =
//   'GET_ALL_TERMS_AND_CONDITIONS_REQUEST';
// export const GET_ALL_TERMS_AND_CONDITIONS_SUCCESS =
//   'GET_ALL_TERMS_AND_CONDITIONS_SUCCESS';
// export const GET_ALL_TERMS_AND_CONDITIONS_FAIL =
//   'GET_ALL_TERMS_AND_CONDITIONS_FAIL';

// export function getAllTransferTypesAction(cb) {
//   return {
//     type: GET_ALL_TERMS_AND_CONDITIONS_REQUEST,
//     payload: {
//       cb,
//     },
//   };
// }

export const ADD_TERMS_AND_CONDITION_REQUEST =
  'ADD_TERMS_AND_CONDITION_REQUEST';
export const ADD_TERMS_AND_CONDITION_SUCCESS =
  'ADD_TERMS_AND_CONDITION_SUCCESS';
export const ADD_TERMS_AND_CONDITION_FAIL = 'ADD_TERMS_AND_CONDITION_FAIL';

export function addTermsConditionAction(formData, cb) {
  return {
    type: ADD_TERMS_AND_CONDITION_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_TERMS_AND_CONDITION_REQUEST =
  'DELETE_TERMS_AND_CONDITION_REQUEST';
export const DELETE_TERMS_AND_CONDITION_SUCCESS =
  'DELETE_TERMS_AND_CONDITION_SUCCESS';
export const DELETE_TERMS_AND_CONDITION_FAIL =
  'DELETE_TERMS_AND_CONDITION_FAIL';

export function deleteTermsConditionAction(id, cb) {
  return {
    type: DELETE_TERMS_AND_CONDITION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const UPDATE_TERMS_AND_CONDITION_REQUEST =
  'UPDATE_TERMS_AND_CONDITION_REQUEST';
export const UPDATE_TERMS_AND_CONDITION_SUCCESS =
  'UPDATE_TERMS_AND_CONDITION_SUCCESS';
export const UPDATE_TERMS_AND_CONDITION_FAIL =
  'UPDATE_TERMS_AND_CONDITION_FAIL';

export function updateTermsConditionAction(formData, cb) {
  return {
    type: UPDATE_TERMS_AND_CONDITION_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_TERMS_AND_CONDITION_REQUEST =
  'GET_SINGLE_TERMS_AND_CONDITION_REQUEST';
export const GET_SINGLE_TERMS_AND_CONDITION_SUCCESS =
  'GET_SINGLE_TERMS_AND_CONDITION_SUCCESS';
export const GET_SINGLE_TERMS_AND_CONDITION_FAIL =
  'GET_SINGLE_TERMS_AND_CONDITION_FAIL';

export function getSingleTermsConditionsAction(id, cb) {
  return {
    type: GET_SINGLE_TERMS_AND_CONDITION_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const GET_ALL_TERMS_AND_CONDITIONS_REQUEST =
  'GET_ALL_TERMS_AND_CONDITIONS_REQUEST';
export const GET_ALL_TERMS_AND_CONDITIONS_SUCCESS =
  'GET_ALL_TERMS_AND_CONDITIONS_SUCCESS';
export const GET_ALL_TERMS_AND_CONDITIONS_FAIL =
  'GET_ALL_TERMS_AND_CONDITIONS_FAIL';

export function getAllTermsConditionsAction(cb) {
  return {
    type: GET_ALL_TERMS_AND_CONDITIONS_REQUEST,
    payload: {
      cb,
    },
  };
}
