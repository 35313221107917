import { takeLatest, call, put } from "redux-saga/effects";
import {
  ADD_WEB_APARTMENT_REQUEST,
  ADD_WEB_APARTMENT_SUCCESS,
  ADD_WEB_APARTMENT_FAIL,
  UPDATE_WEB_APARTMENT_REQUEST,
  UPDATE_WEB_APARTMENT_SUCCESS,
  UPDATE_WEB_APARTMENT_FAIL,
  DELETE_WEB_APARTMENT_REQUEST,
  DELETE_WEB_APARTMENT_SUCCESS,
  DELETE_WEB_APARTMENT_FAIL,
  GET_SINGLE_WEB_APARTMENT_REQUEST,
  GET_SINGLE_WEB_APARTMENT_SUCCESS,
  GET_SINGLE_WEB_APARTMENT_FAIL,
  GET_WEB_APARTMENTS_REQUEST,
  GET_WEB_APARTMENTS_SUCCESS,
  GET_WEB_APARTMENTS_FAIL,
  STATUS_WEB_APARTMENT_REQUEST,
  STATUS_WEB_APARTMENT_SUCCESS,
  STATUS_WEB_APARTMENT_FAIL,
  EXPORT_WEB_APARTMENTS_REQUEST,
  EXPORT_WEB_APARTMENTS_SUCCESS,
  EXPORT_WEB_APARTMENTS_FAIL,
  GET_WEB_APARTMENTS_GALLERY_REQUEST,
  GET_WEB_APARTMENTS_GALLERY_SUCCESS,
  GET_WEB_APARTMENTS_GALLERY_FAIL,
  UPDATE_WEB_APARTMENTS_GALLERY_REQUEST,
  UPDATE_WEB_APARTMENTS_GALLERY_SUCCESS,
  UPDATE_WEB_APARTMENTS_GALLERY_FAIL,
  GET_WEB_APARTMENT_ROOM_REQUEST,
  GET_WEB_APARTMENT_ROOM_SUCCESS,
  GET_WEB_APARTMENT_ROOM_FAIL,
  ADD_WEB_APARTMENT_ROOM_REQUEST,
  ADD_WEB_APARTMENT_ROOM_SUCCESS,
  ADD_WEB_APARTMENT_ROOM_FAIL,
  GET_SINGLE_WEB_APARTMENT_ROOM_REQUEST,
  GET_SINGLE_WEB_APARTMENT_ROOM_SUCCESS,
  GET_SINGLE_WEB_APARTMENT_ROOM_FAIL,
  STATUS_WEB_APARTMENT_ROOM_REQUEST,
  STATUS_WEB_APARTMENT_ROOM_SUCCESS,
  STATUS_WEB_APARTMENT_ROOM_FAIL,
  UPDATE_WEB_APARTMENT_ROOM_REQUEST,
  UPDATE_WEB_APARTMENT_ROOM_SUCCESS,
  UPDATE_WEB_APARTMENT_ROOM_FAIL,
  DELETE_WEB_APARTMENT_ROOM_REQUEST,
  DELETE_WEB_APARTMENT_ROOM_SUCCESS,
  DELETE_WEB_APARTMENT_ROOM_FAIL,
  GET_WEB_APARTMENT_ROOM_GALLERY_REQUEST,
  GET_WEB_APARTMENT_ROOM_GALLERY_SUCCESS,
  GET_WEB_APARTMENT_ROOM_GALLERY_FAIL,
  UPDATE_WEB_APARTMENT_ROOM_GALLERY_REQUEST,
  UPDATE_WEB_APARTMENT_ROOM_GALLERY_SUCCESS,
  UPDATE_WEB_APARTMENT_ROOM_GALLERY_FAIL,
} from "../actions/websiteApartments";
import WebApartments from "../../api/website_management/websiteApartments";

export default function* watcher() {
  yield takeLatest(ADD_WEB_APARTMENT_REQUEST, addApartmentsAction);
  yield takeLatest(UPDATE_WEB_APARTMENT_REQUEST, updateSuitesAction);
  yield takeLatest(DELETE_WEB_APARTMENT_REQUEST, deleteSuitesAction);
  yield takeLatest(GET_SINGLE_WEB_APARTMENT_REQUEST, getSingleSuitesAction);
  yield takeLatest(GET_WEB_APARTMENTS_REQUEST, handleGetSuitesAction);
  yield takeLatest(STATUS_WEB_APARTMENT_REQUEST, handleChangeStatusAction);
  yield takeLatest(EXPORT_WEB_APARTMENTS_REQUEST, handleExportExcelAction);
  yield takeLatest(
    GET_WEB_APARTMENTS_GALLERY_REQUEST,
    handleGetApartmentsGalleryAction
  );
  yield takeLatest(
    UPDATE_WEB_APARTMENTS_GALLERY_REQUEST,
    handleUpdateApartmentsGalleryAction
  );
  //Apartment Rooms
  yield takeLatest(
    GET_WEB_APARTMENT_ROOM_REQUEST,
    handleGetApartmentRoomAction
  );
  yield takeLatest(ADD_WEB_APARTMENT_ROOM_REQUEST, addApartmentRoomAction);
  yield takeLatest(
    GET_SINGLE_WEB_APARTMENT_ROOM_REQUEST,
    getSingleApartmentRoomAction
  );
  yield takeLatest(
    STATUS_WEB_APARTMENT_ROOM_REQUEST,
    handleChangeRoomStatusAction
  );
  yield takeLatest(
    UPDATE_WEB_APARTMENT_ROOM_REQUEST,
    updateApartmentRoomAction
  );
  yield takeLatest(
    DELETE_WEB_APARTMENT_ROOM_REQUEST,
    deleteApartmentRoomAction
  );
  yield takeLatest(
    GET_WEB_APARTMENT_ROOM_GALLERY_REQUEST,
    handleGetApartmentRoomGalleryAction
  );
  yield takeLatest(
    UPDATE_WEB_APARTMENT_ROOM_GALLERY_REQUEST,
    handleUpdateApartmentRoomGalleryAction
  );
}

function* addApartmentsAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebApartments.addApartments, formData);
    yield put({
      type: ADD_WEB_APARTMENT_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_WEB_APARTMENT_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* updateSuitesAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebApartments.updateApartments, formData);
    yield put({
      type: UPDATE_WEB_APARTMENT_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_APARTMENT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* deleteSuitesAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebApartments.deleteApartments, id);
    yield put({
      type: DELETE_WEB_APARTMENT_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_WEB_APARTMENT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* getSingleSuitesAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebApartments.getSingleApartments, id);
    yield put({
      type: GET_SINGLE_WEB_APARTMENT_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_WEB_APARTMENT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetSuitesAction(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;

    const { data } = yield call(
      WebApartments.getApartments,
      page,
      limit,
      search,
      filter,
      sort
    );

    yield put({
      type: GET_WEB_APARTMENTS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_APARTMENTS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleChangeStatusAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebApartments.changeApartmentsStatus, id);
    yield put({
      type: STATUS_WEB_APARTMENT_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_WEB_APARTMENT_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleExportExcelAction(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      WebApartments.handleExportApartmentsExcel,
      page,
      search,
      filter,
      sort
    );
    yield put({
      type: EXPORT_WEB_APARTMENTS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, `Website_Apartments.xlsx`);
  } catch (e) {
    yield put({
      type: EXPORT_WEB_APARTMENTS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleGetApartmentsGalleryAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebApartments.getGallery, id);
    yield put({
      type: GET_WEB_APARTMENTS_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_APARTMENTS_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateApartmentsGalleryAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebApartments.updateGallery, id, formData);
    yield put({
      type: UPDATE_WEB_APARTMENTS_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_APARTMENTS_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

//Apartment Rooms

function* handleGetApartmentRoomAction(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { id } = action.payload;

    const { data } = yield call(
      WebApartments.getApartmentRooms,
      id,
      page,
      limit,
      search,
      filter,
      sort
    );
    yield put({
      type: GET_WEB_APARTMENT_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_APARTMENT_ROOM_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* addApartmentRoomAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebApartments.addApartmentRoom, id, formData);
    yield put({
      type: ADD_WEB_APARTMENT_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_WEB_APARTMENT_ROOM_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* getSingleApartmentRoomAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebApartments.getSingleApartmentRoom, id);
    yield put({
      type: GET_SINGLE_WEB_APARTMENT_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_SINGLE_WEB_APARTMENT_ROOM_FAIL,
      payload: {
        massage: e,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* handleChangeRoomStatusAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebApartments.changeApartmentRoomStatus, id);
    yield put({
      type: STATUS_WEB_APARTMENT_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: STATUS_WEB_APARTMENT_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* updateApartmentRoomAction(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(WebApartments.updateApartmentRoom, formData);
    yield put({
      type: UPDATE_WEB_APARTMENT_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_APARTMENT_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data?.message, null);
    }
  }
}

function* deleteApartmentRoomAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebApartments.deleteApartmentRoom, id);
    yield put({
      type: DELETE_WEB_APARTMENT_ROOM_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_WEB_APARTMENT_ROOM_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* handleGetApartmentRoomGalleryAction(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(WebApartments.getRoomGallery, id);
    yield put({
      type: GET_WEB_APARTMENT_ROOM_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_WEB_APARTMENT_ROOM_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleUpdateApartmentRoomGalleryAction(action) {
  try {
    const { id, formData } = action.payload;
    const { data } = yield call(WebApartments.updateRoomGallery, id, formData);
    yield put({
      type: UPDATE_WEB_APARTMENT_ROOM_GALLERY_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_WEB_APARTMENT_ROOM_GALLERY_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}
