import { takeLatest, call, put } from "redux-saga/effects";
import RoomReservations from "../../api/website_management/roomReservations";
import {
  GET_ROOM_RESERVATIONS_FAIL,
  GET_ROOM_RESERVATIONS_REQUEST,
  GET_ROOM_RESERVATIONS_SUCCESS,
} from "../actions/roomReservations";

export default function* watcher() {
  yield takeLatest(GET_ROOM_RESERVATIONS_REQUEST, getRoomReservations);
}

function* getRoomReservations(action) {
  try {
    const { params } = action.payload;
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      RoomReservations.getRoomReservations,
      page,
      limit,
      search,
      filter,
      sort,
      params
    );

    yield put({
      type: GET_ROOM_RESERVATIONS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ROOM_RESERVATIONS_FAIL,
      payload: {
        massage: e.massage,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

// function* addAdvertisement(action) {
//   try {
//     const { formData } = action.payload;
//     const { data } = yield call(Advertisements.addAdvertisement, formData);
//     yield put({
//       type: ADD_ADVERTISEMENT_SUCCESS,
//       payload: {
//         data: data.result,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(null, data);
//     }
//   } catch (e) {
//     console.log(e);
//     yield put({
//       type: ADD_ADVERTISEMENT_FAIL,
//       payload: {
//         massage: e.massage,
//       },
//     });
//     if (action.payload.cb) {
//       action.payload.cb(e, null);
//     }
//   }
// }
