import { api } from "./Api";
import { isEmpty } from "lodash";

export default class TourVouchers {
  static addTransferVoucher(formData) {
    return api.post(`/transfer_vouchers`, { ...formData });
  }
  static getTransferVouchers(page, limit, search, filter, [{ sort, field }]) {
    if (!isEmpty(filter && filter.status)) {
      filter.state = filter.status;
      delete filter.status;
    }
    return api.get(`/transfer_vouchers`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static getPreviewTransferVoucher(
    id,
    page,
    limit,
    search,
    filter,
    [{ sort, field }]
  ) {
    if (!isEmpty(filter && filter.status)) {
      filter.state = filter.status;
      delete filter.status;
    }
    return api.get(`transfer_vouchers/transfer/${id}`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }

  static deleteTransferVoucher(id) {
    return api.delete(`/transfer_vouchers/${id}`);
  }
  static updateTransferVoucher(formData) {
    return api.put(`/transfer_vouchers/${formData?.id}`, { ...formData });
  }
  static updateTransferVoucherState(formData) {
    return api.put(`/transfer_vouchers/state/${formData?.id}`, { ...formData });
  }
  static getSingleTransferVoucher(id) {
    return api.get(`/transfer_vouchers/single/${id}`);
  }
  static getTourVoucherLoc(id) {
    return api.get(`/locations/last/${id}`);
  }
  static getAllTourVouchers() {
    return api.get(`/tour_vouchers/all`);
  }
  static changeTransferVoucherStatus(id) {
    return api.put(`/transfer_vouchers/status/${id}`);
  }
  static addComment(id, comment) {
    return api.put(`/transfer_vouchers/comment/${id}`, { comment });
  }
  static copy(id) {
    return api.post(`/transfer_vouchers/copy/${id}`);
  }
  static sendTransferVoucherEmail(id, formData) {
    return api.post(`/transfer_vouchers/send_email/${id}`, formData);
  }

  static sendTransferVoucherEmailFromWeb(id, hash, formData) {
    return api.post(`/transfer_vouchers/web_send_email/${id}`, formData, {
      params: {
        hash,
      },
    });
  }

  static handleExportExcel(page, search, filter, [{ sort, field }]) {
    let service_date = {};
    if (filter.service_date) {
      if (filter.service_date["$gte"]) {
        service_date.startDate = filter.service_date["$gte"];
      }
      if (filter.service_date["$lte"]) {
        service_date.endDate = filter.service_date["$lte"];
      }
    }
    return api({
      url: `/transfer_vouchers/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        search,
        service_date,
        page,
        sort,
        field,
      },
    });
  }
  static checkTransferVoucher({ reference_number }) {
    return api.get(`/transfer_vouchers/check/t`, {
      params: {
        hash: reference_number,
      },
    });
  }
  static checkPreviewTransferVoucher(ref_num) {
    return api.get(`/transfer_vouchers/check/preview_transfer_voucher/`, {
      params: {
        reference_number: ref_num,
      },
    });
  }

  static downloadTransferVoucher(data) {
    return api.get(
      `/transfer_vouchers/download_pdf/${data.id}?role=${data.role}`,
      {
        params: {
          hash: data.hash || "",
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
      }
    );
  }

  static fetchTourVouchers() {
    return api.get(`/tour_vouchers/bokun`);
  }
  static shareMyLocation(location) {
    return api.put(`/transfer_vouchers/location/update`, location);
  }
  static getTransferVoucherWebLink(id) {
    return api.get(`/transfer_vouchers/generate/weblink/${id}`);
  }
  static handleDownloadTransferVouchersReport(
    user_id,
    group_field,
    service_date_from,
    service_date_to
  ) {
    let service_date;
    if (service_date_from && service_date_to) {
      service_date = { $gte: service_date_from, $lte: service_date_to };
    } else if (service_date_from && !service_date_to) {
      service_date = { $gte: service_date_from };
    } else if (!service_date_from && service_date_to) {
      service_date = { $lte: service_date_to };
    }
    return api({
      url: `/transfer_vouchers/users/report`,
      method: "GET",
      responseType: "blob",
      params: {
        group_field: group_field && group_field?.id,
        service_date,
        user_id:
          user_id === "all" || user_id === "none" ? user_id : user_id?.id,
      },
    });
  }

  static handleGetTransferVouchersForKPIReport() {
    return api.get("/transfer_vouchers/kpi/report/years");
  }

  static handleDownloadTransferVouchersKPIReport(user_id, group_field, year) {
    return api({
      url: `/transfer_vouchers/kpi/report`,
      method: "GET",
      responseType: "blob",
      params: {
        year: year?.value,
        criteria: group_field && group_field?.id,
        userId: user_id === "all" || user_id === "none" ? user_id : user_id?.id,
      },
    });
  }
}
