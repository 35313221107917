import { api } from "./Api";

export default class CustomSettings {
  static addCustomSetting(formData) {
    return api.post(`/custom_settings`, { ...formData });
  }
  static getCustomSettings(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/custom_settings`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static deleteSetting(id) {
    return api.delete(`/custom_settings/${id}`);
  }
  static updateTransferType(formData) {
    return api.put(`/custom_settings/${formData?.id}`, { ...formData });
  }
  static getSingleSetting(id) {
    return api.get(`/custom_settings/single/${id}`);
  }
  static deleteSelectedTransferTypes(selectedRows) {
    return api.post(`/custom_settings/delete_selected_custom_settings`, {
      selectedRows,
    });
  }
  static getAllTransferTypes() {
    return api.get(`/custom_settings/all`);
  }
  static getDashboardInfo(years) {
    return api.get(`/users/dashboard`, { params: years });
  }
  static getDashboardInfoYear(year, model) {
    return api.get(`/users/dashboard/${model}/${year}`);
  }
  static updateStatus(id) {
    return api.put(`/custom_settings/status/${id}`);
  }

  static getTransferSettingValue(formData) {
    return api.get(
      `/custom_settings/transfers/${formData.transfer_type}/${formData.including_service_type}`
    );
  }

  static handleExportTransferTypesExcel(
    page,
    search,
    filter,
    [{ sort, field }]
  ) {
    return api({
      url: `/custom_settings/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }
}
