import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Page404 from "./pages/Page404";
import { LicenseInfo } from "@mui/x-license-pro";
import { pages } from "./pagesData/pages";
import Wrapper from "./components/wrapper/Wrapper";
import WrapperLogOut from "./components/WrapperLogOut";
import { useSelector } from "react-redux";
import { isEmpty, map } from "lodash";
import { defaultTheme } from "./staticVariables/theme";
import Login from "./pages/Login";
import WebWrapper from "./components/webWrapper/WebWrapper";
import GeneratePdf from "./pages/generatePdf/GeneratePdf";
import WebSearch from "./pages/webSearch/WebSearch";
import SignUp from "./pages/SignUp";
import Auth from "./pages/Auth";
import UserVerify from "./pages/userVerification/UserVerify";
import SetPassword from "./pages/UserPassword/SetPassword";
import ResetPassword from "./pages/UserPassword/ResetPassword";
import ForgotPassword from "./pages/UserPassword/ForgotPassword";
import "animate.css";

LicenseInfo.setLicenseKey(
  "5824e909c89b1e87db7c13b53fded0c2Tz00ODU5OCxFPTE2OTEzMTE5NTMzMjQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  const theme = useSelector((store) => store?.settings?.theme);
  const account = useSelector((store) => store.users.account);
  const [appPages, setAppPages] = useState(pages);
  localStorage.setItem("mode", "light");

  useEffect(() => {
    map(defaultTheme, (value, key) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
  }, [theme]);

  useEffect(() => {
    if (!isEmpty(account)) {
      setAppPages([...pages.filter((p) => p.access.includes(account.role))]);
    }
  }, [account]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<WrapperLogOut />}>
            {/*<Route path="/" element={<Navigate to="/login" />} />*/}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/user/verify/:hash" element={<UserVerify />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/set-password/:hash" element={<SetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<Wrapper />}>
            {/*<Route path="/" element={<Navigate to="/login" />} />*/}
            <Route path="/admin" element={<Navigate to="/dashboard" />} />
            {appPages.map(({ id, path, title, element, children }) =>
              children ? (
                children.map((child) => (
                  <Route
                    key={child.id}
                    path={child.path}
                    title={child.title}
                    element={child.element}
                  />
                ))
              ) : (
                <Route key={id} path={path} title={title} element={element} />
              )
            )}
            <Route path="/*" element={<Navigate to="/404" />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
          </Route>
          <Route element={<WebWrapper />}>
            <Route path="/" element={<Navigate to="/voucher" />} />
            <Route
              path="/voucher"
              element={
                <WebSearch title={"Tour Vouchers"} searchTitle={"Voucher"} />
              }
            />
            <Route
              path="/transfer"
              element={
                <WebSearch
                  title={"Transfer Vouchers"}
                  searchTitle={"Transfer"}
                />
              }
            />
            <Route
              path="/generate_pdf/:page/:reference_number"
              element={<GeneratePdf />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
