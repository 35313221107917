import {
  ADD_WEB_HOTEL_REQUEST,
  ADD_WEB_HOTEL_SUCCESS,
  ADD_WEB_HOTEL_FAIL,
  UPDATE_WEB_HOTEL_REQUEST,
  UPDATE_WEB_HOTEL_SUCCESS,
  UPDATE_WEB_HOTEL_FAIL,
  DELETE_WEB_HOTEL_REQUEST,
  DELETE_WEB_HOTEL_SUCCESS,
  DELETE_WEB_HOTEL_FAIL,
  GET_SINGLE_WEB_HOTEL_REQUEST,
  GET_SINGLE_WEB_HOTEL_SUCCESS,
  GET_SINGLE_WEB_HOTEL_FAIL,
  GET_WEB_HOTELS_REQUEST,
  GET_WEB_HOTELS_SUCCESS,
  GET_WEB_HOTELS_FAIL,
  EXPORT_WEB_HOTELS_REQUEST,
  EXPORT_WEB_HOTELS_SUCCESS,
  EXPORT_WEB_HOTELS_FAIL,
  STATUS_WEB_HOTEL_REQUEST,
  STATUS_WEB_HOTEL_SUCCESS,
  STATUS_WEB_HOTEL_FAIL,
  GET_WEB_HOTELS_GALLERY_REQUEST,
  GET_WEB_HOTELS_GALLERY_SUCCESS,
  GET_WEB_HOTELS_GALLERY_FAIL,
  UPDATE_WEB_HOTELS_GALLERY_REQUEST,
  UPDATE_WEB_HOTELS_GALLERY_SUCCESS,
  UPDATE_WEB_HOTELS_GALLERY_FAIL,
  GET_WEB_HOTEL_ROOM_REQUEST,
  GET_WEB_HOTEL_ROOM_SUCCESS,
  GET_WEB_HOTEL_ROOM_FAIL,
  ADD_WEB_HOTEL_ROOM_REQUEST,
  ADD_WEB_HOTEL_ROOM_SUCCESS,
  ADD_WEB_HOTEL_ROOM_FAIL,
  GET_SINGLE_WEB_HOTEL_ROOM_REQUEST,
  GET_SINGLE_WEB_HOTEL_ROOM_SUCCESS,
  GET_SINGLE_WEB_HOTEL_ROOM_FAIL,
  STATUS_WEB_HOTEL_ROOM_REQUEST,
  STATUS_WEB_HOTEL_ROOM_SUCCESS,
  STATUS_WEB_HOTEL_ROOM_FAIL,
  UPDATE_WEB_HOTEL_ROOM_REQUEST,
  UPDATE_WEB_HOTEL_ROOM_SUCCESS,
  UPDATE_WEB_HOTEL_ROOM_FAIL,
  DELETE_WEB_HOTEL_ROOM_REQUEST,
  DELETE_WEB_HOTEL_ROOM_SUCCESS,
  DELETE_WEB_HOTEL_ROOM_FAIL,
  GET_WEB_HOTEL_ROOM_GALLERY_REQUEST,
  GET_WEB_HOTEL_ROOM_GALLERY_SUCCESS,
  GET_WEB_HOTEL_ROOM_GALLERY_FAIL,
  UPDATE_WEB_HOTEL_ROOM_GALLERY_REQUEST,
  UPDATE_WEB_HOTEL_ROOM_GALLERY_SUCCESS,
  UPDATE_WEB_HOTEL_ROOM_GALLERY_FAIL,
  UPDATE_WEB_HOTEL_TYPE_REQUEST,
  UPDATE_WEB_HOTEL_TYPE_SUCCESS,
  UPDATE_WEB_HOTEL_TYPE_FAIL,
} from "../actions/websiteHotels";

const initialState = {
  rooms: [],
  hotels: [],
  status: "",
  allHotels: [],
  hotelName: "",
  singleHotel: {},
  hotelGallery: [],
  actionStatus: "",
  singleHotelRoom: {},
  hotelRoomGallery: [],
  exportExcelStatus: "",
  getSelectionHotelsStatus: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_WEB_HOTEL_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_WEB_HOTEL_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_WEB_HOTEL_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_WEB_HOTEL_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_HOTEL_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_HOTEL_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_WEB_HOTEL_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_WEB_HOTEL_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_WEB_HOTEL_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_SINGLE_WEB_HOTEL_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_WEB_HOTEL_SUCCESS: {
      return {
        ...state,
        status: "success",
        singleHotel: action.payload.data.hotel,
      };
    }
    case GET_SINGLE_WEB_HOTEL_FAIL: {
      return {
        ...state,
        status: "pending",
      };
    }

    case GET_WEB_HOTELS_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_HOTELS_SUCCESS: {
      return {
        ...state,
        status: "success",
        hotels: action.payload.data.hotels,
        count: action.payload.data.count,
      };
    }
    case GET_WEB_HOTELS_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case STATUS_WEB_HOTEL_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case STATUS_WEB_HOTEL_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case STATUS_WEB_HOTEL_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case EXPORT_WEB_HOTELS_REQUEST: {
      return {
        ...state,
        exportExcelStatus: "pending",
      };
    }
    case EXPORT_WEB_HOTELS_SUCCESS: {
      return {
        ...state,
        exportExcelStatus: "success",
      };
    }
    case EXPORT_WEB_HOTELS_FAIL: {
      return {
        ...state,
        exportExcelStatus: "fail",
      };
    }

    case GET_WEB_HOTELS_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_HOTELS_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
        hotelGallery: action.payload.data?.gallery,
      };
    }
    case GET_WEB_HOTELS_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_HOTELS_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_HOTELS_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_HOTELS_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    //Hotel Rooms
    case GET_WEB_HOTEL_ROOM_REQUEST: {
      return {
        ...state,
        hotelName: "",
        status: "pending",
      };
    }
    case GET_WEB_HOTEL_ROOM_SUCCESS: {
      return {
        ...state,
        status: "success",
        count: action.payload.data.count,
        rooms: action.payload.data?.hotelRooms,
        hotelName: action.payload.data?.hotel?.hotel_name,
      };
    }
    case GET_WEB_HOTEL_ROOM_FAIL: {
      return {
        ...state,
        hotelName: "",
        status: "fail",
      };
    }

    case ADD_WEB_HOTEL_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case ADD_WEB_HOTEL_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case ADD_WEB_HOTEL_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_SINGLE_WEB_HOTEL_ROOM_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_SINGLE_WEB_HOTEL_ROOM_SUCCESS: {
      return {
        ...state,
        status: "success",
        singleHotelRoom: action.payload.data.room,
      };
    }
    case GET_SINGLE_WEB_HOTEL_ROOM_FAIL: {
      return {
        ...state,
        status: "pending",
      };
    }

    case STATUS_WEB_HOTEL_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case STATUS_WEB_HOTEL_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case STATUS_WEB_HOTEL_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case UPDATE_WEB_HOTEL_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case UPDATE_WEB_HOTEL_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case UPDATE_WEB_HOTEL_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case DELETE_WEB_HOTEL_ROOM_REQUEST: {
      return {
        ...state,
        actionStatus: "pending",
      };
    }
    case DELETE_WEB_HOTEL_ROOM_SUCCESS: {
      return {
        ...state,
        actionStatus: "success",
      };
    }
    case DELETE_WEB_HOTEL_ROOM_FAIL: {
      return {
        ...state,
        actionStatus: "fail",
      };
    }

    case GET_WEB_HOTEL_ROOM_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case GET_WEB_HOTEL_ROOM_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
        hotelRoomGallery: action.payload.data?.gallery,
      };
    }
    case GET_WEB_HOTEL_ROOM_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_HOTEL_ROOM_GALLERY_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_HOTEL_ROOM_GALLERY_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_HOTEL_ROOM_GALLERY_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    case UPDATE_WEB_HOTEL_TYPE_REQUEST: {
      return {
        ...state,
        status: "pending",
      };
    }
    case UPDATE_WEB_HOTEL_TYPE_SUCCESS: {
      return {
        ...state,
        status: "success",
      };
    }
    case UPDATE_WEB_HOTEL_TYPE_FAIL: {
      return {
        ...state,
        status: "fail",
      };
    }

    default: {
      return state;
    }
  }
}
