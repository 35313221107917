import React from "react";
import { MdFileCopy } from "react-icons/md";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "react-toastify";

const windowWidth = window.innerWidth;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 600,
    display: "flex",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const getColumns = () => {
  return [
    {
      field: "id",
      headerName: "#",
      width: 60,
    },
    {
      field: "createdBy.company_name",
      headerName: "Company Name",
      width: 200,
      renderCell: (params) => {
        return params.row?.company_name || params?.row?.createdBy?.company_name;
      },
    },
    {
      field: "createdBy.full_name",
      headerName: "User",
      width: 200,
      renderCell: (params) => {
        return (
          params?.row?.createdBy?.full_name ||
          params?.row?.createdBy?.user_name ||
          "Unknown User"
        );
      },
    },
    {
      field: "page",
      headerName: "Tab",
      width: 180,
      renderCell: (params) => {
        return params.row.page;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return params.row.action;
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 190,
      renderCell: (params) => {
        return moment(new Date(params.row.createdAt)).format("L HH:mm:ss");
      },
    },
    {
      field: "description",
      cellClassName: "logs",
      headerName: "Description",
      width: 415,
      renderCell: (params) => (
        <>
          {params.row?.description && (
            <HtmlTooltip
              className="loggerTooltip"
              style={{
                flexWrap: "wrap",
                backgroundColor: "transparent",
                width: "520px",
              }}
              title={
                windowWidth < 1024 ? null : (
                  <React.Fragment>
                    <div
                      style={
                        params.row?.description.length > 250
                          ? {
                              display: "flex",
                              overflowY: "scroll",
                              overflowX: "hidden",
                              width: 450,
                              height: 320,
                            }
                          : {
                              width: 450,
                              display: "flex",
                              overflow: "hidden",
                            }
                      }
                    >
                      <Typography style={{ width: "500px" }} color="inherit">
                        <p
                          className="ads_container"
                          dangerouslySetInnerHTML={
                            !params.row.description.includes("Deleted")
                              ? {
                                  __html: params.row?.description
                                    ?.replaceAll(/\\/g, "")
                                    .replace(/,/g, "</br>"),
                                }
                              : {
                                  __html: params.row?.description
                                    ?.replaceAll(/\\/g, "")
                                    .replaceAll(" ,", "")
                                    .replace("Data", "Data </br>"),
                                }
                          }
                        />
                      </Typography>
                      <CopyToClipboard
                        text={params.row?.description}
                        onCopy={() =>
                          toast.success("Successfully copied.", {
                            autoClose: 5000,
                          })
                        }
                      >
                        <button className="copy_button">
                          <MdFileCopy size={24} />
                        </button>
                      </CopyToClipboard>
                    </div>
                  </React.Fragment>
                )
              }
            >
              {windowWidth < 1024 ? (
                <div
                  style={
                    params.row?.description?.length > 142
                      ? { flexWrap: "wrap" }
                      : { height: "100%", flexWrap: "nowrap" }
                  }
                >
                  {params.row?.description?.length > 142
                    ? params.row?.description?.replaceAll(" ,", ",")
                    : params.row?.description?.replaceAll(" ,", ",")}
                </div>
              ) : (
                <div
                  style={
                    params.row?.description?.length > 100
                      ? { width: "500px" }
                      : { flexWrap: "nowrap", height: "auto" }
                  }
                >
                  {params.row?.description?.length > 95 &&
                  !params.row.action !== "Created"
                    ? params.row?.description
                        ?.replaceAll(" ,", "")
                        .replaceAll(/\\/g, "")
                        .replace(/<\/?p>/g, "")
                        .slice(0, 90) + "..."
                    : params.row?.description
                        ?.replaceAll(" ,", "")
                        .replaceAll(/\\/g, "")
                        .replace(/<\/?p>/g, "")}
                </div>
              )}
            </HtmlTooltip>
          )}
        </>
      ),
    },
  ];
};
