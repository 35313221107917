import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PageTitle from "../../components/PageTitle.js";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import TourVouchersForm from "./TourVouchersForm.js";
import {
  getSingleTourVoucher,
  goToPreviewAction,
  updateTourVoucher,
} from "../../store/actions/tourVouchers";
import { blockPageAction } from "../../store/actions/users";
import { getSelectTours } from "../../store/actions/tours";
import ModalButtonsReset from "../../components/modals/modalButtons/ModalButtonsReset";
import { findVouchersBokunDifference } from "../../services/utils";

function PreviewTourVouchers() {
  const rowStatus = useSelector((store) => store?.tourVouchers?.status);
  const actionStatus = useSelector((store) => store?.tourVouchers?.actionStatus);
  const [formData, setFormData] = useState({});
  const [historyArr, setHistoryArr] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    handleEdit(id);
    return () => {
      dispatch(blockPageAction(""));
    };
  }, []);

  const handleEdit = (id) => {
    dispatch(
      getSingleTourVoucher(id, (err, data) => {
        if (data) {
          if (data.voucherHistory) {
            const voucherHistory = findVouchersBokunDifference([
              ...data.voucherHistory,
              data.voucher,
            ]);
            setHistoryArr(voucherHistory);
          }
          setFormData({
            ...data.voucher,
            service_date: data?.voucher?.tour?.service_date,
            direction_id: data?.voucher?.tour?.direction_id,
          });
          if (
            data?.voucher?.tour?.service_date &&
            data?.voucher?.tour?.direction_id
          ) {
            dispatch(
              getSelectTours(
                data?.voucher?.tour?.service_date,
                data?.voucher?.tour?.direction_id
              )
            );
          }
        } else if (err) {
          navigate("/dashboard");
        }
      })
    );
    return () => {};
  };

  const handleEditConfirm = async (data) => {
    setDisabled(true);
    dispatch(
      updateTourVoucher(data, (err, data) => {
        if (data && data.status === 400) {
          toast.error(data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        } else {
          if (data && data.status === 200) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setTimeout(() => {
              setDisabled(false);
            }, 400);
            navigate("/vouchers");
          }
        }
      })
    );
  };

  const goToPreview = () => {
    dispatch(
      goToPreviewAction(formData?.id, (err, data) => {
        if (data && data.status === 200) {
          if (data.webLink) {
            window.open(data.webLink);
          }
        }
      })
    );
  };
  return (
    <>
      <PageTitle
        parentTitle={"Tour Vouchers"}
        childTitle={formData.reference_number || ""}
        parentLink={"/vouchers"}
      />
      <div className="form-block">
        {rowStatus === "success" ? (
          <TourVouchersForm
            submit={handleEditConfirm}
            formData={formData}
            footer={
              <ModalButtonsReset
                submit={handleEditConfirm}
                reset={() => {
                  handleEdit(id);
                }}
                preview={goToPreview}
                disabled={disabled}
                isLoading={actionStatus === "pending"}
              />
            }
            historyArr={historyArr}
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === "pending"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewTourVouchers;
