import * as React from 'react';
import './adCard.scss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const { REACT_APP_API_URL } = process.env;

export default function AdCard({ data }) {
  return (
    <Card>
      {data?.html ? (
        <CardContent className="ad_card_content_block">
          <dd dangerouslySetInnerHTML={{ __html: data?.html }}></dd>
        </CardContent>
      ) : null}
    </Card>
  );
}
