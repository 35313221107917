import { api } from "./Api";
const { REACT_APP_API_URL } = process.env;

export default class Logs {
  static getLogs(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/logs`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static getAllLogs() {
    return api.get(`/logs`);
  }

  static handleExportRolesExcel(page, search, filter, [{ sort, field }]) {
    return api({
      url: `/logs/excel`,
      method: "GET",
      responseType: "blob",
      params: {
        search,
        filter,
        page,
        sort,
        field,
      },
    });
  }
}
