import React, { useState } from "react";
import FilterBlock from "../filter/FilterBlock";
import Search from "../Search/Search";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import {
  addFilter,
  addServiceFilter,
  clearFilter,
  searchAction,
} from "../../store/actions/search";
import Badge from "@mui/material/Badge";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Grid } from "@mui/material";
import { excludeFilterKeys } from "../../services/globalVariables";

export default function SearchFilterBar({ getList, columns, usersPage }) {
  const dispatch = useDispatch();
  const searchConfig = useSelector((store) => store.search);
  const s = useSelector((store) => store.search.filter);
  const a = useSelector((store) => store.search.filterArr);
  const filter = useSelector((store) => store.search.filter);
  const searchChange = (e) => {
    dispatch(searchAction(e.target.value));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    searchConfig.page = 1;
    getList(searchConfig);
  };
  const handleClear = (e) => {
    dispatch(searchAction(""));
    dispatch(clearFilter());
    searchConfig.search = "";
    searchConfig.filter = {};
    searchConfig.filterArr = [];
    getList(searchConfig);
  };

  return (
    <Grid container className="search_container">
      <Grid item xs={12} md={6} className="search_container_item">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch(e);
          }}
        >
          <div className="search_filter_block">
            <div className="search_block">
              <Search
                searchChange={searchChange}
                searchConfig={searchConfig}
                searchValue={searchConfig.search}
              />
              <div className="button_block search_top">
                <Button
                  variant="outline-secondary"
                  id="button-addon4"
                  className="filter_icon"
                  onClick={() => {
                    if (isEmpty(searchConfig.filterArr)) {
                      dispatch(addFilter(searchConfig.filterArr));
                    } else {
                      dispatch(clearFilter());
                    }
                  }}
                >
                  <Badge
                    badgeContent={
                      !isEmpty(searchConfig.filterArr)
                        ? Object.keys(searchConfig.filterArr).length
                        : null
                    }
                    color={"primary"}
                  >
                    <FilterAltOutlinedIcon
                      className="search-filter-icon"
                      color="action"
                    />
                  </Badge>
                </Button>
              </div>
              <div className="button_block search_top">
                {searchConfig.search || !isEmpty(searchConfig.filterArr) ? (
                  <Button
                    variant="outline-secondary"
                    id="button-addon"
                    onClick={(e) => {
                      handleClear(e);
                    }}
                  >
                    Clear
                  </Button>
                ) : null}

                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  type="submit"
                  onClick={(e) => {
                    handleSearch(e);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
          <div className="button_block search_bottom">
            <Button
              variant="outline-secondary"
              id="button-addon4"
              className="filter_icon"
              type="button"
              onClick={() => {
                if (isEmpty(searchConfig.filterArr)) {
                  dispatch(addFilter(searchConfig.filterArr));
                } else {
                  dispatch(clearFilter());
                }
              }}
            >
              <Badge
                badgeContent={
                  !isEmpty(searchConfig.filterArr)
                    ? Object.keys(searchConfig.filterArr).length
                    : null
                }
                color={"primary"}
              >
                <FilterAltOutlinedIcon
                  className="search-filter-icon"
                  color="action"
                />
              </Badge>
            </Button>
          </div>
          {!isEmpty(searchConfig.filterArr) && (
            <div className="search_filter_block">
              <FilterBlock
                columns={columns.filter(
                  (c) => !excludeFilterKeys.includes(c.field)
                )}
                usersPage={usersPage}
                handleClear={handleClear}
              />
            </div>
          )}
          <div className="button_block search_bottom">
            {searchConfig.search || !isEmpty(searchConfig?.filterArr) ? (
              <Button
                variant="outline-secondary"
                id="button-addon5"
                // type="submit"
                onClick={(e) => {
                  handleClear(e);
                }}
              >
                Clear
              </Button>
            ) : null}

            <Button
              variant="outline-secondary"
              id="button-addon4"
              onClick={(e) => {
                handleSearch(e);
              }}
            >
              Apply
            </Button>
          </div>
        </form>
      </Grid>
      <Grid
        item
        xs={0}
        md={6}
        className="search_container_item search_container_secondary"
      >
        <div className="search_block_secondary"></div>
      </Grid>
    </Grid>
  );
}
