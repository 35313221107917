import { api } from './Api';

export default class TermsConditions {
  static getTermsConditions(page, limit, search, filter, [{ sort, field }]) {
    return api.get(`/terms_and_conditions`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
  static getSingleTermsCondition(id) {
    return api.get(`/terms_and_conditions/single/${id}`);
  }
  static addTermsCondition(formData) {
    return api.post(`/terms_and_conditions`, { ...formData });
  }
  static updateTermsCondition(formData) {
    return api.put(`/terms_and_conditions/${formData?.id}`, { ...formData });
  }
  static deleteTermsCondition(id) {
    return api.delete(`/terms_and_conditions/${id}`);
  }
  static getAllTermsConditions() {
    return api.get(`/terms_and_conditions/all`);
  }
}
