export const GET_TRANSFER_TYPES_REQUEST = 'GET_TRANSFER_TYPES_REQUEST';
export const GET_TRANSFER_TYPES_SUCCESS = 'GET_TRANSFER_TYPES_SUCCESS';
export const GET_TRANSFER_TYPES_FAIL = 'GET_TRANSFER_TYPES_FAIL';

export function getTransferTypesAction(searchConfig) {
  return {
    type: GET_TRANSFER_TYPES_REQUEST,
    payload: {
      searchConfig,
    },
  };
}

export const GET_ALL_TRANSFER_TYPES_REQUEST = 'GET_ALL_TRANSFER_TYPES_REQUEST';
export const GET_ALL_TRANSFER_TYPES_SUCCESS = 'GET_ALL_TRANSFER_TYPES_SUCCESS';
export const GET_ALL_TRANSFER_TYPES_FAIL = 'GET_ALL_TRANSFER_TYPES_FAIL';

export function getAllTransferTypesAction(cb) {
  return {
    type: GET_ALL_TRANSFER_TYPES_REQUEST,
    payload: {
      cb,
    },
  };
}

export const ADD_TRANSFER_TYPE_REQUEST = 'ADD_TRANSFER_TYPE_REQUEST';
export const ADD_TRANSFER_TYPE_SUCCESS = 'ADD_TRANSFER_TYPE_SUCCESS';
export const ADD_TRANSFER_TYPE_FAIL = 'ADD_TRANSFER_TYPE_FAIL';

export function addTransferTypeAction(formData, cb) {
  return {
    type: ADD_TRANSFER_TYPE_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const DELETE_TRANSFER_TYPE_REQUEST = 'DELETE_TRANSFER_TYPE_REQUEST';
export const DELETE_TRANSFER_TYPE_SUCCESS = 'DELETE_TRANSFER_TYPE_SUCCESS';
export const DELETE_TRANSFER_TYPE_FAIL = 'DELETE_TRANSFER_TYPE_FAIL';

export function deleteTransferTypeAction(id, cb) {
  return {
    type: DELETE_TRANSFER_TYPE_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const DELETE_SELECTED_TRANSFER_TYPES_REQUEST =
  'DELETE_SELECTED_TRANSFER_TYPES_REQUEST';
export const DELETE_SELECTED_TRANSFER_TYPES_SUCCESS =
  'DELETE_SELECTED_TRANSFER_TYPES_SUCCESS';
export const DELETE_SELECTED_TRANSFER_TYPES_FAIL =
  'DELETE_SELECTED_TRANSFER_TYPES_FAIL';

export function deleteSelectedTransferTypes(selectedRows, cb) {
  return {
    type: DELETE_SELECTED_TRANSFER_TYPES_REQUEST,
    payload: {
      selectedRows,
      cb,
    },
  };
}

export const UPDATE_TRANSFER_TYPE_REQUEST = 'UPDATE_TRANSFER_TYPE_REQUEST';
export const UPDATE_TRANSFER_TYPE_SUCCESS = 'UPDATE_TRANSFER_TYPE_SUCCESS';
export const UPDATE_TRANSFER_TYPE_FAIL = 'UPDATE_TRANSFER_TYPE_FAIL';

export function updateTransferTypeAction(formData, cb) {
  return {
    type: UPDATE_TRANSFER_TYPE_REQUEST,
    payload: {
      formData,
      cb,
    },
  };
}

export const GET_SINGLE_TRANSFER_TYPE_REQUEST =
  'GET_SINGLE_TRANSFER_TYPE_REQUEST';
export const GET_SINGLE_TRANSFER_TYPE_SUCCESS =
  'GET_SINGLE_TRANSFER_TYPE_SUCCESS';
export const GET_SINGLE_TRANSFER_TYPE_FAIL = 'GET_SINGLE_TRANSFER_TYPE_FAIL';

export function getSingleTransferTypeAction(id, cb) {
  return {
    type: GET_SINGLE_TRANSFER_TYPE_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}

export const EXPORT_TRANSFER_TYPES_REQUEST = 'EXPORT_TRANSFER_TYPES_REQUEST';
export const EXPORT_TRANSFER_TYPES_SUCCESS = 'EXPORT_TRANSFER_TYPES_SUCCESS';
export const EXPORT_TRANSFER_TYPES_FAIL = 'EXPORT_TRANSFER_TYPES_FAIL';

export function exportExcelTransferTypesAction(searchConfig, cb) {
  return {
    type: EXPORT_TRANSFER_TYPES_REQUEST,
    payload: { searchConfig, cb },
  };
}

export const UPDATE_STATUS_TRANSFER_TYPE_REQUEST =
  'UPDATE_STATUS_TRANSFER_TYPE_REQUEST';
export const UPDATE_STATUS_TRANSFER_TYPE_SUCCESS =
  'UPDATE_STATUS_TRANSFER_TYPE_SUCCESS';
export const UPDATE_STATUS_TRANSFER_TYPE_FAIL =
  'UPDATE_STATUS_TRANSFER_TYPE_FAIL';

export function updateStatusTransferTypeAction(id, cb) {
  return {
    type: UPDATE_STATUS_TRANSFER_TYPE_REQUEST,
    payload: {
      id,
      cb,
    },
  };
}
