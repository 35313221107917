import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import BasicLineChart from "./charts/Chart";
import { isEmpty } from "lodash";
import Select from "react-select";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardInfoByYear } from "../store/actions/customSettings";

const customStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
};

function DashboardCard({ name, tours, minYear, model_key, years }) {
  const status = useSelector(
    (state) => state.customSettings.dashboardStatuses[model_key]
  );
  const [selectedMonth, setSelectedMonth] = useState(null);
  const dispatch = useDispatch();
  const handleSelectYear = (selectedOption) => {
    setSelectedMonth(null);
    dispatch(getDashboardInfoByYear(model_key, selectedOption.value));
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption ? selectedOption.value : null);
  };

  return (
    <>
      <Card className="dashboardCard">
        <CardContent style={{ marginLeft: "2%", color: "#3e5674" }}>
          <div className="d-flex dashboard-title justify-content-between">
            <div>
              <Typography variant="h6" component="div" className="dashboard_h6">
                {name} - {tours.length || 0}
              </Typography>
            </div>
            <div className="d-flex date_component">
              <div style={{ marginRight: "15px" }}>
                <span>Year</span>
                <Select
                  id={"year"}
                  size="small"
                  className="row_control"
                  value={{ value: years[model_key] }}
                  onChange={handleSelectYear}
                  getOptionLabel={(i) => i.value}
                  getOptionValue={(i) => i.value}
                  options={Array.from(
                    { length: moment().year() - minYear + 1 },
                    (_, i) => ({ value: minYear + i })
                  ).reverse()}
                  isSearchable={false}
                  styles={customStyles}
                  hideSelectedOptions={true}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                />
              </div>
              <div>
                <span>Month</span>
                <Select
                  id="month"
                  size="small"
                  className="row_control"
                  value={selectedMonth ? { value: selectedMonth } : null}
                  onChange={handleMonthChange}
                  getOptionLabel={(i) => i.value}
                  getOptionValue={(i) => i.value}
                  options={moment.months().map((m) => ({ value: m }))}
                  isSearchable={false}
                  styles={customStyles}
                  hideSelectedOptions={true}
                  isClearable={true}
                  menuPortalTarget={document.body}
                  menuPosition="fixed"
                  maxMenuHeight={220}
                />
              </div>
            </div>
          </div>
          <Typography
            style={{ marginLeft: "1%", fontSize: "16px" }}
            variant="body2"
            color="text.secondary"
          ></Typography>
        </CardContent>
        <BasicLineChart
          data={!isEmpty(tours) ? tours : []}
          selectedMonth={selectedMonth}
          selectedYear={2024}
          status={status}
        />
      </Card>
    </>
  );
}

export default DashboardCard;
