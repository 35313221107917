import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  blockPageAction,
  getAllDriversAction,
} from "../../store/actions/users";
import Select from "react-select";
import { getAllPickUpPointsAction } from "../../store/actions/pickUpPoints";
import { getAllTransferTypesAction } from "../../store/actions/transferTypes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { getAllTermsConditionsAction } from "../../store/actions/termsConditions";
import { Controller, useForm } from "react-hook-form";
import { TrimmedData } from "../../services/TrimObjectFields";
import { getAllCurrency } from "../../store/actions/currency";

function TourTransfersForms({ submit, footer, formData }) {
  const allPickUpPoints = useSelector(
    (store) => store.pickUpPoints.allPickUpPoints
  );
  const drivers = useSelector((store) => store.users?.allDriverUsers);
  const allTransferTypes = useSelector(
    (store) => store.transferTypes.allTransferTypes
  );
  const allTermsConditions = useSelector(
    (store) => store.termsConditions.allTermsConditions
  );
  const allCurrency = useSelector((store) => store.currency.allCurrency);

  const [isFormDisabled, setIsFormDisabled] = useState(true);

  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    reset,
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm();

  useEffect(() => {
    dispatch(getAllCurrency());
    dispatch(getAllDriversAction());
    dispatch(getAllPickUpPointsAction());
    dispatch(getAllTransferTypesAction());
    dispatch(getAllTermsConditionsAction());
  }, []);

  useEffect(() => {
    if (id && formData) {
      reset(formData);
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/transfers/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  const onSubmit = (data) => {
    const trimmedData = TrimmedData(data);
    submit(trimmedData);
  };

  const getCustomNotRequiredStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;
    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#198754" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  const getCustomStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;

    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#dc3545" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  const getCustomStylesForDate = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;

    return createTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: isValid ? "#198754" : "#ced4da",
            },
          },
        },
      },
    });
  };
  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6} md={4}>
                <Form.Group>
                  <Form.Label>
                    Transfer Name
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="transfer_name"
                    name="transfer_name"
                    maxLength={254}
                    value={watch("transfer_name")}
                    isInvalid={isSubmitted && !!errors.transfer_name}
                    isValid={isSubmitted && !errors.transfer_name}
                    {...register("transfer_name", {
                      required: "Required Field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.transfer_name
                      ? errors.transfer_name.message
                      : "Required Field"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group className={!isSubmitted ? "mb-3" : null}>
                  <Form.Label>Duration (h)</Form.Label>
                  <Form.Control
                    id="transfer_duration"
                    name="duration"
                    type="number"
                    maxLength={254}
                    value={watch("duration")}
                    {...register("duration")}
                    style={getCustomStylesForInput("duration")}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group>
                  <Form.Label>Emergency Number</Form.Label>
                  <Controller
                    name="emergency_number"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        country={"gr"}
                        buttonStyle={
                          isSubmitted ? { borderColor: "#198754" } : null
                        }
                        inputStyle={
                          isSubmitted ? { borderColor: "#198754" } : null
                        }
                        inputProps={{
                          name: "emergency_number",
                          id: "transfer_emergency_number",
                        }}
                        value={watch("emergency_number")}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4}>
                <Form.Label>Service Date</Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={"transfer_service_date"}>
                    <ThemeProvider
                      theme={getCustomStylesForDate("service_date")}
                    >
                      <DesktopDatePicker
                        name={"service_date"}
                        className="month_year_picker"
                        views={["year", "month", "day"]}
                        value={
                          watch("service_date")
                            ? dayjs(watch("service_date"))
                            : null
                        }
                        onChange={(newValue) => {
                          if (newValue) {
                            setValue(
                              "service_date",
                              dayjs(newValue).format("YYYY-MM-DD"),
                              { shouldDirty: true }
                            );
                          } else {
                            setValue("service_date", null, {
                              shouldDirty: true,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={watch("send") === "true"}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col sm={6} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Time Start</Form.Label>
                  <Form.Control
                    id={"transfer_start_time"}
                    name="start_time"
                    type="time"
                    value={watch("start_time")}
                    style={getCustomStylesForInput("start_time")}
                    {...register("start_time")}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Time Finish</Form.Label>
                  <Form.Control
                    id={"transfer_end_time"}
                    name="end_time"
                    type="time"
                    value={watch("end_time")}
                    style={getCustomStylesForInput("end_time")}
                    {...register("end_time")}
                    min={watch("start_time")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {watch("end_time") && "Invalid time"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} lg={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Net Price (€)</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={50}
                    name={"net_price"}
                    id={"transfer_net_price"}
                    {...register("net_price")}
                    isValid={isSubmitted && !errors.net_price}
                    isInvalid={isSubmitted && !!errors.net_price}
                    onInput={(e) => {
                      let inputValue = e.target.value;
                      inputValue = inputValue.replace(/[^0-9.]/g, "");

                      const dotCount = (inputValue.match(/\./g) || []).length;
                      if (dotCount > 1) {
                        inputValue = inputValue.substring(
                          0,
                          inputValue.lastIndexOf(".") + 1
                        );
                      }
                      if (inputValue.startsWith(".")) {
                        inputValue = "0" + inputValue;
                      }
                      if (inputValue.includes(".")) {
                        let [integerPart, decimalPart] = inputValue.split(".");
                        decimalPart = decimalPart.substring(0, 2);
                        if (integerPart && integerPart.length > 8) {
                          integerPart = integerPart.substring(0, 8);
                        }
                        inputValue = `${integerPart}.${decimalPart}`;
                      }
                      if (
                        inputValue.startsWith("0") &&
                        inputValue.length > 1 &&
                        inputValue[1] !== "."
                      ) {
                        inputValue = inputValue.substring(1);
                      }
                      if (inputValue.length > 8) {
                        if (!inputValue.includes(".")) {
                          inputValue = inputValue.substring(0, 8) + ".00";
                        } else {
                          let [integerPart, decimalPart] = inputValue.split(".");
                          decimalPart = decimalPart.substring(0, 2);
                          inputValue = `${integerPart}.${decimalPart}`;
                        }
                      }
                      setValue("net_price", inputValue, {
                        shouldDirty: true,
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.net_price && errors.net_price.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/*<Col sm={6} lg={3}>*/}
              {/*  <Form.Group className="mb-3">*/}
              {/*    <Form.Label>*/}
              {/*      Currency*/}
              {/*      {watch("net_price") && !watch("currency_id") && (*/}
              {/*        <span style={{ color: "red" }}> *</span>*/}
              {/*      )}*/}
              {/*    </Form.Label>*/}
              {/*    <Select*/}
              {/*      name="currency_id"*/}
              {/*      // id="transfer_currency"*/}
              {/*      id="currency_id"*/}
              {/*      styles={*/}
              {/*        errors.currency_id && !watch("currency_id")*/}
              {/*          ? getCustomStyles("currency_id")*/}
              {/*          : getCustomNotRequiredStyles("currency_id")*/}
              {/*      }*/}
              {/*      options={allCurrency}*/}
              {/*      getOptionLabel={(i) =>*/}
              {/*        `${i.currency_name}(${i.currency_symbol})`*/}
              {/*      }*/}
              {/*      {...register("currency_id", {*/}
              {/*        required:*/}
              {/*          watch("net_price") &&*/}
              {/*          !watch("currency_id") &&*/}
              {/*          "Required Field",*/}
              {/*      })}*/}
              {/*      getOptionValue={(i) => i.id}*/}
              {/*      // value={*/}
              {/*      //   watch("currency_id") && Array.isArray(allCurrency)*/}
              {/*      //     ? allCurrency.find((i) => i.id === watch("currency_id"))*/}
              {/*      //     : null*/}
              {/*      // }*/}
              {/*      isClearable={false}*/}
              {/*      isDisabled={true}*/}
              {/*      value={allCurrency.find((i) => i.id === 2) || null}*/}
              {/*      onChange={(ev) => {*/}
              {/*        setValue("currency_id", ev ? ev.id : null, {*/}
              {/*          shouldDirty: true,*/}
              {/*        });*/}
              {/*      }}*/}
              {/*    />*/}
              {/*    {errors.currency_id && !watch("currency_id") && (*/}
              {/*      <div className="required-message">*/}
              {/*        {errors.currency_id.message}*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*  </Form.Group>*/}
              {/*</Col>*/}
              <Col sm={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={254}
                    name="from_location"
                    id="transer_from_location"
                    value={watch("from_location")}
                    isInvalid={isSubmitted && !!errors.from_location}
                    isValid={isSubmitted && !errors.from_location}
                    {...register("from_location", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.from_location?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={12} md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={254}
                    name="to_location"
                    id="transfer_to_location"
                    value={watch("to_location")}
                    isInvalid={isSubmitted && !!errors.to_location}
                    isValid={isSubmitted && !errors.to_location}
                    {...register("to_location", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.to_location?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Transfer Type</Form.Label>
                  <Select
                    id={"transfer_transfer_type_id"}
                    name={"transfer_type_id"}
                    value={
                      watch("transfer_type_id") &&
                      Array.isArray(allTransferTypes)
                        ? allTransferTypes.find(
                            (i) => i.id === watch("transfer_type_id")
                          )
                        : {}
                    }
                    styles={getCustomStyles("transfer_type_id")}
                    options={
                      Array.isArray(allTransferTypes) ? allTransferTypes : []
                    }
                    {...register("transfer_type_id")}
                    isClearable={true}
                    getOptionLabel={(i) => i.type}
                    getOptionValue={(i) => i.id}
                    onChange={(ev) => {
                      setValue("transfer_type_id", ev ? ev.id : null, {
                        shouldDirty: true,
                      });
                      setValue("transfer_type", ev ? ev.type : null, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Terms and Conditions</Form.Label>
                  <Select
                    id={"transfer_term_condition_id"}
                    name={"term_condition_id"}
                    value={
                      watch("term_condition_id") &&
                      Array.isArray(allTermsConditions)
                        ? allTermsConditions.find(
                            (i) => i.id === watch("term_condition_id")
                          )
                        : {}
                    }
                    styles={getCustomStyles("term_condition_id")}
                    options={
                      Array.isArray(allTermsConditions)
                        ? allTermsConditions
                        : []
                    }
                    {...register("term_condition_id")}
                    isClearable={true}
                    getOptionLabel={(i) => i.name}
                    getOptionValue={(i) => i.id}
                    onChange={(ev) => {
                      setValue("term_condition_id", ev ? ev.id : null, {
                        shouldDirty: true,
                      });
                      setValue(
                        "including_service_info",
                        ev ? ev.description : null,
                        { shouldDirty: true }
                      );
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Driver
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="driver_user_id"
                    rules={{
                      required: "Required Field",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id={"transfer_driver_user_id"}
                        name={"driver_user_id"}
                        value={
                          watch("driver_user_id") && Array.isArray(drivers)
                            ? drivers.find(
                                (i) => i.id === watch("driver_user_id")
                              )
                            : {}
                        }
                        {...register("driver_user_id")}
                        styles={
                          errors.driver_user_id && !watch("driver_user_id")
                            ? getCustomStyles("driver_user_id")
                            : getCustomNotRequiredStyles("driver_user_id")
                        }
                        options={Array.isArray(drivers) ? drivers : []}
                        isClearable={true}
                        getOptionLabel={(i) => i.full_name}
                        getOptionValue={(i) => i.id}
                        onChange={(ev) => {
                          setValue("driver_user_id", ev ? ev.id : null, {
                            shouldDirty: true,
                          });
                        }}
                      />
                    )}
                  />
                  {errors.driver_user_id && !watch("driver_user_id") ? (
                    <small style={{ color: "#dc3545" }}>
                      {errors?.driver_user_id?.message}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Starting point</Form.Label>
                  <Select
                    id={"transfer_pick_up_point_id"}
                    name={"pick_up_point_id"}
                    value={
                      watch("pick_up_point_id") &&
                      Array.isArray(allPickUpPoints)
                        ? allPickUpPoints.find(
                            (i) => i.id === watch("pick_up_point_id")
                          )
                        : {}
                    }
                    styles={getCustomStyles("pick_up_point_id")}
                    options={
                      Array.isArray(allPickUpPoints) ? allPickUpPoints : []
                    }
                    {...register("pick_up_point_id")}
                    isClearable={true}
                    getOptionLabel={(i) => i.pick_up_name}
                    getOptionValue={(i) => i.id}
                    onChange={(ev) => {
                      setValue("pick_up_point_id", ev ? ev.id : null, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Included services</Form.Label>
                  <Form.Control
                    id={"transfer_including_service_info"}
                    name={"including_service_info"}
                    as="textarea"
                    rows={3}
                    value={watch("including_service_info")}
                    {...register("including_service_info")}
                    style={getCustomStylesForInput("including_service_info")}
                    maxLength={2000}
                  />
                  <div style={{ marginTop: "5px" }}>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Check
                        type="radio"
                        label="Arrival"
                        value="Arrival"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                        checked={watch("including_service_type") === "Arrival"}
                        style={{ marginRight: "20px" }}
                        onChange={(ev) => {
                          setValue(
                            "including_service_type",
                            ev ? ev.target.value : null,
                            { shouldDirty: true }
                          );
                        }}
                      />
                      <Form.Check
                        type="radio"
                        label="Departure"
                        value="Departure"
                        name="formHorizontalRadios"
                        id="formHorizontalRadios2"
                        checked={
                          watch("including_service_type") === "Departure"
                        }
                        onChange={(ev) => {
                          setValue(
                            "including_service_type",
                            ev ? ev.target.value : null,
                            { shouldDirty: true }
                          );
                        }}
                      />
                      <Form.Check.Label
                        htmlFor="show_on_pdf"
                        className="p-0 px-1"
                        style={{ cursor: "pointer" }}
                      ></Form.Check.Label>
                    </Form.Group>
                  </div>
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    id="transfer_notes"
                    name="notes"
                    as="textarea"
                    rows={3}
                    value={watch("notes")}
                    {...register("notes")}
                    style={getCustomStylesForInput("notes")}
                    maxLength={1000}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Driver Location Url</Form.Label>
                  <Form.Control
                    id="transfer_driver_location_url"
                    name="driver_location_url"
                    pattern="https?://.+"
                    value={watch("driver_location_url")}
                    isInvalid={isSubmitted && !!errors.driver_location_url}
                    isValid={isSubmitted && !errors.driver_location_url}
                    {...register("driver_location_url", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.driver_location_url
                      ? errors.driver_location_url.message
                      : "Invalid Format"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        </div>
        <Modal.Footer>
          {React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default TourTransfersForms;
