import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle.js";
import { useNavigate, useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import DirectionsForm from "./DirectionsForm.js";
import {
  getSingleDirection,
  updateDirection,
} from "../../store/actions/directions";
import { blockPageAction } from "../../store/actions/users";
import ModalButtonsReset from "../../components/modals/modalButtons/ModalButtonsReset";
import { updateTransferTypeAction } from "../../store/actions/transferTypes";
import { toast } from "react-toastify";

function PreviewDirections() {
  const rowStatus = useSelector((store) => store?.directions?.status);
  const actionStatus = useSelector((store) => store?.directions?.actionStatus);
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    handleGetSingle(id);
    return () => {
      dispatch(blockPageAction(""));
    };
  }, []);

  const handleGetSingle = (id) => {
    dispatch(
      getSingleDirection(id, (err, data) => {
        if (data && data.direction) {
          setFormData(data.direction);
        } else if (err) {
          navigate(`/dashboard`);
        }
      })
    );
  };

  const handleEditConfirm = async (data) => {
    setDisabled(true);
    dispatch(
      updateDirection(data, (err, data) => {
        if (err) {
          toast.error(err, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
          setDisabled(false);
        } else {
          if (data) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            setTimeout(() => {
              setDisabled(false);
            }, 400);
            navigate("/directions");
          }
        }
      })
    );
  };

  return (
    <>
      <PageTitle
        parentTitle={"Directions"}
        childTitle={formData.direction_name || ""}
        parentLink={"/directions"}
      />
      <div className="form-block">
        {rowStatus === "success" ? (
          <DirectionsForm
            submit={handleEditConfirm}
            formData={formData}
            footer={
              <ModalButtonsReset
                submit={handleEditConfirm}
                reset={() => {
                  handleGetSingle(id);
                }}
                disabled={disabled}
                isLoading={actionStatus === "pending"}
              />
            }
          />
        ) : (
          <div className="form-loading">
            <FadeLoader
              color={`var(--primary_main)`}
              loading={rowStatus === "pending"}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PreviewDirections;
