import {
  GET_ALL_CURRENCY_REQUEST,
  GET_ALL_CURRENCY_SUCCESS,
} from '../actions/currency';

const initialState = {
  currency: [],
  allCurrency: [],
  singleDriver: {},
  status: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CURRENCY_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }

    case GET_ALL_CURRENCY_SUCCESS: {
      return {
        ...state,
        allCurrency: action.payload.data.allCurrencies,
        status: 'success',
      };
    }

    default: {
      return state;
    }
  }
}
