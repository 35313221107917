import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_RESERVATIONS_REQUEST,
  GET_RESERVATIONS_SUCCESS,
  GET_RESERVATIONS_FAIL,
  GET_ALL_RESERVATIONS_REQUEST,
  GET_ALL_RESERVATIONS_SUCCESS,
  GET_ALL_RESERVATIONS_FAIL,
  ADD_RESERVATIONS_REQUEST,
  ADD_RESERVATIONS_SUCCESS,
  ADD_RESERVATIONS_FAIL,
  DELETE_RESERVATION_REQUEST,
  DELETE_RESERVATION_SUCCESS,
  DELETE_RESERVATION_FAIL,
  UPDATE_RESERVATION_REQUEST,
  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_FAIL,
  GET_SINGLE_RESERVATION_REQUEST,
  GET_SINGLE_RESERVATION_SUCCESS,
  GET_SINGLE_RESERVATION_FAIL,
  EXPORT_RESERVATIONS_REQUEST,
  EXPORT_RESERVATIONS_SUCCESS,
  EXPORT_RESERVATIONS_FAIL,
  UPDATE_STATUS_RESERVATIONS_REQUEST,
  UPDATE_STATUS_RESERVATIONS_SUCCESS,
  UPDATE_STATUS_RESERVATIONS_FAIL,
  HANDLE_GET_ACCOMMODATIONS_REQUEST,
  HANDLE_GET_ACCOMMODATIONS_SUCCESS,
  HANDLE_GET_ACCOMMODATIONS_FAIL,
  HANDLE_GET_ACCOMMODATION_ROOMS_REQUEST,
  HANDLE_GET_ACCOMMODATION_ROOMS_SUCCESS,
  HANDLE_GET_ACCOMMODATION_ROOMS_FAIL,
  HANDLE_GET_ROOM_RESERVATIONS_REQUEST,
  HANDLE_GET_ROOM_RESERVATIONS_SUCCESS,
  HANDLE_GET_ROOM_RESERVATIONS_FAIL,
} from "../actions/reservations";
import Reservations from "../../api/reservations";

export default function* watcher() {
  yield takeLatest(GET_RESERVATIONS_REQUEST, handleGetReservations);
  yield takeLatest(GET_ALL_RESERVATIONS_REQUEST, handleGetAllReservations);
  yield takeLatest(ADD_RESERVATIONS_REQUEST, handleAddReservation);
  yield takeLatest(DELETE_RESERVATION_REQUEST, handleDeleteReservation);
  yield takeLatest(UPDATE_RESERVATION_REQUEST, handleUpdateReservation);
  yield takeLatest(GET_SINGLE_RESERVATION_REQUEST, handleGetSingleReservation);
  yield takeLatest(EXPORT_RESERVATIONS_REQUEST, handleExportExcel);
  yield takeLatest(UPDATE_STATUS_RESERVATIONS_REQUEST, handleStatusUpdate);
  yield takeLatest(HANDLE_GET_ACCOMMODATIONS_REQUEST, handleGetAccommodations);
  yield takeLatest(
    HANDLE_GET_ACCOMMODATION_ROOMS_REQUEST,
    handleGetAccommodationRooms
  );
  yield takeLatest(
    HANDLE_GET_ROOM_RESERVATIONS_REQUEST,
    handleGetRoomReservation
  );
}

function* handleGetReservations(action) {
  try {
    const { page, limit, sort, search, filter } = action.payload?.searchConfig;
    const { data } = yield call(
      Reservations.getReservations,
      page,
      limit,
      search,
      filter,
      sort
    );
    yield put({
      type: GET_RESERVATIONS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_RESERVATIONS_FAIL,
      payload: {
        message: e.message,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetAllReservations(action) {
  try {
    const { data } = yield call(Reservations.getAllReservations);
    yield put({
      type: GET_ALL_RESERVATIONS_SUCCESS,
      payload: {
        data: data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: GET_ALL_RESERVATIONS_FAIL,
      payload: {
        message: e.message,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleAddReservation(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Reservations.addReservation, formData);
    yield put({
      type: ADD_RESERVATIONS_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: ADD_RESERVATIONS_FAIL,
      payload: {
        message: e.message,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleDeleteReservation(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Reservations.deleteReservation, id);
    yield put({
      type: DELETE_RESERVATION_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: DELETE_RESERVATION_FAIL,
      payload: {
        message: e.message,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e.response?.data, null);
    }
  }
}

function* handleUpdateReservation(action) {
  try {
    const { formData } = action.payload;
    const { data } = yield call(Reservations.updateReservation, formData);
    yield put({
      type: UPDATE_RESERVATION_SUCCESS,
      payload: {
        data: data.result,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_RESERVATION_FAIL,
      payload: {
        message: e.message,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleGetSingleReservation(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(Reservations.getSingleReservation, id);
    yield put({
      type: GET_SINGLE_RESERVATION_SUCCESS,
      payload: {
        data,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: GET_SINGLE_RESERVATION_FAIL,
      payload: {
        message: e.message,
      },
    });
    if (action.payload.cb) {
      action.payload.cb(e, null);
    }
  }
}

function* handleExportExcel(action) {
  try {
    const { page, search, filter, sort } = action.payload?.searchConfig;
    const { data } = yield call(
      Reservations.handleExportReservationsExcel,
      page,
      search,
      filter,
      sort
    );
    yield put({
      type: EXPORT_RESERVATIONS_SUCCESS,
    });
    const blob = new Blob([data], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, `Reservation.xlsx`);
  } catch (e) {
    console.log(e);
    yield put({
      type: EXPORT_RESERVATIONS_FAIL,
      message: e.message,
    });
    if (action.payload.cb) {
      action.payload.cb(e.message, null);
    }
  }
}

function* handleStatusUpdate(action) {
  try {
    const { id } = action.payload;

    const { data } = yield call(Reservations.updateStatus, id);

    yield put({
      type: UPDATE_STATUS_RESERVATIONS_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: UPDATE_STATUS_RESERVATIONS_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleGetAccommodations(action) {
  try {
    const { key } = action.payload;

    const { data } = yield call(Reservations.getAccommodations, key);

    yield put({
      type: HANDLE_GET_ACCOMMODATIONS_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: HANDLE_GET_ACCOMMODATIONS_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}

function* handleGetAccommodationRooms(action) {
  try {
    const { id, key } = action.payload;

    const { data } = yield call(Reservations.getAccommodationRooms, id, key);

    yield put({
      type: HANDLE_GET_ACCOMMODATION_ROOMS_SUCCESS,
      payload: {
        data,
      },
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: HANDLE_GET_ACCOMMODATION_ROOMS_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}
function* handleGetRoomReservation(action) {
  try {
    const { id, key } = action.payload;

    const { data } = yield call(Reservations.getRoomReservations, id, key);

    yield put({
      type: HANDLE_GET_ROOM_RESERVATIONS_SUCCESS,
      payload: data,
    });

    if (action.payload.cb) {
      action.payload.cb(null, data);
    }
  } catch (e) {
    yield put({
      type: HANDLE_GET_ROOM_RESERVATIONS_FAIL,
      message: e.message,
      payload: e.response.data,
    });
    if (action.payload.cb) {
      action.payload.cb(e.response.data, null);
    }
  }
}
