import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { getAllPickUpPointsAction } from "../../store/actions/pickUpPoints";
import Select from "react-select";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createTheme, TextField, ThemeProvider } from "@mui/material";
import dayjs from "dayjs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getAllDirectionsAction } from "../../store/actions/directions";
import { FiFilter } from "react-icons/fi";
import { getSelectTransfers } from "../../store/actions/toursTransfers";
import { getAllCurrency } from "../../store/actions/currency";
import {
  blockPageAction,
  getAllSalesAgentAction,
} from "../../store/actions/users";
import { Controller, useForm } from "react-hook-form";
import { TrimmedData } from "../../services/TrimObjectFields";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

function TransferVouchersForm({ submit, footer, formData, historyArr = [] }) {
  const allCurrency = useSelector((store) => store.currency.allCurrency);
  const allSalesAgents = useSelector((store) => store.users?.allSalesAgents);
  const allTransfers = useSelector(
    (store) => store.toursTransfers.selectTransfers
  );
  const [inputDisabled, setInputDisabled] = useState(false);
  const [bookingInfo, setBookingInfo] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const account = useSelector((store) => store.users.account);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [showAll, setShowAll] = useState(false);

  const {
    reset,
    watch,
    control,
    register,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted },
  } = useForm({
    defaultValues: {
      adult_members: 1,
      pre_paid_package: true,
    },
  });
  const paymentAmount = watch("payment_amount");
  const netPrice = formData?.transfer?.net_price;

  useEffect(() => {
    dispatch(getAllPickUpPointsAction());
    dispatch(getAllDirectionsAction());
    dispatch(getAllCurrency());
    dispatch(getAllSalesAgentAction());
  }, []);

  useEffect(() => {
    if (id && formData) {
      reset(formData);
      if (formData?.transfer?.service_date) {
        handleGetTransfers(formData?.transfer?.service_date);
      }
    }
  }, [formData]);

  useEffect(() => {
    if (id && formData) {
      if (isDirty) {
        setIsFormDisabled(false);
        dispatch(blockPageAction(`/transfer_vouchers/${id}`));
      } else {
        setIsFormDisabled(true);
        dispatch(blockPageAction(""));
      }
    }
  }, [isDirty]);

  useEffect(() => {
    if (account?.role === "Sales Agent") {
      setInputDisabled(true);
    }
  }, [account]);

  useEffect(() => {
    if (formData && formData.bokun_info) {
      let bookingInfoStr = formData?.bokun_info;
      let parts = bookingInfoStr && bookingInfoStr.split(";");
      let joinedString = parts && parts.join("\n");
      let formattedString = joinedString.includes("undefined" || undefined)
        ? joinedString.replaceAll(/undefined/g, "No data")
        : joinedString;
      setBookingInfo(formattedString);
    }
  }, [formData]);

  useEffect(() => {
    if (paymentAmount && netPrice && +paymentAmount < +netPrice) {
      setError("payment_amount", {
        type: "custom",
        message: "Payment Amount is less than Net Price",
      });
    } else {
      clearErrors("payment_amount");
    }
  }, [paymentAmount, netPrice, setError, clearErrors]);

  const onSubmit = (data) => {
    if (data?.payment_amount && netPrice && +data?.payment_amount < +netPrice) {
      setError("payment_amount", {
        type: "custom",
        message: "Payment Amount is less than Net Price",
      });
      return;
    }
    if (data.adult_members == 0) {
      data.adult_members = 1;
    }
    const trimmedData = TrimmedData(data);
    submit(trimmedData);
  };

  const handleGetTransfers = (service_date) => {
    dispatch(getSelectTransfers(service_date));
  };

  const getCustomNotRequiredStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;
    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#198754" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  const getCustomStyles = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;

    return {
      control: (styles) => ({
        ...styles,
        borderColor: isInvalid ? "#dc3545" : isValid ? "#198754" : "#ced4da",
      }),
      option: (styles) => ({
        ...styles,
        ":active": {
          ...styles[":active"],
          backgroundColor: `var(--primary_light)`,
        },
      }),
    };
  };

  const getCustomStylesForDate = (field) => {
    const isInvalid = errors[field];
    const isValid = isSubmitted && !isInvalid;

    return createTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: isValid ? "#198754" : "#ced4da",
            },
          },
        },
      },
    });
  };
  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };

  const toggleShowAll = (ev) => {
    ev.stopPropagation();
    setShowAll(!showAll);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form-block">
          <Modal.Body>
            <Row>
              <Col sm={6} md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Agency Reference Number</Form.Label>
                  <Form.Control
                    id="transfer_voucher_ag_ref_number"
                    name="ag_ref_number"
                    value={formData?.ag_ref_number || ""}
                    disabled={true}
                    {...register("ag_ref_number")}
                  />
                </Form.Group>
              </Col>
              <Col sm={6} md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Reference Number
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="transfer_voucher_reference_number"
                    name="reference_number"
                    placeholder={"Allowed: a-zA-Z0-9-_"}
                    maxLength={254}
                    disabled={inputDisabled}
                    isInvalid={isSubmitted && !!errors.reference_number}
                    isValid={isSubmitted && !errors.reference_number}
                    {...register("reference_number", {
                      required: "Required Field",
                      pattern: {
                        value: /^(?!\s*$)[a-zA-Z0-9-_]+$/,
                        message: "Invalid Format",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.reference_number
                      ? errors.reference_number.message
                      : "Required Field"}
                  </Form.Control.Feedback>
                  <div>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Check
                        type="checkbox"
                        disabled={inputDisabled}
                        name="show_reference_number"
                        id={"transfer_voucher_show_on_pdf"}
                        checked={watch("show_reference_number")}
                        data-checked={
                          watch("show_reference_number") ? "true" : "false"
                        }
                        {...register("show_reference_number")}
                      />
                      <Form.Check.Label
                        htmlFor="transfer_voucher_show_on_pdf"
                        className="p-0 px-1"
                        style={{
                          userSelect: "none",
                          cursor: inputDisabled ? "default" : "pointer",
                        }}
                      >
                        Show on PDF
                      </Form.Check.Label>
                    </Form.Group>
                  </div>
                </Form.Group>
              </Col>
              <Col sm={6} md={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Passenger Name
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    id="transfer_voucher_passenger_name"
                    name="passenger_name"
                    maxLength={254}
                    disabled={inputDisabled}
                    isInvalid={isSubmitted && !!errors.passenger_name}
                    isValid={isSubmitted && !errors.passenger_name}
                    {...register("passenger_name", {
                      required: "Required Field",
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passenger_name
                      ? errors.passenger_name.message
                      : "Required Field"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={3}>
                <Form.Label>Reservation Date</Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={"transfer_voucher_reservation_date"}>
                    <ThemeProvider
                      theme={getCustomStylesForDate("reservation_date")}
                    >
                      <DesktopDatePicker
                        name={"reservation_date"}
                        className="month_year_picker"
                        views={["year", "month", "day"]}
                        value={
                          watch("reservation_date") &&
                          dayjs(watch("reservation_date"))
                        }
                        disabled={inputDisabled}
                        minDate={dayjs()}
                        onChange={(newValue) => {
                          if (newValue) {
                            setValue(
                              "reservation_date",
                              dayjs(newValue).format("YYYY-MM-DD"),
                              { shouldDirty: true }
                            );
                          } else {
                            setValue("reservation_date", null, {
                              shouldDirty: true,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={watch("send") === "true"}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Sales Agent
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Controller
                    control={control}
                    name="sales_agent_id"
                    rules={{
                      required: "Required Field",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="transfer_voucher_sales_agent_id"
                        {...register("sales_agent_id")}
                        isDisabled={inputDisabled}
                        styles={
                          errors.sales_agent_id && !watch("sales_agent_id")
                            ? getCustomStyles("sales_agent_id")
                            : getCustomNotRequiredStyles("sales_agent_id")
                        }
                        options={allSalesAgents}
                        getOptionLabel={(i) => i.full_name}
                        getOptionValue={(i) => i.id}
                        isClearable={true}
                        onChange={(ev) => {
                          setValue("sales_agent_id", ev ? ev.id : null, {
                            shouldDirty: true,
                          });
                        }}
                        value={
                          watch("sales_agent_id") &&
                          Array.isArray(allSalesAgents)
                            ? allSalesAgents?.find(
                                (i) => i.id === watch("sales_agent_id")
                              )
                            : {}
                        }
                      />
                    )}
                  />
                  {errors.sales_agent_id && !watch("sales_agent_id") ? (
                    <small style={{ color: "#dc3545" }}>
                      {errors?.sales_agent_id?.message}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Passport Details</Form.Label>
                  <Form.Control
                    type="text"
                    id="transfer_voucher_passenger_passport_details"
                    name="passenger_passport_details"
                    maxLength={254}
                    disabled={inputDisabled}
                    isInvalid={
                      isSubmitted && !!errors.passenger_passport_details
                    }
                    isValid={isSubmitted && !errors.passenger_passport_details}
                    {...register("passenger_passport_details", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passenger_passport_details &&
                      errors.passenger_passport_details.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Passenger Email</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={254}
                    name="passenger_email"
                    disabled={inputDisabled}
                    id="transfer_voucher_passenger_email"
                    isValid={isSubmitted && !errors.passenger_email}
                    isInvalid={isSubmitted && !!errors.passenger_email}
                    {...register("passenger_email", {
                      pattern: {
                        value:
                          /^(?!\s*$)[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                        message: "Invalid Format",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passenger_email && errors.passenger_email.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={6} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Passenger Phone</Form.Label>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        {...field}
                        country={"gr"}
                        disabled={inputDisabled}
                        buttonStyle={
                          isSubmitted ? { borderColor: "#198754" } : null
                        }
                        inputStyle={
                          isSubmitted ? { borderColor: "#198754" } : null
                        }
                        inputProps={{
                          name: "phone",
                          id: "transfer_voucher_phone",
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={4} lg={3}>
                <Form.Group className="mb-2">
                  <Form.Label>Pick Up Point</Form.Label>
                  <Form.Control
                    id={"transfer_voucher_pick_up_address"}
                    name="pick_up_address"
                    type="text"
                    maxLength={254}
                    disabled={inputDisabled}
                    isInvalid={isSubmitted && !!errors.pick_up_address}
                    isValid={isSubmitted && !errors.pick_up_address}
                    {...register("pick_up_address", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pick_up_address?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                {/*<div>*/}
                {/*    <Form.Group className="d-flex align-items-center">*/}
                {/*        <Form.Check*/}
                {/*            type="checkbox"*/}
                {/*            id={'show_pick_up_point'}*/}
                {/*            checked={formData?.show_pick_up_point}*/}
                {/*            onChange={ev =>*/}
                {/*                handleChange(*/}
                {/*                    'show_pick_up_point',*/}
                {/*                    ev.target.checked,*/}
                {/*                )*/}
                {/*            }*/}
                {/*        />*/}
                {/*        <Form.Check.Label*/}
                {/*            htmlFor="show_pick_up_point"*/}
                {/*            className="p-0 px-1"*/}
                {/*            style={{cursor: 'pointer'}}*/}
                {/*        >*/}
                {/*            Show Pickup Point*/}
                {/*        </Form.Check.Label>*/}
                {/*    </Form.Group>*/}
                {/*</div>*/}
              </Col>
              <Col sm={6} md={6} lg={2}>
                <Form.Group className={!isSubmitted ? "mb-3" : null}>
                  <Form.Label>Pick Up Time</Form.Label>
                  <Form.Control
                    id="transfer_voucher_pick_up_time"
                    name="pick_up_time"
                    type="time"
                    disabled={inputDisabled}
                    {...register("pick_up_time")}
                    style={getCustomStylesForInput("pick_up_time")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.pick_up_time && "Invalid time"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4} md={4} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Flight Time</Form.Label>
                  <Form.Control
                    id="transfer_voucher_flight_time"
                    name="flight_time"
                    type="time"
                    disabled={inputDisabled}
                    {...register("flight_time")}
                    style={getCustomStylesForInput("flight_time")}
                  />
                </Form.Group>
              </Col>
              <Col sm={4} md={4} lg={2}>
                <Form.Group className="mb-3">
                  <Form.Label>Flight No</Form.Label>
                  <Form.Control
                    id="transfer_voucher_flight_number"
                    name="flight_number"
                    type="text"
                    disabled={inputDisabled}
                    maxLength={254}
                    isInvalid={isSubmitted && !!errors.flight_number}
                    isValid={isSubmitted && !errors.flight_number}
                    {...register("flight_number", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.flight_number?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={4} md={4} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Original Point Of Rental</Form.Label>
                  <Form.Control
                    id="transfer_voucher_original_point_of_rental"
                    name="original_point_of_rental"
                    type="text"
                    disabled={inputDisabled}
                    maxLength={254}
                    isInvalid={isSubmitted && !!errors.original_point_of_rental}
                    isValid={isSubmitted && !errors.original_point_of_rental}
                    {...register("original_point_of_rental", {
                      pattern: {
                        value: /^(?!\s*$).+/,
                        message: "Only spaces are not allowed",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.original_point_of_rental?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4} lg={3}>
                <Form.Group className="mb-2">
                  <Form.Label>Payment Amount (€)</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={50}
                    name="payment_amount"
                    disabled={inputDisabled}
                    id="transfer_voucher_payment_amount"
                    {...register("payment_amount")}
                    isInvalid={!!errors.payment_amount}
                    isValid={isSubmitted && !errors.payment_amount}
                    onInput={(e) => {
                      let inputValue = e.target.value;
                      inputValue = inputValue.replace(/[^0-9.]/g, "");

                      const dotCount = (inputValue.match(/\./g) || []).length;
                      if (dotCount > 1) {
                        inputValue = inputValue.substring(
                          0,
                          inputValue.lastIndexOf(".") + 1
                        );
                      }
                      if (inputValue.startsWith(".")) {
                        inputValue = "0" + inputValue;
                      }
                      if (inputValue.includes(".")) {
                        let [integerPart, decimalPart] = inputValue.split(".");
                        decimalPart = decimalPart.substring(0, 2);
                        if (integerPart && integerPart.length > 8) {
                          integerPart = integerPart.substring(0, 8);
                        }
                        inputValue = `${integerPart}.${decimalPart}`;
                      }
                      if (
                        inputValue.startsWith("0") &&
                        inputValue.length > 1 &&
                        inputValue[1] !== "."
                      ) {
                        inputValue = inputValue.substring(1);
                      }
                      if (inputValue.length > 8) {
                        if (!inputValue.includes(".")) {
                          inputValue = inputValue.substring(0, 8) + ".00";
                        } else {
                          let [integerPart, decimalPart] =
                            inputValue.split(".");
                          decimalPart = decimalPart.substring(0, 2);
                          inputValue = `${integerPart}.${decimalPart}`;
                        }
                      }
                      setValue("payment_amount", inputValue, {
                        shouldDirty: true,
                      });
                    }}
                  />
                  {errors?.payment_amount?.message ? (
                    <span className="required-message">
                      {errors.payment_amount.message}
                    </span>
                  ) : null}
                </Form.Group>
                <div>
                  <Form.Group className="d-flex align-items-center">
                    <Form.Check
                      type="checkbox"
                      disabled={inputDisabled}
                      name={"pre_paid_package"}
                      checked={watch("pre_paid_package")}
                      id={"transfer_voucher_pre_paid_package"}
                      {...register("pre_paid_package")}
                      data-checked={
                        watch("pre_paid_package") ? "true" : "false"
                      }
                    />
                    <Form.Check.Label
                      htmlFor="transfer_voucher_pre_paid_package"
                      className="p-0 px-1"
                      style={{
                        userSelect: "none",
                        cursor: inputDisabled ? "default" : "pointer",
                      }}
                    >
                      Pre Paid Package
                    </Form.Check.Label>
                  </Form.Group>
                </div>
              </Col>
              {/*<Col sm={6} md={4} lg={3}>*/}
              {/*  <Form.Group className="mb-3">*/}
              {/*    <Form.Label>*/}
              {/*      Currency*/}
              {/*      {watch("payment_amount") && !watch("currency_id") && (*/}
              {/*        <span style={{ color: "red" }}> *</span>*/}
              {/*      )}*/}
              {/*    </Form.Label>*/}
              {/*    <Select*/}
              {/*      name="currency_id"*/}
              {/*      // id="transfer_voucher_currency"*/}
              {/*      id="currency_id"*/}
              {/*      styles={*/}
              {/*        errors.currency_id && !watch("currency_id")*/}
              {/*          ? getCustomStyles("currency_id")*/}
              {/*          : getCustomNotRequiredStyles("currency_id")*/}
              {/*      }*/}
              {/*      options={allCurrency}*/}
              {/*      getOptionLabel={(i) =>*/}
              {/*        `${i.currency_name}(${i.currency_symbol})`*/}
              {/*      }*/}
              {/*      {...register("currency_id", {*/}
              {/*        required:*/}
              {/*          watch("payment_amount") &&*/}
              {/*          !watch("currency_id") &&*/}
              {/*          "Required Field",*/}
              {/*      })}*/}
              {/*      getOptionValue={(i) => i.id}*/}
              {/*      // value={*/}
              {/*      //   watch("currency_id") && Array.isArray(allCurrency)*/}
              {/*      //     ? allCurrency.find((i) => i.id === watch("currency_id"))*/}
              {/*      //     : []*/}
              {/*      // }*/}
              {/*      isClearable={false}*/}
              {/*      isDisabled={true}*/}
              {/*      value={allCurrency.find((i) => i.id === 2) || null}*/}
              {/*      onChange={(ev) => {*/}
              {/*        setValue("currency_id", ev ? ev.id : null, {*/}
              {/*          shouldDirty: true,*/}
              {/*        });*/}
              {/*      }}*/}
              {/*    />*/}
              {/*    {errors.currency_id && !watch("currency_id") && (*/}
              {/*      <div className="required-message">*/}
              {/*        {errors.currency_id.message}*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*  </Form.Group>*/}
              {/*</Col>*/}
              <Col xs={4} sm={4} md={4} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Adults</Form.Label>
                  <Form.Control
                    min="1"
                    type="number"
                    maxLength={254}
                    name="adult_members"
                    value={watch("adult_members")}
                    id="transfer_voucher_adult_members"
                    disabled={inputDisabled}
                    {...register("adult_members")}
                    style={getCustomStylesForInput("adult_members")}
                    onChange={(e) => {
                      const value = e.target.value.replace(/^0+/, "");
                      setValue("adult_members", value, { shouldDirty: true });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.adult_members && "Invalid Format"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={4} sm={4} md={4} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Children</Form.Label>
                  <Form.Control
                    id="transfer_voucher_passenger_child"
                    name="child_members"
                    maxLength={254}
                    type="number"
                    min="0"
                    disabled={inputDisabled}
                    {...register("child_members")}
                    style={getCustomStylesForInput("child_members")}
                    onChange={(e) => {
                      const value = e.target.value.replace(/^0+/, "");
                      setValue("child_members", value, { shouldDirty: true });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.child && "Invalid Format"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={4} sm={4} md={4} lg={3}>
                <Form.Group className="mb-3">
                  <Form.Label>Infants</Form.Label>
                  <Form.Control
                    id="transfer_voucher_infant"
                    name="infant_members"
                    disabled={inputDisabled}
                    maxLength={254}
                    type="number"
                    min="0"
                    {...register("infant_members")}
                    style={getCustomStylesForInput("infant_members")}
                    onChange={(e) => {
                      const value = e.target.value.replace(/^0+/, "");
                      setValue("infant_members", value, { shouldDirty: true });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.infant && "Invalid Format"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex align-items-center justify-content-between">
                  <p className={id ? "select_preview_tour" : "select__tour"}>
                    Select Transfer
                  </p>
                  <div className="select__tour__line" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Label className="label-m-1r">
                  Service Date <FiFilter size={16} />{" "}
                </Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} id={"transfer_voucher_service_date"}>
                    <ThemeProvider
                      theme={getCustomStylesForDate("service_date")}
                    >
                      <DesktopDatePicker
                        className="month_year_picker"
                        name="service_date"
                        views={["year", "month", "day"]}
                        value={
                          watch("service_date") && dayjs(watch("service_date"))
                        }
                        onChange={(newValue) => {
                          if (newValue) {
                            handleGetTransfers(
                              dayjs(newValue).format("YYYY-MM-DD")
                            );
                            setValue(
                              "service_date",
                              dayjs(newValue).format("YYYY-MM-DD"),
                              { shouldDirty: true }
                            );
                            setValue("transfer_id", null, {
                              shouldDirty: true,
                            });
                          } else {
                            setValue("service_date", null, {
                              shouldDirty: true,
                            });
                            setValue("transfer_id", null, {
                              shouldDirty: true,
                            });
                          }
                        }}
                        disabled={inputDisabled}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled={formData.send === "true"}
                          />
                        )}
                      />
                    </ThemeProvider>
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Transfer Name</Form.Label>
                  <Select
                    id={"transfer_voucher_transfer_name"}
                    name={"transfer_name"}
                    value={
                      watch("transfer_id") && Array.isArray(allTransfers)
                        ? allTransfers.find(
                            (i) => i.id === watch("transfer_id")
                          )
                        : {}
                    }
                    styles={getCustomStyles("transfer_name")}
                    isDisabled={
                      !watch("service_date") ||
                      watch("service_date") === "Invalid Date" ||
                      inputDisabled
                    }
                    isClearable={true}
                    options={allTransfers}
                    getOptionValue={(i) => i.id}
                    getOptionLabel={(i) => i.transfer_name}
                    onChange={(ev) => {
                      setValue("transfer_id", ev ? ev.id : null, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {id && bookingInfo ? (
              <>
                <h5>
                  <strong>Original Booking Info</strong>
                </h5>
                <div className="info_div">
                  <pre>{bookingInfo}</pre>
                </div>
              </>
            ) : null}
            {id && !isEmpty(historyArr) && historyArr.length > 0 ? (
              <>
                <h5>
                  <strong>Booking History</strong>
                </h5>
                <div className="history_info_div">
                  {historyArr &&
                    historyArr
                      ?.slice(0, showAll ? historyArr.length : 1)
                      ?.map((i, index) => {
                        let modifiedHistory = i.replace(
                          /null|undefined|:\s*,|:\s*$/g,
                          "N/A"
                        );
                        return (
                          <>
                            <p
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: modifiedHistory.replace(/;/g, "<br/>"),
                              }}
                            ></p>
                          </>
                        );
                      })}

                  {!isEmpty(historyArr) && historyArr?.length > 1 ? (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={(ev) => toggleShowAll(ev)}
                    >
                      {showAll && historyArr?.length > 0 ? (
                        <strong style={{ fontSize: "13px" }}>
                          Show Less <MdOutlineKeyboardArrowUp />
                        </strong>
                      ) : (
                        <strong style={{ fontSize: "13px" }}>
                          Show More <MdOutlineKeyboardArrowDown />
                        </strong>
                      )}
                    </span>
                  ) : null}
                </div>
              </>
            ) : null}
          </Modal.Body>
        </div>
        <Modal.Footer>
          {!isEmpty(account) &&
            account.role !== "Sales Agent" &&
            footer &&
            React.cloneElement(footer, { disabled: isFormDisabled })}
        </Modal.Footer>
      </Form>
    </>
  );
}

export default TransferVouchersForm;
