import {
  EDIT_EMAIL_SETUP_REQUEST,
  EDIT_EMAIL_SETUP_SUCCESS,
  GET_EMAIL_SETUPS_LIST_REQUEST,
  GET_EMAIL_SETUPS_LIST_SUCCESS,
  GET_SINGLE_EMAIL_SETUP_REQUEST,
  GET_SINGLE_EMAIL_SETUP_SUCCESS,
  GET_ALL_EMAIL_SETUP_REQUEST,
  GET_ALL_EMAIL_SETUP_SUCCESS,
  ADD_EMAIL_SETUP_REQUEST,
  ADD_EMAIL_SETUP_SUCCESS,
  ADD_EMAIL_SETUP_FAIL,
  EDIT_EMAIL_SETUP_FAIL,
  DELETE_EMAIL_SETUP_REQUEST,
  DELETE_EMAIL_SETUP_SUCCESS,
  DELETE_EMAIL_SETUP_FAIL,
} from '../actions/emailSetups';

const initialState = {
  emailSetups: [],
  singleEmailSetup: {},
  status: '',
  actionStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_EMAIL_SETUPS_LIST_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_EMAIL_SETUPS_LIST_SUCCESS: {
      return {
        ...state,
        emailSetups: action.payload.data.emailSetupsList,
        status: 'success',
        count: action.payload.data.count,
      };
    }
    case GET_SINGLE_EMAIL_SETUP_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_SINGLE_EMAIL_SETUP_SUCCESS: {
      return {
        ...state,
        singleEmailSetup: action.payload.data?.emailSetup,
        status: 'success',
      };
    }
    case EDIT_EMAIL_SETUP_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case EDIT_EMAIL_SETUP_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case EDIT_EMAIL_SETUP_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }
    case GET_ALL_EMAIL_SETUP_REQUEST: {
      return {
        ...state,
        status: 'pending',
      };
    }
    case GET_ALL_EMAIL_SETUP_SUCCESS: {
      return {
        ...state,
        emailSetup: Array.isArray(action.payload.data.result)
          ? action.payload.data.result[0] || {}
          : {},
        hasEmailSetup:
          Array.isArray(action.payload.data.result) &&
          !!action.payload.data.result.length,
        status: 'success',
      };
    }

    case ADD_EMAIL_SETUP_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case ADD_EMAIL_SETUP_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case ADD_EMAIL_SETUP_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    case DELETE_EMAIL_SETUP_REQUEST: {
      return {
        ...state,
        actionStatus: 'pending',
      };
    }
    case DELETE_EMAIL_SETUP_SUCCESS: {
      return {
        ...state,
        actionStatus: 'success',
      };
    }
    case DELETE_EMAIL_SETUP_FAIL: {
      return {
        ...state,
        actionStatus: 'fail',
      };
    }

    default: {
      return state;
    }
  }
}
