import React from 'react';
import { useSelector } from 'react-redux';

function Page404(props) {
  const account = useSelector(store => store.users.account);
  return (
    <div className="admin_tables_block page_loger">
      <div className="homeWelcome">
        <div className="pageNotFound">
          <h1 className="statusCode">404</h1>
          <h5 className="notFound">Page Not Found!</h5>
          <h6>
            Sorry, the page you're trying to access does not exist or was moved.
          </h6>
        </div>
      </div>
    </div>
  );
}

export default Page404;
