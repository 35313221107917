import { takeLatest, call, put } from 'redux-saga/effects';
import {
  GET_ALL_CURRENCY_FAIL,
  GET_ALL_CURRENCY_REQUEST,
  GET_ALL_CURRENCY_SUCCESS,
} from '../actions/currency';
import Currency from '../../api/currency';

export default function* watcher() {
  yield takeLatest(GET_ALL_CURRENCY_REQUEST, handleGetAllCurrency);
}

function* handleGetAllCurrency(action) {
  try {
    const { data } = yield call(Currency.getAllCurrency);
    yield put({
      type: GET_ALL_CURRENCY_SUCCESS,
      payload: {
        data: data,
      },
    });
  } catch (e) {
    yield put({
      type: GET_ALL_CURRENCY_FAIL,
      payload: {
        massage: e.massage,
      },
    });
  }
}
