import { api } from "../Api";

export default class Advertisements {
  static addAdvertisement(formData) {
    return api.post(`/ads`, {
      ...formData,
    });
  }

  static updateAdvertisement(formData) {
    return api.put(`/ads/${formData?.id}`, {
      ...formData,
    });
  }

  static getRoomReservations(
    page,
    limit,
    search,
    filter,
    [{ sort, field }],
    params
  ) {
    const [key] = Object.keys(params);
    return api.get(`/admin/web/room/reservations/${key}/${params[key]}`, {
      params: {
        search,
        filter,
        page,
        limit,
        sort,
        field,
      },
    });
  }
}
