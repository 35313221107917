import { Button, Col, Form, Modal, Row, InputGroup } from "react-bootstrap";
import React, { useEffect, useRef } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { MdOutlineClear } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { yupResolver } from "@hookform/resolvers/yup";
import { supplierValidation } from "./validationSchema";
import { handleUpdateMyProfile } from "../../../store/actions/users";
import { LoadingButton } from "@mui/lab";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Riple } from "react-loading-indicators";

function SuppliersForm({ single, actionStatus, getMyAccountData }) {
  const [pass, setPass] = React.useState({
    old: true,
    new: true,
    new_confirm: true,
  });
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid, isDirty, isSubmitted },
    setError,
    reset,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(supplierValidation),
    defaultValues: { ...single, user_name: single.email } || {},
  });

  const handlePassVis = (key) => {
    if (pass[key] === true) {
      setPass({ ...pass, [key]: false });
    } else {
      setPass({ ...pass, [key]: true });
    }
  };

  useEffect(() => {
    if (single) {
      for (const [key, value] of Object.entries(single)) {
        setValue(key, value);
      }
    }
  }, [single, setValue]);

  const handlePhotoChange = (key, val) => {
    if (val && val.type.startsWith("image/") && val.type !== "image/svg+xml") {
      setValue(key, val.name, { shouldDirty: true });
      setValue(`${key}_file`, val, { shouldDirty: true });
      clearErrors(key);
      trigger(key);
    } else if (val && val.type === "image/svg+xml") {
      setError(key, {
        type: "svg_support",
        message: "SVG files are not supported.",
      });
    }
  };

  const handleRemoveImg = () => {
    setValue("supplier_logo", null, { shouldDirty: true });
    setValue("supplier_logo_file", null, { shouldDirty: true });
  };

  const onEdit = (data) => {
    if (data.password && data.confirm_password && !data.old_password) {
      setError("old_password", { type: "custom", message: "Required Field" });
      return;
    }
    if (
      data.user_name === null ||
      data.user_name === undefined ||
      (!data.user_name && data.email)
    ) {
      data.user_name = data.email;
    }
    dispatch(
      handleUpdateMyProfile(data, (err, data) => {
        if (err) {
          toast.error(err.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
          });
        } else {
          if (data && data?.status === 200) {
            toast.success("Successfully updated.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
            });
            window.location.reload();
            setValue("old_password", "");
            setValue("password", "");
            setValue("confirm_password", "");
          }
        }
      })
    );
  };
  const formData = watch();

  const handleReset = () => {
    reset(single);
    getMyAccountData();
  };

  const getCustomStylesForInput = (field) => {
    const isInvalid = errors[field];
    return {
      borderColor: isSubmitted
        ? isInvalid
          ? "#dc3545"
          : "#198754"
        : "#ced4da",
    };
  };

  return (
    <>
      {actionStatus === "pending" ? (
        <div className="dashboard-empty-div">
          <Riple color="#134074" size="large" text="" textColor="" />
        </div>
      ) : (
        <FormProvider
          {...{
            control,
            register,
            handleSubmit,
            setValue,
            watch,
            formState: { errors, isValid },
          }}
        >
          <Form onSubmit={handleSubmit(onEdit)} noValidate>
            <Modal.Body>
              <div>
                <h5 style={{ fontSize: 17 }}>Personal Information</h5>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Full Name<span style={{ color: "red" }}> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        minLength={2}
                        maxLength={254}
                        name="full_name"
                        isInvalid={isSubmitted && !!errors.full_name}
                        isValid={isSubmitted && !errors.full_name}
                        {...register("full_name")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors?.full_name?.message || "Required Field"}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        User Name
                        {/*<span style={{ color: "red" }}> *</span>*/}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        disabled={true}
                        value={single?.user_name}
                        // isInvalid={errors.user_name}
                        //{...register("user_name")}//
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.user_name?.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label>
                      Email<span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          type="email"
                          {...field}
                          isInvalid={isSubmitted && !!errors.email}
                          isValid={isSubmitted && !errors.email}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email?.message}
                    </Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Label>
                      Phone Number<span style={{ color: "red" }}> *</span>
                    </Form.Label>
                    <PhoneInput
                      inputProps={{
                        name: "phone_number",
                      }}
                      buttonStyle={
                        isSubmitted && errors.phone_number
                          ? { borderColor: "#dc3545" }
                          : isSubmitted && watch("phone_number")
                          ? { borderColor: "#198754" }
                          : { borderColor: "#ced4da" }
                      }
                      inputStyle={
                        isSubmitted && errors.phone_number
                          ? { borderColor: "#dc3545" }
                          : isSubmitted && watch("phone_number")
                          ? { borderColor: "#198754" }
                          : { borderColor: "#ced4da" }
                      }
                      value={watch("phone_number") || ""}
                      onChange={(value) => {
                        setValue("phone_number", value, { shouldDirty: true });
                      }}
                    />
                    {errors.phone_number?.message && (
                      <span className="invalid_message">
                        {errors.phone_number?.message}
                      </span>
                    )}
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>Supplier Logo</Form.Label>
                      <div className="file-input file-upload">
                        <label htmlFor="supplier_logo">
                          <input
                            className="choose-btn"
                            type="button"
                            value="Choose"
                            onClick={() => fileInputRef.current.click()}
                          />
                        </label>
                        <input
                          className="border-none"
                          type="text"
                          value={formData.supplier_logo || ""}
                          readOnly
                          style={getCustomStylesForInput("supplier_logo")}
                        />
                        <input
                          type="file"
                          id="supplier_logo"
                          accept=".jpg, .png, image/jpeg, image/png"
                          ref={fileInputRef}
                          onChange={(e) =>
                            handlePhotoChange(
                              "supplier_logo",
                              e.target.files[0]
                            )
                          }
                        />
                        {formData.supplier_logo && (
                          <MdOutlineClear
                            className="remove_img"
                            onClick={handleRemoveImg}
                          />
                        )}
                      </div>
                      {errors?.supplier_logo &&
                      errors?.supplier_logo.type === "svg_support" ? (
                        <p className="custom-invalid-feedback">
                          {errors?.supplier_logo?.message}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      {...register("description")}
                      maxLength={4000}
                      value={watch("description") || ""}
                      isInvalid={isSubmitted && !!errors.description}
                      isValid={isSubmitted && !errors.description}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="required_message">
                        {isSubmitted && errors.description?.message}
                      </span>
                      <div className="max_style_description">
                        Max length of description is 4000 characters
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div>
                <h5 style={{ fontSize: 17 }}>Change Password</h5>
                <Row>
                  <Col sm={4}>
                    <Form.Group className="mb-3 pass_input">
                      <Form.Group className="mb-3">
                        <Form.Label>Current Password</Form.Label>
                        <InputGroup className="input_block">
                          <Form.Control
                            type={pass.old ? "password" : "text"}
                            autoComplete="off"
                            name="old_password"
                            value={watch("old_password") || ""}
                            {...register("old_password")}
                            className={errors?.old_password && "is-invalid"}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            onClick={() => handlePassVis("old")}
                          >
                            {pass.old ? (
                              <FiEyeOff cursor="pointer" />
                            ) : (
                              <FiEye cursor="pointer" />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <span className="custom-invalid-feedback">
                          {errors.old_password?.message}
                        </span>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3 pass_input">
                      <Form.Group className="mb-3">
                        <Form.Label>New Password</Form.Label>
                        <InputGroup className="input_block">
                          <Form.Control
                            type={pass.new ? "password" : "text"}
                            name="password"
                            className={errors?.password && "is-invalid"}
                            autoComplete="off"
                            value={watch("password") || ""}
                            {...register("password")}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            onClick={() => handlePassVis("new")}
                          >
                            {pass.new ? (
                              <FiEyeOff cursor="pointer" />
                            ) : (
                              <FiEye cursor="pointer" />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <span className="custom-invalid-feedback">
                          {errors.password?.message}
                        </span>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3 pass_input">
                      <Form.Group className="mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup className="input_block">
                          <Form.Control
                            type={pass.new_confirm ? "password" : "text"}
                            name="confirm_password"
                            className={errors?.confirm_password && "is-invalid"}
                            autoComplete="off"
                            value={watch("confirm_password") || ""}
                            {...register("confirm_password")}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            onClick={() => handlePassVis("new_confirm")}
                          >
                            {pass.new_confirm ? (
                              <FiEyeOff cursor="pointer" />
                            ) : (
                              <FiEye cursor="pointer" />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        <span className="custom-invalid-feedback">
                          {errors.confirm_password?.message}
                        </span>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="previewButtonsID">
                <Button
                  variant={!isDirty ? "secondary" : "primary"}
                  onClick={handleReset}
                  disabled={!isDirty}
                >
                  Reset
                </Button>
                <input
                  value="Save"
                  type="submit"
                  disabled={!isDirty}
                  className={`submit_button btn ${
                    !isDirty ? "btn-secondary" : "btn-primary"
                  }`}
                />
              </div>
            </Modal.Footer>
          </Form>
        </FormProvider>
      )}
    </>
  );
}

export default SuppliersForm;
