import { AgChartsReact } from "ag-charts-react";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { isEmpty, range } from "lodash";
import { Riple } from "react-loading-indicators";

function renderer({ datum, xKey, yKey }) {
  return {
    content: datum[yKey]?.toFixed(0) || 0,
  };
}

const ChartExample = ({ data = [], selectedYear, selectedMonth, status }) => {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    if (!isEmpty(data) && selectedYear && !selectedMonth) {
      const months = moment.monthsShort();
      const monthCounts = Array(12).fill(0);
      data.forEach((item) => {
        const createdMonth = moment(item.createdAt).month();
        monthCounts[createdMonth]++;
      });
      const chartData = months.map((month, index) => ({
        month: month.toUpperCase(),
        count: monthCounts[index],
      }));

      setChartData(chartData);
    } else if (!isEmpty(data) && selectedYear && selectedMonth) {
      const daysInMonth = moment([
        selectedYear,
        moment().month(selectedMonth).month(),
      ]).daysInMonth();
      const dayCounts = Array(daysInMonth).fill(0);

      data.forEach((item) => {
        const createdDate = moment(item.createdAt);
        const isSameMonth =
          createdDate.month() === moment().month(selectedMonth).month();

        if (isSameMonth) {
          const createdDay = createdDate.date();
          dayCounts[createdDay - 1]++;
        }
      });

      const chartData = dayCounts.map((count, index) => ({
        day: (index + 1).toString().padStart(2, "0"),
        count: count || null,
      }));
      setChartData(chartData);
    } else if (isEmpty(data)) {
      setChartData([]);
    }
  }, [data, selectedMonth, selectedYear]);

  const options = useMemo(
    () => ({
      // zoom: {
      //   enabled: true,
      // },
      height: 215,
      tooltip: {
        enabled: true,
        content: "hello",
      },
      axes: [
        {
          type: "number",
          position: "left",
        },
        {
          type: "string",
          position: "bottom",
          nice: true,
          label: {
            autoRotate: true,
          },
          tick: {
            minSpacing: 10,
            maxSpacing: 200,
          },
        },
      ],
      data: chartData,
      series: [
        {
          type: "bar",
          tooltip: { renderer: renderer },
          xKey: selectedMonth ? "day" : "month",
          yKey: "count",
          fill: "#134074",
        },
      ],
    }),
    [chartData, selectedMonth]
  );
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "215px" }}>
      {status === "pending" ? (
        <div className="dashboard-empty-div" style={{ height: "100%" }}>
          <Riple color="#134074" size="large" text="" textColor="" />
        </div>
      ) : (
        <AgChartsReact options={options} />
      )}
    </div>
  );
};

export default ChartExample;
